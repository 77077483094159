import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";
import moment from "moment";

export function printDocument(clientName) {

    const input = document.getElementById('divToPrint');
    $("#bg-rmove-print").removeClass('bg-light');
    $( "#header" ).append("<div class='p-2 row'><div class='col-12 d-flex justify-content-start align-item-center'> <div><img src='/static/media/logo.8a9ad6a32d9b85ae8f4a.png' width='100' alt='logo' height='100'/></div> <div class='ps-3 d-flex align-items-center'><div><h4>GIA+ Quality Assurance system</h4><hr style='border: 2px solid #4190C5;'><h5 class='mb-0' style='color:black'>Corrective Action</h5></div></div></div><div style='margin-left:116px'><table><tr><td id='clientNameID' style='min-width: 80px; over-flow:linebreak; color: #6D6D6D;'>clientNameID</td></tr></table></div><br/></div>");

    if($('#flexCheckDefaultPrint').is(":checked")){
        $("#flexCheckDefaultPrint").replaceWith(`<div>Yes</div>`);
        $("#flexCheckDefaultTextPrint").replaceWith(`<div></div>`);

    }else {
        $("#flexCheckDefaultPrint").replaceWith(`<div>No</div>`);
        $("#flexCheckDefaultTextPrint").replaceWith(`<div></div>`);
    }
    
    var textResponsiblePersion = $("#responsibleUserId").children("option:selected").text();
    $("#responsibleUserId").replaceWith(`<div>${textResponsiblePersion}</div>`);
    
    var textCorrectiveAction = $("textarea#correctiveActionsId").text();
    $("textarea#correctiveActionsId").replaceWith(`<div style='word-wrap: break-word'>${textCorrectiveAction}</div>`);
   
    $("#clientNameID").text(clientName);
    // Allocating a memery for latter rendering issue, we will remove this once we found the correct solution (Chrom update)
    html2canvas(input)
        .then((canvas) => {
            // const imgData = canvas.toDataURL('image/png');
            // const pdf = new jsPDF();
            // pdf.addImage(imgData, 'JPEG', 0, 0);
        });
        
    html2canvas(input)
        .then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF();
            pdf.addImage(imgData, 'JPEG', 0, 0);
            // pdf.output('dataurlnewwindow');
            pdf.save(`Corrective-Action${moment().format('DD-MM-YYYY-hh-mm-ss')}.pdf`);
        });
}
