import { actionType } from "../../config/commonTypes";
import {
    FETCH_MIMIC_CLIENTS_REQUEST,
    FETCH_MIMIC_CLIENTS_SUCCESS,
    FETCH_MIMIC_CLIENTS_FAILURE,
} from "../../constants";
import { clientType } from "./clientManagementReducer";


interface initialTypes {
    clients: clientType | null;
}

const initialState: initialTypes = {
    clients: null
};

export default function reducer(
    state: initialTypes = initialState,
    action: actionType
) {
    switch (action.type) {
        case FETCH_MIMIC_CLIENTS_REQUEST:
            return state;
        case FETCH_MIMIC_CLIENTS_SUCCESS:
            state.clients = action?.payload?.data;
            return { ...state };
        case FETCH_MIMIC_CLIENTS_FAILURE:
            return { ...state };
        default:
            return state;
    }
}