import React, {useEffect} from 'react';
import AuditPlannerTab from "../clientManagement/AuditPlannerTab";
import {connect, useDispatch} from "react-redux";
import {IGlobalTemplateSchedule, IGlobalTemplateType} from "../../../redux/modules/auditManagementReducer";
import {GetAuditTemplatesForPlanning, UpsertGlobalTemplateSchedule} from "../../../actions/auditActions";
import {apiResponseType} from "../../../config/commonTypes";
import {toast} from "react-toastify";
import {GLOBAL_TEMPLATE_PLANNING_SCHEDULE_UPDATED} from "../../../constants";

interface IPropsTypes {
    templateList?: IGlobalTemplateType[] | [];
    isLoading?: boolean;
}

function AuditPlannerWrapperTab({templateList = [], isLoading}: IPropsTypes) {

    const dispatch = useDispatch();

    const onChangePlan = (data: IGlobalTemplateSchedule) => {
        UpsertGlobalTemplateSchedule(data,
            (response: apiResponseType) => {
                toast.success(response?.message ? response.message : "Successfully updated");
                dispatch({
                    type: GLOBAL_TEMPLATE_PLANNING_SCHEDULE_UPDATED,
                    payload: data
                });
            },
            (response: apiResponseType) => {
                toast.error(response?.data ? response.data : "Failed");
                dispatch(GetAuditTemplatesForPlanning());
            });
    }

    useEffect(() => {
        dispatch(GetAuditTemplatesForPlanning());
    }, []);

    return (
        <AuditPlannerTab templateList={templateList}
                         onChangePlan={(data: IGlobalTemplateSchedule) => onChangePlan(data)}/>)
}

const mapStateToProps = (state: any) => {
    const auditManageMent = state?.auditManagement;
    return {
        isLoading: auditManageMent?.isLoading,
        templateList: auditManageMent?.templateListPlan,
    };
};
export default connect(mapStateToProps, null)(AuditPlannerWrapperTab);