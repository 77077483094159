import React, {useEffect, useState} from 'react';
import SelectUserPolicies from "../../../components/SelectUserPolicies";
import {clientType} from "../../../redux/modules/clientManagementReducer";
import {useFormik} from "formik";
import * as Yup from "yup";
import {customGroupPermissionsType, rolePermissionType, userType} from "../../../config/commonTypes";
import {
    AccessControlType,
    clientActionType,
    PASSWORD_REG_EEP,
    SELECT_USER_PERMISSION_TYPE,
    UserPermissionsConstant
} from "../../../constants/constants";
import {getRolesByUserIds} from "../../../actions/clientAction";
import {useDispatch} from "react-redux";
import {BootyPagination} from "../../../components/pagination/BootyPagination";
import DataTable, {TableStyles} from "react-data-table-component";
import {useLocation} from "react-router-dom";
import AccessControl from '../../../config/AccessControl';

interface propsType {
    selectedClient?: clientType;
    isLoading?: boolean;
    onCreateOrUpdateUser: (user: any, onSuccess: () => void) => void;
    permissionsData: customGroupPermissionsType[] | undefined;
    users: userType[] | undefined;
    userPermissions?: rolePermissionType[] | undefined;
    openChangePassword: (user: any) => void;
    openDeleteUser: (user: string) => void;
}
interface userTypeCreate {
    id?: string;
    userName: string;
    name: string;
    designation: string;
    email: string;
    password?: string;
    newPassword?: string;
    rolePermissions?: rolePermissionType[] | null;
    roleIds?: string[] | null;
    isActive: boolean;
}
function UserManagementTab({ selectedClient,
    isLoading,
    onCreateOrUpdateUser,
    permissionsData,
    users,
    openChangePassword,
    openDeleteUser
}: propsType) {

    const initialValues: userTypeCreate = {
        name: "",
        userName: "",
        designation: "",
        email: "",
        password: '',
        newPassword: "",
        isActive:true,
    };
    const [selectedUser, setSelectedUser] = useState<userTypeCreate>(initialValues);
    const [isResetPermissions, setIsResetPermissions] = useState<boolean>(false);
    const [dataTableFormat, setDataTableFormat] = useState<any>();
    const urlHiddenState: any = useLocation().state;
    const [isPasswordOpenIcon , setIsPasswordOpenIcon] = useState<boolean>();
    const [isConfirmPasswordOpenIcon , setIsConfirmPasswordOpenIcon] = useState<boolean>();


    const dispatch = useDispatch();
    useEffect(() => {
        if (users && users?.length > 0) {
            const isFetchedRoles = users.map(user => user?.rolePermissions).filter(x => x !== undefined)?.length > 0;
            const userIds: any = users.map(user => user.id);
            userIds?.length > 0 && !isFetchedRoles && dispatch(getRolesByUserIds(userIds));
            setDataTableFormat(formatDataTable(users));
        }
    }, [users]);

    const formik = useFormik({
        initialValues: selectedUser,
        enableReinitialize: true,
        onSubmit: (values) => {
            onCreateOrUpdateUser(selectedUser?.id ? { id: selectedUser?.id, ...values }
                : { ...values },
                () => resetUserManagementForm());
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required("Name is required"),
            designation: Yup.string().required("Designation is required"),
            email: Yup.string().required("Contact email is required").email("Please enter a valid email"),
            roleIds: Yup.mixed(),
            isNotEdit: Yup.boolean().default(!selectedUser?.id),
            userName: Yup.string().when('isNotEdit', {
                is: true,
                then: Yup.string().required("Username is required"),
            }),
            newPassword: Yup.string().when('isNotEdit', {
                is: true,
                then: Yup.string().required("Password is required")
                    .min(8, 'Password is too short - should be 8 chars minimum.')
                    .matches(
                        PASSWORD_REG_EEP,
                        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
                    )
            }),
            password: Yup.string().when('isEdit', {
                is: true,
                then: Yup.string().required("Confirm password is required").when("newPassword", {
                    is: (val: any) => (val && val.length > 0 ? true : false),
                    then: Yup.string().oneOf(
                        [Yup.ref("newPassword")],
                        "Both password need to be the same"
                    )
                })
            })
        })
    });
    const bindUserData = (user: any) => {
        const selectedUser: userTypeCreate = {
            id: user.id,
            name: user?.name,
            userName: user.userName,
            designation: user.designation,
            email: user.email,
            isActive:true,
            roleIds: user?.rolePermissions?.map((role: rolePermissionType) => role.id),
        };
        setSelectedUser(selectedUser);
        formik.setValues(prevValues => ({
            ...prevValues,
            roleIds: user?.rolePermissions?.map((role: rolePermissionType) => role.id)
        }));
        activateToResetSelectUserPermissions();
    }
    const [isBlockedAutoFill, setBlockedAutoFill] = useState<boolean>(true);

    const resetUserManagementForm = () => {
        formik?.resetForm();
        setSelectedUser(initialValues);
        setIsResetPermissions(true);
    }
    const activateToResetSelectUserPermissions = () => {
        setIsResetPermissions(false);
    }
    useEffect(() => {
        return () => {
            resetUserManagementForm();
        }
    }, []);

    const columns: any = [
        {
            name: "Name",
            selector: (row: any) => row.name,
            width: "240px",
            sortable: true,
        },
        {
            name: "User Name",
            selector: (row: any) => row.userName,
            sortable: true
        },
        {
            name: "",
            selector: (row: any) => row.actions,
            sortable: true,
            width: "120px",
        }
    ];
    const customStyles: TableStyles = {
        headRow: {
            style: { backgroundColor: '#f2f2f2', border: '1px solid #d9dee3' }
        },
        rows: {
            style: { border: '1px solid #d9dee3' }
        },
    };

    const formatDataTable = (users: userType[]) => {
        return users.map(user => (
            { actions: userActions(user), ...user }
        ));
    }

    const userActions = (user: userType) => {
        return (
            <span className="d-flex justify-content-center gap-1">
                {urlHiddenState.action === clientActionType.update ?
                    (<>
                        <AccessControl type={AccessControlType.DISABLE}
                                       allowedPermissions={[
                                           UserPermissionsConstant.EditClientUser,
                                       ]}>
                            <button type="button"
                                    onClick={() => bindUserData(user)}
                                    className="btn btn-outline-primary btn-xs app-btn-xs py-0">
                                <i className="bx bx-pencil  small"></i>
                            </button>
                        </AccessControl>
                        <AccessControl type={AccessControlType.DISABLE}
                                       allowedPermissions={[
                                           UserPermissionsConstant.DeleteClientUser,
                                       ]}>
                            <button type="button"
                                    onClick={() => user?.id && openDeleteUser(user?.id)}
                                    className="btn btn-outline-danger btn-xs app-btn-xs py-0">
                                <i className="bx bx-trash-alt  small"></i>
                            </button>
                        </AccessControl>
                    </>) : (
                        <AccessControl type={AccessControlType.DISABLE}
                                       allowedPermissions={[
                                           UserPermissionsConstant.ViewClientUser,
                                       ]}>
                            <button type="button"
                                    onClick={() => bindUserData(user)}
                                    className="btn btn-outline-primary btn-xs app-btn-xs py-0">
                                <i className="bx bx-show  small"></i>
                            </button>
                        </AccessControl>
                    )}
            </span>
        )
    }

    return (
        <>
            <div className="card shadow-none p-0 m-0">
                <div className="cls-client-name">
                    <i className='bx bx-building-house'></i>
                    <span className="fw-bold ms-2">Client Name&nbsp;&nbsp;:&nbsp;&nbsp;</span>
                    <span className="badge align-middle text-capitalize px-3">
                        <p className="m-0">{selectedClient?.companyName}</p>
                    </span>
                </div>
                <form className="w-100" onSubmit={formik.handleSubmit} method="POST">
                    <div className={"row"}>
                        <div className="col-12">
                            <div className="card-body p-3 pt-0 px-0">
                                <div className="row">
                                    <div className="col-4">
                                        <div
                                            className="d-flex justify-content-between align-items-center card-header mb-0 p-0">
                                            <div>
                                                <p className="p-0 m-2 mx-0 secondry-color fw-semibold">User
                                                    Accounts</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <DataTable
                                            title=""
                                            columns={columns}
                                            data={dataTableFormat}
                                            defaultSortFieldId="name"
                                            pagination={true}
                                            paginationPerPage={5}
                                            paginationRowsPerPageOptions={[5, 10, 20, 50, 100]}
                                            paginationComponent={BootyPagination}
                                            selectableRows={false}
                                            customStyles={customStyles}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="row justify-content-between"
                        style={urlHiddenState?.action === clientActionType.view ? { pointerEvents: 'none' } : {}}>
                        <div className="col-4">
                            <h6 className="secondry-color mb-0">User Details</h6>
                            <div className="card-body px-0 pt-0">
                                <div className="row">
                                    <div className="col">
                                        <label htmlFor=""
                                            className="col-form-label text-capitalize fw-semibold">Name
                                            <span className="app-color-danger fw-bolder ms-1">*</span>
                                        </label>
                                        <div className="col-md d-flex justify-content-end">
                                            <input
                                                name="name"
                                                value={formik.values.name}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                type="text"
                                                className="form-control form-control-sm"
                                                placeholder=""
                                            />
                                        </div>
                                        {formik.errors.name && formik.touched.name && (
                                            <p style={{ color: "red" }}>{formik.errors.name}</p>
                                        )}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <label htmlFor="email3"
                                            className="col-form-label text-capitalize fw-semibold">Email
                                            <span className="app-color-danger fw-bolder ms-1">*</span>
                                        </label>
                                        <div className="col-md d-flex justify-content-end">
                                            <input
                                                name="email"
                                                value={formik.values.email}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                type="text"
                                                className="form-control form-control-sm"
                                                id="email3"
                                                placeholder=""
                                            />
                                        </div>
                                        {formik.errors.email && formik.touched.email && (
                                            <p style={{ color: "red" }}>{formik.errors.email}</p>
                                        )}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <label htmlFor="designation"
                                            className="col-form-label text-capitalize fw-semibold">Designation
                                            <span className="app-color-danger fw-bolder ms-1">*</span>
                                        </label>
                                        <div className="col-md d-flex justify-content-end">
                                            <input
                                                name="designation"
                                                value={formik.values.designation}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                type="text"
                                                className="form-control form-control-sm"
                                                id="designation"
                                                placeholder=""
                                            />
                                        </div>
                                        {formik.errors.designation && formik.touched.designation && (
                                            <p style={{ color: "red" }}>{formik.errors.designation}</p>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <p className="secondry-color fw-semibold mb-0">Credential Details</p>
                            <div className="card-body px-0 pt-0">
                                {selectedUser?.name ? (
                                    <>
                                        <label htmlFor="user"
                                            className="col-form-label text-capitalize fw-semibold">Username
                                            <span className="app-color-danger fw-bolder ms-1">*</span>
                                        </label>
                                        <div className="col-md d-flex justify-content-end">
                                            <input
                                                name="userName"
                                                value={formik.values.userName}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                type="text"
                                                className="form-control form-control-sm"
                                                placeholder=""
                                                readOnly={true}
                                                onClick={() => setBlockedAutoFill(false)}
                                            />
                                        </div>
                                        {formik.errors.userName && formik.touched.userName && (
                                            <p style={{ color: "red" }}>{formik.errors.userName}</p>
                                        )}
                                        <div className="form-password-toggle">
                                            <label htmlFor="user"
                                                className="col-form-label text-capitalize fw-semibold">
                                            </label>
                                            <div className="input-group input-group-merge">
                                                <button
                                                    style={{ display: urlHiddenState.action === clientActionType.update ? 'auto' : 'none' }}
                                                    type="button"
                                                    className="btn-outline-primary form-control form-control-sm"
                                                    onClick={() => openChangePassword(selectedUser)}
                                                >Change Password
                                                </button>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="row">
                                            <div className="col">
                                                <label htmlFor="user"
                                                    className="col-form-label text-capitalize fw-semibold">Username
                                                    <span className="app-color-danger fw-bolder ms-1">*</span>
                                                </label>
                                                <div className="col-md d-flex justify-content-end">
                                                    <input
                                                        name="userName"
                                                        value={formik.values.userName}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        type="text"
                                                        className="form-control form-control-sm"
                                                        placeholder=""
                                                        readOnly={isBlockedAutoFill}
                                                        onClick={() => setBlockedAutoFill(false)}
                                                    />
                                                </div>
                                                {formik.errors.userName && formik.touched.userName && (
                                                    <p style={{ color: "red" }}>{formik.errors.userName}</p>
                                                )}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <div className="form-password-toggle">
                                                    <label htmlFor=""
                                                        className="col-form-label text-capitalize fw-semibold">
                                                        New Password
                                                        <span className="app-color-danger fw-bolder ms-1">*</span>
                                                    </label>
                                                    <div className="input-group input-group-merge">
                                                        <input
                                                            name="newPassword"
                                                            value={formik.values.newPassword}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            type={isPasswordOpenIcon ? "text" : "password"}
                                                            className="form-control form-control-sm"
                                                            autoComplete="off"
                                                            readOnly={isBlockedAutoFill}
                                                            onClick={() => setBlockedAutoFill(false)}
                                                        />
                                                        <span onClick={() => setIsPasswordOpenIcon(!isPasswordOpenIcon)}
                                                                    className="input-group-text cursor-pointer"
                                                                ><i className={`bx ${isPasswordOpenIcon ?
                                                                    'bx-show' :
                                                                    'bx-hide'}`}></i></span>
                                                    </div>
                                                    {formik.errors.newPassword && formik.touched.newPassword && (
                                                        <p style={{ color: "red" }}>{formik.errors.newPassword}</p>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <div className="form-password-toggle">
                                                    <label htmlFor="paswordconfirm"
                                                        className="col-form-label text-capitalize fw-semibold">
                                                        Confirm Password
                                                        <span className="app-color-danger fw-bolder ms-1">*</span>
                                                    </label>
                                                    <div className="input-group input-group-merge">
                                                        <input name="password"
                                                            value={formik.values.password}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            type={isConfirmPasswordOpenIcon ? "text" : "password"}
                                                            className="form-control form-control-sm"
                                                            readOnly={isBlockedAutoFill}
                                                            onClick={() => setBlockedAutoFill(false)}
                                                        />
                                                        <span onClick={() => setIsConfirmPasswordOpenIcon(!isConfirmPasswordOpenIcon)}
                                                                    className="input-group-text cursor-pointer"
                                                                ><i className={`bx ${isConfirmPasswordOpenIcon ?
                                                                    'bx-show' :
                                                                    'bx-hide'}`}></i></span>
                                                    </div>
                                                    {formik.errors.password && formik.touched.password && (
                                                        <p style={{ color: "red" }}>{formik.errors.password}</p>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="col-6">

                            <div className="card-body p-3 pt-0 px-0">
                                <SelectUserPolicies
                                    canReset={isResetPermissions}
                                    data={permissionsData}
                                    selectedIds={selectedUser?.roleIds ? selectedUser?.roleIds : []}
                                    type={selectedUser?.id ? SELECT_USER_PERMISSION_TYPE.UPDATE : SELECT_USER_PERMISSION_TYPE.CREATE}
                                    onSelect={(ids: string[]) => {
                                        formik.setValues(prevValues => ({
                                            ...prevValues,
                                            roleIds: ids
                                        }));
                                        activateToResetSelectUserPermissions();
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="card-footer p-0 pt-3 border-1 border-top">
                        <div className="row mx-0">
                            <div className="col p-0 d-flex align-items-end justify-content-end">
                                <div className="p-0 d-flex flex-wrap d-grid gap-2">
                                    <button type="button"
                                        onClick={() => resetUserManagementForm()}
                                        className="btn btn-outline-secondary btn-sm">
                                        Cancel
                                    </button>
                                    <AccessControl type={AccessControlType.DISABLE}
                                                   allowedPermissions={selectedUser?.id ?
                                                       [UserPermissionsConstant.EditClientUser] :
                                                       [UserPermissionsConstant.AddClientUser]}>
                                        <button type="submit"
                                                style={{
                                                    pointerEvents: isLoading ? 'none' : 'auto',
                                                    display: urlHiddenState?.action === clientActionType.view ? 'none' : 'auto'
                                                }}
                                                className="btn btn-primary btn-sm">
                                            {selectedUser?.name ? 'Update' : 'Save'}
                                        </button>
                                    </AccessControl>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
} export default UserManagementTab