import React from 'react';
import MainRoutes from './MainRoutes';
import {Provider as ReduxProvider} from 'react-redux';
import "./assets/vendor/fonts/boxicons.css";
import "./assets/vendor/css/core.css";
import "./assets/vendor/css/pages/page-auth.css";
import "./assets/vendor/css/theme-default.css";
import "./assets/css/demo.css";
import "./assets/vendor/libs/perfect-scrollbar/perfect-scrollbar.css";
import "./assets/css/common.css";

import "../node_modules/jquery/dist/jquery.min.js";
import "../node_modules/bootstrap/dist/js/bootstrap.min.js";
import {persistStore} from 'redux-persist'
import {PersistGate} from "redux-persist/integration/react";
import {store} from './redux/store';
import {ToastContainer} from "react-toastify";
import { BrowserRouter } from 'react-router-dom';


import "../src/js/helpers.js";
import "../src/js/menu.js";
let persistor = persistStore(store);

function App() {

    return (
        <ReduxProvider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <BrowserRouter>
                    <MainRoutes/>
                </BrowserRouter>
            </PersistGate>
        </ReduxProvider>
    );
}

export default App;
