import React, {useEffect, useState} from 'react';
import {userType} from "../../../config/commonTypes";
import {useFormik} from "formik";
import {ICorrectiveActionLogType} from "../../../redux/modules/correctiveActionReducer";
import moment from "moment";
import {jsPDF} from "jspdf";
import logoImage from "../../../assets/img/logo/logo.png";
import AccessControl from '../../../config/AccessControl';
import { AccessControlType, UserPermissionsConstant } from '../../../constants/constants';
import { printDocument } from '../home/printDocument';
import { delay } from '../../../config/commonMethods';
import { clientType } from '../../../redux/modules/clientManagementReducer';
import { useSelector } from 'react-redux';
import { toLocalformatDateTime,toLocalformatDate } from "../../../config/commonMethods";

interface IPropsTypes{
    responsiblePerson: userType[] | [];
    onSubmit: (data: IAddCorrectiveActionType) => void;
    correctiveActionData:ICorrectiveActionLogType | undefined;
    onClose:()=>void;
}
export interface IAddCorrectiveActionType {
    responsibleUserId: string,
    correctiveActions: string,
    isClosed: boolean,
}
function AddCorrectiveActionForm({responsiblePerson ,onSubmit,correctiveActionData,onClose}:IPropsTypes){

    const initialValues: IAddCorrectiveActionType = {
        responsibleUserId: '',
        correctiveActions: '',
        isClosed: false,
    }
    const [initData , setInitData] = useState<IAddCorrectiveActionType>(initialValues);

    useEffect(()=>{
        if(correctiveActionData){
            const data = {
                responsibleUserId: correctiveActionData?.responsibleUserId,
                correctiveActions: correctiveActionData?.correctiveActions,
                isClosed: correctiveActionData?.isClosed === null ? false : correctiveActionData?.isClosed
            }
            setInitData(data);
        }
    },[correctiveActionData]);

    const formik = useFormik({
        initialValues: initData,
        enableReinitialize:true,
        onSubmit: (value: any) => {
            onSubmit(value as IAddCorrectiveActionType);
        }
    });

    const resetToOld = () =>{
        correctiveActionData &&
        formik.setValues(prevValues=>({
            responsibleUserId: correctiveActionData?.responsibleUserId,
                correctiveActions: correctiveActionData?.correctiveActions,
                isClosed: correctiveActionData?.isClosed === null ? false : correctiveActionData?.isClosed
        }));
    }

    const generatePDF =()=>{
        if(!correctiveActionData) return ;
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; //// portrait or landscape
        var doc = new jsPDF(orientation, unit, size);

        doc.addImage(logoImage, 'PNG', 10, 10, 70, 70);
        doc.setFont("times");
        doc.setTextColor(65, 144, 197);
        doc.text('GIA+ Quality Assurance system', 100, 30);
        doc.text('__________________________', 100, 40);
        doc.setTextColor(0, 0, 0);
        doc.text('Corrective Action', 100, 60);

        const fontLabel = 'arial';
        const fontLabelSize = 12;
        const fontValue = 'normal';
        const fontValueSize = 12;
        doc.setTextColor(30, 30, 30);
        //Body
        doc.setFont(fontLabel)
            .setFontSize(fontLabelSize);
        doc.text('Audit :', 100, 120);
        doc.setFont(fontValue)
            .setFontSize(fontValueSize);
        doc.text(`${correctiveActionData?.auditName}`, 160, 120);

        doc.setFont(fontLabel)
            .setFontSize(fontLabelSize);
        doc.text('Findings :', 350, 120);
        doc.setFont(fontValue)
            .setFontSize(fontValueSize);
        doc.text(`${correctiveActionData?.findings}`, 410, 120);

        doc.setFont(fontLabel)
            .setFontSize(fontLabelSize);
        doc.text('Taken by :', 100, 135);
        doc.setFont(fontValue)
            .setFontSize(fontValueSize);
        doc.text(`${correctiveActionData?.startBy + "-" +
        correctiveActionData?.startDate &&
        //moment(correctiveActionData?.startDate).format('DD/MM/YYYY hh:mm:ss')
        toLocalformatDateTime(correctiveActionData?.startDate)
    }`, 160, 135);

        doc.setFont(fontLabel)
            .setFontSize(fontLabelSize);
        doc.text('Criteria :', 350, 135);
        doc.setFont(fontValue)
            .setFontSize(fontValueSize);
        doc.text(`${correctiveActionData?.criteria}`, 410, 135);


        doc.setFont(fontLabel)
            .setFontSize(fontLabelSize);
        doc.text('Complies :', 100, 150);
        doc.setFont(fontValue)
            .setFontSize(fontValueSize);
        doc.text(`${correctiveActionData?.isComplies ? "YES" : "NO"}`, 160, 150);


        doc.setFont(fontLabel)
            .setFontSize(fontLabelSize);
        doc.text('Completed :', 350, 150);
        doc.setFont(fontValue)
            .setFontSize(fontValueSize);
        doc.text(`${correctiveActionData?.completeDate &&
        //moment(correctiveActionData?.completeDate).format('DD/MM/YYYY')
        toLocalformatDate(correctiveActionData?.completeDate)
    }`, 410, 150);


        doc.setFont(fontLabel)
            .setFontSize(fontLabelSize);
        doc.text('Person Responsible :', 100, 170);
        doc.setFont(fontValue)
            .setFontSize(fontValueSize);
        doc.text(`${responsiblePerson.find(x=>x.id === correctiveActionData.responsibleUserId)?.name}`, 240, 170);


        doc.setFont(fontLabel)
            .setFontSize(fontLabelSize);
        doc.text('Closed :', 100, 190);
        doc.setFont(fontValue)
            .setFontSize(fontValueSize);
        doc.text(`${correctiveActionData.isClosed? "YES" : "NO"}`, 240, 190);

        doc.setFont(fontLabel)
            .setFontSize(fontLabelSize);
        doc.text('Corrective Actions :', 100, 210);
        doc.setFont(fontValue)
            .setFontSize(fontValueSize);
        var splitTitle = doc.splitTextToSize(`${correctiveActionData.correctiveActions}`, 300);

        doc.text(splitTitle, 240, 210);


        doc.save("corrective-action.pdf");
    }
    const selectedClientDetails : clientType | undefined = useSelector((state:any)=>state?.auth?.clientDetails);
  
    return (
        <>
            <form method="POST" className={'w-100'} onSubmit={formik.handleSubmit}>
                <div id="divToPrint"  style={correctiveActionData?.isClosed ? {pointerEvents:'none'}:{}} 
                    className="modal-body p-3 pb-0">
                        <div id="header">

                        </div>
                    <div className="row">
                        <div className="col">
                            <div className="row">
                                <div className="col-3">
                                    <p className="fw-bold mb-0">Audit</p>
                                </div>
                                <div className="col">
                                    <p>{correctiveActionData?.auditCode && correctiveActionData?.auditName 
                                    && correctiveActionData?.auditCode + " - " + correctiveActionData?.auditName}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="row">
                                <div className="col-3">
                                    <p className="fw-bold mb-0">Findings</p>
                                </div>
                                <div className="col">
                                    <p >{correctiveActionData?.findings}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="row">
                                <div className="col-3">
                                    <p className="fw-bold mb-0">Taken by</p>
                                </div>
                                <div className="col">
                                    <p>{correctiveActionData?.createdBy} -
                                        {correctiveActionData?.startDate &&
                                        toLocalformatDateTime(correctiveActionData?.startDate)
                                        }
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="row">
                                <div className="col-3">
                                    <p className="fw-bold mb-0">Criteria</p>
                                </div>
                                <div className="col">
                                    <p >{correctiveActionData?.criteria}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="row">
                                <div className="col-3">
                                    <p className="fw-bold mb-0">Complies</p>
                                </div>
                                <div className="col">
                                    {correctiveActionData?.isComplies ? (
                                            <span className="badge app-badge-bg-success text-capitalize p-3 py-2">Yes</span>
                                        ) :
                                        (
                                            <span
                                                className="badge app-badge-bg-danger text-capitalize p-3 py-2">NO</span>
                                        )}
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="row">
                                <div className="col">
                                    {/*<p>*/}
                                    {/*    {correctiveActionData?.criteria}*/}
                                    {/*</p>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <p className="fw-bold mb-1">Person Responsible</p>
                        </div>
                        <div className="col">
                            <p className="mb-0">Completed : <span
                                className="badge app-badge-bg-success text-capitalize p-3 py-2 rounded-4 ms-2">
                               {correctiveActionData?.completeDate
                                   ? /*moment(correctiveActionData?.completeDate).format('DD/MM/YYYY')*/ toLocalformatDate(correctiveActionData?.completeDate) : "-"}
                            </span>
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <select id="responsibleUserId"
                                    className={`form-select form-select-md`}
                                    onChange={(e) => formik.handleChange(e)}>
                                <option>&nbsp;</option>
                                {responsiblePerson?.map((data:userType) => (
                                    <option selected={formik.values?.responsibleUserId === data.id} key={data.id}
                                            value={data.id}>{data.name}</option>
                                ))}

                            </select>
                        </div>
                        <div className="col d-flex justify-content-start align-items-center">
                            <input name="isClosed"
                                   id="flexCheckDefaultPrint"
                                   checked={formik.values.isClosed ? true : false}
                                   onChange={formik.handleChange}
                                   className="form-check-input checkbox-lg" type="checkbox"/>
                            <p id="flexCheckDefaultTextPrint" className="mb-0 ms-3">Mark this item as closed</p>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <p className="fw-bold mb-1 mt-3">Corrective Actions</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                                    <textarea
                                        id="correctiveActionsId"
                                        name="correctiveActions"
                                        value={formik.values?.correctiveActions}
                                        onChange={formik.handleChange}
                                        rows={4}
                                        className="form-control form-control-sm">
                                    </textarea>
                        </div>
                    </div>
                </div>
                <div className="modal-footer p-3">
                    <div className="row m-0">
                        <div className="col p-0 d-flex align-items-end justify-content-end">
                            <div className="p-0 d-flex flex-wrap d-grid gap-2">
                            <AccessControl
                                    type={AccessControlType.DISABLE}
                                    allowedPermissions={[
                                    UserPermissionsConstant.ViewPrintAudit,
                                    UserPermissionsConstant.MimicAccess
                                    ]}
                                >
                                <button type="button"
                                        onClick={()=>{
                                            resetToOld();
                                            delay(2).then(()=>{
                                                printDocument(selectedClientDetails?.companyName);
                                                onClose();
                                            })
                                            // generatePDF();
                                        }}
                                        className="btn btn-outline-secondary btn-sm">
                                    <span className="tf-icons bx bx-printer"> </span>&nbsp; Print
                                </button>
                                </AccessControl>
                                <AccessControl
                                    type={AccessControlType.DISABLE}
                                    allowedPermissions={[
                                    UserPermissionsConstant.PerformAudit,
                                    UserPermissionsConstant.MimicAccess
                                    ]}
                                >
                                <button type="submit"
                                        style={correctiveActionData?.isClosed ? {pointerEvents:'none'}:{}}
                                        className="btn btn-primary btn-sm">
                                    Save
                                </button>
                                </AccessControl>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    )
}

export default AddCorrectiveActionForm;