const Moment = require("moment");

export function groupBy(array, key) {
  const groupByCategory = array?.reduce((group, product) => {
    group[product[key]] = group[product[key]] ?? [];
    group[product[key]].push(product);
    return group;
  }, {});
  return groupByCategory;
}

export function addDataToArray(array, object) {
  object && array.push(object);
  return array;
}

export function namePascalCaseToText(PascalCase) {
  const result = PascalCase.replace(/([A-Z])/g, " $1");
  const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
  return finalResult;
}

export function removeFromArray(array, key, id) {
  return array.filter((arr) => arr[key] !== id);
}

export function delay(time) {
  return new Promise((resolve) => setTimeout(resolve, time));
}

export function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}

export function sortByNumber(array, isAscOrder, key) {
  return isAscOrder
    ? array.sort((a, b) => a[key] - b[key])
    : array.sort((a, b) => b[key] - a[key]);
}

export function sortByDate(array, isAscOrder, key) {
  return isAscOrder
    ? array.sort(
        (a, b) =>
          new Moment(a[key]).format("YYYYMMDD") -
          new Moment(b[key]).format("YYYYMMDD")
      )
    : array.sort(
        (a, b) =>
          new Moment(b[key]).format("YYYYMMDD") -
          new Moment(a[key]).format("YYYYMMDD")
      );
}

export function sortByString(array, isAscOrder, key) {
  array.sort((a, b) => {
    const nameA = a[key]?.toUpperCase(); // ignore upper and lowercase
    const nameB = b[key]?.toUpperCase(); // ignore upper and lowercase
    if (isAscOrder) {
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
    }
    if (!isAscOrder) {
      if (nameA < nameB) {
        return 1;
      }
      if (nameA > nameB) {
        return -1;
      }
    }
    // names must be equal
    return 0;
  });
  return array;
}

export const compareDates = (dateA, dateB) => {
  if (!dateA.isValid() && !dateB.isValid()) {
    return 0; // Both dates are null, so no sorting needed
  } else if (!dateA.isValid()) {
    return 1; // Place null dates after valid dates
  } else if (!dateB.isValid()) {
    return -1; // Place valid dates before null dates
  } else {
    return dateA.isBefore(dateB) ? -1 : dateA.isAfter(dateB) ? 1 : 0; // Regular comparison for valid dates
  }
};

export const toLocalformatDate = (date) => {
  const orginalDate = Moment(date);
  if (orginalDate.isValid()) {
    const utcDate = new Date(date + "Z");
    return Moment(utcDate).format("DD/MM/YYYY");
  }
  return "";
};

export const toLocalformatDateTime = (date) => {
  const orginalDate = Moment(date);
  if (orginalDate.isValid()) {
    const utcDate = new Date(date + "Z");
    return Moment(utcDate).format('DD/MM/YYYY hh:mm:ss');
  }
  return "";
};