import {apiResponseType} from "../config/commonTypes";
import api from "../config/api";

export function GetCorrectiveActionLogByClientID(
    clientId: number,
    status:string ,
    onSuccess: (data: apiResponseType) => void,
    onError: (data: apiResponseType) => void
): any {
    return api.get("CorrctiveActionLog/GetByClientId?clientId=" + clientId+"&status="+status,).then(
        (response: any) => {
            onSuccess(response as apiResponseType);
        },
        (error: any) => {
            onError(error.response.data as apiResponseType);
        }
    );
}


export function AddCorrectiveAction(
    data: any,
    onSuccess: (data: apiResponseType) => void,
    onError: (data: apiResponseType) => void
): any {
    return api.post("CorrctiveActionLog" ,data).then(
        (response: any) => {
            onSuccess(response as apiResponseType);
        },
        (error: any) => {
            onError(error.response.data as apiResponseType);
        }
    );
}

export function UpdateCorrectiveAction(
    data: any,
    onSuccess: (data: apiResponseType) => void,
    onError: (data: apiResponseType) => void
): any {
    return api.put("CorrctiveActionLog" ,data).then(
        (response: any) => {
            onSuccess(response as apiResponseType);
        },
        (error: any) => {
            onError(error.response.data as apiResponseType);
        }
    );
}