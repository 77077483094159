import React, { useState } from "react";
import logoImage from '../../../assets/img/logo/logo.png';
import CustomModal from "../../../components/modal/CustomModal";
import RestForm from "./ResetForm";
import Input from "../../../components/elements/Input";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { userLoginType } from "../../../config/commonTypes";
import { useDispatch } from "react-redux";
import { loginUser } from "../../../actions/userAction";
import { toast } from "react-toastify";

const Login = () => {

    const [isOpenModal, setIsOpenModal] = useState(false);
    const [isPasswordOpenIcon, setIsPasswordOpenIcon] = useState<boolean>();
    const dispatch = useDispatch();
    const loginFormValue: userLoginType = {
        username: "",
        password: ""
    }
    const formik = useFormik({
        initialValues: loginFormValue,
        onSubmit: (values: userLoginType) => {
            dispatch(loginUser(values, (error: any) =>
                toast.error(error?.title ? error.title : 'Login Failed!')
            ));
        },
        validationSchema:
            Yup.object().shape({
                username: Yup.string()
                    .required('User name is required'),
                password: Yup.string()
                    .required('Password is required'),
            })
    });

    return (
        <>
            {isOpenModal &&
                (<CustomModal isClose={!isOpenModal}
                    title={"Reset Password"}
                    onClose={() => setIsOpenModal(false)}>
                    <RestForm />
                </CustomModal>)
            }
            <div className="container-xxl">
                <div className="authentication-wrapper authentication-basic container-p-y">
                    <div className="authentication-inner">
                        <div className="card">
                            <div className="card-body p-0">
                                <div className="app-brand rounded-top mb-0 app-secondry-color justify-content-center">
                                    <div className="d-flex">
                                        <div className="text-center h-100">
                                            <img className="m-3" alt="logo" src={logoImage} width="80"
                                                height="80" />
                                        </div>
                                    </div>
                                    <div className="d-flex pe-3">
                                        <div className="h-100 d-flex justify-content-left align-items-center">
                                            <div>
                                                <h1 className="h1 m-0 text-white fw-bolder">Login required</h1>
                                                <p className="text-white fw-light mb-0">Welcome to GIA+ Quality
                                                    Assurance system, please enter your credentials to login.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <form onSubmit={formik.handleSubmit} id="formAuthentication" className="w-100 mb-3 p-5 pb-2"
                                action="src/views/pages/login/Login#" method="POST">
                                <div className="mb-3">
                                    <div className="input-group input-group-merge">
                                        <Input
                                            name="username"
                                            onChange={formik.handleChange}
                                            hideLabel={true}
                                            iconClasses="bx bx-user"
                                            type="text"
                                            placeholder={"Username"}
                                            inputClasses="form-control"
                                            iconSpanClasses="input-group-text"
                                        />
                                    </div>
                                    {formik.errors.username && formik.touched.username && (
                                        <p style={{ color: "red" }}>{formik.errors.username}</p>
                                    )}
                                </div>
                                <div className="mb-3 form-password-toggle">
                                    <div className="input-group input-group-merge">
                                        <span className={'input-group-text'}>
                                            <i className={'bx bx-lock'}></i>
                                        </span>
                                        <input
                                            name="password"
                                            value={formik.values.password}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            type={isPasswordOpenIcon ? "text" : "password"}
                                            className="form-control form-control-sm"
                                            autoComplete="off"
                                            placeholder={"Password"}
                                        />
                                        <span onClick={() => setIsPasswordOpenIcon(!isPasswordOpenIcon)}
                                            className="input-group-text cursor-pointer">
                                            <i className={`bx ${isPasswordOpenIcon ?
                                                'bx-show' :
                                                'bx-hide'}`}></i></span>
                                    </div>
                                    {formik.errors.password && formik.touched.password && (
                                        <p style={{ color: "red" }}>{formik.errors.password}</p>
                                    )}
                                </div>
                                <div className="mb-3 text-end">
                                    <div className="form-check">
                                        <label className="form-check-label"
                                        ><a onClick={() => setIsOpenModal(true)}
                                            className="link-primary"
                                            data-bs-toggle="modal"
                                            data-bs-target="#modalCenter">Reset
                                                Password</a></label>
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <button className="btn btn-primary app-primary-color btn-primary-color d-grid w-100"
                                        type="submit">LOGIN
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Login;