import React, {useEffect, useState} from 'react';
import AppLayout from "../../Layout/AppLayout";
import Input from "../../../components/elements/Input";
import DataTable, {TableStyles} from 'react-data-table-component';
import {BootyPagination} from "../../../components/pagination/BootyPagination";
import {useFormik} from "formik";
import '../../../assets/css/clintListDataTable.css';
import {jsPDF} from "jspdf";
import autoTable from 'jspdf-autotable';

import {useNavigate} from "react-router-dom";
import {connect, useDispatch} from "react-redux";
import {userType} from "../../../config/commonTypes";
import {deleteUser, getAllUsers} from "../../../actions/userAction";
import {AccessControlType, userActionType, UserPermissionsConstant} from "../../../constants/constants";

import {toast} from "react-toastify";
import {ConfirmModal} from "../../../components/modal/ConfirmModal";
import {authUserType} from "../../../redux/modules/userReducer";
import AccessControl from '../../../config/AccessControl';
import logoImage from "../../../assets/img/logo/logo.png";
import moment from 'moment';
import _ from 'lodash';

interface propsType{
    users?:userType[];
    user?:authUserType;
}

function UserList({users,user}: propsType) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isDelete, setIsDelete] = useState<boolean>(false);
    const [deleteId, setDeleteId] = useState<any>();
    const [superAdminUserList, setSuperAdminUserList] = useState<any>();

    useEffect(() => {
        dispatch(getAllUsers());
 
    }, []);


    const indicateStatus = (superAdmin: userType) => {
        return (
            <span className={`badge bg-label-${superAdmin.isActive ? 'success' : 'secondary'} d-grid`}>
                {superAdmin.isActive ? "Active" : "Inactive"}</span>
        );
    }

    const userActions = (id: any) => {
        return (
            <span className="d-flex justify-content-center gap-1">
                <AccessControl type={AccessControlType.DISABLE}
                               allowedPermissions={[UserPermissionsConstant.EditSuperAdminUser]}>
                    <button type="button"
                            onClick={() => navigate({
                                pathname: '/edit-superadmin/' + id
                            }, {state: {action: userActionType.update}})}
                            className="btn btn-outline-primary btn-xs app-btn-xs py-0">
                        <i className="bx bx-pencil  small"></i>
                    </button>
                </AccessControl>
                <AccessControl type={AccessControlType.DISABLE}
                               allowedPermissions={[UserPermissionsConstant.ViewSuperAdminUser]}>
                    <button type="button"
                            onClick={() => navigate({
                                pathname: '/view-superadmin/' + id
                            }, {state: {action: userActionType.view}})}
                            className="btn btn-outline-primary btn-xs app-btn-xs py-0">
                        <i className="bx bx-show  small"></i>
                    </button>
                </AccessControl>
                <AccessControl type={AccessControlType.DISABLE}
                               allowedPermissions={[UserPermissionsConstant.DeleteSuperAdminUser]}>
                    <button type="button"
                            onClick={() => {
                                setIsDelete(true);
                                setDeleteId(id);
                            }}
                            className="btn btn-outline-danger btn-xs app-btn-xs py-0">
                        <i className="bx bx-trash-alt  small"></i>
                    </button>
                </AccessControl>
            </span>
        )
    }

    useEffect(() => {
        if (users && user && users?.length > 0) {
            const dataSet = formatDataTable(users?.filter((u:userType) => u.id !== user.userId));
            setSuperAdminUserList(dataSet);
        }
    }, [users]);

    const formatDataTable = (superadminArray: userType[]) => {
        return superadminArray?.map((user: userType) => {
            return (
                { actions: userActions(user?.id), indicator: indicateStatus(user), ...user }
            )
        });
    }


    const columns: any = [
        {
            name: "Name",
            selector: (row: any) => row.name,
            sortable: true,
        },
        {
            name: "Username",
            selector: (row: any) => row.userName,
            sortable: true
        },
        {
            name: "Designation",
            selector: (row: any) => row.designation,
            sortable: true
        },
        {
            name: "Email",
            selector: (row: any) => row.email,
            sortable: true
        },        
        {
            name: "Mobile",
            selector: (row: any) => row.phoneNumber,
            sortable: true
        },
        {
            name: "Status",
            selector: (row: any) => row.indicator,
            sortable: false,
        },
        {
            name: "Action",
            selector: (row: any) => row.actions,
            sortable: false,
        }
    ];

    const initialValues: any = {
        name: '',
        email: '',
        status: 'Select',

    }

    const customStyles: TableStyles = {
        headRow: {
            style: { backgroundColor: '#f2f2f2', border: '1px solid #d9dee3' }
        },
        rows: {
            style: { border: '1px solid #d9dee3' }
        },
    }

    const formik = useFormik({
        initialValues: initialValues,
        onSubmit: (value: any) => {
            const cloneALlOriginalUsers: userType[] = users && users?.length > 0 ? [...users.filter(u=>u.id !== user?.userId)] : [];
            const filterCloneUsers = cloneALlOriginalUsers?.filter(
                user => (value?.name ? user?.name.toLowerCase().includes(value?.name?.toLowerCase()) : true)
                    &&
                    (value?.email ? user?.email.toLowerCase().includes(value?.email?.toLowerCase()) : true)
                    &&
                    (value?.status != "Select" ? user?.isActive === (value?.status === "ACTIVE") : true)
            );
            const dataSet = formatDataTable(filterCloneUsers);
            setSuperAdminUserList(dataSet);
        }
    });

    const deleteSuperAdminById = (deleteId: any) => {
        deleteId && dispatch(deleteUser(deleteId,
            (response: any) => {
                setIsDelete(false);
                toast.success(response?.message ? response.message : 'Superadmin Successfully deleted!');
                dispatch(getAllUsers());
               
            },
            (error: any) => {
                setIsDelete(false);
                toast.error(error?.message ? error.message : 'Failed to delete Superadmin!');

            }));
    }
    
    const generatePDF = () => {
        if (_.isEmpty(superAdminUserList)) {
            toast.info("There is no data to print");
            return;
        }
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; //// portrait or landscape
        var doc = new jsPDF(orientation, unit, size);

        doc.addImage(logoImage, "PNG", 10, 10, 70, 70);
        doc.setFont("times");
        doc.setTextColor(65, 144, 197);
        doc.text("GIA+ Quality Assurance system", 100, 30);
        doc.text("__________________________", 100, 40);
        doc.setTextColor(0, 0, 0);
        doc.text("User List", 100, 60);

        const headers: string[] = ['Name', 'Username','Designation', 'Email', 'Mobile', 'Status']
        const headKey: string[] = ['name', 'userName', 'designation', 'email', 'phoneNumber', 'indicator'];
        let data: any = [];
        superAdminUserList.forEach((row: any) => {
            let rowData: string[] = [];
            headKey?.forEach((key: string) => {
                if (key === 'indicator') {
                    rowData.push(row?.indicator?.props?.children)
                } else rowData.push(row[key])
            });
            data.push(rowData);
            rowData = [];
        });

        autoTable(doc, {
            head: [headers],
            margin: {
                top: 100,
            },
            body: data,
        });
        doc.save(`Super-Admin-List-${moment().format('DD-MM-YYYY-hh-mm-ss')}.pdf`)
    }

    return (
        <AppLayout>
            <>
                {isDelete && (
                    <ConfirmModal
                        onCanceled={() => setIsDelete(false)}
                        isOpenConfirmModal={isDelete}
                        title={"Delete Client"}
                        btn1Text={"Cancel"}
                        btn1Class={"btn btn-outline-secondary btn-sm"}
                        btn2Text={"Confirm"}
                        btn2Class="btn btn-danger btn-sm"
                        icon={<i className='bx bx-error-circle display-4 app-col link-warning'></i>}
                        message={"Are you sure you want to delete this item?"}
                        onConfirm={() => deleteId && deleteSuperAdminById(deleteId)} />
                )}
                <div className="row">
                    {/*Custom style1 Breadcrumb */}
                    <div className="col-md">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb breadcrumb-style1">
                                <li className="breadcrumb-item d-flex align-items-center">
                                    <a href="javascript:void(0);">Home</a>
                                </li>
                                <li className="breadcrumb-item d-flex align-items-center">User Management</li>
                                <li className="breadcrumb-item d-flex align-items-center active">Users</li>
                            </ol>
                        </nav>
                    </div>
                </div>

                <div className="row">
                    <form onSubmit={formik.handleSubmit} method="POST" >
                        <div className="col-md">
                            <div className="accordion" id="accordionExample">
                                <div className="card accordion-item shadow-sm active">
                                    <h6 className="accordion-header" id="headingOne">
                                        <button
                                            type="button"
                                            className="accordion-button border-bottom p-3"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#accordionOne"
                                            aria-expanded="true"
                                            aria-controls="accordionOne"
                                        >
                                            Search Filters
                                        </button>
                                    </h6>

                                    <div
                                        id="accordionOne"
                                        className="accordion-collapse collapse show"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body p-3 pt-2">
                                            <div className="row">
                                                <div className="col">
                                                    <Input
                                                        id="name"
                                                        name="name"
                                                        onChange={(e: any) => {
                                                            formik.handleChange(e);
                                                            formik.submitForm();
                                                        }}
                                                        type="text"
                                                        label="Name"
                                                        inputClasses="form-control form-control-sm"
                                                        labelClasses="col-form-label text-capitalize fw-semibold"
                                                    />
                                                </div>
                                                <div className="col">
                                                    <Input
                                                        id="email"
                                                        name="email"
                                                        onChange={(e: any) => {
                                                            formik.handleChange(e);
                                                            formik.submitForm();
                                                        }}
                                                        type="text"
                                                        label="Email"
                                                        inputClasses="form-control form-control-sm"
                                                        labelClasses="col-form-label text-capitalize fw-semibold"
                                                    />
                                                </div>
                                                <div className="col">
                                                    <label htmlFor="defaultFormControlInput"
                                                        className="col-form-label  text-capitalize fw-semibold">Status</label>
                                                    <select id="status"
                                                        onChange={(e: any) => {
                                                            formik.handleChange(e);
                                                            formik.submitForm();
                                                        }}
                                                        className="form-select form-select-sm">
                                                        <option value="Select">Select</option>
                                                        <option value="ACTIVE">Active</option>
                                                        <option value="INACTIVE">Inactive</option>
                                                    </select>
                                                </div>
                                                <div className="col d-flex align-items-end">
                                                    <div
                                                        className="py-3 pb-0 d-flex flex-wrap justify-content-end d-grid gap-2 w-100">
                                                        <button type="button"
                                                            onClick={() => generatePDF()}
                                                            className="btn btn-outline-secondary btn-sm">
                                                            <span className="tf-icons bx bx-printer"></span>&nbsp; Print
                                                        </button>
                                                        {/* <button type="submit"
                                                            className="btn btn-primary btn-sm px-4">Search
                                                    </button> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>

                <div className="row">
                    <div className="col">
                        <div className="card shadow-sm mt-4">
                            {/*{dataTableFormat?.length > 0 && (*/}
                            {/*    <TblFunc data={dataTableFormat}></TblFunc>)}*/}
                            <div
                                className="d-flex justify-content-between align-items-center card-header mb-0 border-bottom p-3">
                                <div>
                                    <h6 className="m-0">Users</h6>
                                </div>
                                <div>
                                    <AccessControl type={AccessControlType.DISABLE}
                                                   allowedPermissions={[UserPermissionsConstant.AddSuperAdminUser]}>
                                        <button type="button"
                                                className="btn btn-primary btn-sm px-4"
                                                onClick={() => navigate({
                                                    pathname: '/add-new-admin/'
                                                }, {state: {action: userActionType.create}})}>
                                            Add New User
                                        </button>
                                    </AccessControl>
                                </div>
                            </div>
                            {superAdminUserList?.length > 0 && (
                                <>
                                    <div className="card-body p-3">
                                        <div className="table-responsive text-nowrap">
                                            <DataTable
                                                title=""
                                                columns={columns}
                                                data={superAdminUserList}
                                                defaultSortFieldId="companyName"
                                                pagination={true}
                                                paginationPerPage={10}
                                                paginationRowsPerPageOptions={[5, 10, 20, 50, 100]}
                                                paginationComponent={BootyPagination}
                                                selectableRows={false}
                                                customStyles={customStyles}
                                            />
                                        </div>
                                    </div>
                                </>)}
                        </div>
                    </div>
                </div>
            </>
        </AppLayout>
    );

}

const mapStateToProps = (state: any) => {
    const user = state?.auth?.user
    const {users, isLoading} = state?.userManagement;
    return {users, isLoading,user}
}
export default connect(mapStateToProps, null)(UserList);