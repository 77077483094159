import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { changeresetUserPassword, getUserByUserIdWithOutDispatch, IUpdateUserType, updateUser } from "../../../actions/userAction";
import { apiResponseType, userFogetPasswordType, userType } from "../../../config/commonTypes";
import AppLayout from "../../Layout/AppLayout";
import {authUserType} from "../../../redux/modules/userReducer";
import CustomModal from "../../../components/modal/CustomModal";
import { ChangePasswordForm } from "../clientManagement/ChangePasswordForm";
import { toast } from "react-toastify";
import * as Yup from "yup";
import {useFormik} from "formik";
import { PASSWORD_REG_EEP, userActionType, clientActionType } from "../../../constants/constants";
import { createNewAdminUserFormType } from "./AddNewUser";

interface IPropsType {
    user?: authUserType | undefined
}
function MyProfile({ user }: IPropsType) {

    const [userDetails, setUserDetails] = useState<userType>();
    const [isEditView, setIsEditView] = useState<boolean>();
    const [isOpenChangePassword, setIsOpenChangePassword] = useState<boolean>(false);
    const [isBlockedAutoFill, setBlockedAutoFill] = useState<boolean>(true);
    const dispatch = useDispatch();
    
    useEffect(() => {
        user?.userId && getUserByUserIdWithOutDispatch(user?.userId,
            (data: apiResponseType) => { 
                setUserDetails(data.data);
            },
            (data: apiResponseType) => {
                console.log("##CAN NOT FETCH USER DATA");
             });
    }, [user]);


    const initialValues: createNewAdminUserFormType = {
        roleIds: [],
        name: "",
        designation: "",
        isActive: true,
        clientId: "",
        createDate: "",
        userName: "",
        email: "",
        phoneNumber: "",
        password: "",
        confirmPassword: "",
    };

    const formik = useFormik({
        initialValues: userDetails ? { ...userDetails } : {...initialValues},
        enableReinitialize:true,
        onSubmit: ((values: any) => {
            if(userDetails?.id){
                const toBeUpdatedUser : IUpdateUserType={
                    id: userDetails?.id,
                    name: values?.name,
                    designation: values?.designation,
                    email: values?.email
                }
                updateUser(toBeUpdatedUser,
                    (response:apiResponseType)=>{
                        toast.success(response?.message ? response.message : 'Successfully changed!');
                    },
                    (error:apiResponseType)=>{
                        toast.error(error?.message ? error.data : 'Change error!');
                    })
            } 

        }),
        validationSchema: Yup.object().shape({
            name: Yup.string().required("Name is required"),
            email: Yup.string().required("Email is required").email("Please enter a valid email"),
            designation: Yup.string(),
        })
    });

    const onChangePassword=({password, userName}: userType)=>{
        if(!userName || !password) return;
        const credentials : userFogetPasswordType = {
            username: userName,
            password: password
        }
        dispatch(
            changeresetUserPassword(credentials, (response) => {
                toast.success(response?.message ? response.message : 'Successfully changed password!');
            }, (error: any) => {
                toast.error(error?.message ? error.message : 'Change password error!');
            }));
    }
    return (
        <AppLayout>
            <>
            {isOpenChangePassword && userDetails && (
                    <CustomModal size="sm" isClose={!isOpenChangePassword}
                                 title="Change Password"
                                 onClose={() => setIsOpenChangePassword(false)}>
                        <ChangePasswordForm
                            name={userDetails.name}
                            userName={userDetails.userName}
                            onChangePassword={(data: any) => {
                                onChangePassword(data);
                                setIsOpenChangePassword(false);
                            }}
                        />
                    </CustomModal>
                )}
             {isEditView ? 
                <>
                 <div className="row">
                    <form onSubmit={formik.handleSubmit} method="POST" >
                    <div className="col-12 col-sm-12 col-md-3 col-lg-3">
                        <div className="card shadow-sm p-3 m-0">
                            <div className="row">
                                <div className="col">
                                    <div
                                        className="d-flex justify-content-between align-items-center card-header mb-0 p-0">
                                        <div>
                                            <h6 className="p-0 mb-2 secondry-color">User Information</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row" >
                                <div className="col">
                                    <div className="card-body p-3 pt-0 px-0 border-top pb-2">
                                        <div className="row">
                                            <div className="col">
                                                <label
                                                    className="col-form-label text-capitalize fw-semibold">Name
                                                    <span className="app-color-danger fw-bolder ms-1">*</span>
                                                </label>
                                                <div className="col-md d-flex justify-content-end">
                                                    <input
                                                        name="name"
                                                        onChange={formik.handleChange}
                                                        value={formik.values.name}
                                                        onBlur={formik.handleBlur}
                                                        type="text"
                                                        className="form-control form-control-sm"
                                                        placeholder=""
                                                        aria-describedby="defaultFormControlHelp"
                                                    />
                                                </div>
                                                {formik.errors.name && formik.touched.name && (
                                                    <p style={{ color: "red" }}>{formik.errors.name}</p>
                                                )}
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col">
                                                <label
                                                    className="col-form-label text-capitalize fw-semibold">Designation</label>
                                                <div className="col-md d-flex justify-content-end">
                                                    <input
                                                        name="designation"
                                                        onChange={formik.handleChange}
                                                        value={formik.values.designation}
                                                        onBlur={formik.handleBlur}
                                                        type="text"
                                                        className="form-control form-control-sm"
                                                        placeholder=""
                                                        aria-describedby="defaultFormControlHelp"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col">
                                                <label
                                                    className="col-form-label text-capitalize fw-semibold">Email
                                                    <span className="app-color-danger fw-bolder ms-1">*</span>
                                                </label>
                                                <div className="col-md d-flex justify-content-end">
                                                    <input
                                                        name="email"
                                                        onChange={formik.handleChange}
                                                        value={formik.values.email}
                                                        onBlur={formik.handleBlur}
                                                        type="text"
                                                        className="form-control form-control-sm"
                                                        placeholder=""
                                                        aria-describedby="defaultFormControlHelp"
                                                    />

                                                </div>
                                                {formik.errors.email && formik.touched.email && (
                                                    <p style={{ color: "red" }}>{formik.errors.email}</p>
                                                )}
                                            </div>
                                        </div>

                                       
                                        <div className="row mx-0">
                                        <div className="col p-0 px-3 d-flex align-items-end justify-content-end mt-4">
                                            <div className="p-0 d-flex flex-wrap d-grid gap-2">
                                                <button type="button"
                                                    onClick={() => setIsEditView(false)}
                                                    className="btn btn-outline-secondary btn-sm">
                                                    Cancel
                                                </button>
                                                <button type="submit"
                                                    className="btn btn-primary btn-sm">
                                                    Save
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    

                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col">
                                    <div className="card-body p-3 pt-0 px-0 border-top pb-2">
                                       
                                            <>
                                                <label htmlFor="user"
                                                       className="col-form-label text-capitalize fw-semibold">Username
                                                    <span className="app-color-danger fw-bolder ms-1">*</span>
                                                </label>
                                                <div className="col-md d-flex justify-content-end">
                                                    <input
                                                        name="userName"
                                                        value={formik.values.userName}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        type="text"
                                                        className="form-control form-control-sm"
                                                        placeholder=""
                                                        readOnly={true}
                                                        onClick={() => setBlockedAutoFill(false)}
                                                    />
                                                </div>
                                                {formik.errors.userName && formik.touched.userName && (
                                                    <p style={{ color: "red" }}>{formik.errors.userName}</p>
                                                )}
                                                <div className="form-password-toggle">
                                                    <label htmlFor="user"
                                                           className="col-form-label text-capitalize fw-semibold">
                                                    </label>
                                                    <div className="input-group input-group-merge">
                                                        <button
                                                            type="button"
                                                            className="btn-outline-primary form-control form-control-sm"
                                                            onClick={() => setIsOpenChangePassword(true)}
                                                        >Change Password
                                                        </button>
                                                    </div>
                                                </div>
                                            </>
                                
                                    </div>
                                </div>
                            </div>

                            <hr />
                        </div>
                    </div>
                    </form>
                </div>

                </> 
            : 
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-3 col-lg-3">

                        <div className="card">
                            <div className="card-body">
                                <div className="d-flex justify-content-between">
                                    <h5 className="card-title">User Information</h5>
                                    <div className="border p-2" onClick={()=>setIsEditView(true)}>
                                        <i className='bx bx-edit-alt'></i>
                                    </div>
                                </div>
                                <div>
                                    <div className="form-group mb-2">
                                        <label className="fw-bold">Name :</label>
                                        <label className="sr-only">{userDetails?.name}</label>
                                    </div>
                                    <div className="form-group mb-2">
                                        <label className="fw-bold">Designation :</label>
                                        <label className="sr-only">{userDetails?.designation}</label>
                                    </div>
                                    <div className="form-group mb-2">
                                        <label className="fw-bold">Email :</label>
                                        <label className="sr-only">{userDetails?.email}</label>
                                    </div>
                                </div>
                                <div className="mt-4">
                                    <h5 className="card-title">Account Information</h5>
                                    <div className="form-group  ">
                                        <label className="fw-bold">User Name :</label>
                                        <label className="sr-only">{userDetails?.userName}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                }
            </>
        </AppLayout>
    )
}
const mapStateToProps = (state: any) => {
    const user = state?.auth?.user
    return { user }
}
export default connect(mapStateToProps, null)(MyProfile);