import React, {useEffect, useState} from 'react';

interface propsTypes {
    totalPage?: number;
    onChangePage?: (value: number) => void;
    currentPage?: number;
    showPages?: number;
}

function Pagination({
                        totalPage = 10,
                        currentPage = 0,
                        onChangePage
                    }: propsTypes) {
    const [pageNumberArray, setPageNumberArray] = useState<number[]>([]);
    const [page, setPage] = useState<number>(0);


    useEffect(() => {
        const array = Array(5).fill(null).map((_, i) => i);
        setPageNumberArray(array);
    }, [])
    useEffect(() => {
        setPage(currentPage);
    }, [currentPage])


    const updatePageArray = (newCurrentPage: number,
                             type: 'goToStar' | 'increase' | 'goToEnd' | 'decrease' | 'number') => {
        let array: number[] = [];
        switch (type) {
            case 'goToStar':
                array = Array(5).fill(null).map((_, i) => i);
                break;
            case "decrease":
                newCurrentPage < 2 ?
                    array = Array(5).fill(null).map((_, i) => i)
                    :
                    array = Array(5).fill(null).map((_, i) => newCurrentPage - 2 + i);
                break;
            case 'number':
                break;
            case "increase":
                (newCurrentPage + 1) + 2 < totalPage ?
                    array = Array(5).fill(null).map((_, i) => newCurrentPage -
                        (newCurrentPage === 1 ? 1 : 2) + i)
                    :
                    array = Array(5).fill(null).map((_, i) => totalPage - 5 + i);
                break
            case 'goToEnd':
                array = Array(5).fill(null).map((_, i) => totalPage - 5 + i);
                break;
            default:
                break;
        }
        if (type !== 'number')
            setPageNumberArray(array);
    }

    const pageNumberBtn = (pageNumber: number) => {
        return (
            <li key={pageNumber} className={`page-item ${page === pageNumber ? 'active' : ''}`}>
                <a className="page-link" onClick={() => pageChange(pageNumber, 'number')}>{pageNumber + 1}</a>
            </li>
        )
    };
    const pageChange = (updatedCurrentPage: number,
                        type: 'goToStar' | 'increase' | 'goToEnd' | 'decrease' | 'number'): void => {
        setPage(updatedCurrentPage);
        updatePageArray(updatedCurrentPage, type);
        onChangePage && onChangePage(updatedCurrentPage);
    }
    return (
        <nav aria-label="Page navigation">
            <ul className="pagination justify-content-end pagination-sm mb-0">
                <li className="page-item first">
                    <a className="page-link"
                       onClick={() => pageChange((0), 'goToStar')}
                    ><i className="tf-icon bx bx-chevrons-left"></i
                    ></a>
                </li>
                <li className="page-item prev">
                    <a className="page-link"
                       onClick={() => pageChange(page > 0 ? page - 1 : page, 'decrease')}
                    ><i className="tf-icon bx bx-chevron-left"></i
                    ></a>
                </li>
                {pageNumberArray && pageNumberArray.map(i => (
                    pageNumberBtn(i)
                ))}
                <li className="page-item next">
                    <a className="page-link"
                       onClick={() => pageChange(page + 1 === totalPage ? page : page + 1, "increase")}
                    ><i className="tf-icon bx bx-chevron-right"></i
                    ></a>
                </li>
                <li className="page-item last">
                    <a className="page-link"
                       onClick={() => pageChange(totalPage - 1, "goToEnd")}
                    ><i className="tf-icon bx bx-chevrons-right"></i
                    ></a>
                </li>
            </ul>
        </nav>
    );
}

export default Pagination;