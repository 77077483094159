import React from 'react';
import {useFormik} from "formik";
import * as Yup from "yup";
import {clientType} from "../../../redux/modules/clientManagementReducer";
import {useNavigate} from "react-router-dom";
import {AccessControlType, clientActionType, PHONE_REG_EEP, UserPermissionsConstant} from "../../../constants/constants";
import moment from 'moment';
import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable';
import { getOriginalNode } from 'typescript';
import { ClientDetailPagePrintTemplate } from '../../../pdfTemplate/ClientDetailPagePrintTemplate'
import { userType } from "../../../config/commonTypes";
import _ from 'lodash';
import { toast } from 'react-toastify';
import logoImage from "../../../assets/img/logo/logo.png";
import AccessControl from '../../../config/AccessControl';
export interface updateClientType extends createNewClientFormType{
    clientId : number
}
export interface createNewClientFormType {
    companyName: string;
    mainContactEmail: string;
    telephoneNo: string;
    mainContactName: string;
    mobileNo: string;
    isActive: boolean;
    startDate: string;
    physicalAddressContactName: string;
    physicalAddressEmail: string;
    physicalAddress: string;
    physicalAddressCity: string;
    physicalAddressPostalCode: string;
    postalAddressContactName: string;
    postalAddressEmail: string;
    postalAddress: string;
    postalAddressCity: string;
    postalAddressPostalCode: string;

}
interface propsTypes{
    createNewClient:(console:createNewClientFormType ,onSuccess: ()=>void)=>void;
    isLoading?: boolean;
    selectedClient?:createNewClientFormType|clientType;
    type: 'CREATE' | 'UPDATE' | 'VIEW';
}
function ClientDetailsTab({ createNewClient, isLoading, selectedClient,
                              type}:propsTypes) {
    const initialValues: createNewClientFormType = {
        companyName: '',
        mainContactEmail: '',
        telephoneNo: '',
        mainContactName: '',
        mobileNo: '',
        isActive: false,
        startDate: '',
        physicalAddressContactName:'',
        physicalAddressEmail:'',
        physicalAddress:'',
        physicalAddressCity:'',
        physicalAddressPostalCode:'',
        postalAddressContactName:'',
        postalAddressEmail:'',
        postalAddress:'',
        postalAddressCity:'',
        postalAddressPostalCode:'',
    };
    const formik = useFormik({
        initialValues:selectedClient? {...selectedClient}: initialValues,
        enableReinitialize:true,
        onSubmit: ((values: any) => {
            createNewClient(values, ()=>{
                formik?.resetForm();
            });
        }),
        validationSchema: Yup.object().shape({
            companyName: Yup.string().required("Company name is required"),
            mainContactEmail: Yup.string().required("Contact email is required").email("Please enter a valid email"),
            mainContactName: Yup.string().required("Contact name is required"),
            mobileNo: Yup.string().matches(PHONE_REG_EEP, 'Mobile number is not valid'),
            telephoneNo:Yup.string().matches(PHONE_REG_EEP, 'Telephone number is not valid'),
            physicalAddressEmail: Yup.string().email("Please enter a valid email"),
            postalAddressEmail: Yup.string().email("Please enter a valid email"),
        })
    });
    const currentDatetValue = moment((new Date())).format("YYYY-MM-DD");  
    const navigate = useNavigate();
    const cancelForm = () => {
        type === 'CREATE' ?
            formik?.resetForm() :
            navigate('/client-list')
    }

    const usePhysicalAddress=()=>{
        formik.setValues(prevValues=>({
            ...prevValues,
            postalAddressContactName:prevValues?.physicalAddressContactName,
            postalAddressEmail:prevValues?.physicalAddressEmail,
            postalAddress:prevValues?.physicalAddress,
            postalAddressCity:prevValues?.physicalAddressCity,
            postalAddressPostalCode:prevValues?.physicalAddressPostalCode,
        }));
    }

    const generatePDF = () => {

        if (_.isEmpty(selectedClient)) {
            toast.info("There is no data to print");
            return;
        }
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; //// portrait or landscape
        var doc = new jsPDF(orientation, unit, size);

        doc.addImage(logoImage, "PNG", 10, 10, 70, 70);
        doc.setFont("times");
        doc.setTextColor(65, 144, 197);
        doc.text("GIA+ Quality Assurance system", 100, 30);
        doc.text("__________________________", 100, 40);
        doc.setTextColor(0, 0, 0);
        doc.text("Client Details", 100, 60);

        const headers: string[] = [
            "Company",
            "Main Email",
            "Telephone",
            "Main Contact",
            "Mobile",
            "Start Date",
          ];
          const clentData : any[] = [selectedClient.companyName ,
            selectedClient.mainContactEmail,
            selectedClient.telephoneNo,
            selectedClient.mainContactName,
            selectedClient.mobileNo,
            selectedClient.startDate ? moment(selectedClient.startDate).format('DD/MM/YYYY') : ''
        ];
        autoTable(doc, {
            head: [headers],
            margin: {
              top: 100,
            },
            body: [clentData],
          });
        
          //   Physical
          const headers2: string[] = [
            "Address Type",
            "Contact Name",
            "Email Adderess",
            "Adderess",
            "City",
            "Post Code"
          ];
          const data2 : any[] = [
            "Physical Address",
            selectedClient.physicalAddressContactName,
            selectedClient.physicalAddressEmail,
            selectedClient.physicalAddress,
            selectedClient.physicalAddressCity,
            selectedClient.physicalAddressPostalCode
        ];
        const data3 : any[] = [
            "Postal Address",
            selectedClient.postalAddressContactName,
            selectedClient.postalAddressEmail,
            selectedClient.postalAddress,
            selectedClient.postalAddressCity,
            selectedClient.postalAddressPostalCode
        ];
          autoTable(doc, {
            
            head: [headers2],
            margin: {
              top: 100,
            },
            body: [data2 ,data3],
          });
          doc.save(`Client-details-${moment().format('DD-MM-YYYY-hh-mm-ss')}.pdf`);

              
    }

    return (
        <div className="card shadow-none p-0 m-0">
            <form className="w-100" onSubmit={formik.handleSubmit} method="POST" >
                <div
                    >
                    <div className="card-body p-0 my-3">
                        <div className="row">
                            <div className="col d-flex justify-content-between">
                                <h6 className="p-0 m-0">
                                    { type === "UPDATE" ? "Update General Information" :
                                       "General Information"
                                    }</h6>
                            </div>
                            <div className="col d-flex align-items-end">
                                <div
                                    className="col d-flex flex-wrap justify-content-end d-grid w-100">
                                    <AccessControl type={AccessControlType.DISABLE}
                                    allowedPermissions={[UserPermissionsConstant.ViewClientUser,
                                        UserPermissionsConstant.EditClientUser]}
                                    >
                                        <button type="button"
                                            onClick={() => generatePDF()}
                                            className="btn btn-outline-secondary btn-sm">
                                            <span className="tf-icons bx bx-printer"></span>&nbsp; Print
                                        </button>  
                                    </AccessControl>                                
                                </div>
                            </div>
                        </div>
                        <div style={type === clientActionType.view?{pointerEvents:'none'}:{}}
                            className="row">
                            <div className="col">
                                <label
                                    className="col-form-label text-capitalize fw-semibold">Company
                                    <span className="app-color-danger fw-bolder ms-1">*</span>
                                </label>
                                <div className="col-md">
                                    <input
                                        name="companyName"
                                        onChange={formik.handleChange}
                                        value={formik.values.companyName}
                                        onBlur={formik.handleBlur}
                                        type="text"
                                        className="form-control form-control-sm"
                                        placeholder=""
                                        aria-describedby="defaultFormControlHelp"
                                    />
                                </div>
                                {formik.errors.companyName && formik.touched.companyName && (
                                    <p style={{color: "red"}}>{formik.errors.companyName}</p>
                                )}
                            </div>
                            <div className="col">
                                <label className="col-form-label text-capitalize fw-semibold">Main
                                    Email
                                    <span className="app-color-danger fw-bolder ms-1">*</span>
                                </label>
                                <div className="col-md">
                                    <input
                                        name="mainContactEmail"
                                        value={formik.values.mainContactEmail}
                                        onChange={formik.handleChange}
                                        onBlur={
                                            (e:any)=>{
                                                formik.handleBlur('mainContactEmail');
                                                formik.setFieldValue(
                                                    "physicalAddressEmail",
                                                    e.target.value
                                                  );
                                            }
                                        }
                                        type="text"
                                        className="form-control form-control-sm"
                                        placeholder=""
                                        aria-describedby="defaultFormControlHelp"
                                    />
                                </div>
                                {formik.errors.mainContactEmail && formik.touched.mainContactEmail && (
                                    <p style={{color: "red"}}>{formik.errors.mainContactEmail}</p>
                                )}
                            </div>
                            <div className="col">
                                <label
                                    className="col-form-label text-capitalize fw-semibold">Telephone</label>
                                <div className="col-md">
                                    <input
                                        name="telephoneNo"
                                        value={formik.values.telephoneNo}
                                        onChange={formik.handleChange}
                                        type="text"
                                        className="form-control form-control-sm"
                                        placeholder=""
                                        aria-describedby="defaultFormControlHelp"
                                    />
                                </div>
                                {formik.errors.telephoneNo && formik.touched.telephoneNo && (
                                    <p style={{color: "red"}}>{formik.errors.telephoneNo}</p>
                                )}
                            </div>
                        </div>
                        <div style={type === clientActionType.view?{pointerEvents:'none'}:{}} 
                            className="row">
                            <div className="col">
                                <label className="col-form-label text-capitalize fw-semibold">Main
                                    Contact
                                    <span className="app-color-danger fw-bolder ms-1">*</span>
                                </label>
                                <div className="col-md">
                                    <input
                                        name="mainContactName"
                                        value={formik.values.mainContactName}
                                        onChange={formik.handleChange}
                                        onBlur={
                                            (e:any)=>{
                                                formik.handleBlur('mainContactName');
                                                formik.setFieldValue(
                                                    "physicalAddressContactName",
                                                    e.target.value
                                                  );
                                            }
                                        }
                                        type="text"
                                        className="form-control form-control-sm"
                                        placeholder=""
                                        aria-describedby="defaultFormControlHelp"
                                    />
                                </div>
                                {formik.errors.mainContactName && formik.touched.mainContactName && (
                                    <p style={{color: "red"}}>{formik.errors.mainContactName}</p>
                                )}
                            </div>
                            <div className="col">
                                <label
                                    className="col-form-label text-capitalize fw-semibold">Mobile
                                </label>
                                <div className="col-md">
                                    <input
                                        name="mobileNo"
                                        value={formik.values.mobileNo}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        type="text"
                                        className="form-control form-control-sm"
                                        placeholder=""
                                        aria-describedby="defaultFormControlHelp"
                                    />
                                </div>
                                {formik.errors.mobileNo && formik.touched.mobileNo && (
                                    <p style={{color: "red"}}>{formik.errors.mobileNo}</p>
                                )}
                            </div>
                            <div className="col">
                                <label className="col-form-label text-capitalize fw-semibold">Start
                                    Date</label>
                                <div className="col-md">
                                    <input
                                        name="startDate"
                                        value={moment(formik.values.startDate).format('YYYY-MM-DD')}
                                        onChange={formik.handleChange}
                                        type="date"
                                        className="form-control form-control-sm"
                                        placeholder=""
                                        aria-describedby="defaultFormControlHelp"
                                    />
                                </div>
                            </div>
                        </div>
                        <div style={type === clientActionType.view?{pointerEvents:'none'}:{}} 
                            className="row">
                            <div className="col">
                                <div className="form-check form-switch mb-0 mt-0 pt-3">
                                    <label
                                        className="form-check-label"
                                        htmlFor="flexSwitchCheckChecked">
                                        Status
                                        <span className="app-color-danger fw-bolder ms-1">*</span>
                                    </label>
                                    <input
                                        name="isActive"
                                        id="isActive"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={formik.values.isActive}
                                        />
                                </div>
                                {formik.errors.isActive && formik.touched.isActive && (
                                    <p style={{color: "red"}}>{formik.errors.isActive}</p>
                                )}
                            </div>
                        </div>
                    </div>
                    <hr/>
                    <div className="card-body p-0 mb-3">
                    <div style={type === clientActionType.view?{pointerEvents:'none'}:{}}
                         className="row">
                        <div className="col">
                            <h6 className="p-0 m-0 secondry-color py-2">Physical
                                Address</h6>
                            <label className="col-form-label text-capitalize fw-semibold">Contact
                                Name</label>
                            <div className="col-md">
                                <input
                                    name="physicalAddressContactName"
                                    value={formik.values.physicalAddressContactName}
                                    onChange={formik.handleChange}
                                    type="text"
                                    className="form-control form-control-sm"
                                    placeholder=""
                                    aria-describedby="defaultFormControlHelp"
                                />
                            </div>
                            <label className="col-form-label text-capitalize fw-semibold">Email
                                Address</label>
                            <div className="col-md">
                                <input
                                    name="physicalAddressEmail"
                                    value={formik.values.physicalAddressEmail}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    type="text"
                                    className="form-control form-control-sm"
                                    placeholder=""
                                    aria-describedby="defaultFormControlHelp"
                                />
                            </div>
                            {formik.errors.physicalAddressEmail && formik.touched.physicalAddressEmail && (
                                <p style={{color: "red"}}>{formik.errors.physicalAddressEmail}</p>
                            )}
                            <label
                                className="col-form-label text-capitalize fw-semibold">Address</label>
                            <div className="col-md">
                                <textarea
                                    name="physicalAddress"
                                    value={formik.values.physicalAddress}
                                    onChange={formik.handleChange}
                                    className="form-control form-control-sm"
                                    rows={4}></textarea>
                            </div>
                            <label
                                className="col-form-label text-capitalize fw-semibold">City</label>
                            <div className="col-md">
                                <input
                                    name="physicalAddressCity"
                                    value={formik.values.physicalAddressCity}
                                    onChange={formik.handleChange}
                                    type="text"
                                    className="form-control form-control-sm"
                                    placeholder=""
                                    aria-describedby="defaultFormControlHelp"
                                />
                            </div>
                            <label
                                className="col-form-label text-capitalize fw-semibold">Postcode</label>
                            <div className="col-md">
                                <input
                                    name="physicalAddressPostalCode"
                                    value={formik.values.physicalAddressPostalCode}
                                    onChange={formik.handleChange}
                                    type="text"
                                    className="form-control form-control-sm"
                                    placeholder=""
                                    aria-describedby="defaultFormControlHelp"
                                />
                            </div>
                        </div>


                        <div className="col">
                            <div className="col-md">
                                <div className="row">
                                <div className="col-5">
                                    <h6 className="p-0 m-0 secondry-color py-2">Postal Address</h6>
                                </div>
                                <div className="col d-flex justify-content-end">
                                    {!selectedClient && (
                                        <>
                                             
                                             <button onClick={() => usePhysicalAddress()}
                                                type="button"
                                                className="btn btn-sm btn-outline-secondary px-3">
                                                 {/*<i className="bx bx-paste  small me-1"></i>*/}
                                                 Use Physical Address</button>
                                        </>
                                        )}
                                </div>
                                </div>
                            </div>
                            <div className="col-md">
                                <label className="col-form-label text-capitalize fw-semibold">Contact Name</label>
                                <input
                                    name="postalAddressContactName"
                                    value={formik.values.postalAddressContactName}
                                    onChange={formik.handleChange}
                                    type="text"
                                    className="form-control form-control-sm"
                                    placeholder=""
                                    aria-describedby="defaultFormControlHelp"
                                />
                            </div>
                            <label className="col-form-label text-capitalize fw-semibold">Email
                                Address</label>
                            <div className="col-md">
                                <input
                                    name="postalAddressEmail"
                                    value={formik.values.postalAddressEmail}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    type="text"
                                    className="form-control form-control-sm"
                                    placeholder=""
                                    aria-describedby="defaultFormControlHelp"
                                />
                            </div>
                            {formik.errors.postalAddressEmail && formik.touched.postalAddressEmail && (
                                <p style={{color: "red"}}>{formik.errors.postalAddressEmail}</p>
                            )}
                            <label
                                className="col-form-label text-capitalize fw-semibold">Address</label>
                            <div className="col-md">
                                <textarea
                                    name="postalAddress"
                                    value={formik.values.postalAddress}
                                    onChange={formik.handleChange}
                                    className="form-control form-control-sm"
                                    id="exampleFormControlTextarea1" rows={4}></textarea>
                            </div>
                            <label
                                className="col-form-label text-capitalize fw-semibold">City</label>
                            <div className="col-md">
                                <input
                                    name="postalAddressCity"
                                    value={formik.values.postalAddressCity}
                                    onChange={formik.handleChange}
                                    type="text"
                                    className="form-control form-control-sm"
                                    placeholder=""
                                    aria-describedby="defaultFormControlHelp"
                                />
                            </div>
                            <label
                                className="col-form-label text-capitalize fw-semibold">Postcode</label>
                            <div className="col-md">
                                <input
                                    name="postalAddressPostalCode"
                                    value={formik.values.postalAddressPostalCode}
                                    onChange={formik.handleChange}
                                    type="text"
                                    className="form-control form-control-sm"
                                    placeholder=""
                                    aria-describedby="defaultFormControlHelp"
                                />
                            </div>
                        </div>
                        <div className="col">

                        </div>
                         
                    </div>
                </div>
                </div>
                <div className="card-footer p-0 pt-3 border-1 border-top">
                    <div className="row mx-0">
                        <div className="col p-0 d-flex align-items-end justify-content-end">
                            <div className="p-0 d-flex flex-wrap d-grid gap-2">
                                <button type="button"
                                        onClick={()=>cancelForm()}
                                        className="btn btn-outline-secondary btn-sm">
                                    Cancel
                                </button>
                                {type !== clientActionType.view && (
                                    <button type="submit"
                                            style={isLoading ? {pointerEvents:'none'}:{}}
                                            className="btn btn-primary btn-sm">
                                        Save
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default ClientDetailsTab
