import { remove } from "jszip";
import {
  actionType,
  rolePermissionType,
  userType,
} from "../../config/commonTypes";
import {
  CREATE_CLIENTS_FAILURE,
  CREATE_CLIENTS_REQUEST,
  CREATE_CLIENTS_SUCCESS,
  CREATE_USER_BY_CLIENT_FAILURE,
  CREATE_USER_BY_CLIENT_REQUEST,
  CREATE_USER_BY_CLIENT_SUCCESS,
  DELETE_CLIENT_USER_FAILURE,
  DELETE_CLIENT_USER_REQUEST,
  DELETE_CLIENT_USER_SUCCESS,
  DELETE_CLIENTS_FAILURE,
  DELETE_CLIENTS_REQUEST,
  DELETE_CLIENTS_SUCCESS,
  FETCH_CLIENT_FAILURE,
  FETCH_CLIENT_REQUEST,
  FETCH_CLIENT_SUCCESS,
  FETCH_CLIENTS_FAILURE,
  FETCH_CLIENTS_REQUEST,
  FETCH_CLIENTS_SUCCESS,
  FETCH_USER_BY_CLIENT_ID_FAILURE,
  FETCH_USER_BY_CLIENT_ID_REQUEST,
  FETCH_USER_BY_CLIENT_ID_SUCCESS,
  FETCH_USERS_ROLES_CLIENTS_FAILURE,
  FETCH_USERS_ROLES_CLIENTS_REQUEST,
  FETCH_USERS_ROLES_CLIENTS_SUCCESS,
  GET_AUDIT_ASSIGNEE_TEMPLATES_REQUEST,
  GET_AUDIT_ASSIGNEE_TEMPLATES_SUCCESS,
  GET_AUDIT_ASSIGNEE_TEMPLATES_FAILURE,
  ASSIGNEE_TEMPLATE_PLANNING_SCHEDULE_UPDATED,
  RESET_CLIENT_DATA,
} from "../../constants";
import { removeFromArray } from "../../config/commonMethods";
import moment from "moment";

export interface ILocalTemplateSchedule {
  localTemplateHeaderId: number;
  localTemplateScheduleId: number;
  january: boolean;
  february: boolean;
  march: boolean;
  april: boolean;
  may: boolean;
  june: boolean;
  july: boolean;
  august: boolean;
  september: boolean;
  october: boolean;
  november: boolean;
  december: boolean;
}

export interface IAddorRemoveClient {
  globalTemplateHeaderId: number;
  clientId: number;
  isActiveToClient: boolean;
}
export interface ILocalTemplateUpdateType {
  localTemplateHeaderId: number;
  globalTemplateHeaderId: number;
  clientId: number;
  isGlobalTemplateModified: boolean;
  localTemplateCode: string;
  localTemplateName: string;
  localTemplateDescription: string;
  isActive: boolean;
  isDeleted: boolean;
  assignedUserId: string;
  isRemoved?:boolean;
  isGlobal?:boolean;
}

export interface ILocalTemplateType {
  localTemplateHeaderId?: number;
  globalTemplateHeaderId: number;
  clientId: number;
  isGlobalTemplateModified: boolean;
  localTemplateCode: string;
  localTemplateName: string;
  localTemplateDescription: string;
  isActive: boolean;
  isDeleted: boolean;
  localSchedule: ILocalTemplateSchedule | undefined;
  startDate: any;
  startBy: string;
  progress: string;
  isClosed: boolean;
  createdLocalTemplateId?: number;
  completedDate: string;
  isRemoved: boolean;
  isGlobal: boolean;
}

interface initialTypes {
  isLoading: boolean;
  clients: clientType[];
  client: clientType | null;
  users: userType[] | null;
  assigneeTemplateListPlan: ILocalTemplateType[] | [];
}
export interface clientType {
  clientId: number;
  companyName: string;
  mainContactEmail: string;
  telephoneNo: string;
  mainContactName: string;
  mobileNo: string;
  isActive: boolean;
  startDate: string;
  physicalAddressContactName: string;
  physicalAddressEmail: string;
  physicalAddress: string;
  physicalAddressCity: string;
  physicalAddressPostalCode: string;
  postalAddressContactName: string;
  postalAddressEmail: string;
  postalAddress: string;
  postalAddressCity: string;
  postalAddressPostalCode: string;
}

export interface ClientsResponse {
  statusCode: number;
  message: string;
  data: clientType[];
}

const initialState: initialTypes = {
  isLoading: false,
  clients: [],
  client: null,
  users: [],
  assigneeTemplateListPlan: [],
};
export default function reducer(
  state: initialTypes = initialState,
  action: actionType
) {
  switch (action.type) {
    case FETCH_CLIENTS_REQUEST:
      state.isLoading = true;
      return state;
    case FETCH_CLIENTS_SUCCESS:
      state.isLoading = false;
      state.clients = action?.payload?.data;
      return { ...state };
    case FETCH_CLIENTS_FAILURE:
      state.isLoading = false;
      return { ...state };

    case CREATE_CLIENTS_REQUEST:
      state.isLoading = true;
      return { ...state };
    case CREATE_CLIENTS_SUCCESS:
      state.isLoading = false;
      return { ...state };
    case CREATE_CLIENTS_FAILURE:
      state.isLoading = false;
      return { ...state };

    case FETCH_CLIENT_REQUEST:
      state.isLoading = true;
      return { ...state };
    case FETCH_CLIENT_SUCCESS:
      state.isLoading = false;
      state.client = action?.payload?.data;
      return { ...state };
    case FETCH_CLIENT_FAILURE:
      state.isLoading = false;
      return { ...state };

    case RESET_CLIENT_DATA:
      state = { ...initialState };
      return { ...state };

    case CREATE_USER_BY_CLIENT_REQUEST:
      state.isLoading = true;
      return { ...state };
    case CREATE_USER_BY_CLIENT_SUCCESS:
      state.isLoading = false;
      return { ...state };
    case CREATE_USER_BY_CLIENT_FAILURE:
      state.isLoading = false;
      return { ...state };

    case FETCH_USER_BY_CLIENT_ID_REQUEST:
      state.isLoading = true;
      return { ...state };
    case FETCH_USER_BY_CLIENT_ID_SUCCESS:
      state.isLoading = false;
      state.users = action?.payload?.data;
      return { ...state };
    case FETCH_USER_BY_CLIENT_ID_FAILURE:
      state.isLoading = false;
      return { ...state };

    case DELETE_CLIENTS_REQUEST:
      state.isLoading = true;
      return { ...state };
    case DELETE_CLIENTS_SUCCESS:
      state.isLoading = false;
      const clientsUpdated = removeFromArray(
        state?.clients,
        "clientId",
        action?.payload?.data
      );
      state.clients = [...clientsUpdated];
      return { ...state };
    case DELETE_CLIENTS_FAILURE:
      state.isLoading = true;
      return { ...state };

    case FETCH_USERS_ROLES_CLIENTS_REQUEST:
      state.isLoading = true;
      return { ...state };
    case FETCH_USERS_ROLES_CLIENTS_SUCCESS:
      state.isLoading = false;
      const updatedUserWithPermissions: userType[] | null = state?.users
        ? addUserPermissionToUser(state?.users, action?.payload?.data)
        : null;
      if (updatedUserWithPermissions) {
        state.users = updatedUserWithPermissions;
      }

      return {
        ...state,
      };
    case FETCH_USERS_ROLES_CLIENTS_FAILURE:
      state.isLoading = false;
      return { ...state };

    case DELETE_CLIENT_USER_REQUEST:
      state.isLoading = true;
      return { ...state };
    case DELETE_CLIENT_USER_SUCCESS:
      state.isLoading = false;
      const removedUser: userType[] | null = state.users
        ? state?.users.filter((user) => user.id !== action.payload?.data?.id)
        : null;
      if (removedUser) state.users = removedUser;
      return { ...state };
    case DELETE_CLIENT_USER_FAILURE:
      state.isLoading = true;
      return { ...state };

    case GET_AUDIT_ASSIGNEE_TEMPLATES_REQUEST:
      state.isLoading = true;
      return state;
    case GET_AUDIT_ASSIGNEE_TEMPLATES_SUCCESS:
      state.isLoading = false;
      state.assigneeTemplateListPlan = action?.payload;
      return { ...state };
    case GET_AUDIT_ASSIGNEE_TEMPLATES_FAILURE:
      state.isLoading = false;
      return { ...state };

    case ASSIGNEE_TEMPLATE_PLANNING_SCHEDULE_UPDATED:
      state.assigneeTemplateListPlan = updateTemplateSchedule(
        state.assigneeTemplateListPlan,
        action.payload
      );
      return { ...state };
    default:
      return state;
  }
}

interface rolesPermissionsType {
  userId: string;
  userPermissions: rolePermissionType[];
}

const addUserPermissionToUser: any = (
  users: userType[],
  rolesPermissions: rolesPermissionsType[]
) => {
  return users.map((user) => {
    const rolesPermissionsData = rolesPermissions
      .filter((rolePermission) => rolePermission.userId === user.id)
      .map((rolePermission) => rolePermission.userPermissions)[0];
    return {
      rolePermissions: rolesPermissionsData,
      ...user,
    };
  });
};

const updateTemplateSchedule = (
  templates: ILocalTemplateType[],
  toBeUpdateSchedule: ILocalTemplateSchedule
) => {
  return templates.map((template) => ({
    ...template,
    localSchedule:
      template.localTemplateHeaderId ===
      toBeUpdateSchedule.localTemplateHeaderId
        ? toBeUpdateSchedule
        : template.localSchedule,
  }));
};
