import React, { useState } from 'react';
import {useFormik} from "formik";
import * as Yup from "yup";
import {PASSWORD_REG_EEP} from "../../../constants/constants";

interface propsType {
    userName: string;
    name: string;
    onChangePassword: (data: any) => void;
}

export function ChangePasswordForm({userName,name, onChangePassword}: propsType) {
    const [isPasswordOpenIcon , setIsPasswordOpenIcon] = useState<boolean>();
    const [isConfirmPasswordOpenIcon , setIsConfirmPasswordOpenIcon] = useState<boolean>();

    const initialValues: any = {
        newPassword: '',
        password: '',
    }
    const formik: any = useFormik({
        initialValues: initialValues,
        onSubmit: (values) => {
            onChangePassword({userName: userName, password: values.newPassword})
        },
        validationSchema: Yup.object().shape({
            newPassword: Yup.string().required("Password is required")
                .min(8, 'Password is too short - should be 8 chars minimum.')
                .matches(
                    PASSWORD_REG_EEP,
                    "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
                ),
            password: Yup.string().required("Confirm password is required").when("newPassword", {
                is: (val: any) => (!!(val && val.length > 0)),
                then: Yup.string().oneOf(
                    [Yup.ref("newPassword")],
                    "Both password need to be the same"
                )
            })
        })
    });
    return (
        <>
            <form onSubmit={formik.handleSubmit} className="w-100" action="POST">
                <div className="content">
                    <div className="modal-body p-3 pb-1">
                        <div className="row">
                            <div className="col">
                                <p>Name : <span className="fw-bold">{name}</span>
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="mb-3 form-password-toggle">
                                    <div className="input-group input-group-merge">
                                        <span id="basic-icon-default-fullname2" className="input-group-text">
                                          <i className="bx bx-lock"></i>
                                        </span>
                                        <input
                                            name="newPassword"
                                            value={formik.values.newPassword}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            type={isPasswordOpenIcon ? "text" : "password"}
                                            id="password"
                                            className="form-control"
                                            placeholder="Password"
                                            aria-describedby="password"
                                        />
                                        <span onClick={()=>setIsPasswordOpenIcon(!isPasswordOpenIcon)}
                                            className="input-group-text cursor-pointer"
                                        ><i  className={`bx ${ isPasswordOpenIcon ?
                                                        'bx-show' : 
                                                        'bx-hide'}`}></i></span>
                                    </div>
                                    {formik.errors.newPassword && formik.touched.newPassword && (
                                        <>
                                            <p style={{color: "red"}}>{formik?.errors?.newPassword}</p>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="mb-3 form-password-toggle">
                                    <div className="input-group input-group-merge">
                                            <span id="basic-icon-default-fullname2" className="input-group-text">
                                              <i className="bx bx-lock"></i>
                                            </span>
                                        <input name="password"
                                               value={formik.values.password}
                                               onChange={formik.handleChange}
                                               onBlur={formik.handleBlur}
                                               type={isConfirmPasswordOpenIcon ? "text" : "password"}
                                               className="form-control"
                                               placeholder="Confirm Password"
                                               aria-describedby="password"
                                        />
                                        <span onClick={()=>setIsConfirmPasswordOpenIcon(!isConfirmPasswordOpenIcon)}
                                            className="input-group-text cursor-pointer"
                                        ><i  className={`bx ${ isConfirmPasswordOpenIcon ?
                                                        'bx-show' : 
                                                        'bx-hide'}`}></i></span>
                                    </div>
                                    {formik.errors.password && formik.touched.password && (
                                        <>
                                            <p style={{color: "red"}}>{formik?.errors?.password}</p>
                                        </>
                                    )}

                                </div>
                            </div>
                        </div>
                        {/*<div className="row">*/}
                        {/*    <div className="col">*/}
                        {/*        <p className="p-0 mb-2 secondry-color fw-semibold">Both password must match</p>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                    <div className="modal-footer p-3 py-0 d-block">
                        <div className="row m-0">
                            <div className="pb-3 d-grid px-0">
                                <button type="submit"
                                        className="btn btn-primary btn-sm">Change Password
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    )
}
