import {connect} from 'react-redux';
import React from "react";
import {AccessControlType} from "../constants/constants";

interface propsType {
    allowedPermissions: string[];
    renderNoAccess?: () => null;
    children: any;
    userPermissions?:any;
    type?: AccessControlType.HIDE | AccessControlType.DISABLE
}

const checkPermissions = (userPermissions: string[],
                          allowedPermissions: string[]) => {
    if (allowedPermissions === null || userPermissions?.length < 1) {
        return false;
    }
    let canAllow :boolean = false;
    allowedPermissions.forEach(permission => {
        if(userPermissions?.includes(permission)){
            canAllow = true;
        }
    });
    return canAllow;
};

const StyleInjector = ( children :any ) => {
    const StyledChildren = () => {
        return (
            React.Children.map(children, child =>
                React.cloneElement(child, {
                    className: `${child.props.className} ${'access-control-disabled'}`,
                })
            ))
    }
    return StyledChildren();
};

const AccessControl = ({allowedPermissions,
                           children,
                           renderNoAccess,
                           userPermissions,
                           type=AccessControlType.HIDE}: propsType) => {
    let permitted = checkPermissions(userPermissions, allowedPermissions);

    if (permitted) {
        return children;
    }
    return renderNoAccess ? renderNoAccess() :
        type === AccessControlType.HIDE ? true : StyleInjector(children);
};


// Compose AccessControl component with redux

export default connect((state: any) => ({
    userPermissions: state?.auth?.roles,
}))(AccessControl);