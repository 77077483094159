import React, {useEffect, useState} from 'react';
import AppLayout from '../../Layout/AppLayout';
import {clientActionType, PASSWORD_REG_EEP, SELECT_USER_PERMISSION_TYPE} from "../../../constants/constants";
import SelectUserPolicies from "../../../components/SelectUserPolicies";
import {
    apiResponseType,
    childPermissionsType,
    customGroupPermissionsType,
    rolePermissionType
} from "../../../config/commonTypes";
import {useFormik} from "formik";
import * as Yup from "yup";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {groupBy, namePascalCaseToText} from "../../../config/commonMethods";
import {getAllPermissions} from "../../../actions/commonAction";
import {getRolesByUserIdsForState, getUserByUserId, updateClientUser} from "../../../actions/clientAction";
import {toast} from "react-toastify";
import {connect, useDispatch} from "react-redux";
import CustomModal from "../../../components/modal/CustomModal";
import {ChangePasswordForm} from "./ChangePasswordForm";
import {changeresetUserPassword, createClientUser} from "../../../actions/userAction";

interface userTypeCreate {
    id?: string;
    userName: string;
    name: string;
    designation: string;
    email: string;
    password?: string;
    newPassword?: string;
    rolePermissions?: rolePermissionType[] | null;
    roleIds?: string[] | null;
    isActive: boolean;
}
function ClientUserDetails({user}:any) {
    const initialValues: userTypeCreate = {
        name: "",
        userName: "",
        designation: "",
        email: "",
        password: '',
        newPassword: "",
        isActive: true,
    };
    const {userId} = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isLoading , setIsLoading] = useState<boolean>();
    const [selectedUser, setSelectedUser] = useState<userTypeCreate>(initialValues);
    const [isResetPermissions, setIsResetPermissions] = useState<boolean>(false);
    const urlHiddenState: any = useLocation().state;
    const [isBlockedAutoFill, setBlockedAutoFill] = useState<boolean>(true);
    const [isOpenChangePassword, setIsOpenChangePassword] = useState<boolean>(false);
    const [formattedRolePermissions, setFormattedRolePermissions] = useState<customGroupPermissionsType[]>();
    const [rolePermissions, setRolePermissions] = useState<rolePermissionType[]>();
    const [rolePermissionsForSelectedUser, setRolePermissionsForSelectedUser] = useState<rolePermissionType[]>([]);
    const [isPasswordOpenIcon , setIsPasswordOpenIcon] = useState<boolean>();
    const [isConfirmPasswordOpenIcon , setIsConfirmPasswordOpenIcon] = useState<boolean>();

    useEffect(() => {
        //if copy and past the update url, It will redirect to client list, bcz it not good way
        (urlHiddenState?.action === undefined && userId !== undefined) ? navigate('/client-user-list') : '';
    }, [urlHiddenState]);

    useEffect(() => {
        getAllPermissions('ClientUser',
            (data: rolePermissionType[]) => setRolePermissions(data.filter(role=>role.isActive)),
            (error: any) => console.log(error));

    }, []);

    useEffect(() => {
        userId && getUserByUserId(userId,
            (data) => {
                setSelectedUser(data.data);
            },
            (error: any) => console.log(error));
        if(userId === undefined) {
            formik?.resetForm();
            setSelectedUser(initialValues);
            setIsResetPermissions(true);
        }
    }, [userId]);

    useEffect(() => {
        selectedUser?.id && getRolesByUserIdsForState([selectedUser?.id],
            (data) => {
                setRolePermissionsForSelectedUser(data?.data[0].userPermissions);
            },
            (error: any) => console.log(error));
    }, [selectedUser])

    useEffect(() => {
        rolePermissionsForSelectedUser && formik.setValues(prevValues => ({
            ...prevValues,
            roleIds: rolePermissionsForSelectedUser.map(r => r.id)
        }))
    }, [rolePermissionsForSelectedUser])

    useEffect(() => {
        if (!rolePermissions) return;
        let formattedRolePermissionsDataArray: customGroupPermissionsType[] = [];
        const namePascalCaseToTextRolePermissions: any = rolePermissions.map((data: rolePermissionType) => {
            return {
                ...data,
                name: namePascalCaseToText(data?.name),
            }
        });
        const permissionsByGroupId = groupBy(namePascalCaseToTextRolePermissions, 'rolesGroupId');
        const keys: any[] = permissionsByGroupId && Object.keys(permissionsByGroupId);
        for (let i = 0; i < keys.length; i++) {
            const formattedChildPermissionsData: childPermissionsType[] =
                permissionsByGroupId[keys[i]]?.map((rolePermission: rolePermissionType) => {
                    return {
                        id: rolePermission.id,
                        name: rolePermission?.name,
                        isSelected: false,
                    }
                });
            let formattedRolePermissionsData: customGroupPermissionsType = {
                childPermissions: [],
                isSelected: false,
                rolesGroupId: "",
                rolesGroupName: ""
            };
            formattedRolePermissionsData['childPermissions'] = formattedChildPermissionsData ?? [];
            formattedRolePermissionsData['rolesGroupId'] = permissionsByGroupId[keys[i]][0]?.rolesGroupId ?? "";
            formattedRolePermissionsData['isSelected'] = false;
            formattedRolePermissionsData['rolesGroupName'] = permissionsByGroupId[keys[i]][0]?.rolesGroupName ?? "";

            formattedRolePermissionsDataArray.push(formattedRolePermissionsData);
        }

        setFormattedRolePermissions(formattedRolePermissionsDataArray);
    }, [rolePermissions]);

    const formik = useFormik({
        initialValues: selectedUser,
        enableReinitialize: true,
        onSubmit: (values) => {
            setIsLoading(true)
            if(selectedUser.id){
                 dispatch(updateClientUser({...values},
                    (response: apiResponseType) => {
                        setIsLoading(false);
                        toast.success(response?.message ? response.message : 'Successfully updated!');
                    },
                    (error: any) => {
                        setIsLoading(false);
                        toast.error(error?.response?.data?.message ? 
                            error?.response?.data?.data :
                            'Update user failed!');
                    }));
            }else{
                user?.clientId && createClientUser({
                        ...values,
                        clientId: user?.clientId
                    }, (response: apiResponseType) => {
                        setIsLoading(false);
                        resetUserManagementForm();
                        toast.success(response?.message ? response.message : 'Successfully created!');
                    },
                    (error: any) => {
                        setIsLoading(false);
                        toast.error(error?.response?.data?.message ?
                             error?.response?.data?.data :
                             'Create user failed!');
                    });
            }
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required("Name is required"),
            designation: Yup.string().required("Designation is required"),
            email: Yup.string().required("Contact email is required").email("Please enter a valid email"),
            roleIds: Yup.array(),
            isNotEdit: Yup.boolean().default(!selectedUser?.id),
            userName: Yup.string().when('isNotEdit', {
                is: true,
                then: Yup.string().required("Username is required"),
            }),
            newPassword: Yup.string().when('isNotEdit', {
                is: true,
                then: Yup.string().required("Password is required")
                    .min(8, 'Password is too short - should be 8 chars minimum.')
                    .matches(
                        PASSWORD_REG_EEP,
                        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
                    )
            }),
            password: Yup.string().when('isEdit', {
                is: true,
                then: Yup.string().required("Confirm password is required").when("newPassword", {
                    is: (val: any) => (!!(val && val.length > 0)),
                    then: Yup.string().oneOf(
                        [Yup.ref("newPassword")],
                        "Both password need to be the same"
                    )
                })
            })
        })
    });

    const resetUserManagementForm = () => {
        if (selectedUser.id) {
            navigate('/user-list')
        } else {
            formik?.resetForm();
            setSelectedUser(initialValues);
            setIsResetPermissions(true);
        }
    }

    const onChangePassword = (user: any) => {
        dispatch(
            changeresetUserPassword(user, (response) => {
                toast.success(response?.message ? response.message : 'Successfully changed password!');
            }, (error: any) => {
                toast.error(error?.message ? error.message : 'Change password error!');
            }));
    }

    return (
        <AppLayout>
            <>
                {isOpenChangePassword && (
                    <CustomModal size="sm" isClose={!isOpenChangePassword}
                                 title="Change Password"
                                 onClose={() => setIsOpenChangePassword(false)}>
                        <ChangePasswordForm
                            name={selectedUser.name}
                            userName={selectedUser.userName}
                            onChangePassword={(data: any) => {
                                onChangePassword(data);
                                setIsOpenChangePassword(false);
                            }}
                        />
                    </CustomModal>
                )}
                <div className="row">
                    <div className="col-md">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb breadcrumb-style1 d-flex align-items-center">
                                <li className="breadcrumb-item d-flex align-items-center">
                                    <a href="javascript:void(0);">Home</a>
                                </li>
                                <li className="breadcrumb-item d-flex align-items-center">
                                    <a href="javascript:void(0);">User Management</a>
                                </li>
                                <li className="breadcrumb-item d-flex align-items-center active">
                                    {urlHiddenState?.action === clientActionType.view ? "View User Details" :
                                        urlHiddenState?.action === clientActionType.update? "Edit User Details" :"Add New User"
                                    }
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <div className="card shadow-sm">
                            <div
                                className="border-bottom p-3">

                                <div className="card shadow-none p-0 m-0">
                                    <form className="w-100" onSubmit={formik.handleSubmit} method="POST">
                                        <div className="row justify-content-between"
                                             style={urlHiddenState?.action === clientActionType.view ? {pointerEvents: 'none'} : {}}>
                                            <div className="col-4">
                                                <h6 className="secondry-color mb-0">User Details</h6>
                                                <div className="card-body px-0 pt-0">
                                                    <div className="row">
                                                        <div className="col">
                                                            <label htmlFor=""
                                                                   className="col-form-label text-capitalize fw-semibold">Name
                                                                <span
                                                                    className="app-color-danger fw-bolder ms-1">*</span>
                                                            </label>
                                                            <div className="col-md d-flex justify-content-end">
                                                                <input
                                                                    name="name"
                                                                    value={formik.values.name}
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                    type="text"
                                                                    className="form-control form-control-sm"
                                                                    placeholder=""
                                                                />
                                                            </div>
                                                            {formik.errors.name && formik.touched.name && (
                                                                <p style={{color: "red"}}>{formik.errors.name}</p>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <label htmlFor="email3"
                                                                   className="col-form-label text-capitalize fw-semibold">Email
                                                                <span
                                                                    className="app-color-danger fw-bolder ms-1">*</span>
                                                            </label>
                                                            <div className="col-md d-flex justify-content-end">
                                                                <input
                                                                    name="email"
                                                                    value={formik.values.email}
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                    type="text"
                                                                    className="form-control form-control-sm"
                                                                    id="email3"
                                                                    placeholder=""
                                                                />
                                                            </div>
                                                            {formik.errors.email && formik.touched.email && (
                                                                <p style={{color: "red"}}>{formik.errors.email}</p>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <label htmlFor="designation"
                                                                   className="col-form-label text-capitalize fw-semibold">Designation
                                                                <span
                                                                    className="app-color-danger fw-bolder ms-1">*</span>
                                                            </label>
                                                            <div className="col-md d-flex justify-content-end">
                                                                <input
                                                                    name="designation"
                                                                    value={formik.values.designation}
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                    type="text"
                                                                    className="form-control form-control-sm"
                                                                    id="designation"
                                                                    placeholder=""
                                                                />
                                                            </div>
                                                            {formik.errors.designation && formik.touched.designation && (
                                                                <p style={{color: "red"}}>{formik.errors.designation}</p>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <p className="secondry-color fw-semibold mb-0">Credential Details</p>
                                                <div className="card-body px-0 pt-0">
                                                    {selectedUser?.name ? (
                                                        <>
                                                            <label htmlFor="user"
                                                                   className="col-form-label text-capitalize fw-semibold">Username
                                                                <span
                                                                    className="app-color-danger fw-bolder ms-1">*</span>
                                                            </label>
                                                            <div className="col-md d-flex justify-content-end">
                                                                <input
                                                                    name="userName"
                                                                    value={formik.values.userName}
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                    type="text"
                                                                    className="form-control form-control-sm"
                                                                    placeholder=""
                                                                    readOnly={true}
                                                                    onClick={() => setBlockedAutoFill(false)}
                                                                />
                                                            </div>
                                                            {formik.errors.userName && formik.touched.userName && (
                                                                <p style={{color: "red"}}>{formik.errors.userName}</p>
                                                            )}
                                                            <div className="form-password-toggle">
                                                                <label htmlFor="user"
                                                                       className="col-form-label text-capitalize fw-semibold">
                                                                </label>
                                                                <div className="input-group input-group-merge">
                                                                    <button
                                                                        style={{display: urlHiddenState?.action === clientActionType.update ? 'auto' : 'none'}}
                                                                        type="button"
                                                                        className="btn-outline-primary form-control form-control-sm"
                                                                        onClick={() => setIsOpenChangePassword(true)}
                                                                    >Change Password
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <div className="row">
                                                                <div className="col">
                                                                    <label htmlFor="user"
                                                                           className="col-form-label text-capitalize fw-semibold">Username
                                                                        <span
                                                                            className="app-color-danger fw-bolder ms-1">*</span>
                                                                    </label>
                                                                    <div className="col-md d-flex justify-content-end">
                                                                        <input
                                                                            name="userName"
                                                                            value={formik.values.userName}
                                                                            onChange={formik.handleChange}
                                                                            onBlur={formik.handleBlur}
                                                                            type="text"
                                                                            className="form-control form-control-sm"
                                                                            placeholder=""
                                                                            readOnly={isBlockedAutoFill}
                                                                            onClick={() => setBlockedAutoFill(false)}
                                                                        />
                                                                    </div>
                                                                    {formik.errors.userName && formik.touched.userName && (
                                                                        <p style={{color: "red"}}>{formik.errors.userName}</p>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col">
                                                                    <div className="form-password-toggle">
                                                                        <label htmlFor=""
                                                                               className="col-form-label text-capitalize fw-semibold">
                                                                            New Password
                                                                            <span
                                                                                className="app-color-danger fw-bolder ms-1">*</span>
                                                                        </label>
                                                                        <div className="input-group input-group-merge">
                                                                            <input
                                                                                name="newPassword"
                                                                                value={formik.values.newPassword}
                                                                                onChange={formik.handleChange}
                                                                                onBlur={formik.handleBlur}
                                                                                type={isPasswordOpenIcon ? "text" : "password"}
                                                                                className="form-control form-control-sm"
                                                                                autoComplete="off"
                                                                                readOnly={isBlockedAutoFill}
                                                                                onClick={() => setBlockedAutoFill(false)}
                                                                            />
                                                                            <span onClick={()=>setIsPasswordOpenIcon(!isPasswordOpenIcon)}
                                                                                    className="input-group-text cursor-pointer">
                                                                                        <i className={`bx ${ isPasswordOpenIcon ?
                                                                                                'bx-show' : 
                                                                                                'bx-hide'}`}></i></span>
                                                                        </div>
                                                                        {formik.errors.newPassword && formik.touched.newPassword && (
                                                                            <p style={{color: "red"}}>{formik.errors.newPassword}</p>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col">
                                                                    <div className="form-password-toggle">
                                                                        <label htmlFor="paswordconfirm"
                                                                               className="col-form-label text-capitalize fw-semibold">
                                                                            Confirm Password
                                                                            <span
                                                                                className="app-color-danger fw-bolder ms-1">*</span>
                                                                        </label>
                                                                        <div className="input-group input-group-merge">
                                                                            <input name="password"
                                                                                   value={formik.values.password}
                                                                                   onChange={formik.handleChange}
                                                                                   onBlur={formik.handleBlur}
                                                                                   type={isConfirmPasswordOpenIcon ? "text" : "password"}
                                                                                   className="form-control form-control-sm"
                                                                                   readOnly={isBlockedAutoFill}
                                                                                   onClick={() => setBlockedAutoFill(false)}
                                                                            />
                                                                            <span onClick={()=>setIsConfirmPasswordOpenIcon(!isConfirmPasswordOpenIcon)}
                                                                                    className="input-group-text cursor-pointer"
                                                                                ><i  className={`bx ${ isConfirmPasswordOpenIcon ?
                                                                                                'bx-show' : 
                                                                                                'bx-hide'}`}></i></span>
                                                                        </div>
                                                                        {formik.errors.password && formik.touched.password && (
                                                                            <p style={{color: "red"}}>{formik.errors.password}</p>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-6">

                                                <div className="card-body p-3 pt-0 px-0">
                                                    <SelectUserPolicies
                                                        canReset={isResetPermissions}
                                                        data={formattedRolePermissions}
                                                        selectedIds={formik.values?.roleIds ? formik.values?.roleIds : []}
                                                        type={selectedUser?.id ? SELECT_USER_PERMISSION_TYPE.UPDATE : SELECT_USER_PERMISSION_TYPE.UPDATE}
                                                        onSelect={(ids: string[]) => {
                                                            formik.setValues(prevValues => ({
                                                                ...prevValues,
                                                                roleIds: ids
                                                            }));
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-footer p-0 pt-3 border-1 border-top">
                                            <div className="row mx-0">
                                                <div className="col p-0 d-flex align-items-end justify-content-end">
                                                    <div className="p-0 d-flex flex-wrap d-grid gap-2">
                                                        <button type="button"
                                                                onClick={() => resetUserManagementForm()}
                                                                className="btn btn-outline-secondary btn-sm">
                                                            Cancel
                                                        </button>
                                                        <button type="submit"
                                                                style={{
                                                                    pointerEvents: isLoading ? 'none' : 'auto',
                                                                    display: urlHiddenState?.action === clientActionType.view ? 'none' : 'auto'
                                                                }}
                                                                className="btn btn-primary btn-sm">
                                                            {selectedUser?.name ? 'Update' : 'Save'}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        </AppLayout>
    )
}

const mapStateToProps = (state: any) => {
    const {user} = state?.auth;
    return {user}
}
export default connect(mapStateToProps, null)(ClientUserDetails);