import React from 'react';

const AppFooter = () => {
    return (
        <footer className="content-footer footer bg-footer-theme border-2 border-top">
            <div className="container-xxl py-2 ">
                <div className="mb-2 mb-md-0 text-end">
                    Copyright © 2022 GIA+ Quality Assurance system. All rights reserved.
                </div>
            </div>
        </footer>
    )
};
export default AppFooter;