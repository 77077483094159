import React, { useEffect, useState } from "react";
import AppLayout from "../../Layout/AppLayout";
import Input from "../../../components/elements/Input";
import { useFormik } from "formik";
import { IMonthsType } from "../../../components/AuditPlanner";
import { connect, useDispatch } from "react-redux";
import { IGlobalTemplateType } from "../../../redux/modules/auditManagementReducer";
import {
  GetAssigneeTemplatesByClientId,
  GetLocalTemplatesWithSchedule,
  UpdateLocalTemplateSchedule,
} from "../../../actions/auditActions";
import moment from "moment";
import { apiResponseType } from "../../../config/commonTypes";
import {
  GET_AUDIT_LOCAL_TEMPLATES_FOR_CLIENT_FAILURE,
  GET_AUDIT_LOCAL_TEMPLATES_FOR_CLIENT_REQUEST,
  GET_AUDIT_LOCAL_TEMPLATES_FOR_CLIENT_SUCCESS,
  LOCAL_TEMPLATE_PLANNING_SCHEDULE_UPDATED_INTERNAL_AUDIT,
} from "../../../constants";
import { toast } from "react-toastify";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import logoImage from "../../../assets/img/logo/logo.png";
import AccessControl from "../../../config/AccessControl";
import {
  AccessControlType,
  UserPermissionsConstant,
} from "../../../constants/constants";
import AuditPlannerForLocal from "../../../components/AuditPlannerForLocal";
import {
  clientType,
  ILocalTemplateSchedule,
  ILocalTemplateType,
} from "../../../redux/modules/clientManagementReducer";
import _ from "lodash";
import { sortByString } from "../../../config/commonMethods";

interface IPropsTypes {
  templateList?: ILocalTemplateType[];
  isLoading?: boolean;
  clientId?: number;
  isSuperAdmin?: boolean;
  selectedClientDetails?: clientType
}

const AddNewInternalAuditPlanner = ({
  templateList,
  isLoading,
  clientId,
  isSuperAdmin,
  selectedClientDetails
}: IPropsTypes) => {
  const initialValues: any = {
    code: "",
    auditName: "",
    months: "",
  };
  const dispatch = useDispatch();
  const [templates, setTemplates] = useState<ILocalTemplateType[] | []>([]);
  const [months, setMonths] = useState<IMonthsType[] | []>([]);
  const [filterValues, setFilterValues] = useState<any>(initialValues);

  useEffect(() => {
    if (templateList) {
      const isLoadFiltered =
        Object.values(filterValues).filter((f) => f !== "").length > 0;
      if (isLoadFiltered) {
        const cloneALlOriginalTemplateList: ILocalTemplateType[] =
          templateList && templateList?.length > 0 ? [...templateList] : [];
        const filterTemplates = cloneALlOriginalTemplateList?.filter(
          (template: any) =>
            (filterValues?.code
              ? template?.localTemplateCode
                  .toLowerCase()
                  .includes(filterValues?.code?.toLowerCase())
              : true) &&
            (filterValues?.auditName && template.localTemplateName
              ? template?.localTemplateName
                  .toLowerCase()
                  .includes(filterValues?.auditName?.toLowerCase())
              : true) &&
            (filterValues?.months && template?.localSchedule
              ? template?.localSchedule[filterValues.months] === true
              : true)
        );
        setTemplates(filterTemplates);
      } else {
        setTemplates(templateList);
      }
    }
  }, [templateList]);

  useEffect(() => {
    let monthsArray: IMonthsType[] = [];
    for (let i = 0; i < 12; i++) {
      let monthObj: IMonthsType = {
        text: "",
        longText: "",
        value: "",
      };
      monthObj.text = moment().month(i).format("MMM");
      monthObj.value = moment().month(i).format("MMMM").toLowerCase();
      monthObj.longText = moment().month(i).format("MMMM");
      monthsArray.push(monthObj);
    }
    setMonths(monthsArray);
  }, []);

  useEffect(() => {
    clientId && fetchLocalTemplatesWithSchedule();
  }, [clientId]);

  const fetchLocalTemplatesWithSchedule = () => {
    if (clientId) {
      dispatch({
        type: GET_AUDIT_LOCAL_TEMPLATES_FOR_CLIENT_REQUEST,
      });
      GetAssigneeTemplatesByClientId(
        clientId,
        (response: ILocalTemplateType[]) => {
          dispatch({
            type: GET_AUDIT_LOCAL_TEMPLATES_FOR_CLIENT_SUCCESS,
            payload: response,
          });
        },
        (response: apiResponseType) => {
          dispatch({
            type: GET_AUDIT_LOCAL_TEMPLATES_FOR_CLIENT_FAILURE,
            payload: response.message,
          });
        }
      );
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: (value: any) => {
      setFilterValues(value);
      const cloneALlOriginalTemplateList: ILocalTemplateType[] =
        templateList && templateList?.length > 0 ? [...templateList] : [];
      const filterTemplates = cloneALlOriginalTemplateList?.filter(
        (template: any) =>
          (value?.code
            ? template?.localTemplateCode
                .toLowerCase()
                .includes(value?.code?.toLowerCase())
            : true) &&
          (value?.auditName && template.localTemplateName
            ? template?.localTemplateName
                .toLowerCase()
                .includes(value?.auditName?.toLowerCase())
            : true) &&
          (value?.months && template?.localSchedule
            ? template?.localSchedule[value.months] === true
            : true)
      );
      setTemplates(filterTemplates);
    },
  });

  const [sortedData, setSortedData] = useState<any>([]);
  const generatePDF = () => {
    if (_.isEmpty(templates)) {
      toast.info("There is no data to print");
      return;
    }
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; //// portrait or landscape
    var doc = new jsPDF(orientation, unit, size);

    doc.addImage(logoImage, "PNG", 10, 10, 70, 70);
    doc.setFont("times");
    doc.setTextColor(65, 144, 197);
    doc.text("GIA+ Quality Assurance system", 100, 30);
    doc.text("__________________________", 100, 40);
    doc.setTextColor(0, 0, 0);
    doc.text(isSuperAdmin ? "Audit Planner" : "Internal Audit Planner", 100, 60);

    autoTable(doc, {
      head: [],
      margin: {
        top: 80,
      },
      theme:'plain',
      body: [
        ['Client', selectedClientDetails?.companyName ? selectedClientDetails?.companyName :'']
      ],
      columnStyles: {
        0:{ cellWidth: 80, textColor:'#3F3F3F'},
        1:{ overflow: "linebreak",minCellWidth:100, textColor:'#6D6D6D'},
      }
    });

    const headers: string[] = [
      "Code",
      "Audit Name",
      ...months.map((month) => month.text),
    ];
    const headKey: string[] = [
      "localTemplateCode",
      "localTemplateName",
      ...months.map((month) => month.value),
    ];
    let data: any = [];
    if(!_.isEmpty(sortedData)){
      sortedData.forEach((row: any) => {
        let rowData: any[] = [];
        headKey?.forEach((key: string) => {
          if (key !== "localTemplateCode" && key !== "localTemplateName") {
            rowData.push(row.localSchedule[key] ? "Yes" : "");
          } else rowData.push(row[key]);
        });
        data.push(rowData);
        rowData = [];
      });
    }else{
      const initSortedData = sortByString(templates, true,'localTemplateCode');
      initSortedData.forEach((row: any) => {
        let rowData: any[] = [];
        headKey?.forEach((key: string) => {
          if (key !== "localTemplateCode" && key !== "localTemplateName") {
            rowData.push(row.localSchedule[key] ? "Yes" : "");
          } else rowData.push(row[key]);
        });
        data.push(rowData);
        rowData = [];
      });
    }

    // autoTable(doc, { html: "#my-table" });
    autoTable(doc, {
      head: [headers],
      margin: {
        top: 100,
      },
      body: data,
    });
    doc.save(`Audit-Planner-${moment().format("DD-MM-YYYY-hh-mm-ss")}.pdf`);
  };

  const onChangePlan = (data: ILocalTemplateSchedule) => {
    UpdateLocalTemplateSchedule(
      data,
      (response: apiResponseType) => {
        toast.success(
          response?.message ? response.message : "Successfully updated"
        );
        dispatch({
          type: LOCAL_TEMPLATE_PLANNING_SCHEDULE_UPDATED_INTERNAL_AUDIT,
          payload: data,
        });
      },
      (response: apiResponseType) => {
        toast.error(response?.data ? response.data : "Failed");
        fetchLocalTemplatesWithSchedule();
      }
    );
  };

  return (
    <AppLayout>
      <>
        <div className="row">
          {/*Custom style1 Breadcrumb -->*/}
          <div className="col-md">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb breadcrumb-style1 d-flex align-items-center">
                <li className="breadcrumb-item d-flex align-items-center">
                  <a>Home</a>
                              </li>
                              <li className="breadcrumb-item d-flex align-items-center">
                                  <a>Internal Audits</a>
                              </li>
                <li className="breadcrumb-item d-flex align-items-center active">
                  Internal Audit Planner
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div className="row">
          <div className="col-md">
            <div className="card shadow-sm">
              <div className="d-flex justify-content-between align-items-center card-header mb-0 border-bottom">
                <div>
                  <h6 className="m-0">Audit Planner</h6>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <form onSubmit={formik.handleSubmit} method="POST">
            <div className="col-md">
              <div className="accordion" id="accordionExample">
                <div className="card accordion-item shadow-sm rounded-0 rounded-top active">
                  <h6 className="accordion-header" id="headingOne">
                    <button
                      type="button"
                      className="accordion-button border-bottom p-3"
                      data-bs-toggle="collapse"
                      data-bs-target="#accordionOne"
                      aria-expanded="true"
                      aria-controls="accordionOne"
                    >
                      Search Filters
                    </button>
                  </h6>

                  <div
                    id="accordionOne"
                    className="accordion-collapse collapse show"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body p-3 pt-2">
                      <div className="row">
                        <div className="col">
                          <Input
                            id="code"
                            name="code"
                            onChange={(e: any) => {
                              formik.handleChange(e);
                              formik.submitForm();
                            }}
                            type="text"
                            label="Code"
                            inputClasses="form-control form-control-sm"
                            labelClasses="col-form-label text-capitalize fw-semibold"
                          />
                        </div>
                        <div className="col">
                          <Input
                            id="auditName"
                            name="auditName"
                            onChange={(e: any) => {
                              formik.handleChange(e);
                              formik.submitForm();
                            }}
                            type="text"
                            label="Audit Name"
                            inputClasses="form-control form-control-sm"
                            labelClasses="col-form-label text-capitalize fw-semibold"
                          />
                        </div>
                        <div className="col">
                          <label
                            htmlFor="defaultFormControlInput"
                            className="col-form-label  text-capitalize fw-semibold"
                          >
                            Months
                          </label>
                          <select
                            id="months"
                            onChange={(e: any) => {
                              formik.handleChange(e);
                              formik.submitForm();
                            }}
                            className="form-select form-select-sm"
                          >
                            <option value="">Select</option>
                            {months.map((month) => (
                              <option value={month.value}>
                                {month.longText}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="col d-flex align-items-end">
                          <div className="py-3 pb-0 d-flex flex-wrap justify-content-end d-grid gap-2 w-100">
                            <AccessControl
                              type={AccessControlType.DISABLE}
                              allowedPermissions={[
                                UserPermissionsConstant.PrintAuditPlan,
                                UserPermissionsConstant.MimicAccess,
                              ]}
                            >
                              <button
                                type="button"
                                onClick={() => generatePDF()}
                                className="btn btn-outline-secondary btn-sm"
                              >
                                <span className="tf-icons bx bx-printer"></span>
                                &nbsp; Print
                              </button>
                            </AccessControl>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>

        <div className="row">
          <div className="col">
            <div className="card shadow-sm rounded-0 rounded-bottom border-top">
              <AccessControl
                type={AccessControlType.DISABLE}
                allowedPermissions={[
                  UserPermissionsConstant.ChangeAuditPlan,
                  UserPermissionsConstant.MimicAccess,
                ]}
              >
                <div className="align-items-center card-header mb-0 border-bottom p-3">
                  {templates && (
                    <AuditPlannerForLocal
                      data={templates}
                      monthsArray={months}
                      onChange={(data: ILocalTemplateSchedule) => {
                        onChangePlan(data);
                      }}
                      setSortData= {(data)=>setSortedData(data)}
                    />
                  )}
                </div>
              </AccessControl>
            </div>
          </div>
        </div>
      </>
    </AppLayout>
  );
};
const mapStateToProps = (state: any) => {
  const auditManageMent = state?.auditManagement;
  const selectedClientDetails : clientType | undefined = state?.auth?.clientDetails;
  return {
    isLoading: auditManageMent?.isLoading,
    templateList: auditManageMent?.localTemplateListPlan,
    clientId: state?.auth?.clientDetails?.clientId,
    isSuperAdmin: state?.auth?.user?.isSuperAdmin,
    selectedClientDetails
  };
};
export default connect(mapStateToProps, null)(AddNewInternalAuditPlanner);
