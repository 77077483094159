import React, { useEffect, useState } from "react";
import AppLayout from "../../Layout/AppLayout";
import ClientDetailsTab, { createNewClientFormType } from "./ClientDetailsTab";
import AuditPlannerTab from "./AuditPlannerTab";
import UserManagementTab from "./UserManagementTab";
import { connect, useDispatch } from "react-redux";
import {
  createClientUser,
  createNewClient,
  deleteUserByUserId,
  getAllUserByClientId,
  getClient,
  getClientsForMimik,
  updateClient,
  updateClientUser,
} from "../../../actions/clientAction";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { clientType } from "../../../redux/modules/clientManagementReducer";
import { getAllPermissions } from "../../../actions/commonAction";
import {
  apiResponseType,
  childPermissionsType,
  customGroupPermissionsType,
  rolePermissionType,
  userType,
} from "../../../config/commonTypes";
import { groupBy, namePascalCaseToText } from "../../../config/commonMethods";
import { RESET_CLIENT_DATA } from "../../../constants";
import {
  AccessControlType,
  clientActionType,
  createOrUpdateClientTabs,
  UserPermissionsConstant,
} from "../../../constants/constants";
import { ConfirmModal } from "../../../components/modal/ConfirmModal";
import { changeresetUserPassword } from "../../../actions/userAction";
import CustomModal from "../../../components/modal/CustomModal";
import { ChangePasswordForm } from "./ChangePasswordForm";
import AccessControl from "../../../config/AccessControl";
import AuditPlannerWrapper from "./AuditPlannerWrapper";

interface propsTypes {
  isLoading?: boolean;
  client?: clientType;
  users?: userType[] | null;
  userPermissions?: any;
}

function AddNewClient({
  isLoading,
  client,
  users,
  userPermissions,
}: propsTypes) {
  const [tabs, setTabs] = useState<any>(
    createOrUpdateClientTabs.CLIENT_DETAILS_TAB
  );
  const dispatch = useDispatch();
  const { clientId } = useParams();
  const navigate = useNavigate();
  const urlHiddenState: any = useLocation().state;
  const [rolePermissions, setRolePermissions] =
    useState<rolePermissionType[]>();
  const [formattedRolePermissions, setFormattedRolePermissions] =
    useState<customGroupPermissionsType[]>();
  const [clientUsers, setClientUsers] = useState<userType[]>();
  const [isCreated, setIsCreated] = useState<boolean>(false);
  const [isDelete, setIsDelete] = useState<boolean>(false);
  const [userIdForDelete, setUserIdForDelete] = useState<string | null>();
  const [createdClientId, setCreatedClientId] = useState<number>();
  const [isOpenChangePassword, setIsOpenChangePassword] =
    useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<any>();
  useEffect(() => {
    //if copy and past the update url, It will redirect to client list, bcz it not good way
    urlHiddenState?.action === undefined && clientId !== undefined
      ? navigate("/client-list")
      : "";
    if (urlHiddenState?.tab === createOrUpdateClientTabs.USER_PERMISSIONS_TAB) {
      const tabsDomElement = document.querySelector(
        ".USER_PERMISSIONS_TAB"
      ) as HTMLElement | null;
      if (tabsDomElement) {
        tabsDomElement?.click();
      }
    }
  }, [urlHiddenState]);

  useEffect(() => {
    if (clientId) {
      dispatch(getClient(parseInt(clientId)));
    } else {
      const tabsDomElement = document.querySelector(
        ".CLIENT_DETAILS_TAB"
      ) as HTMLElement | null;
      if (tabsDomElement) {
        tabsDomElement?.click();
      }
      dispatch({
        type: RESET_CLIENT_DATA,
      });
    }
  }, [clientId]);

  useEffect(() => {
    getAllPermissions(
      "ClientUser",
      (data: rolePermissionType[]) =>
        setRolePermissions(data?.filter((role) => role.isActive)),
      (error: any) => console.log(error)
    );

    return () => {
      dispatch({
        type: RESET_CLIENT_DATA,
      });
    };
  }, []);

  useEffect(() => {
    users && setClientUsers(users);
  }, [users]);

  useEffect(() => {
    if (!rolePermissions) return;
    let formattedRolePermissionsDataArray: customGroupPermissionsType[] = [];
    const namePascalCaseToTextRolePermissions: any = rolePermissions.map(
      (data: rolePermissionType) => {
        return {
          ...data,
          name: namePascalCaseToText(data?.name),
        };
      }
    );
    const permissionsByGroupId = groupBy(
      namePascalCaseToTextRolePermissions,
      "rolesGroupId"
    );
    const keys: any[] =
      permissionsByGroupId && Object.keys(permissionsByGroupId);
    for (let i = 0; i < keys.length; i++) {
      const formattedChildPermissionsData: childPermissionsType[] =
        permissionsByGroupId[keys[i]]?.map(
          (rolePermission: rolePermissionType) => {
            return {
              id: rolePermission.id,
              name: rolePermission?.name,
              isSelected: false,
            };
          }
        );
      let formattedRolePermissionsData: customGroupPermissionsType = {
        childPermissions: [],
        isSelected: false,
        rolesGroupId: "",
        rolesGroupName: "",
      };
      formattedRolePermissionsData["childPermissions"] =
        formattedChildPermissionsData ?? [];
      formattedRolePermissionsData["rolesGroupId"] =
        permissionsByGroupId[keys[i]][0]?.rolesGroupId ?? "";
      formattedRolePermissionsData["isSelected"] = false;
      formattedRolePermissionsData["rolesGroupName"] =
        permissionsByGroupId[keys[i]][0]?.rolesGroupName ?? "";

      formattedRolePermissionsDataArray.push(formattedRolePermissionsData);
    }

    setFormattedRolePermissions(formattedRolePermissionsDataArray);
  }, [rolePermissions]);

  const createOrUpdateClient = (
    newClient: createNewClientFormType,
    onSuccess: () => void
  ) => {
    if (clientId) {
      dispatch(
        updateClient(
          { clientId: parseInt(clientId), ...newClient },
          (response) => {
            dispatch(getClient(parseInt(clientId)));
            dispatch(getClientsForMimik());
            toast.success(
              response?.message ? response.message : "Successfully updated!"
            );
          },
          (error: any) => {
            toast.error(
              error?.response?.data?.message
                ? error?.response?.data?.message
                : "Update client failed!"
            );
          }
        )
      );
    } else {
      dispatch(
        createNewClient(
          newClient,
          (response) => {
            if (checkPermissionsForEditClients()) {
              setIsCreated(true);
              setCreatedClientId(response.data?.clientId);
            } else {
              toast.success(
                response?.message ? response.message : "Successfully created!"
              );
            }
            dispatch(getClientsForMimik());
            onSuccess && onSuccess();
          },
          (error: any) => {
            toast.error(
              error?.response?.data?.message
                ? error?.response?.data?.message
                : "Create client failed!"
            );
          }
        )
      );
    }
  };

  const checkPermissionsForEditClients = () => {
    return (
      userPermissions?.includes(UserPermissionsConstant.EditClients) &&
      (userPermissions?.includes(UserPermissionsConstant.AddClientUser) ||
        userPermissions?.includes(UserPermissionsConstant.EditClientUser) ||
        userPermissions?.includes(UserPermissionsConstant.ViewClientUser) ||
        userPermissions?.includes(UserPermissionsConstant.DeleteClientUser))
    );
  };
  function onCreateOrUpdateUser(user: any, onSuccess: () => void) {
    if (user?.id) {
      clientId &&
        dispatch(
          updateClientUser(
            {
              clientId,
              ...user,
              isClientAdmin: user?.roleIds?.length === rolePermissions?.length,
            },
            (response: apiResponseType) => {
              clientId && getAllUsers(parseInt(clientId));
              toast.success(
                response?.message ? response.message : "Successfully updated!"
              );
              onSuccess && onSuccess();
            },
            (error: apiResponseType) => {
              toast.error(
                error?.message ? error?.message : "Create user failed!"
              );
            }
          )
        );
    } else {
      clientId &&
        dispatch(
          createClientUser(
            {
              clientId,
              ...user,
              isClientAdmin: user?.roleIds?.length === rolePermissions?.length,
            },
            (response: any) => {
              clientId && getAllUsers(parseInt(clientId));
              toast.success(
                response?.message ? response.message : "Successfully created!"
              );
              onSuccess && onSuccess();
            },
            (error: any) => {
              toast.error(
                error?.response?.data?.message
                  ? error?.response?.data?.data
                  : "Create user failed!"
              );
            }
          )
        );
    }
  }

  const getAllUsers = (clientId: number) => {
    dispatch(getAllUserByClientId(clientId));
  };

  const switchTab = (tab: string) => {
    switch (tab) {
      case createOrUpdateClientTabs.CLIENT_DETAILS_TAB:
        setTabs(createOrUpdateClientTabs.CLIENT_DETAILS_TAB);
        break;
      case createOrUpdateClientTabs.AUDIT_PLANNER_TAB:
        clientId && setTabs(createOrUpdateClientTabs.AUDIT_PLANNER_TAB);
        break;
      case createOrUpdateClientTabs.USER_PERMISSIONS_TAB:
        clientId && setTabs(createOrUpdateClientTabs.USER_PERMISSIONS_TAB);
        clientId && getAllUsers(parseInt(clientId));
        break;
      default:
        return;
    }
  };

  const onChangePassword = (user: any) => {
    dispatch(
      changeresetUserPassword(
        user,
        (response) => {
          toast.success(
            response?.message
              ? response.message
              : "Successfully changed password!"
          );
        },
        (error: any) => {
          toast.error(
            error?.message ? error.message : "Change password error!"
          );
        }
      )
    );
  };
  const deleteUserById = (userIdForDelete: string) => {
    setIsDelete(false);
    setUserIdForDelete(null);
    dispatch(
      deleteUserByUserId(
        userIdForDelete,
        (response: apiResponseType) => {
          toast.success(
            response?.message ? response.message : "Successfully deleted user!"
          );
        },
        (error: apiResponseType) => {
          toast.error(error?.message ? error.message : "Delete user failed!");
        }
      )
    );
  };
  return (
    <AppLayout>
      <>
        {isOpenChangePassword && (
          <CustomModal
            size="sm"
            isClose={!isOpenChangePassword}
            title="Change Password"
            onClose={() => setIsOpenChangePassword(false)}
          >
            <ChangePasswordForm
              name={selectedUser.name}
              userName={selectedUser.userName}
              onChangePassword={(data: any) => {
                onChangePassword(data);
                setIsOpenChangePassword(false);
              }}
            />
          </CustomModal>
        )}
        {isCreated && (
          <ConfirmModal
            onCanceled={() => {
              setIsCreated(false);
              navigate("/client-list");
            }}
            isOpenConfirmModal={isCreated}
            title={"Client details"}
            btn1Text={"No"}
            btn1Class={"btn btn-outline-secondary btn-sm"}
            btn2Text={"Yes"}
            btn2Class="btn btn-primary btn-sm"
            icon={
              <i className="bx bx-check-circle display-4 app-col link-success"></i>
            }
            message={
              "Client has been created successfully. " +
              "Do you want to proceed to User Management?"
            }
            onConfirm={() => {
              createdClientId &&
                navigate(
                  {
                    pathname: "/edit-client/" + createdClientId,
                  },
                  {
                    state: {
                      action: clientActionType.update,
                      tab: createOrUpdateClientTabs.USER_PERMISSIONS_TAB,
                    },
                  }
                );
              setIsCreated(false);
            }}
          />
        )}
        {isDelete && (
          <ConfirmModal
            onCanceled={() => setIsDelete(false)}
            isOpenConfirmModal={isDelete}
            title={"Delete User"}
            btn1Text={"Cancel"}
            btn1Class={"btn btn-outline-secondary btn-sm"}
            btn2Text={"Confirm"}
            btn2Class="btn btn-danger btn-sm"
            icon={
              <i className="bx bx-error-circle display-4 app-col link-warning"></i>
            }
            message={"Are you sure you want to delete this item?"}
            onConfirm={() => userIdForDelete && deleteUserById(userIdForDelete)}
          />
        )}
        <div className="row">
          <div className="col-md">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb breadcrumb-style1 d-flex align-items-center">
                <li className="breadcrumb-item d-flex align-items-center">
                  <a href="javascript:void(0);">Home</a>
                </li>
                <li className="breadcrumb-item d-flex align-items-center">
                  <a href="javascript:void(0);">Client Management</a>
                </li>
                <li className="breadcrumb-item d-flex align-items-center active">
                  {   tabs === createOrUpdateClientTabs.CLIENT_DETAILS_TAB ?
                        clientId ? 
                          urlHiddenState?.action === "UPDATE" ?
                            'Update Client' :
                            urlHiddenState?.action === "VIEW" ? 'View Client' :''
                        : 'Add New Client' 
                    : tabs === createOrUpdateClientTabs.AUDIT_PLANNER_TAB ?
                        clientId ? 
                          urlHiddenState?.action === "UPDATE" ?
                              'Update Client' :
                          urlHiddenState?.action === "VIEW" ? 
                              'View Client' : '' :''
                    : tabs === createOrUpdateClientTabs.USER_PERMISSIONS_TAB ?
                        clientId ? 
                          urlHiddenState?.action === "UPDATE" ?
                            'Update Client' :
                          urlHiddenState?.action === "VIEW" ? 'View Client' 
                        : '' :'':''

                  }
                </li>
              </ol>
            </nav>
          </div>
        </div>

        <div className="row">
          <div className="nav-align-top">
            <ul className="nav nav-tabs" role="tablist">
              <li className="nav-item">
                <AccessControl
                  type={AccessControlType.DISABLE}
                  allowedPermissions={[
                    UserPermissionsConstant.AddClients,
                    UserPermissionsConstant.EditClients,
                    UserPermissionsConstant.ViewClients,
                  ]}
                >
                  <button
                    type="button"
                    className="nav-link active CLIENT_DETAILS_TAB"
                    role="tab"
                    data-bs-toggle="tab"
                    data-bs-target="#navs-client-details"
                    aria-controls="navs-client-details"
                    aria-selected="true"
                    onClick={() =>
                      switchTab(createOrUpdateClientTabs.CLIENT_DETAILS_TAB)
                    }
                  >
                    <h6 className="m-0">Client Details</h6>
                  </button>
                </AccessControl>
              </li>
              <li className="nav-item">
                <AccessControl
                  type={AccessControlType.DISABLE}
                  allowedPermissions={[
                    UserPermissionsConstant.ChangeAuditPlan,
                    UserPermissionsConstant.PrintAuditPlan,
                  ]}
                >
                  <button
                    style={!clientId ? { pointerEvents: "none" } : {}}
                    type="button"
                    className="nav-link AUDIT_PLANNER_TAB"
                    role="tab"
                    data-bs-toggle="tab"
                    data-bs-target="#navs-internal-audit-planner"
                    aria-controls="navs-internal-audit-planner"
                    aria-selected="false"
                    onClick={() =>
                      switchTab(createOrUpdateClientTabs.AUDIT_PLANNER_TAB)
                    }
                  >
                    <h6 className="m-0">Internal Audit Planner</h6>
                  </button>
                </AccessControl>
              </li>
              <li className="nav-item">
                <AccessControl
                  type={AccessControlType.DISABLE}
                  allowedPermissions={
                    urlHiddenState?.action === clientActionType.update
                      ? [
                          UserPermissionsConstant.AddClientUser,
                          UserPermissionsConstant.EditClientUser,
                          UserPermissionsConstant.DeleteClientUser,
                        ]
                      : [UserPermissionsConstant.ViewClientUser]
                  }
                >
                  <button
                    style={!clientId ? { pointerEvents: "none" } : {}}
                    type="button"
                    className="nav-link USER_PERMISSIONS_TAB"
                    role="tab"
                    data-bs-toggle="tab"
                    data-bs-target="#navs-user-permissions"
                    aria-controls="navs-user-permissions"
                    aria-selected="false"
                    onClick={() =>
                      switchTab(createOrUpdateClientTabs.USER_PERMISSIONS_TAB)
                    }
                  >
                    <h6 className="m-0">User Management</h6>
                  </button>
                </AccessControl>
              </li>
            </ul>
            <div className="tab-content p-3 pt-0">
              <div
                className="tab-pane fade show active"
                id="navs-client-details"
                role="tabpanel"
              >
                {tabs === createOrUpdateClientTabs.CLIENT_DETAILS_TAB && (
                  <ClientDetailsTab
                    type={clientId ? urlHiddenState?.action : "CREATE"}
                    selectedClient={client}
                    isLoading={isLoading}
                    createNewClient={(
                      client: createNewClientFormType,
                      onSuccess
                    ) => createOrUpdateClient(client, onSuccess)}
                  />
                )}
              </div>
              <div
                className="tab-pane fade show"
                id="navs-internal-audit-planner"
                role="tabpanel"
              >
                {/*TODO: add wrapper component*/}
                {tabs === createOrUpdateClientTabs.AUDIT_PLANNER_TAB &&
                  clientId && (
                    <AuditPlannerWrapper clientId={parseInt(clientId)} />
                  )}
              </div>
              <div
                className="tab-pane fade"
                id="navs-user-permissions"
                role="tabpanel"
              >
                {tabs === createOrUpdateClientTabs.USER_PERMISSIONS_TAB && (
                  <UserManagementTab
                    openDeleteUser={(userId: string) => {
                      setIsDelete(true);
                      setUserIdForDelete(userId);
                    }}
                    users={clientUsers}
                    permissionsData={formattedRolePermissions}
                    onCreateOrUpdateUser={(user: any, onSuccess) =>
                      onCreateOrUpdateUser(user, onSuccess)
                    }
                    isLoading={isLoading}
                    selectedClient={client}
                    openChangePassword={(user: any) => {
                      setIsOpenChangePassword(true);
                      setSelectedUser(user);
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="col-md"></div>
        </div>
      </>
    </AppLayout>
  );
}

const mapStateToProps = (state: any) => {
  const client = state?.clientManagement;
  return {
    isLoading: client?.isLoading,
    client: client?.client,
    users: client?.users,
    userPermissions: state?.auth?.roles,
  };
};
export default connect(mapStateToProps, null)(AddNewClient);
