import React from 'react';

function Input({
                   id,
                   labelClasses = "col-form-label  text-capitalize fw-semibold",
                   label,
                   hideLabel = false,
                   iconSpanClasses = "input-group-text",
                   iconClasses = "bx bx-user",
                   type = 'text',
                   min = '1',
                   max = '11',
                   maxlength,
                   pattern,
                   placeholder = "",
                   name,
                   onBlur,
                   onChange,
                   inputClasses = "form-control",
                   value,
                   disabled = false,
                   refInput,
                   onKeyDown
               }: inputType) {

    const input = () => {
        return (
            <input
                id={id}
                type={type}
                min={min}
                max={max}
                pattern={pattern}
                maxLength={maxlength}
                name={name}
                placeholder={placeholder}
                value={value}
                className={inputClasses}
                onBlur={onBlur}
                onChange={onChange}
                disabled={disabled}
                ref={refInput}
                onKeyDown={onKeyDown}
            />
        )
    }
    return (
        <>
            {hideLabel ?
                <>
                <span className={iconSpanClasses}>
                    <i className={iconClasses}></i>
                </span>
                    {input()}
                </>
                :
                <>
                    <label className={labelClasses}>{label}</label>
                    <div className="col-md">
                        {input()}
                    </div>
                </>
            }


        </>
    );
}

interface inputType {
    id?: string,
    labelClasses?: string,
    label?: string,
    hideLabel?: boolean,
    iconSpanClasses?: string,
    iconClasses?: string,
    type?: string,
    min?: string,
    max?: string,
    pattern?: string,
    maxlength?: number,
    name?: string,
    placeholder?: string,
    value?: any,
    inputClasses: string,
    onBlur?: () => {},
    onChange?: (e: any) => void,
    disabled?: any,
    refInput?: any,
    onKeyDown?: () => {}
};


export default Input;
