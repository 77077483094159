import React, {useEffect, useState} from 'react';
import AppLayout from '../../Layout/AppLayout';
import {useNavigate} from "react-router-dom";
import {connect, useDispatch} from "react-redux";
import {TERMS_AND_CONDITION_POLICY_SUCCESS} from "../../../constants";
import {logOut, updateUserPolicy} from "../../../actions/userAction";
import {apiResponseType} from "../../../config/commonTypes";
import {toast} from "react-toastify";

function TermsConditions({user}:any){
    const [isAgreeWithTermsCondition, setIsAgreeWithTermsCondition] = useState<boolean>(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const logout = () => {
        dispatch(logOut())
    }
    
    useEffect(() => {
        user && setIsAgreeWithTermsCondition(user.isAgreeWithTermsCondition);
    }, [user]);

    const confirmTermsCondition =(isSelect:boolean)=>{
        if(!isSelect) return;
        updateUserPolicy(user?.userId,isSelect,'T',(response)=>{
            dispatch({
                type:TERMS_AND_CONDITION_POLICY_SUCCESS
            });
            toast.success(response?.message ? response.message : 'Successfully accepted!');
            navigate('/');
        },(error:apiResponseType)=>{
            toast.error(error?.message ? error?.message : 'Failed!');
        });
    }

    return (
        <AppLayout>
            <>

            <div className="row">
                <div className="col-md">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb breadcrumb-style1 d-flex align-items-center">
                            <li className="breadcrumb-item d-flex align-items-center">
                                    <a href="javascript:void(0);">Home</a>
                            </li>
                            <li className="breadcrumb-item d-flex align-items-center active">Terms and Conditions</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div className="card shadow-sm">
                        <div className="card-body p-3">
                            <div className="d-flex justify-content-left align-items-center card-header p-0">
                                <div>
                                    <h5 className="m-0 p-2">Terms and Conditions</h5>
                                    <h6 className="m-0 p-2 app-color-dark">Healthcare Compliance Solutions Ltd trading at GIA+:</h6>
                                    <ul>
                                        <li className='p-2'>
                                            <p className="mb-0">
                                            	Will provide Services based on the best information available to GIA+ at the time that the Services are provided and all due care will be exercised in the preparation for and delivery of all Services. Because it is not possible to foresee all possible uses of the information provided by GIA+ as a result of the delivery of any Services or to predict all future developments and trends, any subsequent action that relies on the accuracy of the information is the sole commercial decision of you and is therefore taken at your own risk.
                                            </p>
                                            </li>
                                        <li className='p-2'>
                                            <p className="mb-0">
                                                Have made and continue to make every attempt to ensure the currency, correctness, completeness and accuracy of the documents provided to you we do not warrant, expressly or impliedly nor do we assume any legal liability or responsibility.
                                            </p>
                                        </li>
                                        <li className='p-2'>
                                            <p className="mb-0">
                                                Disclaims any liability whatsoever in respect of any losses or damages arising out of the use of this GIA+ provided documentation and information.
                                            </p>
                                        </li>
                                        <li className='p-2'>
                                            <p className="mb-0">
                                            	Is not privy to every detailed aspect of your business operation therefore there may be areas of your service that GIA+ assesses as meeting expected outcomes that on further assessment or considering a larger sample of information would not fully meet the expected outcomes. HCSL and GIA+ disclaims any future liability whatsoever in respect of any losses or damages arising from these situations. 
                                            </p>
                                        </li>
                                        <li className='p-2'>
                                            <p className="mb-0">
                                                Both the Client and GIA+ will not be liable for any failure to perform or delay in performance to the extent that the cause of such failure or delay is beyond either parties reasonable control. Where possible, notice is given to the other party within 3 working days of the party becoming aware of the cause for failure. In providing notice either party will provide the other with all available information detailing the cause and give an estimate of the period of time required to overcome the delay.
                                            </p>
                                        </li>                                        
                                    </ul>
                                    <p className="mb-0">
                                        Any feedback or concerns regarding these terms and conditions should be directed to 
                                        <a href="mailto:admin@giachecks.com"> admin@giachecks.com </a>
                                        These policies will be updated from time to time to meet our legal obligations.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="card-footer p-3 border-1 border-top">
                            <div className="row mx-0">
                                <div className="col p-0 d-flex align-items-end justify-content-end"
                                style={user?.isAgreeWithTermsCondition?{pointerEvents :'none', opacity:0}:{}}>
                                    <div className="d-flex align-items-center justify-content-center">
                                        <input className="form-check-input checkbox-lg m-1"
                                               type="checkbox"
                                               checked={isAgreeWithTermsCondition}                                               
                                               onClick={(e: any) => setIsAgreeWithTermsCondition(e.target.checked)}
                                               name="isClientAdmin"/>
                                        <label className="secondry-color fw-semibold px-3">I have read and accept the terms and conditions</label>                                        
                                    </div>
                                    <div className="p-0 d-flex flex-wrap d-grid gap-2">
                                        <button type="button" 
                                        onClick={() => logout()}
                                        className="btn btn-outline-secondary btn-sm">
                                             Cancel
                                        </button>
                                        <button onClick={() => confirmTermsCondition(isAgreeWithTermsCondition)}
                                                disabled={!isAgreeWithTermsCondition}
                                                type="button"
                                                className="btn btn-primary btn-sm">
                                            Agree
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </>
        </AppLayout>
    );
    
}
const mapStateToProps = (state: any) => {
    return {
        user: state?.auth?.user,
    }
}
export default connect(mapStateToProps, null)(TermsConditions);