import React, {useEffect, useState} from "react";
import {AccessControlType, UserPermissionsConstant,} from "../../../constants/constants";
import Input from "../../../components/elements/Input";
import AccessControl from "../../../config/AccessControl";
import {useFormik} from "formik";
import {IGlobalTemplateSchedule, IGlobalTemplateType} from "../../../redux/modules/auditManagementReducer";
import AuditPlanner, {IMonthsType} from "../../../components/AuditPlanner";
import moment from "moment";
import {jsPDF} from "jspdf";
import logoImage from "../../../assets/img/logo/logo.png";
import autoTable from "jspdf-autotable";
import _ from "lodash";
import { toast } from "react-toastify";
import { sortByString } from "../../../config/commonMethods";

interface IPropsTypes {
    templateList: IGlobalTemplateType[] | [];
    isLoading?: boolean;
    onChangePlan: (data: IGlobalTemplateSchedule) => void;
}

const initialValues: any = {
    code: '',
    auditName: '',
    months: '',
}

function AuditPlannerTab({templateList, isLoading, onChangePlan}: IPropsTypes) {
    const [filterValues, setFilterValues] = useState<any>(initialValues);
    const [templates, setTemplates] = useState<IGlobalTemplateType[] | []>([]);
    const [months, setMonths] = useState<IMonthsType[] | []>([]);

    useEffect(() => {
        let monthsArray: IMonthsType[] = [];
        for (let i = 0; i < 12; i++) {
            let monthObj: IMonthsType = {
                text: "",
                longText: "",
                value: ""
            };
            monthObj.text = moment().month(i).format("MMM");
            monthObj.value = moment().month(i).format("MMMM").toLowerCase();
            monthObj.longText = moment().month(i).format("MMMM");
            monthsArray.push(monthObj);
        }
        setMonths(monthsArray);
    }, []);

    useEffect(() => {
        if (templateList) {
            const isLoadFiltered = Object.values(filterValues).filter(f => f !== '').length > 0;
            if (isLoadFiltered) {
                const cloneALlOriginalTemplateList: IGlobalTemplateType[] =
                    templateList && templateList?.length > 0 ? [...templateList] : [];
                const filterTemplates = cloneALlOriginalTemplateList?.filter(
                    (template: any) =>
                        (filterValues?.code ? template?.globalTemplateCode.toLowerCase().includes(filterValues?.code?.toLowerCase()) : true)
                        &&
                        ((filterValues?.auditName && template.globalTemplateName) ? template?.globalTemplateName.toLowerCase().includes(filterValues?.auditName?.toLowerCase()) : true)
                        &&
                        ((filterValues?.months && template?.globalSchedule) ? template?.globalSchedule[filterValues.months] === true : true)
                );
                setTemplates(filterTemplates);
            } else {
                setTemplates(templateList);
            }
        }
    }, [templateList]);

    const formik = useFormik({
        initialValues: initialValues,
        onSubmit: (value: any) => {
            setFilterValues(value);
            const cloneALlOriginalTemplateList: IGlobalTemplateType[] =
                templateList && templateList?.length > 0 ? [...templateList] : [];
            const filterTemplates = cloneALlOriginalTemplateList?.filter(
                (template: any) =>
                    (value?.code ? template?.globalTemplateCode.toLowerCase().includes(value?.code?.toLowerCase()) : true)
                    &&
                    ((value?.auditName && template.globalTemplateName) ? template?.globalTemplateName.toLowerCase().includes(value?.auditName?.toLowerCase()) : true)
                    &&
                    ((value?.months && template?.globalSchedule) ? template?.globalSchedule[value.months] === true : true)
            );
            setTemplates(filterTemplates);
        }
    });

    const [sortedData, setSortedData] = useState<any>([]);
    const generatePDF = () => {
        if (_.isEmpty(templates)) {
            toast.info("There is no data to print");
            return;
        }
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; //// portrait or landscape
        var doc = new jsPDF(orientation, unit, size);

        doc.addImage(logoImage, 'PNG', 10, 10, 70, 70);
        doc.setFont("times");
        doc.setTextColor(65, 144, 197);
        doc.text('GIA+ Quality Assurance system', 100, 30);
        doc.text('__________________________', 100, 40);
        doc.setTextColor(0, 0, 0);
        doc.text('Audit Planner', 100, 60);

        const headers: string[] = [
            "Code",
            "Audit Name",
            ...months.map(month => month.text),
        ];
        const headKey: string[] = [
            "globalTemplateCode",
            "globalTemplateName",
            ...months.map(month => month.value)
        ];
        let data: any = [];
        if(sortedData?.length > 0){
            sortedData.forEach((row: any) => {
                let rowData: any[] = [];
                headKey?.forEach((key: string) => {
                    if (key !== "globalTemplateCode" && key !== "globalTemplateName") {
                        rowData.push(row.globalSchedule[key] ? "Yes" : "");
                    } else rowData.push(row[key]);
                });
                data.push(rowData);
                rowData = [];
            });
        }else {
            const initSortedData = sortByString(templates, true,'globalTemplateCode');
            initSortedData.forEach((row: any) => {
            let rowData: any[] = [];
            headKey?.forEach((key: string) => {
                if (key !== "globalTemplateCode" && key !== "globalTemplateName") {
                    rowData.push(row.globalSchedule[key] ? "Yes" : "");
                } else rowData.push(row[key]);
            });
            data.push(rowData);
            rowData = [];
        });
    }

        // autoTable(doc, { html: "#my-table" });
        autoTable(doc, {
            head: [headers],
            margin: {
                top: 100,
            },
            body: data,
        });
        doc.save(`Audit-Planner-${moment().format('DD-MM-YYYY-hh-mm-ss')}.pdf`);
    }

    return (
        <>
            <div className="row">
                <form onSubmit={formik.handleSubmit} method="POST" className="px-0">
                    <div className="col-md">
                        <div className="accordion" id="accordionExample">
                            <div className=" accordion-item rounded-0 rounded-top shadow-none active">
                                <h6 className="accordion-header" id="headingOne">
                                    <button
                                        type="button"
                                        className="accordion-button border-bottom p-3 pt-0"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#accordionOne"
                                        aria-expanded="true"
                                        aria-controls="accordionOne"
                                    >
                                        Search Filters
                                    </button>
                                </h6>

                                <div
                                    id="accordionOne"
                                    className="accordion-collapse collapse show"
                                    data-bs-parent="#accordionExample"
                                >
                                    <div className="accordion-body p-3 pt-2">
                                        <div className="row">
                                            <div className="col">
                                                <Input
                                                    id="code"
                                                    name="code"
                                                    onChange={(e: any) => {
                                                        formik.handleChange(e);
                                                        formik.submitForm();
                                                    }}
                                                    type="text"
                                                    label="Code"
                                                    inputClasses="form-control form-control-sm"
                                                    labelClasses="col-form-label text-capitalize fw-semibold"
                                                />
                                            </div>
                                            <div className="col">
                                                <Input
                                                    id="auditName"
                                                    name="auditName"
                                                    onChange={(e: any) => {
                                                        formik.handleChange(e);
                                                        formik.submitForm();
                                                    }}
                                                    type="text"
                                                    label="Audit Name"
                                                    inputClasses="form-control form-control-sm"
                                                    labelClasses="col-form-label text-capitalize fw-semibold"
                                                />
                                            </div>
                                            <div className="col">
                                                <label htmlFor="defaultFormControlInput"
                                                       className="col-form-label  text-capitalize fw-semibold">Months</label>
                                                <select id="months"
                                                        onChange={(e: any) => {
                                                            formik.handleChange(e);
                                                            formik.submitForm();
                                                        }}
                                                        className="form-select form-select-sm">
                                                    <option value="">Select</option>
                                                    {months.map((month) => (
                                                        <option value={month.value}>{month.longText}</option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="col d-flex align-items-end">
                                                <div
                                                    className="py-3 pb-0 d-flex flex-wrap justify-content-end d-grid gap-2 w-100">
                                                    <AccessControl type={AccessControlType.DISABLE}
                                                                   allowedPermissions={[UserPermissionsConstant.PrintAuditPlan]}>

                                                        <button type="button"
                                                                onClick={() => generatePDF()}
                                                                className="btn btn-outline-secondary btn-sm">
                                                            <span className="tf-icons bx bx-printer"></span>&nbsp; Print
                                                        </button>
                                                    </AccessControl>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div className="row">
                <div className="col px-0">
                    <div className="rounded-0 rounded-bottom border-top">
                        <AccessControl type={AccessControlType.DISABLE}
                                       allowedPermissions={[UserPermissionsConstant.ChangeAuditPlan]}>
                            <div
                                className="align-items-center card-header mb-0 px-2 pb-0">
                                {templates && (<AuditPlanner
                                    data={templates}
                                    monthsArray={months}
                                    onChange={(data: IGlobalTemplateSchedule) => {
                                        onChangePlan(data)
                                    }}
                                    setSortData= {(data)=>setSortedData(data)}
                                />)}
                            </div>
                        </AccessControl>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AuditPlannerTab;
