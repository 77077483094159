import React, { useEffect, useState } from "react";
import AppLayout from "../../Layout/AppLayout";
import Input from "../../../components/elements/Input";
import { useNavigate } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { inactiveUserType } from "../../../config/commonTypes";
import { getSystemInactiveUsers } from "../../../actions/userAction";
import DataTable from "react-data-table-component";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { BootyPagination } from "../../../components/pagination/BootyPagination";
import { useFormik } from "formik";
import moment from "moment";
import logoImage from "../../../assets/img/logo/logo.png";
import _ from "lodash";
import { toast } from "react-toastify";
import {
  compareDates,
  sortByString,
  toLocalformatDate,
} from "../../../config/commonMethods";

{
  /*
TOTO: I have removed the react-data-table-component-extensions package for print data,
Here we are planned to use jsPdf package so Need to change here (I already used in print clientList
*/
}
function UserActivities({ inactiveusers }: any) {
  //This is enable sorting for activity dates
  const compareActivityDates = (rowA: any, rowB: any) => {
    // Parse the date strings using Moment.js
    const dateA = rowA.shortDateString ? moment(rowA.shortDateString) : null;
    const dateB = rowB.shortDateString ? moment(rowB.shortDateString) : null;
    return compareDates(dateA, dateB);
  };

  const formatDate = (row: any) => {
    return toLocalformatDate(row.shortDateString); // moment(row.shortDateString).format("DD/MM/YYYY");
  };

  const columns: any = [
    {
      name: "Client",
      selector: (row: any) => row.companyName,
      sortable: true,
      width: "15%",
    },
    {
      name: "User",
      selector: (row: any) => row.userDisplay,
      sortable: true,
      width: "15%",
    },
    {
      name: "Email",
      selector: (row: any) => row.userEmail,
      sortable: true,
      width: "30%",
    },
    {
      name: "Phone",
      selector: (row: any) => row.userPhone,
      sortable: true,
      width: "20%",
    },
    {
      name: "Last Activity Date",
      selector: (row: any) => toLocalformatDate(row.activityTime),
      sortable: true,
      width: "20%",
      sortFunction: compareActivityDates,
    },
  ];

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [dataTableFormat, setDataTableFormat] = useState<any>();
  const [selectedDatetValue, setselectedDatetValue] = useState<any>();
  const [sortedData, setSortesData] = useState<any>();

  const currentDatetValue = moment(new Date()).format("DD-MM-YYYY");
  const defaulDatetValue = moment(new Date())
    .subtract(14, "d")
    .format("DD-MM-YYYY");
  const maxDatetValue = moment(new Date())
    .subtract(14, "d")
    .format("DD-MM-YYYY");

  useEffect(() => {
    dispatch(getSystemInactiveUsers());
  }, []);

  const clientActions = (id: string | number) => {
    return (
      <span className="d-flex justify-content-center gap-1">
        <button
          type="button"
          onClick={() => navigate("/edit-client/" + id)}
          className="btn btn-outline-primary btn-xs app-btn-xs py-0"
        >
          <i className="bx bx-pencil  small"></i>
        </button>
        <button
          type="button"
          className="btn btn-outline-primary btn-xs app-btn-xs py-0"
        >
          <i className="bx bx-show  small"></i>
        </button>
        <button
          type="button"
          className="btn btn-outline-danger btn-xs app-btn-xs py-0"
        >
          <i className="bx bx-trash-alt  small"></i>
        </button>
      </span>
    );
  };
  useEffect(() => {
    defaultDateFiltering(inactiveusers);
  }, [inactiveusers]);

  const defaultDateFiltering = (inactiveUserArray: inactiveUserType[]) => {
    const dataSet = inactiveUserArray?.filter(
      (iUser) =>
        //moment(iUser?.activityTime).format("DD-MM-YYYY")
        toLocalformatDate(iUser?.activityTime) <=
        moment(defaulDatetValue).format("DD-MM-YYYY")
    );
    setselectedDatetValue(defaulDatetValue);
    setDataTableFormat(dataSet);
  };

  const generatePDF = () => {
    if (_.isEmpty(dataTableFormat)) {
      toast.info("There is no data to print");
      return;
    }
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; //// portrait or landscape
    var doc = new jsPDF(orientation, unit, size);

    doc.addImage(logoImage, "PNG", 10, 10, 70, 70);
    doc.setFont("times");
    doc.setTextColor(65, 144, 197);
    doc.text("GIA+ Quality Assurance system", 100, 30);
    doc.text("__________________________", 100, 40);
    doc.setTextColor(0, 0, 0);
    doc.text("Inactive Users", 100, 60);

    const headers: string[] = [
      "Client",
      "User",
      "Email",
      "Phone",
      "Activity Date",
    ];
    const headKey: string[] = [
      "companyName",
      "userDisplay",
      "userEmail",
      "userPhone",
      "activityTime",
    ];
    let data: any = [];

    if (sortedData?.length > 0) {
      sortedData.forEach((row: any) => {
        let rowData: string[] = [];
        headKey?.forEach((key: string) => {
          if (key === "activityTime") {
            rowData.push(toLocalformatDate(row[key]));
          }
          if (key === "status") {
            rowData.push(row?.indicator?.props?.children);
          } else rowData.push(row[key]);
        });
        data.push(rowData);
        rowData = [];
      });
    } else {
      const initSortedData = sortByString(dataTableFormat, true, "Client");
      initSortedData.forEach((row: any) => {
        let rowData: string[] = [];
        headKey?.forEach((key: string) => {
          if (key === "activityTime") {
            rowData.push(toLocalformatDate(row[key]));
          }
          if (key === "status") {
            rowData.push(row?.indicator?.props?.children);
          } else rowData.push(row[key]);
        });
        data.push(rowData);
        rowData = [];
      });
    }

    autoTable(doc, {
      head: [headers],
      body: data,
      margin: {
        top: 100,
      },
    });
    doc.save(`Inactive-Users-${moment().format("DD-MM-YYYY-hh-mm-ss")}.pdf`);
  };

  const initialValues: any = {
    company: "",
    mainContact: "",
    status: "",
  };
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: (value: any) => {
      const filterIactiveusers: inactiveUserType[] = inactiveusers;

      const filterCloneClients =
        value?.company && value?.startDate
          ? filterIactiveusers?.filter(
              (iUser) =>
                iUser?.companyName
                  .toLocaleLowerCase()
                  .includes(value?.company?.toLowerCase()) &&
                moment(iUser?.activityTime).format("DD-MM-YYYY") <=
                  moment(value?.startDate).format("DD-MM-YYYY")
            )
          : value?.company
          ? filterIactiveusers?.filter((iUser) =>
              iUser?.companyName
                .toLocaleLowerCase()
                .includes(value?.company?.toLowerCase())
            )
          : filterIactiveusers?.filter(
              (iUser) =>
                moment(iUser?.activityTime).format("DD-MM-YYYY") <=
                moment(value?.startDate).format("DD-MM-YYYY")
            );

      setselectedDatetValue(moment(value?.startDate).format("DD-MM-YYYY"));
      setDataTableFormat(filterCloneClients);
    },
  });
  return (
    <AppLayout>
      <>
        <div className="row">
          {/*Custom style1 Breadcrumb */}
          <div className="col-md">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb breadcrumb-style1">
                <li className="breadcrumb-item d-flex align-items-center">
                  <a href="javascript:void(0);">Home</a>
                </li>
                <li className="breadcrumb-item d-flex align-items-center">
                  <a href="javascript:void(0);">Client Management</a>
                </li>
                <li className="breadcrumb-item d-flex align-items-center">
                  User Activities
                </li>
              </ol>
            </nav>
          </div>
        </div>

        <div className="row">
          <form onSubmit={formik.handleSubmit} method="POST">
            <div className="col-md">
              <div className="accordion" id="accordionExample">
                <div className="card accordion-item shadow-sm active">
                  <h6 className="accordion-header" id="headingOne">
                    <button
                      type="button"
                      className="accordion-button border-bottom p-3"
                      data-bs-toggle="collapse"
                      data-bs-target="#accordionOne"
                      aria-expanded="true"
                      aria-controls="accordionOne"
                    >
                      Search Filters
                    </button>
                  </h6>

                  <div
                    id="accordionOne"
                    className="accordion-collapse collapse show"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body p-3 pt-2">
                      <div className="row">
                        <div className="col">
                          <Input
                            id="company"
                            name="company"
                            onChange={(e: any) => {
                              formik.handleChange(e);
                              formik.submitForm();
                            }}
                            type="text"
                            label="Client"
                            inputClasses="form-control form-control-sm"
                            labelClasses="col-form-label text-capitalize fw-semibold"
                          />
                        </div>

                        <div className="col">
                          <label className="col-form-label text-capitalize fw-semibold">
                            Start Date
                          </label>
                          <div className="col-md">
                            <input
                              name="startDate"
                              value={formik.values.startDate}
                              onChange={(e: any) => {
                                formik.handleChange(e);
                                formik.submitForm();
                              }}
                              type="date"
                              className="form-control form-control-sm"
                              placeholder=""
                              aria-describedby="defaultFormControlHelp"
                              defaultValue={defaulDatetValue}
                              max={maxDatetValue}
                            />
                          </div>
                        </div>

                        <div className="col d-flex align-items-end">
                          <div className="py-3 pb-0 d-flex flex-wrap justify-content-end d-grid gap-2 w-100">
                            <button
                              type="button"
                              className="btn btn-outline-secondary btn-sm"
                              onClick={() => generatePDF()}
                            >
                              <span className="tf-icons bx bx-printer"></span>
                              &nbsp; Print
                            </button>
                            {/*<button type="submit"*/}
                            {/*    className="btn btn-primary btn-sm px-4">Search*/}
                            {/*</button>*/}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>

        <div className="row">
          <div className="col">
            <div className="card shadow-sm mt-3">
              <div className="d-flex justify-content-between align-items-center card-header mb-0 border-bottom p-3">
                <div>
                  <h6 className="m-0">
                    Inactive User’s From{" "}
                    <span className="app-color-dark">{currentDatetValue}</span>{" "}
                    to{" "}
                    <span className="app-color-dark">{selectedDatetValue}</span>
                  </h6>
                </div>
                <div></div>
              </div>

              <div className="card-body p-3">
                <div className="table-responsive text-nowrap">
                  <DataTable
                    title=""
                    className="table table-bordered table-hover"
                    onSort={(selectedColumn, sortDirection, sortedRows) => {
                      setSortesData(sortedRows);
                    }}
                    columns={columns}
                    data={dataTableFormat}
                    defaultSortFieldId="companyName"
                    paginationPerPage={10}
                    paginationRowsPerPageOptions={[5, 10, 20, 50, 100]}
                    paginationComponent={BootyPagination}
                    selectableRows={false}
                    highlightOnHover
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </AppLayout>
  );
}
const mapStateToProps = (state: any) => {
  const { inactiveusers, isLoading } = state?.userManagement;
  return { inactiveusers, isLoading };
};
export default connect(mapStateToProps, null)(UserActivities);
