import React, { useEffect, useState } from 'react';
import * as Yup from "yup";
import { useFormik } from "formik";
import AppLayout from "../../Layout/AppLayout";
import { connect, useDispatch } from "react-redux";
import {
    changeresetUserPassword,
    createNewUser,
    getRolesByUserIds,
    getUserByUserId,
    updateSuperAdminUser
} from "../../../actions/userAction";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import SelectUserPolicies from '../../../components/SelectUserPolicies';
import { getAllPermissions } from "../../../actions/commonAction";
import {
    childPermissionsType,
    customGroupPermissionsType,
    rolePermissionType,
    userFogetPasswordType,
    userType
} from "../../../config/commonTypes";
import { delay, groupBy, namePascalCaseToText } from "../../../config/commonMethods";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
    clientActionType,
    PASSWORD_REG_EEP,
    PHONE_REG_EEP,
    SELECT_USER_PERMISSION_TYPE,
    userActionType
} from "../../../constants/constants";
import { RESET_USER_DATA_UM } from "../../../constants";
import CustomModal from "../../../components/modal/CustomModal";
import { ChangePasswordForm } from "../clientManagement/ChangePasswordForm";


export interface createNewAdminUserFormType {
    roleIds?: string[];
    name: string;
    designation: string;
    isActive: boolean,
    clientId: string;
    createDate: string;
    userName: string;
    email: string;
    phoneNumber: string;
    password: string;
    confirmPassword: string;
}

interface propsTypes {
    isLoading?: boolean;
    selectedUser?: userType;

}

function AddNewUser({ isLoading }: propsTypes) {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [isBlockedAutoFill, setBlockedAutoFill] = useState<boolean>(true);
    const [rolePermissions, setRolePermissions] = useState<rolePermissionType[]>();
    const [formattedRolePermissions, setFormattedRolePermissions] = useState<customGroupPermissionsType[]>();
    const [isResetPermissions, setIsResetPermissions] = useState<boolean>(false);
    const [isOpenChangePassword, setIsOpenChangePassword] = useState<boolean>(false);
    const [selectedStateUser, setSelectedStateUser] = useState<userType>();
    const [stateUserRolesPermissions, setStateUserRolesPermissions] = useState<rolePermissionType[]>();
    const { userid } = useParams();
    const urlHiddenState: any = useLocation().state;
    const [isPasswordOpenIcon, setIsPasswordOpenIcon] = useState<boolean>();
    const [isConfirmPasswordOpenIcon, setIsConfirmPasswordOpenIcon] = useState<boolean>();


    const initialValues: createNewAdminUserFormType = {
        roleIds: [],
        name: "",
        designation: "",
        isActive: true,
        clientId: "",
        createDate: "",
        userName: "",
        email: "",
        phoneNumber: "",
        password: "",
        confirmPassword: "",
    };


    useEffect(() => {
        getAllPermissions('AdminUser',
            (data: rolePermissionType[]) => setRolePermissions(data?.filter(role => role.isActive)),
            (error: any) => console.log("##CAN NOT FETCH "));
        if (!selectedStateUser) resetForm();
        return () => {
            dispatch({
                type: RESET_USER_DATA_UM
            });
        }
    }, []);

    useEffect(() => {
        console.log(userid)
        if (userid) {
            dispatch(getUserByUserId(userid,
                (success) => {
                    setSelectedStateUser(success.data)
                },
                (error) => {
                    console.log("##CAN NOT FETCH ")
                }));
        } else {
            setIsResetPermissions(false);
            setSelectedStateUser(undefined);
            delay(5).then(()=>resetForm());
            dispatch({
                type: RESET_USER_DATA_UM
            });
        }
    }, [userid]);

    useEffect(() => {
        if (selectedStateUser && userid) {
            dispatch(getRolesByUserIds([userid],
                (success) => {
                    setStateUserRolesPermissions(success.data[0]?.userPermissions);
                },
                (failed) => { console.log("##CAN NOT FETCH ") }));
        }
    }, [selectedStateUser]);

    useEffect(() => {
        if (stateUserRolesPermissions && stateUserRolesPermissions?.length > 0) {
            formik.setValues(prevValues => ({
                ...prevValues,
                roleIds: stateUserRolesPermissions?.map(x => x.id)
            }));
        }
    }, [stateUserRolesPermissions]);

    const cancelForm = () => {
        if (!selectedStateUser) {
            resetForm();
        } else {
            navigate('/user-list');
        }
    }

    const formik = useFormik({
        initialValues: selectedStateUser ? { ...selectedStateUser } : initialValues,
        enableReinitialize: true,
        onSubmit: ((values: any) => {
            if (!selectedStateUser?.id) {
                dispatch(
                    createNewUser({ ...values, isSuperAdmin: true, }, (response) => {
                        toast.success(response?.message ? response.message : 'Successfully created!');
                        resetForm();
                    }, (error: any) => {
                        toast.error(error?.message ? error.data : 'Create user failed!');
                    }));
            } else {
                dispatch(
                    updateSuperAdminUser({ ...values, isSuperAdmin: true }, (response) => {
                        toast.success(response?.message ? response.message : 'Successfully updated!');
                        navigate('/user-list');
                        resetForm();
                    }, (error: any) => {
                        toast.error(error?.response?.data.message ?
                            error?.response?.data?.data
                            : 'Update user failed!');
                    }));
            }

        }),
        validationSchema: Yup.object().shape({
            name: Yup.string().required("Name is required"),
            email: Yup.string().required("Email is required").email("Please enter a valid email"),
            phoneNumber: Yup.string().required("Mobile number is required")
                .matches(PHONE_REG_EEP, 'Mobile number is not valid'),
            isNotEdit: Yup.boolean().default(!selectedStateUser?.id),
            roleIds: Yup.array().of(Yup.string()),
            userName: Yup.string()
                .when('isNotEdit', {
                    is: true,
                    then: Yup.string().required("Username is required"),
                }),
            password: Yup.string().when('isNotEdit', {
                is: true,
                then: Yup.string().required("Password is required")
                    .min(8, 'Password is too short - should be 8 chars minimum.')
                    .matches(
                        PASSWORD_REG_EEP,
                        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
                    )
            }),
            confirmPassword: Yup.string().when('isEdit', {
                is: true,
                then: Yup.string().required("Confirm password is required").when("newPassword", {
                    is: (val: any) => (val && val.length > 0 ? true : false),
                    then: Yup.string().oneOf(
                        [Yup.ref("newPassword")],
                        "Both password need to be the same"
                    )
                })
            })
        })
    });

    const resetForm = () => {
        formik?.resetForm();
        setIsResetPermissions(true);
    }
    const activateToResetSelectUserPermissions = () => {
        setIsResetPermissions(false);
    }

    useEffect(() => {
        setPermissions();
    }, [rolePermissions, stateUserRolesPermissions]);

    const setPermissions = () => {
        if (!rolePermissions) return;
        let formattedRolePermissionsDataArray: customGroupPermissionsType[] = [];
        const namePascalCaseToTextRolePermissions: any = rolePermissions.map((data: rolePermissionType) => {
            return {
                ...data,
                name: namePascalCaseToText(data?.name),
            }
        });
        const permissionsByGroupId = groupBy(namePascalCaseToTextRolePermissions, 'rolesGroupId');
        const keys: any[] = permissionsByGroupId && Object.keys(permissionsByGroupId);
        for (let i = 0; i < keys.length; i++) {
            const formattedChildPermissionsData: childPermissionsType[] =
                permissionsByGroupId[keys[i]]?.map((rolePermission: rolePermissionType) => {
                    return {
                        id: rolePermission.id,
                        name: rolePermission?.name,
                        isSelected: false
                    }
                });
            let formattedRolePermissionsData: customGroupPermissionsType = {
                childPermissions: [],
                isSelected: false,
                rolesGroupId: "",
                rolesGroupName: ""
            };

            formattedRolePermissionsData['childPermissions'] = formattedChildPermissionsData ?? [];
            formattedRolePermissionsData['rolesGroupId'] = permissionsByGroupId[keys[i]][0]?.rolesGroupId ?? "";
            formattedRolePermissionsData['isSelected'] = false;
            formattedRolePermissionsData['rolesGroupName'] = permissionsByGroupId[keys[i]][0]?.rolesGroupName ?? "";

            formattedRolePermissionsDataArray.push(formattedRolePermissionsData);


        }

        setFormattedRolePermissions(formattedRolePermissionsDataArray);
    }

    const onChangePassword = ({ password, userName }: userType) => {
        if (!userName || !password) return;
        const credentials: userFogetPasswordType = {
            username: userName,
            password: password
        }
        dispatch(
            changeresetUserPassword(credentials, (response) => {
                toast.success(response?.message ? response.message : 'Successfully changed password!');
            }, (error: any) => {
                toast.error(error?.message ? error.message : 'Change password error!');
            }));
    }

    return (
        <AppLayout>
            <>
                {isOpenChangePassword && selectedStateUser && (
                    <CustomModal size="sm" isClose={!isOpenChangePassword}
                        title="Change Password"
                        onClose={() => setIsOpenChangePassword(false)}>
                        <ChangePasswordForm
                            name={selectedStateUser.name}
                            userName={selectedStateUser.userName}
                            onChangePassword={(data: any) => {
                                onChangePassword(data);
                                setIsOpenChangePassword(false);
                            }}
                        />
                    </CustomModal>
                )}
                <div className="row">
                    <div className="col-md">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb breadcrumb-style1 d-flex align-items-center">
                                <li className="breadcrumb-item d-flex align-items-center">
                                    <a href="javascript:void(0);">Home</a>
                                </li>
                                <li className="breadcrumb-item d-flex align-items-center">
                                    <a href="javascript:void(0);">User Management</a>
                                </li>
                                <li className="breadcrumb-item d-flex align-items-center active">Add New User</li>
                            </ol>
                        </nav>
                    </div>
                </div>

                <div className="row">
                    <form onSubmit={formik.handleSubmit} method="POST" >
                        <div className="card shadow-sm p-3 m-0">
                            <div className="row">
                                <div className="col-4">
                                    <div
                                        className="d-flex justify-content-between align-items-center card-header mb-0 p-0">
                                        <div>
                                            <h6 className="p-0 mb-2 secondry-color">User Details</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div
                                        className="d-flex justify-content-between align-items-center card-header mb-0 p-0">
                                        <div>
                                            <h6 className="p-0 mb-2 secondry-color">User Credentials </h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row" style={urlHiddenState?.action === userActionType.view ? { pointerEvents: 'none' } : {}}>
                                <div className="col-4">
                                    <div className="card-body p-3 pt-0 px-0 border-top pb-2">
                                        <div className="row">
                                            <div className="col">
                                                <label
                                                    className="col-form-label text-capitalize fw-semibold">Name
                                                    <span className="app-color-danger fw-bolder ms-1">*</span>
                                                </label>
                                                <div className="col-md d-flex justify-content-end">
                                                    <input
                                                        name="name"
                                                        onChange={formik.handleChange}
                                                        value={formik.values.name}
                                                        onBlur={formik.handleBlur}
                                                        type="text"
                                                        className="form-control form-control-sm"
                                                        placeholder=""
                                                        aria-describedby="defaultFormControlHelp"
                                                    />
                                                </div>
                                                {formik.errors.name && formik.touched.name && (
                                                    <p style={{ color: "red" }}>{formik.errors.name}</p>
                                                )}
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col">
                                                <label
                                                    className="col-form-label text-capitalize fw-semibold">Email
                                                    <span className="app-color-danger fw-bolder ms-1">*</span>
                                                </label>
                                                <div className="col-md d-flex justify-content-end">
                                                    <input
                                                        name="email"
                                                        onChange={formik.handleChange}
                                                        value={formik.values.email}
                                                        onBlur={formik.handleBlur}
                                                        type="text"
                                                        className="form-control form-control-sm"
                                                        placeholder=""
                                                        aria-describedby="defaultFormControlHelp"
                                                    />

                                                </div>
                                                {formik.errors.email && formik.touched.email && (
                                                    <p style={{ color: "red" }}>{formik.errors.email}</p>
                                                )}
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col">
                                                <label
                                                    className="col-form-label text-capitalize fw-semibold">Designation</label>
                                                <div className="col-md d-flex justify-content-end">
                                                    <input
                                                        name="designation"
                                                        onChange={formik.handleChange}
                                                        value={formik.values.designation}
                                                        onBlur={formik.handleBlur}
                                                        type="text"
                                                        className="form-control form-control-sm"
                                                        placeholder=""
                                                        aria-describedby="defaultFormControlHelp"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <label
                                                    className="col-form-label text-capitalize fw-semibold">Mobile
                                                    <span className="app-color-danger fw-bolder ms-1">*</span>
                                                </label>
                                                <div className="col-md d-flex justify-content-end">
                                                    <input
                                                        name="phoneNumber"
                                                        value={formik.values.phoneNumber}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        type="text"
                                                        className="form-control form-control-sm"
                                                        placeholder=""
                                                        aria-describedby="defaultFormControlHelp"
                                                    />
                                                </div>
                                                {formik.errors.phoneNumber && formik.touched.phoneNumber && (
                                                    <p style={{ color: "red" }}>{formik.errors.phoneNumber}</p>
                                                )}
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className="col">
                                                <div className="form-check form-switch mb-4 mt-0 pt-3">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        name="isActive"
                                                        checked={formik.values.isActive}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                    />
                                                    <label className="form-check-label"
                                                        htmlFor="flexSwitchCheckChecked">Status: Active
                                                        <span className="app-color-danger fw-bolder ms-1">*</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="card-body p-3 pt-0 px-0 border-top pb-2">
                                        {selectedStateUser?.name ? (
                                            <>
                                                <label htmlFor="user"
                                                    className="col-form-label text-capitalize fw-semibold">Username
                                                    <span className="app-color-danger fw-bolder ms-1">*</span>
                                                </label>
                                                <div className="col-md d-flex justify-content-end">
                                                    <input
                                                        name="userName"
                                                        value={formik.values.userName}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        type="text"
                                                        className="form-control form-control-sm"
                                                        placeholder=""
                                                        readOnly={true}
                                                        onClick={() => setBlockedAutoFill(false)}
                                                    />
                                                </div>
                                                {formik.errors.userName && formik.touched.userName && (
                                                    <p style={{ color: "red" }}>{formik.errors.userName}</p>
                                                )}
                                                <div className="form-password-toggle">
                                                    <label htmlFor="user"
                                                        className="col-form-label text-capitalize fw-semibold">
                                                    </label>
                                                    <div className="input-group input-group-merge">
                                                        <button
                                                            style={{ display: urlHiddenState?.action === clientActionType.update ? 'auto' : 'none' }}
                                                            type="button"
                                                            className="btn-outline-primary form-control form-control-sm"
                                                            onClick={() => setIsOpenChangePassword(true)}
                                                        >Change Password
                                                        </button>
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div className="row">
                                                    <div className="col">
                                                        <label htmlFor="user"
                                                            className="col-form-label text-capitalize fw-semibold">Username
                                                            <span className="app-color-danger fw-bolder ms-1">*</span>
                                                        </label>
                                                        <div className="col-md d-flex justify-content-end">
                                                            <input
                                                                name="userName"
                                                                value={formik.values.userName}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                type="text"
                                                                className="form-control form-control-sm"
                                                                placeholder=""
                                                                readOnly={isBlockedAutoFill}
                                                                onClick={() => setBlockedAutoFill(false)}
                                                            />
                                                        </div>
                                                        {formik.errors.userName && formik.touched.userName && (
                                                            <p style={{ color: "red" }}>{formik.errors.userName}</p>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-password-toggle">
                                                            <label htmlFor=""
                                                                className="col-form-label text-capitalize fw-semibold">
                                                                New Password
                                                                <span className="app-color-danger fw-bolder ms-1">*</span>
                                                            </label>
                                                            <div className="input-group input-group-merge">
                                                                <input
                                                                    name="confirmPassword"
                                                                    value={formik.values.confirmPassword}
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                    type={isPasswordOpenIcon ? "text" : "password"}
                                                                    className="form-control form-control-sm"
                                                                    autoComplete="off"
                                                                    readOnly={isBlockedAutoFill}
                                                                    onClick={() => setBlockedAutoFill(false)}
                                                                />

                                                                <span onClick={() => setIsPasswordOpenIcon(!isPasswordOpenIcon)}
                                                                    className="input-group-text cursor-pointer"
                                                                ><i className={`bx ${isPasswordOpenIcon ?
                                                                    'bx-show' :
                                                                    'bx-hide'}`}></i></span>
                                                            </div>
                                                            {formik.errors.confirmPassword && formik.touched.confirmPassword && (
                                                                <p style={{ color: "red" }}>{formik.errors.confirmPassword}</p>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-password-toggle">
                                                            <label htmlFor="paswordconfirm"
                                                                className="col-form-label text-capitalize fw-semibold">
                                                                Confirm Password
                                                                <span className="app-color-danger fw-bolder ms-1">*</span>
                                                            </label>
                                                            <div className="input-group input-group-merge">
                                                                <input name="password"
                                                                    value={formik.values.password}
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                    type={isConfirmPasswordOpenIcon ? "text" : "password"}
                                                                    className="form-control form-control-sm"
                                                                    readOnly={isBlockedAutoFill}
                                                                    onClick={() => setBlockedAutoFill(false)}
                                                                />
                                                                <span onClick={() => setIsConfirmPasswordOpenIcon(!isConfirmPasswordOpenIcon)}
                                                                    className="input-group-text cursor-pointer"
                                                                ><i className={`bx ${isConfirmPasswordOpenIcon ?
                                                                    'bx-show' :
                                                                    'bx-hide'}`}></i></span>
                                                            </div>
                                                            {formik.errors.password && formik.touched.password && (
                                                                <p style={{ color: "red" }}>{formik.errors.password}</p>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <hr />
                            <div className="row">
                                <div className='col-6'>
                                    <div className="card-body p-3 pt-0 px-0" style={urlHiddenState?.action === userActionType.view ? { pointerEvents: 'none' } : {}}>
                                        <SelectUserPolicies
                                            canReset={isResetPermissions}
                                            data={formattedRolePermissions}
                                            selectedIds={formik.values.roleIds}
                                            type={selectedStateUser?.id ? SELECT_USER_PERMISSION_TYPE.UPDATE : SELECT_USER_PERMISSION_TYPE.CREATE}
                                            onSelect={(ids: string[]) => {
                                                formik.setValues(prevValues => ({
                                                    ...prevValues,
                                                    roleIds: ids
                                                }));
                                                activateToResetSelectUserPermissions();
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="card-footer p-0 pt-3 border-1 border-top">
                                    <div className="row mx-0">
                                        <div className="col p-0 px-3 d-flex align-items-end justify-content-end">
                                            <div className="p-0 d-flex flex-wrap d-grid gap-2">
                                                <button type="button"
                                                    onClick={() => cancelForm()}
                                                    className="btn btn-outline-secondary btn-sm">
                                                    Cancel
                                                </button>
                                                <button type="submit"
                                                    style={{ display: urlHiddenState?.action === clientActionType.view ? 'none' : 'auto' }}
                                                    className="btn btn-primary btn-sm">
                                                    {userid ? 'Update' : 'Save'}
                                                </button>
                                            </div>
                                        </div>
                                    </div></div>
                            </div>
                        </div>
                    </form>
                </div>


            </>
        </AppLayout >
    );
}

const mapStateToProps = (state: any) => {
    const { isLoading } = state?.userManagement;
    return { isLoading }

}
export default connect(mapStateToProps, null)(AddNewUser);