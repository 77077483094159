import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import userReducer from "./modules/userReducer";
import clientManagement from "./modules/clientManagementReducer";
import userManagement from "./modules/userManagementReducer";
import auditManagement from "./modules/auditManagementReducer";
import correctiveLogs from "./modules/auditCorrectiveLogReducer";
import commonManagement from "./modules/commonReducer";
import correctiveAction from "./modules/correctiveActionReducer";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";

const config = {
  key: "root",
  storage,
};
const rootReducer = combineReducers({
  auth: persistReducer(config, userReducer),
  clientManagement,
  userManagement,
  auditManagement,
  commonManagement,
  correctiveLogs,
  correctiveAction
});

export const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: [thunk],
});
