import _ from "lodash";
import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  editAuditTemplate,
  GetAuditTemplates,
  GetTemplateByID,
  updateAuditAssignees,
} from "../../../actions/auditActions";
import { getClients } from "../../../actions/clientAction";
import {
  ClientListModal,
  IClientTypeCheckBox,
} from "../../../components/modal/ClientListModal";
import CustomModal from "../../../components/modal/CustomModal";
import {
  CREATE_AUDIT_TEMPLATE_SUCCESS,
  SET_TEMPLATE_HEADER,
} from "../../../constants";
import { TransformClientsToCheckBoxType } from "../../../DTO/transformers";
import { IGlobalTemplateType } from "../../../redux/modules/auditManagementReducer";
import { clientType } from "../../../redux/modules/clientManagementReducer";

interface propsTypes {
  openAssignToModal: () => void;
  templateList: IGlobalTemplateType[];
  clients: clientType[];
  template?: IGlobalTemplateType;
}
function AssignTemplates({
  openAssignToModal,
  templateList = [],
  clients = [],
  template,
}: propsTypes) {
  const dispatch = useDispatch();

  const TransformedClients = React.useMemo(
    () => TransformClientsToCheckBoxType(clients),
    [clients]
  );

  const [isOpenModal, setIsOpenModal] = React.useState(false);
  const [isAllAssigneesSelected, setSelectAllAssignees] = React.useState(false);
  const [selectedClientsData, SetSelectedClients] =
    React.useState<IClientTypeCheckBox[]>(TransformedClients);
  const [TemplateSearchData, setSearchTemplateData] = React.useState<
    IGlobalTemplateType[]
  >([]);
  const [templateSearchQueryName, setSearchQueryName] =
    React.useState<string>("");
  const [templateSearchQuerCode, setSearchQueryCode] =
    React.useState<string>("");
  const [searchData, setSearchData] = React.useState<IClientTypeCheckBox[]>([]);
  const [searchAssigneeString, setSearchString] = React.useState<string>("");

  useEffect(() => {
    dispatch<any>(getClients());
  }, []);

  useEffect(() => {
    if (template?.assignClients?.length) {
      setSelectedAssigneesForTemplate(template?.assignClients);
    } else {
      SetSelectedClients(TransformClientsToCheckBoxType(clients));
    }
  }, [template]);

  const onchangeTemplateCode = (val: string) => {
    if (!val) {
      setSearchTemplateData([]);
      setSearchQueryCode(val);
      return;
    }
    let statedata = [...templateList].filter((object) => {
      return object.globalTemplateCode
        ?.toLowerCase()
        .includes(val.toLowerCase());
    });
    setSearchTemplateData(statedata);
    setSearchQueryCode(val);
  };

  const onChangeTemplateName = (val: string) => {
    if (!val) {
      setSearchTemplateData([]);
      setSearchQueryName(val);
      return;
    }
    let statedata = [...templateList].filter((object) => {
      return object.globalTemplateName
        ?.toLowerCase()
        .includes(val.toLowerCase());
    });
    setSearchTemplateData(statedata);
    setSearchQueryName(val);
  };

  useEffect(() => {
    dispatch(GetAuditTemplates());
  }, []);

  const selectTemplate = (item: IGlobalTemplateType) => {
    dispatch({ type: SET_TEMPLATE_HEADER, payload: item });
    setSelectAllAssignees(false);
    dispatch(
      GetTemplateByID(
        item.globalTemplateHeaderId?.toString()!!,
        (error: any) => {
          toast.error(
            error?.response?.data?.data
              ? error?.response?.data?.data
                  : "Something went wrong!"
          );
        }
      )
    );
  };

  const selectedClients = (client: IClientTypeCheckBox) => {
    let statedata = [...selectedClientsData];
    if (searchAssigneeString) {
      let searcList = [...searchData];
      let findObject = searcList.find((o) => o.clientId == client.clientId);
      if (!findObject) return;
      let index = searcList.indexOf(findObject);
      searcList[index] = client;
      setSearchData(searcList);
    }
    let findObject = statedata.find((o) => o.clientId == client.clientId);
    if (!findObject) return;
    let index = statedata.indexOf(findObject);
    statedata[index] = client;
    SetSelectedClients(statedata);
  };

  const selectAssigneeData = () => {
    getAllSelectedAssignees();
    setIsOpenModal(false);
  };

  const getAllSelectedAssignees = () => {
    let assignedList = [...selectedClientsData]
      .filter((o) => o.isSelected)
      .map((o) => {
        return o.clientId;
      });
    return assignedList;
  };

  const selectAllAssignees = (selected: boolean) => {
    let statedata = [...selectedClientsData];
    statedata.forEach((o) => {
      if (o.isActive) {
        o.isSelected = selected;
      }
    });
    SetSelectedClients(statedata);
    setSelectAllAssignees(selected);
  };

  const searchClients = (query: string) => {
    if (!query) {
      setSearchData([]);
      setSearchString(query);
      return;
    }
    let statedata = [...selectedClientsData].filter((object) => {
      return object.companyName?.toLowerCase().includes(query.toLowerCase());
    });
    setSearchData(statedata);
    setSearchString(query);
  };

  const setSelectedAssigneesForTemplate = (assigneeList: clientType[]) => {
    let statedata = [...selectedClientsData];
    assigneeList.map((o) => {
      let findObject = statedata.find((obj) => obj.clientId == o.clientId);
      if (!findObject) return;
      let index = statedata.indexOf(findObject);
      statedata[index] = { ...statedata[index], isSelected: true };
    });
    SetSelectedClients(statedata);
  };

  const removeAssignee = (item: IClientTypeCheckBox) => {
    let statedata = [...selectedClientsData];
    statedata.map((o) => {
      if (o.clientId === item.clientId) {
        o.isSelected = false;
      }
      return o;
    });
    SetSelectedClients(statedata);
  };

  const updateTemplate = () => {
    dispatch(
      updateAuditAssignees(
        {
          ...{
            globalTemplateHeaderId: template?.globalTemplateHeaderId,
            assineeIds: getAllSelectedAssignees(),
            globalTemplateCode: template?.globalTemplateCode!!,
            globalTemplateName: template?.globalTemplateName,
            globalTemplateDescription: template?.globalTemplateDescription,
            isActive: template?.isActive,
            isGlobal: template?.isGlobal,
          },
        },
        (response) => {
          toast.success(
            response?.message
              ? response.message
              : "Template Created Successfully!"
          );
          dispatch(GetAuditTemplates());
          dispatch({
            type: CREATE_AUDIT_TEMPLATE_SUCCESS,
            payload: undefined,
          });
        },
        (error: any) => {
          toast.error(
            error?.response?.data?.data
              ? error?.response?.data?.data
              : "Template update failed!"
          );
        }
      )
    );
  };

  return (
    <>
      <div className="row m-0 tb-mh">
        <div className="col-sm-12 col-md-4 col-xl-3 py-0 ps-0">
          <div className="row d-grid mb-0">
            <h6 className="m-0 mb-2 secondry-color fw-semibold">
              Audit Templates
            </h6>
          </div>
          <div className="row mt-2 mx-auto">
            <div className="col p-0">
              <div className="gridtable">
                <table className="scrollTable table table-bordered table-hover tbl-audit-planner mb-1">
                  <thead className="fixedHeader">
                    <tr>
                      <th className="colda text-capitalize fw-semibold">
                        Code
                      </th>
                      <th className="coldb text-capitalize fw-semibold">
                        Audit Name
                      </th>
                    </tr>
                  </thead>
                  <tbody className="scrollContent">
                    <tr>
                      <td className="colda">
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          id="defaultFormControlInput1"
                          placeholder=""
                          aria-describedby="defaultFormControlHelp"
                          onChange={(e) => onchangeTemplateCode(e.target.value)}
                        />
                      </td>
                      <td className="coldb">
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          id="defaultFormControlInput2"
                          placeholder=""
                          aria-describedby="defaultFormControlHelp"
                          onChange={(e) => onChangeTemplateName(e.target.value)}
                        />
                      </td>
                    </tr>
                    {templateList && !TemplateSearchData.length
                      ? templateList.map((o, index) => {
                          return (
                            <tr
                              className={
                                o?.isAssignedtoClient
                                  ? `${
                                      o.globalTemplateHeaderId ==
                                      template?.globalTemplateHeaderId
                                        ? "selected"
                                        : ""
                                    }`
                                  : o?.isGlobal
                                  ? `aud-temp-b ${
                                      o.globalTemplateHeaderId ==
                                      template?.globalTemplateHeaderId
                                        ? "selected"
                                        : ""
                                    }`
                                  : `aud-temp-y ${
                                      o.globalTemplateHeaderId ==
                                      template?.globalTemplateHeaderId
                                        ? "selected"
                                        : ""
                                    }`
                              }
                              key={index}
                              onClick={() => selectTemplate(o)}
                            >
                              <td className="text-wrap">
                                <p className="py-2">{o.globalTemplateCode}</p>
                              </td>
                              <td className="text-wrap">
                                <p className="py-2">{o.globalTemplateName}</p>
                              </td>
                            </tr>
                          );
                        })
                      : null}
                    {TemplateSearchData.length
                      ? TemplateSearchData.map((o, index) => {
                          return (
                            <tr
                              className={
                                o?.isAssignedtoClient
                                  ? `${
                                      o.globalTemplateHeaderId ==
                                      template?.globalTemplateHeaderId
                                        ? "selected"
                                        : ""
                                    }`
                                  : o?.isGlobal
                                  ? `aud-temp-b ${
                                      o.globalTemplateHeaderId ==
                                      template?.globalTemplateHeaderId
                                        ? "selected"
                                        : ""
                                    }`
                                  : `aud-temp-y ${
                                      o.globalTemplateHeaderId ==
                                      template?.globalTemplateHeaderId
                                        ? "selected"
                                        : ""
                                    }`
                              }
                              key={index}
                              onClick={() => selectTemplate(o)}
                            >
                              <td className="text-wrap">
                                <p className="py-2">{o.globalTemplateCode}</p>
                              </td>
                              <td className="text-wrap">
                                <p className="py-2">{o.globalTemplateName}</p>
                              </td>
                            </tr>
                          );
                        })
                      : null}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 pt-2">
              <div className="d-flex justtify-contents-between  ">
                <div className="p-0 d-flex justify-contents-center align-items-center">
                  <div className="legnd legnd-gt"></div>
                  <label className="form-check-label ps-2 small">Global</label>
                </div>
                <div className="ps-3  d-flex justify-contents-center align-items-center">
                  <div className="legnd legnd-na"></div>
                  <label className="form-check-label ps-2 small">
                    Not Assigned
                  </label>
                </div>
                <div className="ps-3  d-flex justify-contents-center align-items-center">
                  <div className="legnd legnd-at border"></div>
                  <label className="form-check-label ps-2 small">
                    Assigned
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-8 col-xl-9">
          <div className="row">
            <div className="col p-3 py-0">
              <div className="row">
                <div className="col p-0 d-flex justify-content-between">
                  <h6 className="m-0 mb-2 secondry-color fw-semibold">
                    Selected Templates
                  </h6>
                </div>
              </div>
              <div className="row">
                <div className="col p-2 app-bg-gray border mt-2">
                  <div className="row">
                    <div className="col p-3 py-1 d-flex gap-2">
                      <span className="badge app-badge-bg app-color-dark align-middle text-capitalize app-bg-primary-light">
                        {`${template?.globalTemplateName ?
                         template?.globalTemplateName : ""} ${
                          !template?.assineeIds?.length && template?.isGlobal
                            ? ""
                            : ""
                        }`}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col p-3 py-0">
              <div className="row">
                <div className="col p-0 d-flex justify-content-start align-items-center">
                  <h6 className="m-0 mb-2 secondry-color fw-semibold">
                    Assignee
                  </h6>
                </div>
                <div className="col p-0 d-flex justify-content-end align-items-center gap-2">
                  <button
                    type="button"
                    className="btn btn-outline-primary btn-sm"
                    data-bs-toggle="modal"
                    data-bs-target="#modalCenter2"
                    disabled={_.isEmpty(template) || template.isGlobal}
                    onClick={() => setIsOpenModal(true)}
                  >
                    <i className="bx bx-user-plus"></i>&nbsp; Assign To
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-secondary btn-sm"
                    onClick={() => {
                      SetSelectedClients([]);
                    }}
                  >
                    Clear List
                  </button>
                </div>
              </div>
              <div className="row">
                <div className="col p-2 app-bg-gray border mt-2">
                  <div className="row">
                    <div className="col p-3 py-1 d-flex cls-scroller">
                      <div className="p-0">
                        {(!template?.assineeIds?.length && template?.isGlobal) && 
                        <span className="badge app-badge-bg app-color-dark align-middle text-capitalize app-bg-primary-light">
                          Assigned To All Clients
                        </span>}
                        {selectedClientsData &&
                          selectedClientsData.map((o, index) => {
                            return (
                              o.isSelected && (
                                <span
                                  key={index}
                                  className="badge app-badge-bg app-color-dark align-middle text-capitalize app-bg-primary-light me-2"
                                >
                                  {o.companyName}
                                  {template?.isGlobal &&
                                  template.assineeIds?.length ? null : (
                                    <a
                                      href="#"
                                      onClick={() => removeAssignee(o)}
                                    >
                                      <i className="bx bx-x fs-6 btn-outline-danger shadow-none ms-1"></i>
                                    </a>
                                  )}
                                </span>
                              )
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col p-0 d-flex justify-content-end">
                  <button
                    type="button"
                    className="btn btn-primary btn-sm px-4"
                    onClick={() => {
                      updateTemplate();
                    }}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
            {isOpenModal && (
              <CustomModal
                size="lg"
                isClose={!isOpenModal}
                title="Select Assignee"
                onClose={() => setIsOpenModal(false)}
              >
                <ClientListModal
                  clients={
                    searchAssigneeString.length
                      ? searchData
                      : selectedClientsData
                  }
                  onClientSelected={selectedClients}
                  selectAssignees={selectAssigneeData}
                  selectAll={selectAllAssignees}
                  isSelectAll={isAllAssigneesSelected}
                  searchAssignees={searchClients}
                  searhQuery={searchAssigneeString}
                />
              </CustomModal>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state: any) => {
  const auditManageMent = state?.auditManagement;
  const clientManageMent = state?.clientManagement;
  return {
    isLoading: auditManageMent?.isLoading,
    error: auditManageMent?.error,
    template: auditManageMent?.template,
    clients: clientManageMent.clients,
    templateList: auditManageMent?.templateList,
  };
};
export default connect(mapStateToProps, null)(AssignTemplates);
