import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  AccessControlType,
  UserPermissionsConstant,
  userType,
} from "../../../constants/constants";
import { connect } from "react-redux";
import AccessControl from "../../../config/AccessControl";

interface subMenuItemType {
  id: number;
  title?: string;
  isActive?: boolean;
  path?: string;
  accessRoles: string[];
  isAccessByPermissionRole?: boolean;
  allowedPermissions?: string[];
  iconClass?:string;
}

interface menuItemType {
  id: number;
  title: string;
  isActive: boolean;
  accessRoles: string[];
  child: any;
  subItems: subMenuItemType[];
  isAccessByPermissionRole?: boolean;
  allowedPermissions?: string[];
}

const initMenuItems: menuItemType[] = [
  {
    id: 1,
    title: "Internal Audits",
    isActive: false,
    accessRoles: [userType.superAdmin],
    child: (
      <>
        {/*<i className="menu-icon tf-icons bx bx-layout"></i>*/}
        {/*<div data-i18n="Internal Audits">Internal Audits</div>*/}
        <i className="menu-icon bx bx-check-square"></i>
        <div data-i18n="Internal Audits">Internal Audits</div>
      </>
    ),
    subItems: [
      {
        id: 1.1,
        title: "Internal Audit Log",
        isActive: false,
        path: "/internal-audit-log",
        accessRoles: [userType.clientAdmin],
      },
      {
        id: 1.2,
        title: "Internal Audit Planner",
        isActive: false,
        path: "/internal-audit-planner",
        accessRoles: [userType.clientAdmin],
      },
      {
        id: 1.3,
        title: "Audit Planner",
        isActive: false,
        path: "/internal-audit-planner",
        accessRoles: [userType.superAdmin],
      },
      {
        id: 1.4,
        title: "Audit Templates",
        isActive: false,
        path: "/internal-audit-management",
        accessRoles: [userType.superAdmin],
      },
      {
        id: 1.5,
        title: "Assign Audit Templates",
        isActive: false,
        path: "/internal-audit-assign-templates",
        accessRoles: [userType.superAdmin],
      },
    ],
  },
  {
    id: 2,
    title: "Corrective Action Log",
    isActive: false,
    accessRoles: [userType.clientAdmin],
    child: (
      <>
        <i className="menu-icon bx bx-book"></i>
        <div data-i18n="Corrective Action Log">Corrective Action Log</div>
      </>
    ),
    subItems: [
      {
        id: 2.1,
        title: "Action Log",
        isActive: false,
        path: "/corrective-action-log",
        accessRoles: [userType.clientAdmin],
      },
      {
        id: 2.2,
        title: "Create Corrective Action",
        isActive: false,
        path: "/create-corrective-action",
        accessRoles: [userType.clientAdmin],
      }
    ],
  },
  {
    id: 3,
    title: "User Management",
    isActive: false,
    accessRoles: [userType.superAdmin, userType.clientAdmin],
    isAccessByPermissionRole: true,
    allowedPermissions: [],
    child: (
      <>
        <i className="menu-icon bx bx-user"></i>
        <div data-i18n="User Management">User Management</div>
      </>
    ),
    subItems: [
      {
        id: 3.1,
        title: "Users",
        isActive: false,
        path: "/user-list",
        accessRoles: [userType.superAdmin, userType.clientAdmin],
      },
      {
        id: 3.2,
        title: "Add New User",
        isActive: false,
        path: "/add-new-admin",
        isAccessByPermissionRole: true,
        allowedPermissions: [UserPermissionsConstant.AddSuperAdminUser],
        accessRoles: [userType.superAdmin],
      },
      {
        id: 3.3,
        title: "Add New User",
        isActive: false,
        path: "/add-new-user",
        isAccessByPermissionRole: true,
        allowedPermissions: [UserPermissionsConstant.AddClientUser],
        accessRoles: [userType.clientAdmin],
      },
    ],
  },
  {
    id: 4,
    title: "Client Management",
    isActive: false,
    accessRoles: [userType.superAdmin],
    isAccessByPermissionRole: true,
    allowedPermissions: [
      UserPermissionsConstant.AddClients,
      UserPermissionsConstant.EditClients,
      UserPermissionsConstant.ViewClients,
      UserPermissionsConstant.DeleteClients,
    ],
    child: (
      <>
        <i className="menu-icon bx bx-user"></i>
        <div data-i18n="User Management">Client Management</div>
      </>
    ),
    subItems: [
      {
        id: 4.1,
        title: "Clients",
        isActive: false,
        path: "/client-list",
        accessRoles: [userType.superAdmin, userType.clientAdmin],
      },
      {
        id: 4.2,
        title: "Add New Client",
        isActive: false,
        path: "/add-new-client",
        accessRoles: [userType.superAdmin],
      },
      {
        id: 4.3,
        path: "/edit-client",
        accessRoles: [userType.superAdmin, userType.clientAdmin],
      },
      {
        id: 4.4,
        title: "User Activities",
        isActive: false,
        path: "/user-activities",
        accessRoles: [userType.superAdmin],
      },
    ],
  },
];

const AppSidebar = ({ role, user }: any) => {
  const [sideMenuItems, setSideMenuItems] =
    useState<menuItemType[]>(initMenuItems);
  const [expandedMenuItemId, setExpandedMenuItemId] = useState<number>(1);
  const browserPath = window.location.pathname.split("/");
  const navigate = useNavigate();
  const urlState: any = useLocation();

  useEffect(() => {
    setSideMenuItems(initMenuItems);
  }, []);

  useEffect(() => {
    if (user) {
      setSideMenuItems(updateIndicator(sideBarMenuUpdateWithPermissions));
    }
  }, [user]);

  useEffect(() => {
    setSideMenuItems(updateIndicator(sideBarMenuUpdateWithPermissions));
  }, [urlState]);

  const updateRolesBasedOnPermissions = (): menuItemType[] => {
    const toBeUpdateInitMenuItems: menuItemType[] = [
      {
        id: 1,
        title: user?.isSuperAdmin ? "Audit Management" : "Internal Audits",
        isActive: false,
        accessRoles: [userType.superAdmin, userType.clientAdmin],
        isAccessByPermissionRole: true,
        allowedPermissions: user?.isSuperAdmin
          ? [
              UserPermissionsConstant.CreateAuditTemplates,
              UserPermissionsConstant.ChangeAuditTemplates,
              UserPermissionsConstant.AssignTemplates,
              UserPermissionsConstant.ChangeAuditPlan,
              UserPermissionsConstant.PrintAuditPlan,
            ]
          : [
              UserPermissionsConstant.ChangeAuditPlan,
              UserPermissionsConstant.PrintAuditPlan,
              UserPermissionsConstant.ViewPrintAudit,
              UserPermissionsConstant.PerformAudit,
              UserPermissionsConstant.MimicAccess
            ],
        child: (
          <>
            <i data-toggle="tooltip"
                 data-placement="right" 
                 title={user?.isSuperAdmin ? "Audit Management" : "Internal Audits"}
                 className="menu-icon bx bx-check-square"></i>
            <div data-i18n="Internal Audits">
              {user?.isSuperAdmin ? "Audit Management" : "Internal Audits"}
            </div>
          </>
        ),
        subItems: [
          {
            id: 1.6,
            title: "New Audit",
            iconClass:'bx-book-add',
            isActive: false,
            path: "/add-new-audit",
            accessRoles: [userType.clientAdmin],
            isAccessByPermissionRole: true,
            allowedPermissions: [
              UserPermissionsConstant.PerformAudit, 
              UserPermissionsConstant.MimicAccess
            ],
          },
          {
            id: 1.1,
            title: "Internal Audit log",
            isActive: false,
            path: "/internal-audit-log",
            iconClass:'bx-book-content',
            accessRoles: [userType.clientAdmin],
            isAccessByPermissionRole: true,
            allowedPermissions: [
              UserPermissionsConstant.ViewPrintAudit,
              UserPermissionsConstant.PerformAudit,
              UserPermissionsConstant.MimicAccess
            ],
          },
          {
            id: 1.2,
            title: user?.isSuperAdmin
              ? "Audit Planner"
              : "Internal Audit Planner",
            isActive: false,
            path: "/internal-audit-planner",
            iconClass: 'bx-notepad',
            accessRoles: [userType.clientAdmin, userType.superAdmin],
            isAccessByPermissionRole: true,
            allowedPermissions: [
              UserPermissionsConstant.ChangeAuditPlan,
              UserPermissionsConstant.PrintAuditPlan,
              UserPermissionsConstant.MimicAccess
            ],
          },
          {
            id: 1.4,
            title: "Audit Templates",
            isActive: false,
            path: "/internal-audit-management",
            iconClass: 'bx-book-content',
            accessRoles: [userType.superAdmin],
            isAccessByPermissionRole: true,
            allowedPermissions: [
              UserPermissionsConstant.ChangeAuditTemplates,
              UserPermissionsConstant.CreateAuditTemplates,
            ],
          },
          {
            id: 1.5,
            title: "Assign Audit Templates",
            isActive: false,
            path: "/internal-audit-assign-templates",
            iconClass:'bxs-book-content',
            accessRoles: [userType.superAdmin],
            isAccessByPermissionRole: true,
            allowedPermissions: [
              UserPermissionsConstant.AssignTemplates,
            ],
          },
        ],
      },
      {
        id: 2,
        title: "Corrective Action Log",
        isActive: false,
        accessRoles: [userType.clientAdmin],
        child: (
            <>
              <i data-toggle="tooltip"
                 data-placement="right"
                 title="Corrective Action Log"
                 className="menu-icon bx bx-book"></i>
              <div>Corrective Action Log</div>
            </>
        ),
        isAccessByPermissionRole: true,
        allowedPermissions: [
          UserPermissionsConstant.ViewPrintAudit,
          UserPermissionsConstant.PerformAudit,
          UserPermissionsConstant.MimicAccess
        ],
        subItems: [
          {
            id: 2.1,
            title: "Action Log",
            isActive: false,
            path: "/corrective-action-log",
            iconClass: "bxs-notepad",
            accessRoles: [userType.clientAdmin],
            isAccessByPermissionRole: true,
            allowedPermissions: [
              UserPermissionsConstant.ViewPrintAudit,
              UserPermissionsConstant.PerformAudit,
              UserPermissionsConstant.MimicAccess
            ],
          },
          {
            id: 2.2,
            title: "Create Corrective Action",
            isActive: false,
            path: "/create-corrective-action",
            iconClass: "bx-list-plus",
            accessRoles: [userType.clientAdmin],
            isAccessByPermissionRole: true,
            allowedPermissions: [
                  UserPermissionsConstant.PerformAudit,
                  UserPermissionsConstant.MimicAccess
                ],
          },
        ],
      },
      {
        id: 3,
        title: "User Management",
        isActive: false,
        accessRoles: [userType.superAdmin, userType.clientAdmin],
        isAccessByPermissionRole: true,
        allowedPermissions: user?.isSuperAdmin
          ? [
              UserPermissionsConstant.AddSuperAdminUser,
              UserPermissionsConstant.EditSuperAdminUser,
              UserPermissionsConstant.ViewSuperAdminUser,
              UserPermissionsConstant.DeleteSuperAdminUser,
            ]
          : [
              UserPermissionsConstant.AddClientUser,
              UserPermissionsConstant.EditClientUser,
              UserPermissionsConstant.ViewClientUser,
              UserPermissionsConstant.DeleteClientUser,
              UserPermissionsConstant.MimicAccess
            ],
        child: (
          <>
            <i data-toggle="tooltip"
               data-placement="right"
               title={'User Management'} className="menu-icon bx bx-user"></i>
            <div data-i18n="User Management">User Management</div>
          </>
        ),
        subItems: [
          {
            id: 3.1,
            title: "Users",
            isActive: false,
            path: "/user-list",
            iconClass: 'bx-group',
            accessRoles: [userType.superAdmin, userType.clientAdmin],
            isAccessByPermissionRole: true,
            allowedPermissions: user?.isSuperAdmin
              ? [
                  UserPermissionsConstant.AddSuperAdminUser,
                  UserPermissionsConstant.EditSuperAdminUser,
                  UserPermissionsConstant.ViewSuperAdminUser,
                  UserPermissionsConstant.DeleteSuperAdminUser,
                ]
              : [
                  UserPermissionsConstant.AddClientUser,
                  UserPermissionsConstant.EditClientUser,
                  UserPermissionsConstant.ViewClientUser,
                  UserPermissionsConstant.DeleteClientUser,
                  UserPermissionsConstant.MimicAccess
                ],
          },
          {
            id: 3.2,
            title: "Add New User",
            isActive: false,
            path: "/add-new-admin",
            iconClass: 'bx-user-plus',
            accessRoles: [userType.superAdmin],
            isAccessByPermissionRole: true,
            allowedPermissions: [UserPermissionsConstant.AddSuperAdminUser],
          },
          {
            id: 3.3,
            title: "Add New User",
            isActive: false,
            path: "/add-new-user",
            iconClass: 'bx-user-plus',
            accessRoles: [userType.clientAdmin],
            isAccessByPermissionRole: true,
            allowedPermissions: [
              UserPermissionsConstant.AddClientUser,
              UserPermissionsConstant.MimicAccess
            ],
          },
        ],
      },
      {
        id: 4,
        title: "Client Management",
        isActive: false,
        accessRoles: [userType.superAdmin],
        isAccessByPermissionRole: true,
        allowedPermissions: [
          UserPermissionsConstant.AddClients,
          UserPermissionsConstant.EditClients,
          UserPermissionsConstant.ViewClients,
          UserPermissionsConstant.DeleteClients
        ],
        child: (
          <>
            <i data-toggle="tooltip"
               data-placement="right"
               title="Client Management"
               className="menu-icon bx bx-user"></i>
            <div>Client Management</div>
          </>
        ),
        subItems: [
          {
            id: 4.1,
            title: "Clients",
            isActive: false,
            path: "/client-list",
            iconClass: 'bx-buildings',
            accessRoles: [userType.superAdmin, userType.clientAdmin],
            isAccessByPermissionRole: true,
            allowedPermissions: [
              UserPermissionsConstant.AddClients,
              UserPermissionsConstant.EditClients,
              UserPermissionsConstant.ViewClients,
              UserPermissionsConstant.DeleteClients
            ],
          },
          {
            id: 4.2,
            title: "Add New Client",
            isActive: false,
            path: "/add-new-client",
            iconClass: 'bx-building',
            accessRoles: [userType.superAdmin],
            isAccessByPermissionRole: true,
            allowedPermissions: [UserPermissionsConstant.AddClients],
          },
          {
            id: 4.4,
            title: "User Activities",
            isActive: false,
            path: "/user-activities",
            iconClass: 'bxs-user-detail',
            accessRoles: [userType.superAdmin],
            isAccessByPermissionRole: true,
            allowedPermissions: [
              UserPermissionsConstant.AddClients,
              UserPermissionsConstant.EditClients,
              UserPermissionsConstant.ViewClients,
              UserPermissionsConstant.DeleteClients
            ],
          },
        ],
      },
    ];
    return toBeUpdateInitMenuItems;
  };
  const sideBarMenuUpdateWithPermissions = useMemo(
    () => updateRolesBasedOnPermissions(),
    [user]
  );

  const updateIndicator = (menuItems: menuItemType[]) => {
    let activeSubItem: subMenuItemType | undefined = undefined;
    for (var i = 0; i < menuItems.length; i++) {
      activeSubItem = sideMenuItems[i].subItems.find(
        (subItem) => subItem.path === `${"/" + browserPath[1]}`
      );
      if (activeSubItem !== undefined) break;
    }
    const updatedUsActive: any = menuItems.map((sideMenuItem: menuItemType) => {
      if (sideMenuItem.id === Number(String(activeSubItem?.id).charAt(0))) {
        sideMenuItem.isActive = true;
        sideMenuItem.subItems.map((sideSubMenuItem) => {
          sideSubMenuItem.isActive = sideSubMenuItem.id === activeSubItem?.id;
          return sideSubMenuItem;
        });
      } else {
        sideMenuItem.isActive = false;
        if (sideMenuItem.id !== Number(String(activeSubItem?.id).charAt(0))) {
          sideMenuItem.subItems.map((sideSubMenuItem) => {
            sideSubMenuItem.isActive = false;
            return sideSubMenuItem;
          });
        }
      }
      return sideMenuItem;
    });
    return updatedUsActive;
  };

  useEffect(() => {
    sideMenuItems.map((x) => (x.isActive ? setExpandedMenuItemId(x.id) : ""));
  }, [sideMenuItems]);

  const toggleSideMenu = (id: any) => {
    expandedMenuItemId === id
      ? setExpandedMenuItemId(0)
      : setExpandedMenuItemId(id);
  };

  const closeExpandedMenuItem = () => {
    document.body.classList.remove("layout-menu-expanded");
  };

  const goToHome = () => {
    const { isAgreeWithPrivacyPolicy, isAgreeWithTermsCondition } = user;
    if (
      (!isAgreeWithPrivacyPolicy && !isAgreeWithTermsCondition) ||
      (!isAgreeWithPrivacyPolicy && isAgreeWithTermsCondition)
    )
      navigate("/privacy-statement");
    if (isAgreeWithPrivacyPolicy && !isAgreeWithTermsCondition)
      navigate("/terms-conditions");

    if (isAgreeWithPrivacyPolicy && isAgreeWithTermsCondition) navigate("/");
  };
  const toggleClass = () => {
    document.body.classList.toggle("layout-menu-expanded");
  };

  return (
    <aside
      id="layout-menu"
      className="layout-menu menu-vertical menu bg-menu-theme aside-menu-toggle">
        <div>
            <a   onClick={toggleClass}  href="javascript:void(0);" className="layout-menu-toggle-1 menu-link text-large ms-auto d-block d-xl-none">
                        <i className="bx bx-chevron-left bx-sm align-middle"></i>
                    </a>
                    </div>
      <div className="menu-inner-shadow"></div>
    
      <ul className="menu-inner py-4 pt-3 ms-1">
        {sideMenuItems.map(
          (sideMenuItem) =>
            sideMenuItem.accessRoles.includes(role) &&
            (sideMenuItem.isAccessByPermissionRole &&
            sideMenuItem?.allowedPermissions ? (
              <AccessControl
                type={AccessControlType.HIDE}
                allowedPermissions={sideMenuItem?.allowedPermissions}
              >
                <li
                  className={`menu-item
                    ${sideMenuItem.isActive ? "active" : ""} 
                    // TODO : All expanded HCSL-223
                    // ${expandedMenuItemId === sideMenuItem.id ? "open" : ""} 
                    open`
                  }
                  key={sideMenuItem.id}
                >
                  <a
                    onClick={() => toggleSideMenu(sideMenuItem.id)}
                    className={`menu-link menu-toggle
                           ${sideMenuItem.isActive ? "fw-bolderl" : ""} `}
                  >
                    {sideMenuItem.child}
                  </a>

                  <ul className="menu-sub pointer">
                    {sideMenuItem.subItems.map(
                      (sideSubMenuItem) =>
                        sideSubMenuItem?.title &&
                        sideSubMenuItem.accessRoles.includes(role) &&
                        (sideSubMenuItem.isAccessByPermissionRole &&
                        sideSubMenuItem?.allowedPermissions ? (
                          <AccessControl
                            type={AccessControlType.DISABLE}
                            allowedPermissions={
                              sideSubMenuItem?.allowedPermissions
                            }
                          >
                            <li
                              key={sideSubMenuItem.id}
                              onClick={() =>
                                sideSubMenuItem?.path &&
                                navigate(sideSubMenuItem?.path)
                              }
                              className={`left-menu-items mx-1 ps-3 ms-3 ${
                                sideSubMenuItem?.isActive ? "active-subment-item" : ""
                              }`}
                            >
                              <i data-toggle="tooltip"
                                data-placement="right"
                                title={sideSubMenuItem.title} 
                                className={`my-2 d-inline-block left-menu-icon-color 
                                  bx ${sideSubMenuItem.iconClass? sideSubMenuItem.iconClass :'bx-circle'}`}>                                    
                                  </i>
                              <a className="mx-2 d-inline-block">
                                <div>{sideSubMenuItem.title}</div>
                              </a>
                            </li>
                          </AccessControl>
                        ) : (
                          <li
                            key={sideSubMenuItem.id}
                            onClick={() =>
                              sideSubMenuItem?.path &&
                              navigate(sideSubMenuItem?.path)
                            }
                            className={`menu-item ${
                              sideSubMenuItem?.isActive ? "active" : ""
                            }`}
                          >
                            <a className="menu-link">
                              <div>{sideSubMenuItem.title}</div>
                            </a>
                          </li>
                        ))
                    )}
                  </ul>
                </li>
              </AccessControl>
            ) : (
              <li
                className={`menu-item pointer
                    ${sideMenuItem.isActive ? "active" : ""} 
                    ${expandedMenuItemId === sideMenuItem.id ? "open" : ""}`}
                key={sideMenuItem.id}
              >
                <a
                  onClick={() => toggleSideMenu(sideMenuItem.id)}
                  className={`menu-link menu-toggle
                           ${sideMenuItem.isActive ? "fw-bolderl" : ""} `}
                >
                  {sideMenuItem.child}
                </a>

                <ul className="menu-sub">
                  {sideMenuItem.subItems.map(
                    (sideSubMenuItem) =>
                      sideSubMenuItem?.title &&
                      sideSubMenuItem.accessRoles.includes(role) &&
                      (sideSubMenuItem.isAccessByPermissionRole &&
                      sideSubMenuItem?.allowedPermissions ? (
                        <AccessControl
                          type={AccessControlType.DISABLE}
                          allowedPermissions={
                            sideSubMenuItem?.allowedPermissions
                          }
                        >
                          <li
                            key={sideSubMenuItem.id}
                            onClick={() =>
                              sideSubMenuItem?.path &&
                              navigate(sideSubMenuItem?.path)
                            }
                            className={`menu-item ${
                              sideSubMenuItem?.isActive ? "active" : ""
                            }`}
                          >
                            <a className="menu-link">
                              <div>{sideSubMenuItem.title}</div>
                            </a>
                          </li>
                        </AccessControl>
                      ) : (
                        <li
                          key={sideSubMenuItem.id}
                          onClick={() =>
                            sideSubMenuItem?.path &&
                            navigate(sideSubMenuItem?.path)
                          }
                          className={`menu-item ${
                            sideSubMenuItem?.isActive ? "active" : ""
                          }`}
                        >
                          <a className="menu-link">
                            <div>{sideSubMenuItem.title}</div>
                          </a>
                        </li>
                      ))
                  )}
                </ul>
              </li>
            ))
        )}
      </ul>
   
          <ul className="menu-inner justify-content-end">
              <li className="menu-item  border-top" title= "Privacy Policy">
          <a
            className="menu-link py-2 pointer"
            onClick={() => navigate("/privacy-statement")}
          >
    <i className='bx bx-check-shield me-2'></i>
            <div className="App">Privacy Policy</div>
          </a>
        </li>
              <li className="menu-item" title="Terms and Condition">
          <a
            className="menu-link py-2 pointer"
            onClick={() => navigate("/terms-conditions")}
          >
           <i className='bx bx-certification me-2'></i>
            <div className="App">Terms and Condition</div>
          </a>
        </li>
        
      </ul>
    </aside>
  );
};
const mapStateToProps = (state: any) => {
  return {
    role: state?.auth?.user?.isSuperAdmin
      ? userType.superAdmin
      : userType.clientAdmin,
    user: state?.auth?.user,
  };
};
export default connect(mapStateToProps, null)(AppSidebar);
