import React, {useEffect, useState} from 'react';
import Input from "../../../components/elements/Input";
import {useFormik} from "formik";
import {ILocalTemplateType} from "../../../redux/modules/clientManagementReducer";
import {BootyPagination} from "../../../components/pagination/BootyPagination";
import DataTable, {TableStyles} from "react-data-table-component";


interface propsTypes {
    datas?: ILocalTemplateType[] | [];
    onSelect?:(data:ILocalTemplateType)=> void;
}

function AuditListWithSearch({datas ,onSelect}: propsTypes) {
    const [dataTableFormat, setDataTableFormat] = useState<any>();

    const formik = useFormik({
        initialValues: {
            code: "",
            audit: ""
        },
        onSubmit: (values) => {
            const isLoadFiltered = Object.values(values).filter(f => f !== '').length > 0;
            if (isLoadFiltered && datas) {
                const cloneALlOriginalTemplateList: ILocalTemplateType[] =
                    datas && datas?.length > 0 ? [...datas] : [];
                const filterTemplates = cloneALlOriginalTemplateList?.filter(
                    (template: any) =>
                        (values?.code ? template?.localTemplateCode.toLowerCase().includes(values?.code?.toLowerCase()) : true)
                        &&
                        ((values?.audit && template.localTemplateName) ? template?.localTemplateName.toLowerCase().includes(values?.audit?.toLowerCase()) : true)
                );
                setDataTableFormat(formatData(filterTemplates));
            }else datas && setDataTableFormat(formatData(datas));
        }
    });

    useEffect(() => {
        if(datas){
            setDataTableFormat(formatData(datas));
        }
    },[datas]);

    const formatData = (templates: ILocalTemplateType[])=>{
        let formattedDatas:any=[];
        templates.forEach((data:ILocalTemplateType) =>{
            const formattedData = {
                code: data.localTemplateCode,
                auditName: data.localTemplateName,
                action: addSelectAction(data)
            }
            formattedDatas.push(formattedData);
        });
        return formattedDatas;
    }

    const addSelectAction = (templates: ILocalTemplateType) => {
        return (
            <button
                onClick={()=>onSelect && onSelect(templates)}
                type="button"
                className="btn btn-outline-primary btn-xs px-3">
                Select
            </button>
        )
    }

    const customStyles: TableStyles = {
        headRow: {
            style: { backgroundColor: "#f2f2f2", border: "1px solid #d9dee3" },
        },
        rows: {
            style: { border: "1px solid #d9dee3" },
        },
    };
    const columns: any = [
        {
            name: "Code",
            selector: (row: any) => row.code,
            sortable: true,
            width: "120px",
        },{
            name: "Audit",
            selector: (row: any) => row.auditName,
            sortable: true,
            wrap:true
        },
        {
            name: "",
            selector: (row: any) => row.action,
            sortable: false,
            width:'120px'
        }

    ];

    return (<>
        <div className="modal-body pt-2">
            {/*Bordered Table -->*/}
            <form className={'w-100'} onSubmit={formik.handleSubmit}>
                <div className="row mb-3 border-bottom align-items-end pb-3">
                    <div className="col-4">
                        <Input
                            id="code"
                            name="code"
                            onChange={(e)=> {
                                formik.handleChange(e)
                                formik.handleSubmit()
                            }}
                            type="text"
                            label="Code"
                            inputClasses="form-control form-control-sm"
                            labelClasses="col-form-label  text-capitalize fw-semibold"
                        />

                    </div>
                    <div className="col-4">
                        <Input
                            name="audit"
                            onChange={(e)=> {
                                formik.handleChange(e)
                                formik.handleSubmit()
                            }}
                            type="text"
                            label="Audit"
                            inputClasses="form-control form-control-sm"
                            labelClasses="col-form-label  text-capitalize fw-semibold"
                            placeholder=""
                        />
                    </div>
                    <div className="col-4 d-flex align-items-end">
                        <div className="col-md d-flex justify-content-end">
                            {/*<button*/}
                            {/*    type="submit"*/}
                            {/*    className="btn btn-primary btn-sm px-4"*/}
                            {/*    data-bs-toggle="modal"*/}
                            {/*    data-bs-target="#modalCenter">*/}
                            {/*    Search*/}
                            {/*</button>*/}
                        </div>
                    </div>
                </div>
            </form>
            <div className="row">
                <div className="col-md">
                    <div className="table-responsive text-nowrap">
                        <DataTable
                            title=""
                            columns={columns}
                            data={dataTableFormat}
                            defaultSortFieldId="code"
                            pagination={true}
                            paginationPerPage={10}
                            paginationComponent={BootyPagination}
                            selectableRows={false}
                            customStyles={customStyles}
                        />
                    </div>
                </div>
            </div>
            {/*Bordered Table -->*/}
        </div>
    </>);
}

export default AuditListWithSearch;