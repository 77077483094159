import api from "../config/api";

export function getAllPermissions(userType:string, onSuccess: (data: any) => void,
                                  onFailure: (data: any) => void):any {

    let url = '/ApplicationRoles/ByCreateUserType?createUserType=' + userType;
    return api.get(url).then(
        (response: any) => {
            onSuccess && onSuccess(response?.data);
        },
        (error: any) => {
            onFailure && onFailure(error);
        }
    );
}

