import React from "react";
import AppLayout from "../../Layout/AppLayout";

function AccountSettings(){

    return (
        <AppLayout>
                Account settings
        </AppLayout>
    )
}
export default AccountSettings;