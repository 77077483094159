import api from "../config/api";
import {
  CREATE_CLIENTS_FAILURE,
  CREATE_CLIENTS_REQUEST,
  CREATE_CLIENTS_SUCCESS,
  CREATE_USER_BY_CLIENT_FAILURE,
  CREATE_USER_BY_CLIENT_REQUEST,
  CREATE_USER_BY_CLIENT_SUCCESS,
  DELETE_CLIENT_USER_FAILURE,
  DELETE_CLIENT_USER_REQUEST,
  DELETE_CLIENT_USER_SUCCESS,
  DELETE_CLIENTS_FAILURE,
  DELETE_CLIENTS_REQUEST,
  DELETE_CLIENTS_SUCCESS,
  FETCH_CLIENT_FAILURE,
  FETCH_CLIENT_REQUEST,
  FETCH_CLIENT_SUCCESS,
  FETCH_CLIENTS_FAILURE,
  FETCH_CLIENTS_REQUEST,
  FETCH_CLIENTS_SUCCESS,
  FETCH_MIMIC_CLIENTS_FAILURE,
  FETCH_MIMIC_CLIENTS_REQUEST,
  FETCH_MIMIC_CLIENTS_SUCCESS,
  FETCH_USER_BY_CLIENT_ID_FAILURE,
  FETCH_USER_BY_CLIENT_ID_REQUEST,
  FETCH_USER_BY_CLIENT_ID_SUCCESS,
  FETCH_USERS_ROLES_CLIENTS_FAILURE,
  FETCH_USERS_ROLES_CLIENTS_REQUEST,
  FETCH_USERS_ROLES_CLIENTS_SUCCESS,
  UPDATE_CLIENTS_FAILURE,
  UPDATE_CLIENTS_REQUEST,
  UPDATE_CLIENTS_SUCCESS
} from "../constants";
import {ClientsResponse} from "../redux/modules/clientManagementReducer";
import {createNewClientFormType, updateClientType,} from "../views/pages/clientManagement/ClientDetailsTab";
import {GET_ROLES_BY_USER_IDS, ROOT_USER_URL} from "../constants/constants";
import {apiResponseType} from "../config/commonTypes";

export function createNewClient(
  client: createNewClientFormType,
  onSuccess: (data: any) => void,
  onFailure: (data: any) => void
): any {
  let url = "/Client";
  return (dispatch: any) => {
    dispatch({
      type: CREATE_CLIENTS_REQUEST,
      payload: "",
    });
    return api.post(url, client).then(
      (response: any) => {
        dispatch({
          type: CREATE_CLIENTS_SUCCESS,
          payload: response,
        });
        onSuccess && onSuccess(response);
      },
      (error: any) => {
        dispatch({
          type: CREATE_CLIENTS_FAILURE,
        });
        onFailure && onFailure(error?.response?.data);
      }
    );
  };
}

//TODO : pagination will be added latter
export function getClients(): any {
  let url = "/Client";
  return (dispatch: any) => {
    dispatch({
      type: FETCH_CLIENTS_REQUEST,
      payload: "",
    });
    return api.get(url).then(
      (response: any) => {
        dispatch({
          type: FETCH_CLIENTS_SUCCESS,
          payload: response as ClientsResponse,
        });
      },
      (error: any) => {
        dispatch({
          type: FETCH_CLIENTS_FAILURE,
        });
      }
    );
  };
}
export function getClient(id: number): any {
  let url = "/Client/" + id;
  return (dispatch: any) => {
    dispatch({
      type: FETCH_CLIENT_REQUEST,
      payload: "",
    });
    return api.get(url).then(
      (response: any) => {
        dispatch({
          type: FETCH_CLIENT_SUCCESS,
          payload: response,
        });
      },
      (error: any) => {
        dispatch({
          type: FETCH_CLIENT_FAILURE,
        });
      }
    );
  };
}
export function createClientUser(
  user: any,
  onSuccess: (data: any) => void,
  onFailure: (data: any) => void
): any {
  let url = "/Users";
  return (dispatch: any) => {
    dispatch({
      type: CREATE_USER_BY_CLIENT_REQUEST,
      payload: "",
    });
    return api.post(url, user).then(
      (response: any) => {
        dispatch({
          type: CREATE_USER_BY_CLIENT_SUCCESS,
          payload: response,
        });
        onSuccess && onSuccess(response);
      },
      (error: any) => {
        dispatch({
          type: CREATE_USER_BY_CLIENT_FAILURE,
        });
        onFailure && onFailure(error);
      }
    );
  };
}
export function updateClientUser(
  user: any,
  onSuccess: (data: apiResponseType) => void,
  onFailure: (data: apiResponseType) => void
): any {
  let url = ROOT_USER_URL;
  return (dispatch: any) => {
    return api.put(url, user).then(
      (response: any) => {
        onSuccess && onSuccess(response);
      },
      (error: any) => {
        onFailure && onFailure(error);
      }
    );
  };
}

export function getAllUserByClientId(
  clientId: number,
  onSuccess?: (data: any) => void,
  onFailure?: (data: any) => void
): any {
  let url = "/Users/ByClientId?clientId=" + clientId;
  return (dispatch: any) => {
    dispatch({
      type: FETCH_USER_BY_CLIENT_ID_REQUEST,
      payload: "",
    });
    return api.get(url).then(
      (response: any) => {
        dispatch({
          type: FETCH_USER_BY_CLIENT_ID_SUCCESS,
          payload: response,
        });
        onSuccess && onSuccess(response?.data);
      },
      (error: any) => {
        dispatch({
          type: FETCH_USER_BY_CLIENT_ID_FAILURE,
          payload: error,
        });
        onFailure && onFailure(error);
      }
    );
  };
}

export function updateClient(
  client: updateClientType,
  onSuccess: (data: any) => void,
  onFailure: (data: any) => void
): any {
  let url = "/Client";
  return (dispatch: any) => {
    dispatch({
      type: UPDATE_CLIENTS_REQUEST,
      payload: "",
    });
    return api.put(url, client).then(
      (response: any) => {
        dispatch({
          type: UPDATE_CLIENTS_SUCCESS,
          payload: response,
        });
        onSuccess && onSuccess(response);
      },
      (error: any) => {
        dispatch({
          type: UPDATE_CLIENTS_FAILURE,
        });
        onFailure && onFailure(error);
      }
    );
  };
}

export function deleteClient(
  clientId: number,
  onSuccess?: (data: any) => void,
  onFailure?: (data: any) => void
): any {
  let url = "/Client/" + clientId;
  return (dispatch: any) => {
    dispatch({
      type: DELETE_CLIENTS_REQUEST,
      payload: "",
    });
    return api.delete(url).then(
      (response: any) => {
        dispatch({
          type: DELETE_CLIENTS_SUCCESS,
          payload: response,
        });
        onSuccess && onSuccess(response);
      },
      (error: any) => {
        dispatch({
          type: DELETE_CLIENTS_FAILURE,
        });
        onFailure && onFailure(error);
      }
    );
  };
}

export function getRolesByUserIds(userIds: string[]): any {
  return (dispatch: any) => {
    dispatch({
      type: FETCH_USERS_ROLES_CLIENTS_REQUEST,
    });
    return api.post(GET_ROLES_BY_USER_IDS, userIds).then(
      (response: any) => {
        dispatch({
          type: FETCH_USERS_ROLES_CLIENTS_SUCCESS,
          payload: response,
        });
      },
      (error: any) =>
        dispatch({
          type: FETCH_USERS_ROLES_CLIENTS_FAILURE,
          error,
        })
    );
  };
}

export function deleteUserByUserId(
  userId: any,
  onSuccess: (data: apiResponseType) => void,
  onFailure: (data: apiResponseType) => void
): any {
  let url = ROOT_USER_URL + "/" + userId;
  return (dispatch: any) => {
    dispatch({
      type: DELETE_CLIENT_USER_REQUEST,
      payload: "",
    });
    return api.delete(url).then(
      (response: any) => {
        dispatch({
          type: DELETE_CLIENT_USER_SUCCESS,
          payload: { data: { id: userId } },
        });
        onSuccess(response);
      },
      (error: any) => {
        dispatch({
          type: DELETE_CLIENT_USER_FAILURE,
          payload: error,
        });
        onFailure(error);
      }
    );
  };
}

export function getUserByUserId(
  userId: string,
  onSuccess: (data: apiResponseType) => void,
  onFailure: (data: apiResponseType) => void
): any {
  let url = "/Users/" + userId;
  return api.get(url).then(
    (response: any) => {
      onSuccess(response);
    },
    (error: any) => {
      onFailure(error);
    }
  );
}

export function getRolesByUserIdsForState(
  userIds: string[],
  onSuccess: (data: apiResponseType) => void,
  onFailure: (data: apiResponseType) => void
): any {
  return api.post(GET_ROLES_BY_USER_IDS, userIds).then(
    (response: any) => {
      onSuccess(response);
    },
    (error: any) => {
      onFailure(error);
    }
  );
}

export function deleteStateUserByUserId(
  userId: any,
  onSuccess: (data: apiResponseType) => void,
  onFailure: (data: apiResponseType) => void
): any {
  let url = ROOT_USER_URL + "/" + userId;
  return api.delete(url).then(
    (response: any) => {
      onSuccess(response);
    },
    (error: any) => {
      onFailure(error);
    }
  );
}


export function getClientsForMimik() : any{
  let url = '/Client/mimicClients';
  return (dispatch: any) => {
      dispatch({
          type: FETCH_MIMIC_CLIENTS_REQUEST,
          payload: '',
      });
      return api.get(url).then(
          (response: any) => {
              dispatch({
                  type: FETCH_MIMIC_CLIENTS_SUCCESS,
                  payload: response,
              });
          },
          (error: any) => {
              dispatch({
                  type: FETCH_MIMIC_CLIENTS_FAILURE,
              });
          }
      );
  };
}