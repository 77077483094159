import api from "../config/api";
import {
  CREATE_PASSWORD_RESET_FAILURE,
  CREATE_PASSWORD_RESET_REQUEST,
  CREATE_PASSWORD_RESET_SUCCESS,
  CREATE_USER_FAILURE,
  CREATE_USER_REQUEST,
  CREATE_USER_SUCCESS,
  DELETE_USER_FAILURE,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  FETCH_PASSWORD_RESET_FAILURE,
  FETCH_PASSWORD_RESET_REQUEST,
  FETCH_PASSWORD_RESET_SUCCESS,
  FETCH_SELECTED_USER_ROLES_FAILURE,
  FETCH_SELECTED_USER_ROLES_REQUEST,
  FETCH_SELECTED_USER_ROLES_SUCCESS,
  FETCH_USER_BY_ID_FAILURE,
  FETCH_USER_BY_ID_REQUEST,
  FETCH_USER_BY_ID_SUCCESS,
  FETCH_USERS_ACTIVITY_FAILURE,
  FETCH_USERS_ACTIVITY_REQUEST,
  FETCH_USERS_ACTIVITY_SUCCESS,
  FETCH_USERS_FAILURE,
  FETCH_USERS_REQUEST,
  FETCH_USERS_SUCCESS,
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  LOGOUT_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  UPDATE_PASSWORD_RESET_FAILURE,
  UPDATE_PASSWORD_RESET_REQUEST,
  UPDATE_PASSWORD_RESET_SUCCESS,
  UPDATE_SUPER_ADMIN_USER_FAILURE,
  UPDATE_SUPER_ADMIN_USER_REQUEST,
  UPDATE_SUPER_ADMIN_USER_SUCCESS,
  REFRESH_TOKEN_REQUEST,
  REFRESH_TOKEN_SUCCESS,
  REFRESH_TOKEN_FAILED,
} from "../constants";
import {
  apiResponseType,
  userFogetPasswordType,
  userLoginType,
  userType,
} from "../config/commonTypes";
import { authUserType } from "../redux/modules/userReducer";
import { createNewAdminUserFormType } from "../views/pages/userManagement/AddNewUser";
import { GET_ROLES_BY_USER_IDS } from "../constants/constants";

export function getSystemInactiveUsers(): any {
  let url = "/SystemInactiveUsers";
  return (dispatch: any) => {
    dispatch({
      type: FETCH_USERS_ACTIVITY_REQUEST,
    });
    return api.get(url).then(
      (response: any) => {
        dispatch({
          type: FETCH_USERS_ACTIVITY_SUCCESS,
          payload: response,
        });
      },
      (error: any) =>
        dispatch({
          type: FETCH_USERS_ACTIVITY_FAILURE,
          error,
        })
    );
  };
}

export function loginUser(
  user: userLoginType,
  onError: (err: any) => void
): any {
  let url = "/Authenticate/login";
  return (dispatch: any) => {
    return api.post(url, user).then(
      (response: any) => {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: response,
        });
      },
      (error: any) => {
        dispatch({
          type: LOGIN_FAILURE,
          error,
        });
        onError && onError(error);
      }
    );
  };
}

export function createNewUser(
  user: createNewAdminUserFormType,
  onSuccess: (data: any) => void,
  onFailure: (data: any) => void
): any {
  let url = "/Users";
  console.log(user);
  return (dispatch: any) => {
    dispatch({
      type: CREATE_USER_REQUEST,
      payload: "",
    });
    return api.post(url, user).then(
      (response: any) => {
        dispatch({
          type: CREATE_USER_SUCCESS,
          payload: response,
        });
        onSuccess && onSuccess(response);
      },
      (error: any) => {
        dispatch({
          type: CREATE_USER_FAILURE,
        });
        onFailure && onFailure(error?.response?.data);
      }
    );
  };
}

export function getAllUsers(): any {
  let url = "/Users/SuperAdmins";
  return (dispatch: any) => {
    dispatch({
      type: FETCH_USERS_REQUEST,
    });
    return api.get(url).then(
      (response: any) => {
        dispatch({
          type: FETCH_USERS_SUCCESS,
          payload: response,
        });
      },
      (error: any) =>
        dispatch({
          type: FETCH_USERS_FAILURE,
          error,
        })
    );
  };
}

export function logOut(): any {
  let url = "/Authenticate/logout";
  return (dispatch: any) => {
    dispatch({ type: LOGOUT_REQUEST });
    return api.get(url).then(
      (response: any) => {
        dispatch({
          type: LOGOUT_SUCCESS,
        });
      },
      (error: any) => {
        dispatch({
          type: LOGOUT_FAILURE,
          error,
        });
      }
    );
  };
}

export function RefreshToken(
  user: {
    accessToken: string;
    refreshToken: string;
  },
  onSuccess: (data: any) => void,
  onFailure: (data: any) => void
) {
  let url = "/Authenticate/refresh-token";
  return (dispatch: any) => {
    dispatch({ type: REFRESH_TOKEN_REQUEST });
    return api.post(url, user).then(
      (response: any) => {
        dispatch({
          type: REFRESH_TOKEN_SUCCESS,
        });
        onSuccess && onSuccess(response);
      },
      (error: any) => {
        dispatch({
          type: REFRESH_TOKEN_FAILED,
          error,
        });
        onFailure && onFailure(error);
      }
    );
  };
}

export async function RefreshTokenAsync(user: {
  accessToken: string;
  refreshToken: string;
}) {
  return api.post("/Authenticate/refresh-token", user);
}

export function resetUserPassword(
  user: userFogetPasswordType,
  onSuccess: (data: any) => void,
  onFailure: (data: any) => void
): any {
  let url = "/Users/ForgetPassword";
  return (dispatch: any) => {
    dispatch({
      type: CREATE_PASSWORD_RESET_REQUEST,
      payload: "",
    });
    return api.post(url, user).then(
      (response: any) => {
        dispatch({
          type: CREATE_PASSWORD_RESET_SUCCESS,
          payload: response,
        });
        onSuccess && onSuccess(response);
      },
      (error: any) => {
        dispatch({
          type: CREATE_PASSWORD_RESET_FAILURE,
        });
        onFailure && onFailure(error?.response);
      }
    );
  };
}

export function getResetPass(
  userName: any,
  token: any,
  onSuccess: (data: any) => void,
  onFailure: (data: any) => void
): any {
  let url = "/Users/ResetPasswordVerify/" + userName + "/verifytoken=" + token;
  return (dispatch: any) => {
    dispatch({
      type: FETCH_PASSWORD_RESET_REQUEST,
      payload: "",
    });
    return api.get(url).then(
      (response: any) => {
        dispatch({
          type: FETCH_PASSWORD_RESET_SUCCESS,
          payload: response,
        });
        onSuccess && onSuccess(response);
        console.log(response);
      },
      (error: any) => {
        dispatch({
          type: FETCH_PASSWORD_RESET_FAILURE,
          payload: error,
        });
        onFailure && onFailure(error.response.data);
      }
    );
  };
}

export function changeresetUserPassword(
  user: userFogetPasswordType,
  onSuccess: (data: any) => void,
  onFailure: (data: any) => void
): any {
  let url = "/Users/ResetPassword";
  return (dispatch: any) => {
    dispatch({
      type: UPDATE_PASSWORD_RESET_REQUEST,
      payload: "",
    });
    return api.post(url, user).then(
      (response: any) => {
        dispatch({
          type: UPDATE_PASSWORD_RESET_SUCCESS,
          payload: response,
        });
        onSuccess && onSuccess(response);
      },
      (error: any) => {
        dispatch({
          type: UPDATE_PASSWORD_RESET_FAILURE,
        });
        onFailure && onFailure(error?.response);
      }
    );
  };
}

export function deleteUser(
  clientId: number,
  onSuccess?: (data: any) => void,
  onFailure?: (data: any) => void
): any {
  let url = "/Users/" + clientId;
  console.log(url);
  return (dispatch: any) => {
    dispatch({
      type: DELETE_USER_REQUEST,
      payload: "",
    });
    return api.delete(url).then(
      (response: any) => {
        dispatch({
          type: DELETE_USER_SUCCESS,
          payload: response,
        });
        onSuccess && onSuccess(response);
      },
      (error: any) => {
        dispatch({
          type: DELETE_USER_FAILURE,
        });
        onFailure && onFailure(error);
      }
    );
  };
}

export function getUserByUserId(
  userId: string,
  onSuccess: (data: apiResponseType) => void,
  onFailure: (data: apiResponseType) => void
): any {
  let url = "/Users/" + userId;
  return (dispatch: any) => {
    dispatch({
      type: FETCH_USER_BY_ID_REQUEST,
    });
    return api.get(url).then(
      (response: any) => {
        dispatch({
          type: FETCH_USER_BY_ID_SUCCESS,
          payload: response,
        });
        onSuccess && onSuccess(response);
      },
      (error: any) => {
        dispatch({
          type: FETCH_USER_BY_ID_FAILURE,
          error,
        });
        onFailure && onFailure(error);
      }
    );
  };
}

export function updateSuperAdminUser(
  user: authUserType,
  onSuccess: (data: apiResponseType) => void,
  onFailure: (data: apiResponseType) => void
): any {
  let url = "/Users";
  return (dispatch: any) => {
    dispatch({
      type: UPDATE_SUPER_ADMIN_USER_REQUEST,
      payload: "",
    });
    return api.put(url, user).then(
      (response: any) => {
        dispatch({
          type: UPDATE_SUPER_ADMIN_USER_SUCCESS,
          payload: response,
        });
        onSuccess && onSuccess(response);
      },
      (error: any) => {
        dispatch({
          type: UPDATE_SUPER_ADMIN_USER_FAILURE,
        });
        onFailure && onFailure(error);
      }
    );
  };
}

export function getRolesByUserIds(
  userIds: string[],
  onSuccess: (data: apiResponseType) => void,
  onFailure: (data: apiResponseType) => void
): any {
  return (dispatch: any) => {
    dispatch({
      type: FETCH_SELECTED_USER_ROLES_REQUEST,
    });
    return api.post(GET_ROLES_BY_USER_IDS, userIds).then(
      (response: any) => {
        dispatch({
          type: FETCH_SELECTED_USER_ROLES_SUCCESS,
          payload: response,
        });
        onSuccess && onSuccess(response);
      },
      (error: any) => {
        dispatch({
          type: FETCH_SELECTED_USER_ROLES_FAILURE,
          error,
        });
        onFailure && onFailure(error);
      }
    );
  };
}

export function getAllUserByClientId(
  clientId: number,
  onSuccess?: (data: userType[]) => void,
  onFailure?: (data: any) => void
): any {
  let url = "/Users/ByClientId?clientId=" + clientId;
  return api.get(url).then(
    (response: any) => {
      onSuccess && onSuccess(response?.data);
    },
    (error: any) => {
      onFailure && onFailure(error.response.data);
    }
  );
}

export function createClientUser(
  user: any,
  onSuccess: (data: any) => void,
  onFailure: (data: any) => void
): any {
  let url = "/Users";
  return api.post(url, user).then(
    (response: any) => {
      onSuccess && onSuccess(response);
    },
    (error: any) => {
      onFailure && onFailure(error);
    }
  );
}

export function fetchLandingPageData(
  clientId: number,
  onSuccess: (data: any) => void,
  onFailure: (data: any) => void
): any {
  let url = "/Users/DashboardStats/" + clientId;
  return api.get(url).then(
    (response: any) => {
      onSuccess && onSuccess(response);
    },
    (error: any) => {
      onFailure && onFailure(error);
    }
  );
}

export function updateUserPolicy(
  userId: string,
  isAgree: boolean,
  conditionType: "T" | "P",
  onSuccess?: (data: apiResponseType) => void,
  onFailure?: (data: apiResponseType) => void
): any {
  let url =
    "/Users/UpdateUserPrivacyPolicy?id=" +
    userId +
    "&conditionType=" +
    conditionType +
    "&isAgree=" +
    isAgree;
  return api.put(url).then(
    (response: any) => {
      onSuccess && onSuccess(response);
    },
    (error: any) => {
      onFailure && onFailure(error);
    }
  );
}

export function getUserByUserIdWithOutDispatch(
  userId: string,
  onSuccess: (data: apiResponseType) => void,
  onFailure: (data: apiResponseType) => void
): any {
  let url = "/UserProfile/" + userId;
  return api.get(url).then(
    (response: any) => {
      onSuccess(response);
    },
    (error: any) => {
      onFailure(error?.response?.data);
    }
  );
}

export interface IUpdateUserType {
  id: string;
  name: string;
  designation: string;
  email: string;
}
export function updateUser(
  user: IUpdateUserType,
  onSuccess: (data: apiResponseType) => void,
  onFailure: (data: apiResponseType) => void
): any {
  let url = "/UserProfile";

  return api.put(url, user).then(
    (response: any) => {
      onSuccess(response);
    },
    (error: any) => {
      onFailure(error?.response?.data);
    }
  );
}
