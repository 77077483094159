import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import {
  GetAssigneeTemplatesByClientId,
  UpsertLocalTemplateSchedule,
} from "../../../actions/auditActions";
import { apiResponseType } from "../../../config/commonTypes";
import {
  ASSIGNEE_TEMPLATE_PLANNING_SCHEDULE_UPDATED,
  GET_AUDIT_ASSIGNEE_TEMPLATES_SUCCESS,
} from "../../../constants";
import AuditPlannerTabForLocal from "./AuditPlannerTabForLocal";
import { toast } from "react-toastify";
import {
  ILocalTemplateSchedule,
  ILocalTemplateType,
} from "../../../redux/modules/clientManagementReducer";

interface IPropsTypes {
  clientId: number;
  templateList?: ILocalTemplateType[] | [];
  isLoading?: boolean;
}

function AuditPlannerWrapper({
  templateList = [],
  isLoading,
  clientId,
}: IPropsTypes) {
  const dispatch = useDispatch();

  useEffect(() => {
    fetchAssigneeTemplatesWithSchedule(clientId);
  }, [clientId]);

  const refreshClieantList = () => {
    fetchAssigneeTemplatesWithSchedule(clientId);
  };

  const fetchAssigneeTemplatesWithSchedule = (cId: number) => {
    GetAssigneeTemplatesByClientId(
      cId,
      (data: ILocalTemplateType[]) => {
        dispatch({
          type: GET_AUDIT_ASSIGNEE_TEMPLATES_SUCCESS,
          payload: data,
        });
      },
      (data: apiResponseType) => {
        console.log("### Something went wrong!");
      }
    );
  };

  const onChangePlan = (data: ILocalTemplateSchedule) => {
    UpsertLocalTemplateSchedule(
      data,
      (response: apiResponseType) => {
        toast.success(
          response?.message ? response.message : "Successfully updated"
        );
        dispatch({
          type: ASSIGNEE_TEMPLATE_PLANNING_SCHEDULE_UPDATED,
          payload: response.data,
        });
      },
      (response: apiResponseType) => {
        fetchAssigneeTemplatesWithSchedule(clientId);
        toast.error(response?.message ? response.message : "Failed");
      }
    );
  };

  return (
    <>
      {templateList && (
        <AuditPlannerTabForLocal
          templateList={templateList}
          onChangePlan={(data: ILocalTemplateSchedule) => onChangePlan(data)}
          refresh={refreshClieantList}
        />
      )}
    </>
  );
}

const mapStateToProps = (state: any) => {
  const clientManagement = state?.clientManagement;
  return {
    isLoading: clientManagement?.isLoading,
    templateList: clientManagement?.assigneeTemplateListPlan,
  };
};
export default connect(mapStateToProps, null)(AuditPlannerWrapper);
