import { actionType, userType } from "../../config/commonTypes";
import {
  EDIT_LOCAL_TEMPLATE_ASSIGNEE_LIST_FAILURE,
  EDIT_LOCAL_TEMPLATE_ASSIGNEE_LIST_REQUEST,
  EDIT_LOCAL_TEMPLATE_ASSIGNEE_LIST_SUCCESS,
  GET_CORRECTIVE_ACTION_LOG_FAILURE,
  GET_CORRECTIVE_ACTION_LOG_REQUEST,
  GET_CORRECTIVE_ACTION_LOG_SUCCESS,
  GET_RESPONSIBLE_USER_IN_CORRECTIVE_ACTION_FAILURE,
  GET_RESPONSIBLE_USER_IN_CORRECTIVE_ACTION_REQUEST,
  GET_RESPONSIBLE_USER_IN_CORRECTIVE_ACTION_SUCCESS,
} from "../../constants";

export interface ICorrectiveActionLogType {
  correctiveActionLogId: number;
  localTemplateCriteriaId: number;
  localTemplateHeaderId: number;
  clientId: number;
  auditCode: string;
  auditName: string;
  startDate: any;
  completeDate: any;
  isComplies: boolean | null;
  findings: string;
  responsibleUserId: string;
  correctiveActions: string;
  isClosed: boolean | null;
  criteria: string;
  responsibleUsername: string;
  startBy: string;
  createdBy:string;
  responsibleUserFullName: string;
}
interface InitialStateTypeCorrectiveAction {
  isLoading: boolean;
  correctiveActionLogs: ICorrectiveActionLogType[] | [];
  responsiblePersons: userType[] | [];
}

const initialState: InitialStateTypeCorrectiveAction = {
  isLoading: false,
  correctiveActionLogs: [],
  responsiblePersons: [],
};
export default function reducer(
  state: InitialStateTypeCorrectiveAction = initialState,
  action: actionType
) {
  switch (action.type) {
    case GET_CORRECTIVE_ACTION_LOG_REQUEST:
      state.isLoading = true;
      return { ...state };
    case GET_CORRECTIVE_ACTION_LOG_SUCCESS:
      state.isLoading = false;
      state.correctiveActionLogs = action.payload;
      return { ...state };
    case GET_CORRECTIVE_ACTION_LOG_FAILURE:
      return { ...state };

    case GET_RESPONSIBLE_USER_IN_CORRECTIVE_ACTION_REQUEST:
      state.isLoading = false;
      return { ...state };
    case GET_RESPONSIBLE_USER_IN_CORRECTIVE_ACTION_SUCCESS:
      state.isLoading = false;
      state.responsiblePersons = action.payload;
      return { ...state };
    case GET_RESPONSIBLE_USER_IN_CORRECTIVE_ACTION_FAILURE:
      state.isLoading = false;
      return { ...state };

    case EDIT_LOCAL_TEMPLATE_ASSIGNEE_LIST_REQUEST:
      state.isLoading = false;
      return { ...state };
    case EDIT_LOCAL_TEMPLATE_ASSIGNEE_LIST_SUCCESS:
      state.isLoading = false;
      return { ...state };
    case EDIT_LOCAL_TEMPLATE_ASSIGNEE_LIST_FAILURE:
      state.isLoading = false;
      return { ...state };

    default:
      return { ...state };
  }
}
