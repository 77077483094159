import React, {useEffect, useState} from "react";
import AppLayout from "../../Layout/AppLayout";
import Input from "../../../components/elements/Input";
import {useNavigate} from "react-router-dom";
import {connect, useDispatch} from "react-redux";
import {clientType} from "../../../redux/modules/clientManagementReducer";
import {deleteClient, getClients, getClientsForMimik} from "../../../actions/clientAction";
import DataTable, {TableStyles} from "react-data-table-component";
import {BootyPagination} from "../../../components/pagination/BootyPagination";
import {useFormik} from "formik";
import "../../../assets/css/clintListDataTable.css";
import {jsPDF} from "jspdf";
import autoTable from "jspdf-autotable";
import {AccessControlType, clientActionType, UserPermissionsConstant} from "../../../constants/constants";
import {toast} from "react-toastify";
import {ConfirmModal} from "../../../components/modal/ConfirmModal";
import AccessControl from '../../../config/AccessControl';
import _ from "lodash";
import logoImage from "../../../assets/img/logo/logo.png";
import moment from "moment";


function ClientList({ clients }: any) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isDelete, setIsDelete] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<number>();
  const [dataTableFormat, setDataTableFormat] = useState<any>();

  useEffect(() => {
    dispatch(getClients());
  }, []);

  const indicateStatus = (client: clientType) => {
    return (
      <span
        className={`badge bg-label-${
          client.isActive ? "success" : "secondary"
        } d-grid`}
      >
        {client.isActive ? "Active" : "Inactive"}
      </span>
    );
  };
  const clientActions = (id: number) => {
    return (
        <span className="d-flex justify-content-center gap-1">
        <AccessControl type={AccessControlType.DISABLE}
                       allowedPermissions={[UserPermissionsConstant.EditClients]}>
        <button
            type="button"
            onClick={() =>
                navigate(
                    {
                      pathname: "/edit-client/" + id,
                    },
                    {state: {action: clientActionType.update}}
                )
            }
            className="btn btn-outline-primary btn-xs app-btn-xs py-0"
        >
          <i className="bx bx-pencil  small"></i>
        </button>
        </AccessControl>
        <AccessControl type={AccessControlType.DISABLE}
                       allowedPermissions={[UserPermissionsConstant.ViewClients]}>
        <button
            type="button"
            onClick={() =>
                navigate(
                    {
                      pathname: "/view-client/" + id,
                    },
                    {state: {action: clientActionType.view}}
                )
            }
            className="btn btn-outline-primary btn-xs app-btn-xs py-0"
        >
          <i className="bx bx-show  small"></i>
        </button>
        </AccessControl>
        <AccessControl type={AccessControlType.DISABLE}
                       allowedPermissions={[UserPermissionsConstant.DeleteClients]}>
        <button
            type="button"
            onClick={() => {
              setIsDelete(true);
              setDeleteId(id);
            }}
            className="btn btn-outline-danger btn-xs app-btn-xs py-0"
        >
          <i className="bx bx-trash-alt  small"></i>
        </button>
        </AccessControl>
      </span>
    );
  };
  useEffect(() => {
    if (clients?.length > 0) {
      const dataSet = formatDataTable(clients);
      setDataTableFormat(dataSet);
    }
  }, [clients]);

  const formatDataTable = (clientsArray: clientType[]) => {
    return clientsArray?.map((client: clientType) => {
      return {
        actions: clientActions(client?.clientId),
        indicator: indicateStatus(client),
        ...client,
      };
    });
  };

  const columns: any = [
    {
      name: "Company",
      selector: (row: any) => row.companyName,
      sortable: true,
      wrap:true,
    },
    {
      name: "Main Contact",
      selector: (row: any) => row.mainContactName,
      sortable: true,
      minWidth: '100px',
      wrap:true,
    },
    {
      name: "Email",
      selector: (row: any) => row.mainContactEmail,
      sortable: true,
      minWidth: '120px',
      maxWidth: '190px',
      wrap:true,
    },
    {
      name: "Mobile",
      selector: (row: any) => row.mobileNo,
      sortable: true,
      maxWidth: "150px",
    },
    {
      name: "Status",
      selector: (row: any) => row.indicator,
      sortable: false,
      width: "90px",

    },
    {
      name: "Action",
      selector: (row: any) => row.actions,
      sortable: false,
      width: "140px",
    },
  ];

  const initialValues: any = {
    company: "",
    mainContact: "",
    status: "",
  };

  const customStyles: TableStyles = {
    headRow: {
      style: { backgroundColor: "#f2f2f2", border: "1px solid #d9dee3" },
    },
    rows: {
      style: { border: "1px solid #d9dee3" },
    },
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: (value: any) => {
      const cloneALlOriginalClients: clientType[] =
        clients?.length > 0 ? [...clients] : [];
      const filterCloneClients = cloneALlOriginalClients?.filter(
        (client) =>
          (value?.company
            ? client?.companyName
                .toLowerCase()
                .includes(value?.company?.toLowerCase())
            : true) &&
          (value?.mainContact
            ? client?.mainContactName
                .toLowerCase()
                .includes(value?.mainContact?.toLowerCase())
            : true) &&
          (value?.status
            ? client?.isActive === (value?.status === "ACTIVE")
            : true)
      );
      const dataSet = formatDataTable(filterCloneClients);
      setDataTableFormat(dataSet);
    },
  });

  const deleteClientById = (deleteId: number) => {
    deleteId &&
      dispatch(
        deleteClient(
          deleteId,
          (response: any) => {
            setIsDelete(false);
            toast.success(
              response?.message
                ? response.message
                : "Successfully client deleted!!"
            );
            dispatch(getClients());
            dispatch(getClientsForMimik());
          },
          (error: any) => {
            setIsDelete(false);
            toast.error(
              error?.message ? error.message : "Failed to delete client!!"
            );
          }
        )
      );
  };

  const generatePDF = () => {
    if (_.isEmpty(dataTableFormat)) {
      toast.info("There is no data to print");
      return;
    }
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; //// portrait or landscape
    var doc = new jsPDF(orientation, unit, size);

    doc.addImage(logoImage, "PNG", 10, 10, 70, 70);
        doc.setFont("times");
        doc.setTextColor(65, 144, 197);
        doc.text("GIA+ Quality Assurance system", 100, 30);
        doc.text("__________________________", 100, 40);
        doc.setTextColor(0, 0, 0);
        doc.text("Client List", 100, 60);

    const headers: string[] = [
      "Company",
      "Main Contact",
      "Email",
      "Mobile",
      "Status",
    ];
    const headKey: string[] = [
      "companyName",
      "mainContactName",
      "mainContactEmail",
      "mobileNo",
      "status",
    ];
    let data: any = [];
    dataTableFormat.forEach((row: any) => {
      let rowData: string[] = [];
      headKey?.forEach((key: string) => {
        if (key === "status") {
          rowData.push(row?.indicator?.props?.children);
        } else rowData.push(row[key]);
      });
      data.push(rowData);
      rowData = [];
    });

    autoTable(doc, {
      head: [headers],
      margin: {
        top: 100,
      },
      body: data,
    });
    doc.save(`Client-List-${moment().format('DD-MM-YYYY-hh-mm-ss')}.pdf`);
  };

  return (
    <AppLayout>
      <>
        {isDelete && (
          <ConfirmModal
            onCanceled={() => setIsDelete(false)}
            isOpenConfirmModal={isDelete}
            title={"Delete Client"}
            btn1Text={"Cancel"}
            btn1Class={"btn btn-outline-secondary btn-sm"}
            btn2Text={"Confirm"}
            btn2Class="btn btn-danger btn-sm"
            icon={
              <i className="bx bx-error-circle display-4 app-col link-warning"></i>
            }
            message={"Are you sure you want to delete this item?"}
            onConfirm={() => deleteId && deleteClientById(deleteId)}
          />
        )}
        <div className="row">
          {/*Custom style1 Breadcrumb */}
          <div className="col-md">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb breadcrumb-style1">
                <li className="breadcrumb-item d-flex align-items-center">
                  <a href="javascript:void(0);">Home</a>
                </li>
                <li className="breadcrumb-item d-flex align-items-center">
                  Client Management
                </li>
                <li className="breadcrumb-item d-flex align-items-center active">
                  Clients
                </li>
              </ol>
            </nav>
          </div>
        </div>

        <div className="row">
          <form onSubmit={formik.handleSubmit} method="POST">
            <div className="col-md">
              <div className="accordion" id="accordionExample">
                <div className="card accordion-item shadow-sm active">
                  <h6 className="accordion-header" id="headingOne">
                    <button
                      type="button"
                      className="accordion-button border-bottom p-3"
                      data-bs-toggle="collapse"
                      data-bs-target="#accordionOne"
                      aria-expanded="true"
                      aria-controls="accordionOne"
                    >
                      Search Filters
                    </button>
                  </h6>

                  <div
                    id="accordionOne"
                    className="accordion-collapse collapse show"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body p-3 pt-2">
                      <div className="row">
                        <div className="col">
                          <Input
                            id="company"
                            name="company"
                            onChange={(e: any) => {
                              formik.handleChange(e);
                              formik.submitForm();
                            }}
                            type="text"
                            label="Company"
                            inputClasses="form-control form-control-sm"
                            labelClasses="col-form-label text-capitalize fw-semibold"
                          />
                        </div>
                        <div className="col">
                          <Input
                            id="mainContact"
                            name="mainContact"
                            onChange={(e: any) => {
                              formik.handleChange(e);
                              formik.submitForm();
                            }}
                            type="text"
                            label="Main Contact"
                            inputClasses="form-control form-control-sm"
                            labelClasses="col-form-label text-capitalize fw-semibold"
                          />
                        </div>
                        <div className="col">
                          <label
                            htmlFor="defaultFormControlInput"
                            className="col-form-label  text-capitalize fw-semibold"
                          >
                            Status
                          </label>
                          <select
                            id="status"
                            onChange={(e: any) => {
                              formik.handleChange(e);
                              formik.submitForm();
                            }}
                            className="form-select form-select-sm"
                          >
                            <option value="">&nbsp;</option>
                            <option value="ACTIVE">Active</option>
                            <option value="INACTIVE">Inactive</option>
                          </select>
                        </div>
                        <div className="col d-flex align-items-end">
                          <div className="py-3 pb-0 d-flex flex-wrap justify-content-end d-grid gap-2 w-100">
                            <button
                              type="button"
                              onClick={() => generatePDF()}
                              className="btn btn-outline-secondary btn-sm"
                            >
                              <span className="tf-icons bx bx-printer"></span>
                              &nbsp; Print
                            </button>
                            {/* <button type="submit"
                                                            className="btn btn-primary btn-sm px-4">Search
                                                    </button> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>

        <div className="row">
          <div className="col">
            <div className="card shadow-sm mt-3">
              {/*{dataTableFormat?.length > 0 && (*/}
              {/*    <TblFunc data={dataTableFormat}></TblFunc>)}*/}
              <div className="d-flex justify-content-between align-items-center card-header mb-0 border-bottom p-3">
                <div>
                  <h6 className="m-0">Clients</h6>
                </div>
                <div>
                  <AccessControl type={AccessControlType.DISABLE}
                                 allowedPermissions={[UserPermissionsConstant.AddClients]}>
                  <button
                    type="button"
                    className="btn btn-primary btn-sm px-4"
                    onClick={() => navigate("/add-new-client/")}
                  >
                    Add New Client
                  </button>
                  </AccessControl>
                </div>
              </div>
              {dataTableFormat?.length > 0 && (
                <>
                  <div className="card-body p-3">
                    <div className="table-responsive text-nowrap">
                      <DataTable
                        title=""
                        columns={columns}
                        data={dataTableFormat}
                        defaultSortFieldId="companyName"
                        pagination={true}
                        paginationPerPage={10}
                        paginationRowsPerPageOptions={[5, 10, 20, 50, 100]}
                        paginationComponent={BootyPagination}
                        selectableRows={false}
                        customStyles={customStyles}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </>
    </AppLayout>
  );
}
const mapStateToProps = (state: any) => {
  const { clients, isLoading } = state?.clientManagement;
  return { clients, isLoading };
};
export default connect(mapStateToProps, null)(ClientList);
