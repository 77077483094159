import React, {useState} from "react";
import Input from "../../../components/elements/Input";
import {useFormik} from "formik";
import * as Yup from 'yup';
import {useDispatch} from "react-redux";
import {resetUserPassword} from "../../../actions/userAction";
import { userFogetPasswordType } from "../../../config/commonTypes";
import { commonMessages,resetMessages } from "../../../messages/messages";

function RestForm() {

    const dispatch = useDispatch();
    const [resetResponseMessage , setResetResponseMessage] = useState<string | null>(null);
    //reset form values
    const formik = useFormik({
        initialValues: {
            username: "",
        },
        onSubmit: (values: userFogetPasswordType) => {
            //send values to action
            setResetResponseMessage(null);
            dispatch(resetUserPassword(values, (response) => {
                setResetResponseMessage(resetMessages.We_have_emailed_your_password_reset_link);
                }, (error: any) => {
                setResetResponseMessage(resetMessages.We_have_emailed_your_password_reset_link)
                }
            ));
        },
        validationSchema:
            Yup.object().shape({
                username: Yup.string()
                    .required(commonMessages.Email_is_required)
            })
    });

    return (
        <>
            <form className="w-100" onSubmit={formik.handleSubmit}>
                <div className="modal-body pb-0">
                    <div className="row">
                        <div className="col mb-3">
                            <Input
                                name="username"
                                onChange={formik.handleChange}
                                type="text"
                                label="Username"
                                placeholder={"Enter your Username"}
                                inputClasses="form-control"
                                labelClasses="col-form-label  text-capitalize fw-semibold"
                            />
                        </div>
                        {formik.errors.username && formik.touched.username && (
                            <p style={{ color: "red" }}>{formik.errors.username}</p>
                        )}
                        {resetResponseMessage && (<p className="text-primary">
                            {resetResponseMessage}
                        </p>)}
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="submit"
                        className="btn btn-primary app-primary-color btn-primary-color d-grid w-100">RESET
                    </button>
                </div>
            </form>
        </>
    );
}
export default RestForm