import React, { useState } from "react";
import { IClientTypeCheckBox } from "./ClientListModal";

interface IAssigneeListType {
  assigneeList: IClientTypeCheckBox[];
  removeSelection: (selection: IClientTypeCheckBox) => void;
}
export function AllClientListModal(props: IAssigneeListType) {
  return (
    <div>
      <div className="modal-body p-3">
        {props.assigneeList &&
          props.assigneeList.map((o) => {
            return (
              o.isSelected && (
                <div className="row m-0 gap-3">
                  <div className="col d-flex align-items-center justify-content-between py-2 border">
                    <div className="d-flex justify-content-center align-items-center">
                      <label>{o.companyName}</label>
                    </div>
                    {/* <button
                      type="button"
                      className="btn btn-outline-danger btn-xs px-0"
                      onClick={() => props.removeSelection(o)}
                    >
                      <i className="bx bx-x"></i>
                    </button> */}
                  </div>
                </div>
              )
            );
          })}
      </div>
    </div>
  );
}
