import { overflow } from 'html2canvas/dist/types/css/property-descriptors/overflow';
import React, {useEffect, useState} from 'react';

function CustomModal({
                         title = 'Custom Modal Title', onClose,
                         isClose = true,
                         children,
                         size = "sm"
                     }: modelType) {
    const [isClosed, setIsClosed] = useState(isClose);
    const closeModal = () => {
        document.body.style.overflow = '';
        document.body.style.paddingRight = '';
        document.body.classList.remove('modal-open');
        setIsClosed(true);
        onClose && onClose();
    };
    useEffect(()=>{
        document.body.style.overflow = 'hidden';
        document.body.style.paddingRight = '17px';
        document.body.classList.add('modal-open');
        return()=>{
            document.body.style.overflow = 'auto';
        }
    },[]);
    useEffect(() => {
        setIsClosed(isClose)
    }, [isClose]);

    return (<>
            {!isClosed && (<div className="modal-backdrop fade show"></div>)}
        <div className={`modal fade ${isClosed ? "" : "show"}`}
             id="" aria-hidden="true"
             style={isClosed ? {} : {display: 'block'}}>
            <div className={`modal-dialog modal-dialog-centered modal-${size}`} role="document">
                <div className="modal-content">
                    <div className="modal-header app-secondry-color p-3 px-4">
                        <h5 className="modal-title text-white fw-bolder" id="modalCenterTitle">{title}</h5>
                        <button
                            onClick={closeModal}
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div style={{maxHeight: '430px',
                                overflowY: 'scroll',
                                overflowX:'hidden'}}    >
                        {children}
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

interface modelType {
    title?: string,
    onClose?: () => void,
    isClose?: boolean,
    children?: any,
    size?: string
}

export default CustomModal;