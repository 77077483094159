import React, { useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";

import Home from "./views/pages/home/Home";
import Login from "./views/pages/login/Login";
import InternalAuditDetails from "./views/pages/internalAudit/InternalAuditDetails";
import InternalAuditPlanner from "./views/pages/internalAudit/InternalAuditPlanner";
import { connect } from "react-redux";
import ClientList from "./views/pages/clientManagement/ClientList";
import AddNewClient from "./views/pages/clientManagement/AddNewClient";
import UserList from "./views/pages/userManagement/UserList";

import AddNewUser from "./views/pages/userManagement/AddNewUser";
import ResetPassword from "./views/pages/login/ResetPassword";
import ResetUserError from "./views/pages/login/ResetUserError";
import NotFound from "./views/pages/NotFound";
import {
  AuditPlannerTabs,
  UserPermissionsConstant,
  userType,
} from "./constants/constants";
import UserActivities from "./views/pages/userManagement/UserActivities";
import ClientUserList from "./views/pages/clientManagement/ClientUserList";
import ClientUserDetails from "./views/pages/clientManagement/ClientUserDetails";
import PrivacyStatement from "./views/pages/privacyPolicy/PrivacyStatement";
import TermsConditions from "./views/pages/privacyPolicy/TermsAndConditions";
import LandingPage from "./views/pages/home/LandingPage";
import AddNewInternalAuditPlanner from "./views/pages/internalAudit/AddNewInternalAuditPlanner";
import AuditCorrectiveLog from "./views/pages/home/CorrectiveAuditLogs";
import CreateCorrectiveAction from "./views/pages/correctiveAction/CreateCorrectiveAction";
import CorrectiveActionLog from "./views/pages/correctiveAction/CorrectiveActionLog";
import MyProfile from "./views/pages/userManagement/MyProfile";
import AccountSettings from "./views/pages/userManagement/AccountSettings";

interface routeType {
  path: string;
  accessRoles: string[];
  component: any;
  isAccessByPermissionRole?: boolean;
  allowedPermissions?: string[];
}

const rootRoutPath: routeType[] = [
  {
    path: "",
    accessRoles: [userType.superAdmin, userType.clientAdmin],
    component: <LandingPage />,
  },
  {
    path: "/internal-audit-log",
    accessRoles: [userType.clientAdmin],
    component: <Home />,
    isAccessByPermissionRole: true,
    allowedPermissions: [
      UserPermissionsConstant.ViewPrintAudit,
      UserPermissionsConstant.PerformAudit,
      UserPermissionsConstant.MimicAccess
    ],
  },
  {
    path: "/add-new-audit",
    accessRoles: [userType.clientAdmin],
    component: <Home />,
    isAccessByPermissionRole: true,
    allowedPermissions: [
      UserPermissionsConstant.ViewPrintAudit,
      UserPermissionsConstant.PerformAudit,
      UserPermissionsConstant.MimicAccess
    ],
  },
  {
    path: "internal-audit-details",
    accessRoles: [userType.clientAdmin],
    component: <InternalAuditDetails />,
  },
  {
    path: "internal-audit-planner",
    accessRoles: [userType.clientAdmin],
    component: <AddNewInternalAuditPlanner />,
    isAccessByPermissionRole: true,
    allowedPermissions: [
      UserPermissionsConstant.ChangeAuditPlan,
      UserPermissionsConstant.PrintAuditPlan,
      UserPermissionsConstant.MimicAccess
    ],
  },
  {
    path: "internal-audit-planner",
    accessRoles: [userType.superAdmin, userType.clientAdmin],
    isAccessByPermissionRole: true,
    allowedPermissions: [
      UserPermissionsConstant.ChangeAuditPlan,
      UserPermissionsConstant.PrintAuditPlan,
    ],
    component: (
      <InternalAuditPlanner selectedTab={AuditPlannerTabs.AUDIT_PLANNER} />
    ),
  },
  {
    path: "internal-audit-management",
    accessRoles: [userType.superAdmin],
    component: (
      <InternalAuditPlanner selectedTab={AuditPlannerTabs.AUDIT_MANAGEMENT} />
    ),
  },
  {
    path: "internal-audit-results/:templateId",
    accessRoles: [userType.clientAdmin],
    component: <AuditCorrectiveLog criteriaList={[]} clients={[]} />,
  },
  {
    path: "internal-audit-assign-templates",
    accessRoles: [userType.superAdmin],
    isAccessByPermissionRole: true,
    allowedPermissions: [UserPermissionsConstant.AssignTemplates],
    component: (
      <InternalAuditPlanner selectedTab={AuditPlannerTabs.AUDIT_TEMPLATE} />
    ),
  },
  {
    path: "client-list",
    accessRoles: [userType.superAdmin],
    component: <ClientList />,
    isAccessByPermissionRole: true,
    allowedPermissions: [
      UserPermissionsConstant.AddClients,
      UserPermissionsConstant.DeleteClients,
      UserPermissionsConstant.EditClients,
      UserPermissionsConstant.ViewClients
    ],
  },
  {
    path: "user-list",
    accessRoles: [userType.superAdmin],
    component: <UserList />,
    isAccessByPermissionRole: true,
    allowedPermissions: [
      UserPermissionsConstant.AddSuperAdminUser,
      UserPermissionsConstant.ViewSuperAdminUser,
      UserPermissionsConstant.EditSuperAdminUser,
      UserPermissionsConstant.DeleteSuperAdminUser,
    ],
  },
  {
    path: "/user-list",
    accessRoles: [userType.clientAdmin],
    component: <ClientUserList />,
    isAccessByPermissionRole: true,
    allowedPermissions: [
      UserPermissionsConstant.AddClientUser,
      UserPermissionsConstant.ViewClientUser,
      UserPermissionsConstant.EditClientUser,
      UserPermissionsConstant.DeleteClientUser,
      UserPermissionsConstant.MimicAccess
    ],
  },
  {
    path: "user-activities",
    accessRoles: [userType.superAdmin],
    component: <UserActivities />,
    isAccessByPermissionRole: true,
    allowedPermissions: [
      UserPermissionsConstant.AddClients,
      UserPermissionsConstant.EditClients,
      UserPermissionsConstant.ViewClients,
      UserPermissionsConstant.DeleteClients
    ],
  },
  {
    path: "add-new-client",
    accessRoles: [userType.superAdmin],
    component: <AddNewClient />,
    isAccessByPermissionRole: true,
    allowedPermissions: [UserPermissionsConstant.AddClients],
  },
  {
    path: "add-new-admin",
    accessRoles: [userType.superAdmin],
    component: <AddNewUser />,
    isAccessByPermissionRole: true,
    allowedPermissions: [UserPermissionsConstant.AddSuperAdminUser],
  },
  {
    path: "view-superadmin/:userid",
    accessRoles: [userType.superAdmin],
    component: <AddNewUser />,
    isAccessByPermissionRole: true,
    allowedPermissions: [UserPermissionsConstant.ViewSuperAdminUser],
  },
  {
    path: "edit-superadmin/:userid",
    accessRoles: [userType.superAdmin],
    component: <AddNewUser />,
    isAccessByPermissionRole: true,
    allowedPermissions: [UserPermissionsConstant.EditSuperAdminUser],
  },
  {
    path: "edit-client/:clientId",
    accessRoles: [userType.superAdmin],
    component: <AddNewClient />,
    isAccessByPermissionRole: true,
    allowedPermissions: [UserPermissionsConstant.EditClients],
  },
  {
    path: "view-client/:clientId",
    accessRoles: [userType.superAdmin],
    component: <AddNewClient />,
    isAccessByPermissionRole: true,
    allowedPermissions: [UserPermissionsConstant.ViewClients],
  },
  {
    path: "view-client-user-details/:userId",
    accessRoles: [userType.clientAdmin],
    component: <ClientUserDetails />,
    isAccessByPermissionRole: true,
    allowedPermissions: [UserPermissionsConstant.ViewClientUser, UserPermissionsConstant.MimicAccess],
  },
  {
    path: "edit-client-user-details/:userId",
    accessRoles: [userType.clientAdmin],
    component: <ClientUserDetails />,
    isAccessByPermissionRole: true,
    allowedPermissions: [UserPermissionsConstant.EditClientUser, UserPermissionsConstant.MimicAccess],
  },
  {
    path: "/add-new-user",
    accessRoles: [userType.clientAdmin],
    component: <ClientUserDetails />,
    isAccessByPermissionRole: true,
    allowedPermissions: [UserPermissionsConstant.AddClientUser, UserPermissionsConstant.MimicAccess],
  },
  {
    path: "/privacy-statement",
    accessRoles: [userType.superAdmin, userType.clientAdmin],
    component: <PrivacyStatement />,
  },
  {
    path: "/terms-conditions",
    accessRoles: [userType.superAdmin, userType.clientAdmin],
    component: <TermsConditions />,
  },
  {
    path: "/create-corrective-action",
    accessRoles: [userType.clientAdmin],
    component: <CorrectiveActionLog />,
    isAccessByPermissionRole: true,
    allowedPermissions: [
      UserPermissionsConstant.ViewPrintAudit,
      UserPermissionsConstant.PerformAudit,
      UserPermissionsConstant.MimicAccess
    ],
  },
  {
    path: "/corrective-action-log",
    accessRoles: [userType.clientAdmin],
    component: <CorrectiveActionLog />,
    isAccessByPermissionRole: true,
    allowedPermissions: [
      UserPermissionsConstant.ViewPrintAudit,
      UserPermissionsConstant.PerformAudit,
      UserPermissionsConstant.MimicAccess
    ],
  },
  {
    path: "/my-profile",
    accessRoles: [userType.clientAdmin, userType.superAdmin],
    component: <MyProfile />,
  },
  {
    path: "/account-setting",
    accessRoles: [userType.clientAdmin, userType.superAdmin],
    component: <AccountSettings />,
  },
];
const MainRoutes = ({ auth, role, userPermissions }: any) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [stateRootRoutPath, setStateRootRoutPath] =
    useState<routeType[]>(rootRoutPath);
  const browserPath = window.location.pathname.split("/");
  const navigate = useNavigate();

  useEffect(() => {
    redirect(!!auth?.token);
    setIsLoggedIn(!!auth?.token);
  }, [auth]);

  const redirect = (isLogIn: boolean) => {
    if (!isLogIn && browserPath[1] !== "resetPassword") {
      navigate("/login");
    }
    if ((browserPath[1] === "" || browserPath[1] === "login") && isLogIn) {
      // auth?.user?.isSuperAdmin ? navigate("/user-list") : navigate("/");
      const { isAgreeWithPrivacyPolicy, isAgreeWithTermsCondition } =
        auth?.user;
      if (
        (!isAgreeWithPrivacyPolicy && !isAgreeWithTermsCondition) ||
        (!isAgreeWithPrivacyPolicy && isAgreeWithTermsCondition)
      )
        navigate("/privacy-statement");
      if (isAgreeWithPrivacyPolicy && !isAgreeWithTermsCondition)
        navigate("/terms-conditions");

      if (isAgreeWithPrivacyPolicy && isAgreeWithTermsCondition) navigate("/");
    }
  };

  useEffect(() => {
    if (userPermissions) {
      const filteredRouts = filterByPermissions(rootRoutPath);
      setStateRootRoutPath(filteredRouts);
    }
  }, [userPermissions]);

  const filterByPermissions = (routs: routeType[]) => {
    const isCanAllowed = (allowedPermissions: string[]): boolean => {
      let canAllow: boolean = false;
      allowedPermissions.forEach((permission) => {
        if (userPermissions?.includes(permission)) {
          canAllow = true;
        }
      });
      return canAllow;
    };
    return routs.filter((rout) =>
      rout.isAccessByPermissionRole
        ? rout?.allowedPermissions && isCanAllowed(rout?.allowedPermissions)
        : rout
    );
  };

  return (
    <>
      <Routes>
        {isLoggedIn && (
          <>
            {stateRootRoutPath.map(
              (rout, i) =>
                rout.accessRoles.includes(role) && (
                  <Route key={i} path={rout.path} element={rout.component} />
                )
            )}
            <Route path="*" element={<NotFound />} />
          </>
        )}
        <Route path="/login" element={<Login />} />
        <Route path="/resetPassword/:userId/" element={<ResetPassword />} />
        <Route path="/resetPassword" element={<ResetUserError />} />
      </Routes>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    auth: state?.auth,
    role: state?.auth?.user?.isSuperAdmin
      ? userType.superAdmin
      : userType.clientAdmin,
    userPermissions: state?.auth?.roles,
  };
};
export default connect(mapStateToProps, null)(MainRoutes);
