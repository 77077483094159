import React, { useState, useEffect } from 'react';
import {useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { changeresetUserPassword, getResetPass } from "../../../actions/userAction";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { useQuery } from '../../../components/hooks/useQuery';
import Input from '../../../components/elements/Input';
import logoImage from '../../../assets/img/logo/logo.png';
import { commonMessages, resetMessages } from "../../../messages/messages";

export interface ResetPasswordFormType {
    confirmPassword: string;
    password: string;
    userName: string | undefined;
}

function RestPassword() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { userId } = useParams();

    const query = useQuery();

    //when check parameter page load
    useEffect(() => {
            dispatch(
                getResetPass(userId, query.get("verifyToken"), (response) => {
                    toast.success(response?.message ? response.message : resetMessages.Successfully_ResetPassword_Link);
                }, (error: any) => {
                    navigate('/resetPassword');
                    toast.error(error?.message ? error.message : resetMessages.ResetPassword_Link);
                }));
        },[userId, query]);

    //decoding encode username and show 
    const decodedUsername = atob(userId || '');

    const initialValues: ResetPasswordFormType = {
        password: "",
        confirmPassword: "",
        userName: decodedUsername
    };

    //pass form data
    const formik = useFormik({
        initialValues: initialValues,
        onSubmit: ((values: any) => {
            dispatch(
                changeresetUserPassword(values, (response) => {
                    toast.success(response?.message ? response.message : resetMessages.Successfully_ResetPassword);
                    setTimeout(() => navigate('/login'), 4000);
                    formik?.resetForm();
                }, (error: any) => {
                    toast.error(error?.message ? error.message : 'ResetPassword Error!');
                }));
        }),
        validationSchema: Yup.object().shape({
            password: Yup.string().required("New Password is required").min(8, 'Password is too short - should be 8 chars minimum.')
                .matches(
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                    "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
                ),
            confirmPassword: Yup.string().required("Confirm Password is required").when("password", {
                is: (val: string) => (val && val.length > 0 ? true : false),
                then: Yup.string().oneOf(
                    [Yup.ref("password")],
                    "Both passwords need to be the same!"
                )
            })
        })
    });

    return (
        <>
            <div className="container-xxl">
                <div className="authentication-wrapper authentication-basic container-p-y">
                    <div className="authentication-inner">
                        <div className="card">
                            <div className="card-body p-0">
                                <div className="app-brand rounded-top mb-0 app-secondry-color justify-content-center">
                                    <div className="d-flex">
                                        <div className="text-center h-100">
                                            <img className="m-3" alt="logo" src={logoImage} width="80"
                                                height="80" />
                                        </div>
                                    </div>
                                    <div className="d-flex pe-3">
                                        <div className="h-100 d-flex justify-content-left align-items-center">
                                            <div>
                                                <h1 className="h1 m-0 text-white fw-bolder" style={{ fontSize: "36px" }} >Create New Password</h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <p className="text-black fw-light mb-0 text-center py-4" >Your new password must be different from previous used password.</p> */}
                            <form onSubmit={formik.handleSubmit} id="formAuthentication" className="w-100 mb-3 px-5 pt-5 pb-2" method="POST">
                                <div className="mb-3 form-password-toggle">
                                    <div className="input-group input-group-merge">
                                        <Input
                                            name="Username"
                                            value={decodedUsername}
                                            hideLabel={true}
                                            iconClasses="bx bx-user"
                                            type="text"
                                            placeholder={"Password"}
                                            inputClasses="form-control"
                                            iconSpanClasses="input-group-text bg-light"
                                            disabled="true"
                                        />
                                    </div>
                                    {formik.errors.password && formik.touched.password && (
                                        <p style={{ color: "red" }}>{formik.errors.password}</p>
                                    )}
                                </div>
                                <div className="mb-3 form-password-toggle">
                                    <div className="input-group input-group-merge">
                                        <Input
                                            name="password"
                                            value={formik.values.password}
                                            onChange={formik.handleChange}
                                            hideLabel={true}
                                            iconClasses="bx bx-lock"
                                            type="password"
                                            placeholder={"Password"}
                                            inputClasses="form-control"
                                            iconSpanClasses="input-group-text"

                                        />
                                    </div>
                                    {formik.errors.password && formik.touched.password && (
                                        <p style={{ color: "red" }}>{formik.errors.password}</p>
                                    )}
                                </div>
                                <div className="mb-3 form-password-toggle">
                                    <div className="input-group input-group-merge">
                                        <Input
                                            name="confirmPassword"
                                            value={formik.values.confirmPassword}
                                            onChange={formik.handleChange}
                                            hideLabel={true}
                                            iconSpanClasses="input-group-text"
                                            iconClasses="bx bx-lock"
                                            type="password"
                                            placeholder={"Confirm Password"}
                                            inputClasses="form-control"
                                        />
                                    </div>
                                    {formik.errors.confirmPassword && formik.touched.confirmPassword && (
                                        <p style={{ color: "red" }}>{formik.errors.confirmPassword}</p>
                                    )}
                                </div>
                                <div className="mb-4">
                                    <button className="btn btn-primary app-primary-color btn-primary-color d-grid w-100"
                                        type="submit">Reset Password
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>


        </>
    );
}


export default RestPassword;