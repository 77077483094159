import { apiHost } from "./global";
import axios from "axios";
import { store } from "../redux/store";
import {
  LOGOUT_SUCCESS,
  REFRESH_TOKEN_REQUEST,
  REFRESH_TOKEN_SUCCESS,
} from "../constants";
import { RefreshTokenAsync } from "../actions/userAction";

const instance = axios.create({
  baseURL: apiHost,
});

let unAutherizeDOccurred = false;
instance.interceptors.request.use(
  async (config) => {
    // eslint-disable-next-line no-param-reassign

    const token = store.getState()?.auth?.token;
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  (error) => Promise.reject(error)
);
instance.interceptors.response.use(
  (response) => {
    return response.data;
  },
  async function (error) {
      const originalRequest = error.config;
      if (error.response.status === 409) {
          unAutherizeDOccurred = true;
          originalRequest._retry = false;
          store.dispatch({
              type: LOGOUT_SUCCESS,
          });
          return Promise.reject(error);
      }
      else {
          if (
              error.response.status === 401 &&
              !originalRequest._retry &&
              !unAutherizeDOccurred
          ) {
              unAutherizeDOccurred = true;
              originalRequest._retry = true;
              try {
                  store.dispatch({ type: REFRESH_TOKEN_REQUEST });
                  const token = store.getState()?.auth?.token;
                  const refreshToken = store.getState()?.auth?.refreshToken;

                  const data = await RefreshTokenAsync({
                      accessToken: token,
                      refreshToken: refreshToken,
                  });
                  if (data) {
                      instance.defaults.headers[
                          "Authorization"
                      ] = `Bearer ${data.accessToken}`;
                      store.dispatch({
                          type: REFRESH_TOKEN_SUCCESS,
                          payload: data,
                      });
                      setTimeout(() => {
                          unAutherizeDOccurred = false;
                      }, 1000);
                      return instance(originalRequest);
                  } else {
                      unAutherizeDOccurred = false;
                      store.dispatch({
                          type: LOGOUT_SUCCESS,
                      });
                      return Promise.reject(error);
                  }
              } catch (error) {
                  unAutherizeDOccurred = false;
                  store.dispatch({
                      type: LOGOUT_SUCCESS,
                  });
                  console.log("refresh token error", error);
                  return Promise.reject(error);
              }
          } else {
              throw error;
          }
      }
  }
);
export default instance;
