import React, { useEffect, useState } from "react";
import AppLayout from "../../Layout/AppLayout";
import { connect } from "react-redux";
import DataTable, { TableStyles } from "react-data-table-component";
import { BootyPagination } from "../../../components/pagination/BootyPagination";
import { fetchLandingPageData } from "../../../actions/userAction";
import { toLocalformatDate } from "../../../config/commonMethods";
import moment from "moment";

interface IclientData {
  companyName: string;
  mainContactName: string;
  mainContactEmail: string;
  lastActivityDate: string;
}

interface IlandingPageDataType {
  totalCompletedInternalAudits?: number;
  totalPendingCorrectiveActions?: number;
  totalUsers?: number;

  totalAuditTemplates?: number;
  totalClients?: number;
  totalClientUsers?: number;
  usersWithLastActivity?: IclientData[] | [];
}

const initialValues: IlandingPageDataType = {
  totalCompletedInternalAudits: 0,
  totalPendingCorrectiveActions: 0,
  totalUsers: 0,

  totalAuditTemplates: 0,
  totalClients: 0,
  totalClientUsers: 0,
  usersWithLastActivity: [],
};
const LandingPage = ({ user }: any) => {
  const [dashboardData, setDashboardData] =
    useState<IlandingPageDataType>(initialValues);
  const [formattedData, setFormattedData] = useState<any>();

  useEffect(() => {
    user &&
      fetchLandingPageData(
        user?.clientId | 0,
        (data) => {
          setDashboardData(data.data);
        },
        () => {
          console.log("### COULD NOT FETCH DATA");
        }
      );
  }, [user]);

  const setUserData = (clientData: IclientData) => {
    return (
      <div>
        <span>{clientData?.mainContactName}</span>
        <span>
          <i className="tf-icon bx bx-envelope px-2"></i>
          {clientData?.mainContactEmail}
        </span>
      </div>
    );
  };

  function formatData(usersWithLastActivity: IclientData[] | undefined) {
    return usersWithLastActivity?.map((userWithLastActivity) => {
      return {
        facility: userWithLastActivity?.companyName,
        user: user && setUserData(userWithLastActivity),
        lastActivityDate: moment(userWithLastActivity?.lastActivityDate).format(
          "DD-MM-YYYY"
        ),
      };
    });
  }

  useEffect(() => {
    if (dashboardData && dashboardData?.usersWithLastActivity) {
      setFormattedData(formatData(dashboardData?.usersWithLastActivity));
    }
  }, [dashboardData]);

  const columns: any = [
    {
      name: "Client",
      selector: (row: any) => row.facility,
      sortable: true,
      width: "330px",
    },
    {
      name: "User",
      selector: (row: any) => row.user,
      sortable: false,
    },
    {
      name: "Last Activity Date",
      //selector: (row: any) => row.lastActivityDate,
      selector: (row: any) => toLocalformatDate(row.lastActivityDate),
      sortable: true,
      width: "160px",
    },
  ];
  const customStyles: TableStyles = {
    headRow: {
      style: { backgroundColor: "#f2f2f2", border: "1px solid #d9dee3" },
    },
    rows: {
      style: { border: "1px solid #d9dee3" },
    },
  };
  return (
    <AppLayout>
      {user.isSuperAdmin ? (
        <>
          <div className="flex-grow-1 py-3 pt-1">
            <div className="row">
              <div className="col">
                <div className="card shadow-sm mt-0">
                  <div className="card-body p-3">
                    <div className="d-flex justify-content-center align-items-center card-header p-0 pb-3">
                      <div>
                        <div className="display-3 d-flex align-items-center primary-color primary-color">
                          <span>
                            <i className="bx bx-check-square display-3"></i>
                          </span>
                          <span className="px-1">
                            {dashboardData?.totalAuditTemplates}
                          </span>
                        </div>
                        <h6 className="m-0 text-center">Audit Templates</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card shadow-sm mt-0">
                  <div className="card-body p-3">
                    <div className="d-flex justify-content-center align-items-center card-header p-0 pb-3">
                      <div>
                        <div className="display-3 d-flex align-items-center primary-color primary-color">
                          <span>
                            <i className="bx bx-user display-3"></i>
                          </span>
                          <span className="px-1">
                            {dashboardData?.totalClients}
                          </span>
                        </div>
                        <h6 className="m-0 text-center">Clients</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card shadow-sm mt-0">
                  <div className="card-body p-3">
                    <div className="d-flex justify-content-center align-items-center card-header p-0 pb-3">
                      <div>
                        <div className="display-3 d-flex align-items-center primary-color primary-color">
                          <span>
                            <i className="bx bx-user display-3"></i>
                          </span>
                          <span className="px-1">
                            {dashboardData?.totalClientUsers}
                          </span>
                        </div>
                        <h6 className="m-0 text-center">Client Users</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="card shadow-sm mt-3">
                  <div className="d-flex justify-content-between align-items-center card-header mb-0">
                    <div>
                      <h6 className="m-0">
                        Client Activities within Last Two Weeks
                      </h6>
                    </div>
                  </div>
                  <div className="card-body p-3 pt-0">
                    <div className="table-responsive text-nowrap">
                      <DataTable
                        title=""
                        columns={columns}
                        data={formattedData}
                        defaultSortFieldId="name"
                        pagination={true}
                        paginationPerPage={5}
                        paginationRowsPerPageOptions={[5, 10, 20, 50, 100]}
                        paginationComponent={BootyPagination}
                        selectableRows={false}
                        customStyles={customStyles}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="flex-grow-1 py-2">
            <div className="row">
              <div className="col">
                <div className="card shadow-sm mt-0">
                  <div className="card-body p-3">
                    <div className="d-flex justify-content-center align-items-center card-header p-0">
                      <div>
                        <div className="display-3 d-flex align-items-center justify-content-center primary-color primary-color">
                          <span>
                            <i className="bx bx-book display-3"></i>
                          </span>
                          <span className="px-1">
                            {dashboardData?.totalCompletedInternalAudits}
                          </span>
                        </div>
                        <h6 className="m-0 text-center">
                          Completed Internal Audits
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card shadow-sm mt-0">
                  <div className="card-body p-3">
                    <div className="d-flex justify-content-center align-items-center  card-header p-0">
                      <div>
                        <div className="display-3 d-flex align-items-center justify-content-center primary-color primary-color">
                          <span>
                            <i className="bx bx-check-square display-3"></i>
                          </span>
                          <span className="px-1">
                            {dashboardData?.totalPendingCorrectiveActions}
                          </span>
                        </div>
                        <h6 className="m-0 text-center">
                          Pending Corrective Actions
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card shadow-sm mt-0">
                  <div className="card-body p-3">
                    <div className="d-flex justify-content-center align-items-center card-header p-0">
                      <div>
                        <div className="display-3 d-flex align-items-center justify-content-center primary-color primary-color">
                          <span>
                            <i className="bx bx-user display-3"></i>
                          </span>
                          <span className="px-1">
                            {dashboardData?.totalUsers}
                          </span>
                        </div>
                        <h6 className="m-0 text-center">No of Users</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </AppLayout>
  );
};
const mapStateToProps = (state: any) => {
  const user = state?.auth?.user;
  return { user };
};
export default connect(mapStateToProps, null)(LandingPage);
