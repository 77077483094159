import React, {useEffect, useState} from 'react';
import AppLayout from '../../Layout/AppLayout';
import {connect, useDispatch} from "react-redux";
import {logOut, updateUserPolicy} from "../../../actions/userAction";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import {apiResponseType} from "../../../config/commonTypes";
import {PRIVACY_POLICY_SUCCESS} from "../../../constants";

function PrivacyStatement({user}:any){
    const [isAgreeWithPrivacyPolicy ,setIsAgreeWithPrivacyPolicy] = useState<boolean>(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const logout = () => {
        dispatch(logOut())
    }
    
    useEffect(() => {
        user && setIsAgreeWithPrivacyPolicy(user.isAgreeWithPrivacyPolicy);
    },[user]);

    const confirmPrivacyStatement =(isSelect:boolean)=>{
        if(!isSelect) return;
        updateUserPolicy(user?.userId,isSelect,'P',(response)=>{
            dispatch({
                type:PRIVACY_POLICY_SUCCESS
            });
            toast.success(response?.message ? response.message : 'Successfully accepted!');
            navigate(!user?.isAgreeWithTermsCondition ? '/terms-conditions' : '/');
        },(error:apiResponseType)=>{
            toast.error(error?.message ? error?.message : 'Failed!');
        });
    }
    return (
        <AppLayout>
            <>
            <div className="row">
                <div className="col-md">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb breadcrumb-style1 d-flex align-items-center">
                            <li className="breadcrumb-item d-flex align-items-center">
                                    <a href="javascript:void(0);">Home</a>
                            </li>
                            <li className="breadcrumb-item d-flex align-items-center active">Privacy Statement</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div className="card shadow-sm">
                        <div className="card-body p-3">
                            <div className="d-flex justify-content-left align-items-center card-header p-0">
                                <div>
                                    <h5 className="m-0 p-2">Privacy Statement</h5>
                                    <h6 className="m-0 p-2 app-color-dark">Healthcare Compliance Solutions Ltd (HCSL) trading at GIA+:</h6>
                                    <ul>
                                        <li className='p-2'>
                                            <p className="mb-0">
                                                Shall treat information held or about you (including any information regarding your organisation, methods, business or finances and your administration and operation, any personal or client information, budgetary information and information relating to policy decisions which have been or are to be made by you) that may be acquired in the course of any work conducted for you as confidential to you.
                                            </p>
                                        </li>
                                        <li className='p-2'>
                                            <p className="mb-0">
                                                Shall use such information only for the purposes of any work which we may do for you.
                                            </p>
                                        </li>
                                        <li className='p-2'>
                                            <p className="mb-0">
                                                Shall not disclose any such information to any person or organisation except you and your employees, as directed in writing by you or as required by law.
                                            </p>
                                        </li>
                                        <li className='p-2'>
                                            <p className="mb-0">
                                                Shall take all reasonable measures to ensure the security of Internet based information held about you, your organisation, residents and staff. Information obtained and stored is held on a secure server which includes encrypted content for added security. GIA+ does not accept liability for security breaches occurring as the result of unlawful access to this information through cyber-crime, related 'hacking' or other unauthorized access or uncontrollable event.    
                                            </p>
                                        </li>
                                        <li className='p-2'>
                                            <p className="mb-0">
                                                GIA+ reserve the right to utilise non-identifiable data held on the GIA+ System and related website for the purposes of industry benchmarking and/or research.
                                            </p>
                                        </li>
                                        <li className='p-2'>
                                            <p className="mb-0">
                                                GIA+, if required by law to disclose any information of any nature whatsoever which has been obtained by the client, shall immediately notify you by telephone and/or in writing of the requirements that have been imposed. GIA+ shall, where able, provide you with the opportunity to exercise such legal remedies as are available to you to prevent the disclosure of information, to the extent that GIA+ is able to do so, having regard to the time limits of any requirement to disclose any information under any law.
                                            </p>
                                        </li>
                                        <li className='p-2'>
                                            <p className="mb-0">
                                                The above notwithstanding; GIA+ may share information gained during the conducting of services to you with other coaching consultants employed or contracted by GIA+ for the purposes of receiving professional supervision or for consultation purposes in respect of you.
                                            </p>
                                        </li>
                                        <li className='p-2'>
                                            <p className="mb-0">
                                                May change the host of this software / system / website from time to time in accordance with our business requirements. This may mean that the information collected is hosted overseas. GIA+ does its best to use reputable companies that have appropriate information protection to host our software applications and website.    
                                            </p>
                                        </li>
                                        <li className='p-2'>
                                            <p className="mb-0">
                                                Has no control over the information accessed through links from our site. You use links at your own risk. If you post your personal information in a public forum, we have no control over how it is used and no responsibility to protect it for you.
                                            </p>
                                        </li>
                                    </ul>
                                    <h6 className="m-0 p-2 app-color-dark">The Client (You):</h6>
                                    <ul>
                                        <li className='p-2'>
                                            <p className="mb-0">
                                                Shall treat information, practices and processes about HCSL and GIA+ (including any information regarding our organisation, methods, business or finances and our administration and operation, any personal or client information, budgetary information and information relating to policy decisions which have been or are to be made by HCSL and GIA+) that may be acquired in the course of any work conducted for you as confidential to HCSL.
                                            </p>
                                        </li>
                                        <li className='p-2'>
                                            <p className="mb-0">
                                            	Shall not disclose any such information to any person or organisation except you and your employees, as directed in writing by HCSL and/or GIA+ or as required by law.
                                            </p>
                                        </li>
                                        <li className='p-2'>
                                            <p className="mb-0">
                                            	Shall not duplicate (without prior written permission from HCSL and GIA+), copy, amend, modify, on-sell or provide to another person outside of your organisation any HCSL and GIA+ provided documentation unless required by law to do so.    
                                            </p>
                                        </li>
                                        <li className='p-2'>
                                            <p className="mb-0">
                                            	If required by law to disclose any information of any nature whatsoever which has been obtained as a consequence of GIA+ undertaking the project described in any proposal or service agreement, shall immediately notify HCSL Managing Director in writing of the requirements that have been imposed. You shall provide HCSL and GIA+ with the opportunity to exercise such legal remedies as are available to GIA+ to prevent the disclosure of information, to the extent that you are able to do so, having regard to the time limits of any requirement to disclose any information under any law.
                                            </p>
                                        </li>
                                        <li className='p-2'>
                                            <p className="mb-0">
                                                Shall ensure the security of user access codes and passwords is maintained.
                                            </p>
                                        </li>
                                        <li className='p-2'>
                                            <p className="mb-0">
                                            	Shall deactivate the user and access codes for staff no longer authorised access, to ensure the integrity of data entered by you or on your behalf.
                                            </p>
                                        </li>
                                    </ul>
                                    <p className="mb-0">
                                    Any feedback or concerns regarding the way GIA+ manage personal information should be directed to 
                                    <a href="mailto:admin@giachecks.com"> admin@giachecks.com </a>
                                      GIA+ will take action to fix any problems, within reason. This policy will be updated from time to time to meet our legal obligations.    
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="card-footer p-3 border-1 border-top">
                            <div className="row mx-0">
                                <div className="col p-0 d-flex align-items-end justify-content-end"
                                style={{opacity: user?.isAgreeWithPrivacyPolicy ? 0 : 1}}>
                                    <div className="d-flex align-items-center justify-content-center">
                                        <input className="form-check-input checkbox-lg m-1"
                                               checked={isAgreeWithPrivacyPolicy}
                                               onClick={(e: any) => setIsAgreeWithPrivacyPolicy(e.target.checked)}
                                               type="checkbox"/>
                                        <label className="secondry-color fw-semibold px-3">I have read and accept the privacy statement</label>
                                    </div>
                                    <div className="p-0 d-flex flex-wrap d-grid gap-2">
                                        <button type="button" 
                                        onClick={() => logout()}
                                        className="btn btn-outline-secondary btn-sm">
                                             Cancel
                                        </button>
                                        <button type="button"
                                                disabled={!isAgreeWithPrivacyPolicy}
                                                onClick={() => confirmPrivacyStatement(isAgreeWithPrivacyPolicy)}
                                                className="btn btn-primary btn-sm">
                                                {user?.isAgreeWithTermsCondition ? 'Agree' : 'Agree with next'}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>        
            </>
        </AppLayout>
    );

}
const mapStateToProps = (state: any) => {
    return {
        user: state?.auth?.user,
    }
}
export default connect(mapStateToProps, null)(PrivacyStatement);