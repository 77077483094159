import React, { useState } from "react";
import { clientType } from "../../redux/modules/clientManagementReducer";

export interface IClientTypeCheckBox extends clientType {
  isSelected: boolean;
}
interface IClientListModel {
  clients: IClientTypeCheckBox[];
  onClientSelected: (item: IClientTypeCheckBox) => void;
  selectAssignees: () => void;
  selectAll: (selected: boolean) => void;
  isSelectAll: boolean;
  searchAssignees: (searchQuery: string) => void;
  searhQuery: string;
}

export function ClientListModal(props: IClientListModel) {
  return (
    <div>
      <div className="modal-body px-3 pt-3 pb-0">
        <table className="table table-hover table-bordered table-sm">
          <tbody>
            <tr>
              <td>
                <input
                  type="text"
                  className="form-control form-control-sm"
                  id="defaultFormControlInput"
                  placeholder=""
                  aria-describedby="defaultFormControlHelp"
                  onChange={(e) => props.searchAssignees(e.target.value)}
                  value={props.searhQuery}
                />
              </td>
            </tr>
            {!props.searhQuery && (
              <tr>
                <td>
                  <div className="form-check d-flex align-items-center gap-2">
                    <input
                      className="form-check-input checkbox-lg"
                      type="checkbox"
                      value=""
                      id=""
                      onChange={(e) => props.selectAll(e.target.checked)}
                      checked={props.isSelectAll}
                    />
                    <label>Select All</label>
                  </div>
                </td>
              </tr>
            )}
            {props?.clients &&
              props?.clients.map((item, i) => {
                return (
                  item.isActive && (
                    <tr key={i}>
                      <td>
                        {" "}
                        <div className="form-check d-flex  align-items-center gap-2">
                          <input
                            className="form-check-input checkbox-lg"
                            type="checkbox"
                            value=""
                            id=""
                            onChange={(e) =>
                              props.onClientSelected({
                                ...item,
                                isSelected: e.target.checked,
                              })
                            }
                            checked={item.isSelected}
                          />
                          <label>{item.companyName}</label>
                        </div>
                      </td>
                    </tr>
                  )
                );
              })}
          </tbody>
        </table>
      </div>
      <div className="modal-footer px-3 pb-3">
        <div className="row ">
          <div className="col p-0 d-flex align-items-end justify-content-end">
            <div className="p-0 d-flex flex-wrap d-grid gap-2">
              <button
                type="button"
                className="btn btn-primary btn-sm"
                onClick={() => props.selectAssignees()}
              >
                Assign
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
