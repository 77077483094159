// login actions
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";

export const REFRESH_TOKEN_REQUEST = "REFRESH_TOKEN_REQUEST";
export const REFRESH_TOKEN_SUCCESS = "REFRESH_TOKEN_SUCCESS";
export const REFRESH_TOKEN_FAILED = "REFRESH_TOKEN_FAILED";

//Auth actions types
export const AUTH_FETCH_USER_ROLES_REQUEST = "AUTH_FETCH_USER_ROLES_REQUEST";
export const AUTH_FETCH_USER_ROLES_SUCCESS = "AUTH_FETCH_USER_ROLES_SUCCESS";
export const AUTH_FETCH_USER_ROLES_FAILURE = "AUTH_FETCH_USER_ROLES_FAILURE";

export const AUTH_FETCH_CLIENT_DETAILS_REQUEST =
  "AUTH_FETCH_CLIENT_DETAILS_REQUEST";
export const AUTH_FETCH_CLIENT_DETAILS_SUCCESS =
  "AUTH_FETCH_CLIENT_DETAILS_SUCCESS";
export const AUTH_FETCH_CLIENT_DETAILS_FAILURE =
  "AUTH_FETCH_CLIENT_DETAILS_FAILURE";

export const SET_TEMPLATE_HEADER = "SET_TEMPLATE_HEADER";

//User management
export const FETCH_USERS_REQUEST = "FETCH_USERS_REQUEST";
export const FETCH_USERS_SUCCESS = "FETCH_USERS_SUCCESS";
export const FETCH_USERS_FAILURE = "FETCH_USERS_FAILURE";

export const FETCH_SELECTED_USER_ROLES_REQUEST =
  "FETCH_SELECTED_USER_ROLES_REQUEST";
export const FETCH_SELECTED_USER_ROLES_SUCCESS =
  "FETCH_SELECTED_USER_ROLES_SUCCESS";
export const FETCH_SELECTED_USER_ROLES_FAILURE =
  "FETCH_SELECTED_USER_ROLES_FAILURE";

export const CREATE_USER_REQUEST = "CREATE_USER_REQUEST";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const CREATE_USER_FAILURE = "CREATE_USER_FAILURE";

export const UPDATE_SUPER_ADMIN_USER_REQUEST =
  "UPDATE_SUPER_ADMIN_USER_REQUEST";
export const UPDATE_SUPER_ADMIN_USER_SUCCESS =
  "UPDATE_SUPER_ADMIN_USER_SUCCESS";
export const UPDATE_SUPER_ADMIN_USER_FAILURE =
  "UPDATE_SUPER_ADMIN_USER_FAILURE";

export const RESET_USER_DATA_UM = "RESET_USER_DATA_UM";

export const PRIVACY_POLICY_REQUEST = "PRIVACY_POLICY_REQUEST";
export const PRIVACY_POLICY_SUCCESS = "PRIVACY_POLICY_SUCCESS";
export const PRIVACY_POLICY_FAILURE = "PRIVACY_POLICY_FAILURE";

export const TERMS_AND_CONDITION_POLICY_REQUEST =
  "TERMS_AND_CONDITION_POLICY_REQUEST";
export const TERMS_AND_CONDITION_POLICY_SUCCESS =
  "TERMS_AND_CONDITION_POLICY_SUCCESS";
export const TERMS_AND_CONDITION_POLICY_FAILURE =
  "TERMS_AND_CONDITION_POLICY_FAILURE";

//forget and reset password
export const CREATE_PASSWORD_RESET_REQUEST = "CREATE_PASSWORD_RESET_REQUEST";
export const CREATE_PASSWORD_RESET_SUCCESS = "CREATE_PASSWORD_RESET_SUCCESS";
export const CREATE_PASSWORD_RESET_FAILURE = "CREATE_PASSWORD_RESET_FAILURE";

export const FETCH_PASSWORD_RESET_REQUEST = "FETCH_PASSWORD_RESET_REQUEST";
export const FETCH_PASSWORD_RESET_SUCCESS = "FETCH_PASSWORD_RESET_SUCCESS";
export const FETCH_PASSWORD_RESET_FAILURE = "FETCH_PASSWORD_RESET_FAILURE";

export const UPDATE_PASSWORD_RESET_REQUEST = "CREATE_PASSWORD_RESET_REQUEST";
export const UPDATE_PASSWORD_RESET_SUCCESS = "CREATE_PASSWORD_RESET_SUCCESS";
export const UPDATE_PASSWORD_RESET_FAILURE = "CREATE_PASSWORD_RESET_FAILURE";

export const FETCH_USERS_ACTIVITY_REQUEST = "FETCH_USERS_ACTIVITY_REQUEST";
export const FETCH_USERS_ACTIVITY_SUCCESS = "FETCH_USERS_ACTIVITY_SUCCESS";
export const FETCH_USERS_ACTIVITY_FAILURE = "FETCH_USERS_ACTIVITY_FAILURE";

//Client management
export const FETCH_CLIENTS_REQUEST = "FETCH_CLIENTS_REQUEST";
export const FETCH_CLIENTS_SUCCESS = "FETCH_CLIENTS_SUCCESS";
export const FETCH_CLIENTS_FAILURE = "FETCH_CLIENTS_FAILURE";

export const FETCH_CLIENT_REQUEST = "FETCH_CLIENT_REQUEST";
export const FETCH_CLIENT_SUCCESS = "FETCH_CLIENT_SUCCESS";
export const FETCH_CLIENT_FAILURE = "FETCH_CLIENT_FAILURE";

export const CREATE_USER_BY_CLIENT_REQUEST = "CREATE_USER_BY_CLIENT_REQUEST";
export const CREATE_USER_BY_CLIENT_SUCCESS = "CREATE_USER_BY_CLIENT_SUCCESS";
export const CREATE_USER_BY_CLIENT_FAILURE = "CREATE_USER_BY_CLIENT_FAILURE";

export const GET_AUDIT_ASSIGNEE_TEMPLATES_REQUEST =
  "GET_AUDIT_ASSIGNEE_TEMPLATES_REQUEST";
export const GET_AUDIT_ASSIGNEE_TEMPLATES_SUCCESS =
  "GET_AUDIT_ASSIGNEE_TEMPLATES_SUCCESS";
export const GET_AUDIT_ASSIGNEE_TEMPLATES_FAILURE =
  "GET_AUDIT_ASSIGNEE_TEMPLATES_FAILURE";

export const ASSIGNEE_TEMPLATE_PLANNING_SCHEDULE_UPDATED =
  "ASSIGNEE_TEMPLATE_PLANNING_SCHEDULE_UPDATED";

export const RESET_CLIENT_DATA = "RESET_CLIENT_DATA";

export const FETCH_USER_BY_CLIENT_ID_REQUEST =
  "FETCH_USER_BY_CLIENT_ID_REQUEST";
export const FETCH_USER_BY_CLIENT_ID_SUCCESS =
  "FETCH_USER_BY_CLIENT_ID_SUCCESS";
export const FETCH_USER_BY_CLIENT_ID_FAILURE =
  "FETCH_USER_BY_CLIENT_ID_FAILURE";

export const CREATE_CLIENTS_REQUEST = "CREATE_CLIENTS_REQUEST";
export const CREATE_CLIENTS_SUCCESS = "CREATE_CLIENTS_SUCCESS";
export const CREATE_CLIENTS_FAILURE = "CREATE_CLIENTS_FAILURE";

export const UPDATE_CLIENTS_REQUEST = "UPDATE_CLIENTS_REQUEST";
export const UPDATE_CLIENTS_SUCCESS = "UPDATE_CLIENTS_SUCCESS";
export const UPDATE_CLIENTS_FAILURE = "UPDATE_CLIENTS_FAILURE";

export const DELETE_CLIENTS_REQUEST = "DELETE_CLIENTS_REQUEST";
export const DELETE_CLIENTS_SUCCESS = "DELETE_CLIENTS_SUCCESS";
export const DELETE_CLIENTS_FAILURE = "DELETE_CLIENTS_FAILURE";

//Audit management
export const GET_TEMPLATE_BY_ID_REQUEST = "GET_TEMPLATE_BY_ID";
export const GET_TEMPLATE_BY_ID_SUCCESS = "GET_TEMPLATE_BY_ID_SUCCESS";
export const GET_TEMPLATE_BY_ID_FAILURE = "GET_TEMPLATE_BY_ID_FAILURE";

export const ADD_OR_DELETE_CLIENT_REQUEST = "ADD_OR_DELETE_CLIENT_REQUEST";
export const ADD_OR_DELETE_CLIENT_SUCCESS = "ADD_OR_DELETE_CLIENT_SUCCESS";
export const ADD_OR_DELETE_CLIENT_FAILURE = "ADD_OR_DELETE_CLIENT_FAILURE";

export const EDIT_LOCAL_TEMPLATE_ASSIGNEE_LIST_REQUEST =
  "EDIT_LOCAL_TEMPLATE_ASSIGNEE_LIST";
export const EDIT_LOCAL_TEMPLATE_ASSIGNEE_LIST_SUCCESS =
  "EDIT_LOCAL_TEMPLATE_ASSIGNEE_LIST_SUCCESS";
export const EDIT_LOCAL_TEMPLATE_ASSIGNEE_LIST_FAILURE =
  "EDIT_LOCAL_TEMPLATE_ASSIGNEE_LIST_FAILURE";

export const GET_CRITERIA_LIST_BY_ID_REQUEST =
  "GET_CRITERIA_LIST_BY_ID_REQUEST";
export const GET_CRITERIA_LIST_BY_ID_SUCCESS =
  "GET_CRITERIA_LIST_BY_ID_SUCCESS";
export const GET_CRITERIA_LIST_BY_ID_FAILED = "GET_CRITERIA_LIST_BY_ID_FAILED";

export const CREATE_AUDIT_TEMPLATE_REQUEST = "CREATE_AUDIT_TEMPLATE";
export const CREATE_AUDIT_TEMPLATE_SUCCESS = "CREATE_AUDIT_TEMPLATE_SUCCESS";
export const CREATE_AUDIT_TEMPLATE_FAILURE = "CREATE_AUDIT_TEMPLATE_FAILURE";

export const EDIT_AUDIT_TEMPLATE_REQUEST = "EDIT_AUDIT_TEMPLATE_REQUEST";
export const EDIT_AUDIT_TEMPLATE_SUCCESS = "EDIT_AUDIT_TEMPLATE_SUCCESS";
export const EDIT_AUDIT_TEMPLATE_FAILURE = "EDIT_AUDIT_TEMPLATE_FAILURE";

export const UPDATE_AUDIT_ASSIGNEE_REQUEST = "UPDATE_AUDIT_ASSIGNEE_REQUEST";
export const UPDATE_AUDIT_ASSIGNEE_SUCCESS = "UPDATE_AUDIT_ASSIGNEE_SUCCESS";
export const UPDATE_AUDIT_ASSIGNEE_FAILURE = "UPDATE_AUDIT_ASSIGNEE_FAILURE";

export const GET_AUDIT_TEMPLATES_REQUEST = "GET_AUDIT_TEMPLATES_REQUEST";
export const GET_AUDIT_TEMPLATES_SUCCESS = "GET_AUDIT_TEMPLATES_SUCCESS";
export const GET_AUDIT_TEMPLATES_FAILURE = "GET_AUDIT_TEMPLATES_FAILURE";

export const GET_AUDIT_TEMPLATES_FOR_PLANNING_REQUEST =
  "GET_AUDIT_TEMPLATES_FOR_PLANNING_REQUEST";
export const GET_AUDIT_TEMPLATES_FOR_PLANNING_SUCCESS =
  "GET_AUDIT_TEMPLATES_FOR_PLANNING_SUCCESS";
export const GET_AUDIT_TEMPLATES_FOR_PLANNING_FAILURE =
  "GET_AUDIT_TEMPLATES_FOR_PLANNING_FAILURE";

export const GET_AUDIT_LOCAL_TEMPLATES_FOR_CLIENT_REQUEST =
  "GET_AUDIT_LOCAL_TEMPLATES_FOR_CLIENT_REQUEST";
export const GET_AUDIT_LOCAL_TEMPLATES_FOR_CLIENT_SUCCESS =
  "GET_AUDIT_LOCAL_TEMPLATES_FOR_CLIENT_SUCCESS";
export const GET_AUDIT_LOCAL_TEMPLATES_FOR_CLIENT_FAILURE =
  "GET_AUDIT_LOCAL_TEMPLATES_FOR_CLIENT_FAILURE";

export const GET_INTERNAL_AUDIT_LOG_AUDIT_MANAGEMENT_REQUEST =
  "GET_INTERNAL_AUDIT_LOG_AUDIT_MANAGEMENT_REQUEST";
export const GET_INTERNAL_AUDIT_LOG_AUDIT_MANAGEMENT_SUCCESS =
  "GET_INTERNAL_AUDIT_LOG_AUDIT_MANAGEMENT_SUCCESS";
export const GET_INTERNAL_AUDIT_LOG_AUDIT_MANAGEMENT_FAILURE =
  "GET_INTERNAL_AUDIT_LOG_AUDIT_MANAGEMENT_FAILURE";

export const GLOBAL_TEMPLATE_PLANNING_SCHEDULE_UPDATED =
  "GLOBAL_TEMPLATE_PLANNING_SCHEDULE_UPDATED";

export const LOCAL_TEMPLATE_PLANNING_SCHEDULE_UPDATED_INTERNAL_AUDIT =
  "LOCAL_TEMPLATE_PLANNING_SCHEDULE_UPDATED_INTERNAL_AUDIT";

export const CREATE_CRITERIA_FOR_TEMPLATE_REQUEST =
  "CREATE_CRITERIA_FOR_TEMPLATE_REQUEST";
export const CREATE_CRITERIA_FOR_TEMPLATE_REQUEST_SUCCESS =
  "CREATE_CRITERIA_FOR_TEMPLATE_REQUEST_SUCCESS";
export const CREATE_CRITERIA_FOR_TEMPLATE_REQUEST_FAILURE =
  "CREATE_CRITERIA_FOR_TEMPLATE_REQUEST_FAILURE";

export const UPDATE_CRITERIA_REQUEST = "UPDATE_CRITERIA_REQUEST";
export const UPDATE_CRITERIA_SUCCESS = "UPDATE_CRITERIA_REQUEST_SUCCESS";
export const UPDATE_CRITERIA_FAILED = "UPDATE_CRITERIA_FAILED";

export const UPDATE_CRITERIA_ORDER_REQUEST = "UPDATE_CRITERIA_ORDER_REQUEST";
export const UPDATE_CRITERIA_ORDER_SUCCESS = "UPDATE_CRITERIA_ORDER_SUCCESS";
export const UPDATE_CRITERIA_ORDER_FAILED = "UPDATE_CRITERIA_ORDER_FAILED";

export const DELETE_CRITERIA_REQUEST = "DELETE_CRITERIA_REQUEST";
export const DELETE_CRITERIA_SUCCESS = "DELETE_CRITERIA_SUCCESS";
export const DELETE_CRITERIA_FAILED = "DELETE_CRITERIA_FAILED";

export const CRITERIA_FOR_TEMPLATE_REQUEST = "CRITERIA_FOR_TEMPLATE_REQUEST";
export const CRITERIA_FOR_TEMPLATE_REQUEST_SUCCESS =
  "CRITERIA_FOR_TEMPLATE_REQUEST_SUCCESS";
export const CRITERIA_FOR_TEMPLATE_REQUEST_FAILURE =
  "CRITERIA_FOR_TEMPLATE_REQUEST_FAILURE";

export const SET_CRITERIA_EDIT = "SET_CRITERIA_EDIT";
export const SORT_CRITERIA_LIST_ON_DRAG = "SORT_CRITERIA_LIST_ON_DRAG";

export const UPDATE_CRITERIA_AUDIT_LOG_REQUEST =
  "UPDATE_CRITERIA_AUDIT_LOG_REQUEST";
export const UPDATE_CRITERIA_AUDIT_LOG_SUCCESS =
  "UPDATE_CRITERIA_AUDIT_LOG_SUCCESS";
export const UPDATE_CRITERIA_AUDIT_LOG_FAILURE =
  "UPDATE_CRITERIA_AUDIT_LOG_FAILURE";

export const GET_CRITERIA_AUDIT_LOG_BY_ID_REQUEST =
  "GET_CRITERIA_AUDIT_LOG_BY_ID_REQUEST";
export const GET_CRITERIA_AUDIT_LOG_BY_ID_SUCCESS =
  "GET_CRITERIA_AUDIT_LOG_BY_ID_SUCCESS";
export const GET_CRITERIA_AUDIT_LOG_BY_ID_FAILURE =
  "GET_CRITERIA_AUDIT_LOG_BY_ID_FAILURE";

export const FETCH_USERS_ROLES_CLIENTS_REQUEST =
  "FETCH_USERS_ROLES_CLIENTS_REQUEST";
export const FETCH_USERS_ROLES_CLIENTS_SUCCESS =
  "FETCH_USERS_ROLES_CLIENTS_SUCCESS";
export const FETCH_USERS_ROLES_CLIENTS_FAILURE =
  "FETCH_USERS_ROLES_CLIENTS_FAILURE";

export const UPDATE_CRITERIA_LIST_VALUE =
 'UPDATE_CRITERIA_LIST_VALUE';

export const DELETE_CLIENT_USER_REQUEST = "DELETE_CLIENT_USER_REQUEST";
export const DELETE_CLIENT_USER_SUCCESS = "DELETE_CLIENT_USER_SUCCESS";
export const DELETE_CLIENT_USER_FAILURE = "DELETE_CLIENT_USER_FAILURE";

export const DELETE_USER_REQUEST = "DELETE_USER_REQUEST";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAILURE = "DELETE_USER_FAILURE";

export const FETCH_USER_BY_ID_REQUEST = "FETCH_USER_BY_ID_REQUEST";
export const FETCH_USER_BY_ID_SUCCESS = "FETCH_USER_BY_ID_SUCCESS";
export const FETCH_USER_BY_ID_FAILURE = "FETCH_USER_BY_ID_FAILURE";

export const FETCH_MIMIC_CLIENTS_REQUEST = "FETCH_MIMIC_CLIENTS_REQUEST";
export const FETCH_MIMIC_CLIENTS_SUCCESS = "FETCH_MIMIC_CLIENTS_SUCCESS";
export const FETCH_MIMIC_CLIENTS_FAILURE = "FETCH_MIMIC_CLIENTS_FAILURE";

export const SWITCH_CLIENT_ID_FOR_MIMIC = "SWITCH_CLIENT_ID_FOR_MIMIC";

export const RESET_CRITERIA_LIST = "RESET_CRITERIA_LIST";
//Corrective Action logs

export const GET_CORRECTIVE_ACTION_LOG_REQUEST =
  "GET_CORRECTIVE_ACTION_LOG_REQUEST";
export const GET_CORRECTIVE_ACTION_LOG_SUCCESS =
  "GET_CORRECTIVE_ACTION_LOG_SUCCESS";
export const GET_CORRECTIVE_ACTION_LOG_FAILURE =
  "GET_CORRECTIVE_ACTION_LOG_FAILURE";

export const GET_RESPONSIBLE_USER_IN_CORRECTIVE_ACTION_REQUEST =
  "GET_RESPONSIBLE_USER_IN_CORRECTIVE_ACTION_REQUEST";
export const GET_RESPONSIBLE_USER_IN_CORRECTIVE_ACTION_SUCCESS =
  "GET_RESPONSIBLE_USER_IN_CORRECTIVE_ACTION_SUCCESS";
export const GET_RESPONSIBLE_USER_IN_CORRECTIVE_ACTION_FAILURE =
  "GET_RESPONSIBLE_USER_IN_CORRECTIVE_ACTION_FAILURE";


export const DELETE_INTERNAL_AUDIT_REMOVE_TEMPLATE_REQUEST = "DELETE_INTERNAL_AUDIT_REQUEST";
export const DELETE_INTERNAL_AUDIT_REMOVE_TEMPLATE_SUCCESS = "DELETE_INTERNAL_AUDIT_SUCCESS";
export const DELETE_INTERNAL_AUDIT_REMOVE_TEMPLATE_FAILURE = "DELETE_INTERNAL_AUDIT_FAILURE";
