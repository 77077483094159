import internal from "stream";
import {actionType, apiResponseType} from "../../config/commonTypes";
import {
  CREATE_AUDIT_TEMPLATE_FAILURE,
  CREATE_AUDIT_TEMPLATE_REQUEST,
  CREATE_AUDIT_TEMPLATE_SUCCESS,
  CREATE_CRITERIA_FOR_TEMPLATE_REQUEST,
  CREATE_CRITERIA_FOR_TEMPLATE_REQUEST_FAILURE,
  CREATE_CRITERIA_FOR_TEMPLATE_REQUEST_SUCCESS,
  CRITERIA_FOR_TEMPLATE_REQUEST,
  CRITERIA_FOR_TEMPLATE_REQUEST_SUCCESS,
  DELETE_CRITERIA_FAILED,
  DELETE_CRITERIA_REQUEST,
  DELETE_CRITERIA_SUCCESS,
  DELETE_INTERNAL_AUDIT_REMOVE_TEMPLATE_FAILURE,
  DELETE_INTERNAL_AUDIT_REMOVE_TEMPLATE_REQUEST,
  DELETE_INTERNAL_AUDIT_REMOVE_TEMPLATE_SUCCESS,
  EDIT_AUDIT_TEMPLATE_FAILURE,
  EDIT_AUDIT_TEMPLATE_REQUEST,
  EDIT_AUDIT_TEMPLATE_SUCCESS,
  GET_AUDIT_LOCAL_TEMPLATES_FOR_CLIENT_FAILURE,
  GET_AUDIT_LOCAL_TEMPLATES_FOR_CLIENT_REQUEST,
  GET_AUDIT_LOCAL_TEMPLATES_FOR_CLIENT_SUCCESS,
  GET_AUDIT_TEMPLATES_FAILURE,
  GET_AUDIT_TEMPLATES_FOR_PLANNING_FAILURE,
  GET_AUDIT_TEMPLATES_FOR_PLANNING_REQUEST,
  GET_AUDIT_TEMPLATES_FOR_PLANNING_SUCCESS,
  GET_AUDIT_TEMPLATES_REQUEST,
  GET_AUDIT_TEMPLATES_SUCCESS, GET_INTERNAL_AUDIT_LOG_AUDIT_MANAGEMENT_FAILURE,
  GET_INTERNAL_AUDIT_LOG_AUDIT_MANAGEMENT_REQUEST,
  GET_INTERNAL_AUDIT_LOG_AUDIT_MANAGEMENT_SUCCESS,
  GLOBAL_TEMPLATE_PLANNING_SCHEDULE_UPDATED,
  LOCAL_TEMPLATE_PLANNING_SCHEDULE_UPDATED_INTERNAL_AUDIT,
  RESET_CRITERIA_LIST,
  SET_CRITERIA_EDIT,
  SET_TEMPLATE_HEADER,
  SORT_CRITERIA_LIST_ON_DRAG,
  UPDATE_AUDIT_ASSIGNEE_FAILURE,
  UPDATE_AUDIT_ASSIGNEE_REQUEST,
  UPDATE_AUDIT_ASSIGNEE_SUCCESS,
  UPDATE_CRITERIA_FAILED,
  UPDATE_CRITERIA_ORDER_FAILED,
  UPDATE_CRITERIA_ORDER_REQUEST,
  UPDATE_CRITERIA_ORDER_SUCCESS,
  UPDATE_CRITERIA_REQUEST,
  UPDATE_CRITERIA_SUCCESS,
} from "../../constants/";
import {
  clientType,
  ILocalTemplateSchedule,
  ILocalTemplateType,
} from "./clientManagementReducer";

export interface IinitialStateTypeAuditmanagement {
  isLoading: boolean;
  template: IGlobalTemplateType | undefined;
  criteriaList: ICriteriaType[] | [];
  error: string;
  selectedCriteria: ICriteriaType | undefined;
  templateList: IGlobalTemplateType[] | [];
  templateListPlan: IGlobalTemplateType[] | [];
  localTemplateListPlan: ILocalTemplateType[] | [];
  internalAuditLog: ILocalTemplateType[] | [];
}

export interface ICriteriaType {
  globalTemplateCriteriaId?: number;
  globalTemplateHeaderId?: number;
  globalTemplateCriteriaCode: string;
  globalTemplateCriteriaGroup: string;
  globalTemplateCriteriaDescription: string;
  globalTemplateCriteriaAssignUserId?: number;
  created?: string;
  createdBy?: string;
  lastModified?: string | null;
  lastModifiedBy?: string | null;
  lineNo?: number;
}
export interface IGlobalTemplateType {
  globalTemplateHeaderId?: number;
  createdDate?: string;
  globalTemplateCode: string;
  assineeIds?: number[];
  assignClients?: clientType[];
  globalTemplateName?: string;
  globalTemplateDescription?: string;
  globalTemplateGroup?: string;
  isActive?: boolean;
  isDeleted?: boolean;
  isGlobal?: boolean;
  globalTemplateCriteriaList?: ICriteriaType[];
  globalSchedule?: IGlobalTemplateSchedule | undefined;
  isAssignedtoClient?: boolean;
}

export interface IGlobalTemplateSchedule {
  globalTemplateHeaderId: number;
  january: boolean;
  february: boolean;
  march: boolean;
  april: boolean;
  may: boolean;
  june: boolean;
  july: boolean;
  august: boolean;
  september: boolean;
  october: boolean;
  november: boolean;
  december: boolean;
}

export interface IAuditCreationResponseType extends apiResponseType {
  data: IGlobalTemplateType;
}

export interface ICriteriaResponseType extends apiResponseType {
  data: ICriteriaType;
}

export interface ICriteriaListResponseType extends apiResponseType {
  data: ICriteriaType[];
}

const initialState: IinitialStateTypeAuditmanagement = {
  isLoading: false,
  template: undefined,
  selectedCriteria: undefined,
  templateList: [],
  templateListPlan: [],
  criteriaList: [],
  error: "",
  localTemplateListPlan:  [],
  internalAuditLog:[],
};

export default function reducer(
  state: IinitialStateTypeAuditmanagement = initialState,
  action: actionType
) {
  switch (action.type) {
    case CREATE_AUDIT_TEMPLATE_REQUEST:
      state.isLoading = true;
      return state;
    case CREATE_AUDIT_TEMPLATE_SUCCESS:
      state.isLoading = false;
      state.template = action?.payload;
      return { ...state };
    case SET_TEMPLATE_HEADER:
      state.isLoading = false;
      state.template = action?.payload;
      return { ...state };
    case SET_CRITERIA_EDIT:
      state.selectedCriteria = action?.payload as ICriteriaType;
      return { ...state };
    case SORT_CRITERIA_LIST_ON_DRAG:
      state.criteriaList = action?.payload as ICriteriaType[];
      return { ...state };
    case CREATE_AUDIT_TEMPLATE_FAILURE:
      state.isLoading = false;
      state.error = action?.payload?.data;
      return { ...state };
    case CREATE_CRITERIA_FOR_TEMPLATE_REQUEST:
      state.isLoading = true;
      return state;
    case CREATE_CRITERIA_FOR_TEMPLATE_REQUEST_SUCCESS:
      state.isLoading = false;
      state.selectedCriteria = action?.payload;
      return { ...state };
    case CREATE_CRITERIA_FOR_TEMPLATE_REQUEST_FAILURE:
      state.isLoading = false;
      state.error = action?.payload?.data;
      return { ...state };
    case CRITERIA_FOR_TEMPLATE_REQUEST:
      state.isLoading = true;
      return state;
    case CRITERIA_FOR_TEMPLATE_REQUEST_SUCCESS:
      state.isLoading = false;
      state.criteriaList = action?.payload;
      return { ...state };
    case CREATE_CRITERIA_FOR_TEMPLATE_REQUEST_FAILURE:
      state.isLoading = false;
      state.error = action?.payload?.data;
      return { ...state };
    case UPDATE_CRITERIA_REQUEST:
      state.isLoading = true;
      return state;
    case UPDATE_CRITERIA_SUCCESS:
      state.isLoading = false;
      state.selectedCriteria = action?.payload;
      return { ...state };
    case UPDATE_CRITERIA_FAILED:
      state.isLoading = false;
      state.error = action?.payload?.data;
      return { ...state };
    case DELETE_CRITERIA_REQUEST:
      state.isLoading = true;
      return state;
    case DELETE_CRITERIA_SUCCESS:
      state.isLoading = false;
      return { ...state };
    case DELETE_CRITERIA_FAILED:
      state.isLoading = false;
      state.error = action?.payload?.data;
      return { ...state };
    case GET_AUDIT_TEMPLATES_REQUEST:
      state.isLoading = true;
      return state;
    case GET_AUDIT_TEMPLATES_SUCCESS:
      state.isLoading = false;
      state.templateList = action?.payload;
      return { ...state };
    case GET_AUDIT_TEMPLATES_FAILURE:
      state.isLoading = false;
      state.error = action?.payload?.data;
      return { ...state };
    case EDIT_AUDIT_TEMPLATE_REQUEST:
      state.isLoading = true;
      return state;
    case EDIT_AUDIT_TEMPLATE_SUCCESS:
      state.isLoading = false;
      state.template = action?.payload;
      return { ...state };
    case EDIT_AUDIT_TEMPLATE_FAILURE:
      state.isLoading = false;
      state.error = action?.payload?.data;
      return { ...state };
    case UPDATE_CRITERIA_ORDER_REQUEST:
      state.isLoading = true;
      return state;
    case UPDATE_CRITERIA_ORDER_SUCCESS:
      state.isLoading = false;

      return { ...state };
    case UPDATE_CRITERIA_ORDER_FAILED:
      state.isLoading = false;
      state.error = action?.payload?.data;
      return { ...state };

    case GET_AUDIT_TEMPLATES_FOR_PLANNING_REQUEST:
      state.isLoading = true;
      return state;
    case GET_AUDIT_TEMPLATES_FOR_PLANNING_SUCCESS:
      state.isLoading = false;
      state.templateListPlan = action?.payload;
      return { ...state };
    case GET_AUDIT_TEMPLATES_FOR_PLANNING_FAILURE:
      state.isLoading = false;
      state.error = action?.payload?.data;
      return { ...state };

    case GLOBAL_TEMPLATE_PLANNING_SCHEDULE_UPDATED:
      state.templateListPlan = updateTemplateSchedule(
        state.templateListPlan,
        action.payload
      );
      return { ...state };

    case GET_AUDIT_LOCAL_TEMPLATES_FOR_CLIENT_REQUEST:
      state.isLoading = true;
      return state;
    case GET_AUDIT_LOCAL_TEMPLATES_FOR_CLIENT_SUCCESS:
      state.isLoading = false;
      state.localTemplateListPlan = action?.payload;
      return { ...state };
    case GET_AUDIT_LOCAL_TEMPLATES_FOR_CLIENT_FAILURE:
      state.isLoading = false;
      state.error = action?.payload;
      return { ...state };

    case UPDATE_AUDIT_ASSIGNEE_REQUEST:
      state.isLoading = true;
      return state;
    case UPDATE_AUDIT_ASSIGNEE_SUCCESS:
      state.isLoading = false;
      return { ...state };
    case UPDATE_AUDIT_ASSIGNEE_FAILURE:
      state.isLoading = false;
      state.error = action?.payload;
      return { ...state };

    case LOCAL_TEMPLATE_PLANNING_SCHEDULE_UPDATED_INTERNAL_AUDIT:
      state.localTemplateListPlan = updateLocalTemplateSchedule(
        state.localTemplateListPlan,
        action.payload
      );
      return { ...state };

    case GET_INTERNAL_AUDIT_LOG_AUDIT_MANAGEMENT_REQUEST :
      state.isLoading = true;
      return {...state };
    case GET_INTERNAL_AUDIT_LOG_AUDIT_MANAGEMENT_SUCCESS :
      state.isLoading = false;
      state.internalAuditLog = action?.payload;
      return {...state };
    case GET_INTERNAL_AUDIT_LOG_AUDIT_MANAGEMENT_FAILURE :
      state.isLoading = false;
      return {...state };

    case RESET_CRITERIA_LIST :
      state.criteriaList = action?.payload;
      return {...state}

    case DELETE_INTERNAL_AUDIT_REMOVE_TEMPLATE_REQUEST:
      state.isLoading = true;
      return { ...state }
    case DELETE_INTERNAL_AUDIT_REMOVE_TEMPLATE_SUCCESS:
      state.isLoading = false;
      state.internalAuditLog = state?.internalAuditLog.filter(template => 
        template.localTemplateHeaderId != action.payload)
      return { ...state }
    case DELETE_INTERNAL_AUDIT_REMOVE_TEMPLATE_FAILURE:
      state.isLoading = false;
      return { ...state }

    default:
      return state;
  }
}

const updateTemplateSchedule = (
  templates: IGlobalTemplateType[],
  toBeUpdateSchedule: IGlobalTemplateSchedule
) => {
  return templates.map((template) => ({
    ...template,
    globalSchedule:
      template.globalTemplateHeaderId ===
      toBeUpdateSchedule.globalTemplateHeaderId
        ? toBeUpdateSchedule
        : template.globalSchedule,
  }));
};

const updateLocalTemplateSchedule = (
  templates: ILocalTemplateType[],
  toBeUpdateSchedule: ILocalTemplateSchedule
) => {
  return templates.map((template) => ({
    ...template,
    localSchedule:
      template.localTemplateHeaderId ===
      toBeUpdateSchedule.localTemplateHeaderId
        ? toBeUpdateSchedule
        : template.localSchedule,
  }));
};
