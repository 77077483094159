import { useFormik } from "formik";
import _ from "lodash";
import moment from "moment";
import React, { useState } from "react";
import {
  ClientUser,
  CriteriaCorrectiveLog,
} from "../../redux/modules/auditCorrectiveLogReducer";
import * as Yup from "yup";
import logoImage from "../../assets/img/logo/logo.png";
import jsPDF from "jspdf";
import AccessControl from "../../config/AccessControl";
import {
  AccessControlType,
  UserPermissionsConstant,
} from "../../constants/constants";
import { printDocument } from "../../views/pages/home/printDocument";
import { delay } from "../../config/commonMethods";
import { useSelector } from "react-redux";
import { clientType } from "../../redux/modules/clientManagementReducer";
import { toLocalformatDate,toLocalformatDateTime } from "../../config/commonMethods";

export interface ICorrectiveLogData {
  markClosed?: boolean;
  correctiveActions: string;
  item: CriteriaCorrectiveLog;
  personResponsible: string;
  selectedAssignee: ClientUser;
}
interface CorrectiveActionLogPorps {
  CorrectiveLogData: CriteriaCorrectiveLog;
  auditName: string;
  auditCode: string;
  clientUsers: ClientUser[];
  saveCorrectiveLog: (data: ICorrectiveLogData) => void;
  takenBy: string;
  takenDate:string;
  isTemplateClosed?: boolean;
  onClose:()=>void;
}

interface IntialCorrectiveLogFormValues {
  personResponsible: string;
  correctiveActions: string;
  markClosed?: boolean;
}

export function CorrectiveActionLogModal(props: CorrectiveActionLogPorps) {
  const [selectedAssignee, setAssignee] = React.useState<ClientUser>();
  const initialValues: IntialCorrectiveLogFormValues = {
    personResponsible: "",
    correctiveActions: "",
  };
  const selectedClientDetails : clientType | undefined = useSelector((state:any)=>state?.auth?.clientDetails);
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: (values: IntialCorrectiveLogFormValues) => {
      props.saveCorrectiveLog({
        correctiveActions: values.correctiveActions,
        item: props.CorrectiveLogData,
        personResponsible: values.personResponsible!!,
        selectedAssignee: selectedAssignee!!,
        markClosed: values.markClosed,
      });
    },
    validationSchema: Yup.object().shape({
      personResponsible: Yup.string().required(
        "Person Responsible is required"
      ),
      correctiveActions: Yup.string().required("Corrective Action is required"),
    }),
  });

  React.useEffect(() => {
    if (props.CorrectiveLogData.responsibleUserId) {
      setAssignee(
        findAssigneeFromId(props.CorrectiveLogData.responsibleUserId)
      );
      formik.setFieldValue(
        "personResponsible",
        props.CorrectiveLogData.responsibleUserId
      );
    }

    formik.setFieldValue(
      "correctiveActions",
      props.CorrectiveLogData.correctiveActions
    );
    formik.setFieldValue(
      "markClosed",
      props.CorrectiveLogData.isClosed || props.isTemplateClosed
    );
  }, [props.clientUsers]);

  const resetToOld = () =>{
    if (props.CorrectiveLogData.responsibleUserId) {
      setAssignee(
        findAssigneeFromId(props.CorrectiveLogData.responsibleUserId)
      );
      formik.setFieldValue(
        "personResponsible",
        props.CorrectiveLogData.responsibleUserId
      );
    }

    formik.setFieldValue(
      "correctiveActions",
      props.CorrectiveLogData.correctiveActions
    );
    formik.setFieldValue(
      "markClosed",
      props.CorrectiveLogData.isClosed || props.isTemplateClosed
    );
}

  const findAssigneeFromId = (id: string) =>
    props.clientUsers.find((o) => o.userId == id);

  const onChangeAssignTo = (id: string) => {
    setAssignee(findAssigneeFromId(id));
  };

  const generatePDF = (item: CriteriaCorrectiveLog) => {
    if (!item) return;
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; //// portrait or landscape
    var doc = new jsPDF(orientation, unit, size);

    doc.addImage(logoImage, "PNG", 10, 10, 70, 70);
    doc.setFont("times");
    doc.setTextColor(65, 144, 197);
    doc.text("GIA+ Quality Assurance system", 100, 30);
    doc.text("__________________________", 100, 40);
    doc.setTextColor(0, 0, 0);
    doc.text("Corrective Action", 100, 60);

    const fontLabel = "arial";
    const fontLabelSize = 12;
    const fontValue = "normal";
    const fontValueSize = 12;
    doc.setTextColor(30, 30, 30);
    //Body
    doc.setFont(fontLabel).setFontSize(fontLabelSize);
    doc.text("Audit :", 100, 120);
    doc.setFont(fontValue).setFontSize(fontValueSize);
    doc.text(`${props.auditName}`, 160, 120);

    doc.setFont(fontLabel).setFontSize(fontLabelSize);
    doc.text("Findings :", 350, 120);
    doc.setFont(fontValue).setFontSize(fontValueSize);
    doc.text(`${item.findings}`, 410, 120);

    doc.setFont(fontLabel).setFontSize(fontLabelSize);
    doc.text("Taken by :", 100, 135);
    doc.setFont(fontValue).setFontSize(fontValueSize);
    doc.text(`${props.takenBy} - ${props.takenDate}`, 160, 135);

    doc.setFont(fontLabel).setFontSize(fontLabelSize);
    doc.text("Criteria :", 350, 135);
    doc.setFont(fontValue).setFontSize(fontValueSize);
    doc.text(`${item.localTemplateCriteriaDescription}`, 410, 135);

    doc.setFont(fontLabel).setFontSize(fontLabelSize);
    doc.text("Complies :", 100, 150);
    doc.setFont(fontValue).setFontSize(fontValueSize);
    doc.text(`${item.isComplies ? "YES" : "NO"}`, 160, 150);

    doc.setFont(fontLabel).setFontSize(fontLabelSize);
    doc.text("Completed :", 350, 150);
    doc.setFont(fontValue).setFontSize(fontValueSize);
    doc.text(
      `${item.completeDate && 
        //moment(item.completeDate).format("DD/MM/YYYY")
        toLocalformatDate(item.completeDate)
      }
      
      `,
      410,
      150
    );

    doc.setFont(fontLabel).setFontSize(fontLabelSize);
    doc.text("Person Responsible :", 100, 170);
    doc.setFont(fontValue).setFontSize(fontValueSize);
    doc.text(`${item.responsibleUsername}`, 240, 170);

    doc.setFont(fontLabel).setFontSize(fontLabelSize);
    doc.text("Closed :", 100, 190);
    doc.setFont(fontValue).setFontSize(fontValueSize);
    doc.text(`${item.isClosed ? "YES" : "NO"}`, 240, 190);

    doc.setFont(fontLabel).setFontSize(fontLabelSize);
    doc.text("Corrective Actions :", 100, 210);
    doc.setFont(fontValue).setFontSize(fontValueSize);
    var splitTitle = doc.splitTextToSize(`${item.correctiveActions}`, 300);

    doc.text(splitTitle, 240, 210);

    doc.save("corrective-action.pdf");
  };

  return (
    <div>
      <div className="modal-body p-0">
        <form
          onSubmit={formik.handleSubmit}
          method="POST"
          className="w-100 px-0"
        >
          <div id="divToPrint">
            <div id="header">

            </div>

            <div id='bg-rmove-print' className="bg-light p-3 border-bottom ">
              <div className="row ">
                <div className="col-6">
                  <div className="row">
                    <label className="col-sm-4 form-label fw-bold">Audit</label>
                    <div className="col-sm-8">
                      <label>{`${props.auditCode} - ${props.auditName}`}</label>
                    </div>
                  </div>
                  <div className="row">
                    <label className="col-sm-4 form-label  fw-bold">Taken by</label>
                    <div className="col-sm-8">
                      <label>{props.takenBy} - {props.takenDate}</label>
                    </div>
                  </div>
                  <div className="row">
                    <label className="col-sm-4 fw-bold form-label">Complies</label>
                    <div className="col-sm-8">
                      <label>
                        {props.CorrectiveLogData.isComplies == null ? (
                          <span className="badge bg-danger"> - </span>
                        ) : props.CorrectiveLogData.isComplies == 1 ? (
                          <span className="badge bg-success"> Yes </span>
                        ) : props.CorrectiveLogData.isComplies == 0 ? (
                          <span className="badge bg-danger"> No </span>
                        ) : (<span className="badge bg-info"> Not Applicable </span>)}
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="row">
                    <label className="col-sm-4 form-label  fw-bold">Findings</label>
                    <div className="col-sm-8">
                      <label style={{wordBreak: 'break-word'}}>{props.CorrectiveLogData.findings}</label>
                    </div>
                  </div>
                  <div className="row">
                    <label className="col-sm-4 form-label fw-bold">Criteria</label>
                    <div className="col-sm-8">
                      <label>
                        {props.CorrectiveLogData.localTemplateCriteriaDescription}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-white p-3 ">
              <div className="row">
                <div className="col-6">
                  <label className="col-sm-4 form-label fw-bold">
                    Person responsible
                  </label>
                  <select
                    id="responsibleUserId"
                    className="form-select form-select-sm"
                    onChange={(e) => {
                      formik.handleChange(e);
                      onChangeAssignTo(e.target.value);
                    }}
                    value={formik.values.personResponsible}
                    onBlur={formik.handleBlur}
                    name="personResponsible"
                    disabled={props.CorrectiveLogData.isClosed!!}
                  >
                    {_.isEmpty(props.CorrectiveLogData?.responsibleUserId) && (
                      <option selected={true}>Select</option>
                    )}
                    {!_.isEmpty(props.clientUsers) &&
                      props.clientUsers?.map((o: any) => {
                        return (
                          <option
                            selected={
                              props.CorrectiveLogData?.responsibleUserId ==
                              o.userId
                            }
                            value={o.userId}
                          >
                            {o.responsibleUserFullName}
                          </option>
                        );
                      })}
                  </select>
                  {formik.errors.personResponsible &&
                    formik.touched.personResponsible && (
                      <p style={{ color: "red" }}>
                        {formik.errors.personResponsible}
                      </p>
                    )}
                </div>
                <div className="col-6">
                  <label className="col-sm-2 form-label fw-bold">Completed</label>
                  <label className="ms-2">
                    <span className="badge badge-pill bg-success">
                      {props.CorrectiveLogData.isClosed ?
                       //moment(props.CorrectiveLogData.completeDate).format("DD/MM/YYYY")
                       toLocalformatDate(props.CorrectiveLogData.completeDate)
                        : '-'}
                    </span>
                  </label>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="flexCheckDefaultPrint"
                      name="markClosed"
                      onChange={() =>
                        formik.setFieldValue(
                          "markClosed",
                          !formik.values.markClosed
                        )
                      }
                      disabled={
                        !formik.values.correctiveActions ||
                        !formik.values.personResponsible ||
                        props.CorrectiveLogData.isClosed!!
                      }
                      checked={formik.values.markClosed}
                      onBlur={formik.handleBlur}
                    />
                    <label id="flexCheckDefaultTextPrint" className="form-check-label">
                      Mark this item as closed
                    </label>
                    {formik.errors.markClosed && formik.touched.markClosed && (
                      <p style={{ color: "red" }}>{formik.errors.markClosed}</p>
                    )}
                  </div>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-12">
                  <label className="col-sm-4 form-label fw-bold">
                    Corrective actions
                  </label>
                  <textarea
                    className="form-control form-control-sm"
                    id="correctiveActionsId"
                    onChange={formik.handleChange}
                    value={formik.values.correctiveActions}
                    onBlur={formik.handleBlur}
                    name="correctiveActions"
                    disabled={props.CorrectiveLogData.isClosed!!}
                    rows={3}
                  ></textarea>
                  {formik.errors.correctiveActions &&
                    formik.touched.correctiveActions && (
                      <p style={{ color: "red" }}>
                        {formik.errors.correctiveActions}
                      </p>
                    )}
                </div>
              </div>

            </div>
            </div>
            <div className="p-3 row mt-3">
              <div className="col-12 text-end">
                <AccessControl
                  type={AccessControlType.DISABLE}
                  allowedPermissions={[
                    UserPermissionsConstant.ViewPrintAudit,
                    UserPermissionsConstant.MimicAccess,
                  ]}
                >
                  <button
                    type="button"
                    className="btn btn-outline-secondary btn-sm me-3"
                    onClick={() => {
                      resetToOld();
                      delay(2).then(()=>{
                        printDocument(selectedClientDetails?.companyName);
                        props.onClose();
                      })
                      //  generatePDF(props.CorrectiveLogData!!);
                    }}
                  >
                    <span className="bx bx-printer"></span>&nbsp; Print
                  </button>
                </AccessControl>
                <AccessControl
                  type={AccessControlType.DISABLE}
                  allowedPermissions={[
                    UserPermissionsConstant.PerformAudit,
                    UserPermissionsConstant.MimicAccess,
                  ]}
                >
                  <button
                    disabled={props.CorrectiveLogData.isClosed!!}
                    type="submit"
                    className="btn btn-primary btn-sm px-4 "
                  >
                    Save
                  </button>
                </AccessControl>
              </div>
            </div>
          
        </form>
      </div>
    </div>
  );
}
