import React from 'react';
import AppLayout from "../../Layout/AppLayout";
import Pagination from "../../../components/pagination/Pagination";
import Dropdown from "../../../components/dropdown/Dropdown";

function InternalAuditDetails() {
    return (
        <AppLayout>
            <>
                <div className="row">
                    {/*Custom style1 Breadcrumb -->*/}
                    <div className="col-md">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb breadcrumb-style1 d-flex align-items-center">
                                <li className="breadcrumb-item d-flex align-items-center">
                                    <a href="javascript:void(0);">Home</a>
                                </li>
                                <li className="breadcrumb-item d-flex align-items-center">
                                    <a href="javascript:void(0);">Internal Audit List</a>
                                </li>
                                <li className="breadcrumb-item d-flex align-items-center active">Internal Audit
                                    Details
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>


                <div className="row mb-4">
                    <div className="col-md">
                        <div className="card shadow-sm">
                            <div
                                className="d-flex justify-content-between align-items-center card-header mb-0 border-bottom">
                                <div>
                                    <h6 className="m-0">Internal Audit Details</h6>
                                </div>
                                <div className="d-flex flex-wrap justify-content-end">
                                    <button type="button" className="btn btn-outline-secondary btn-sm">
                                        <span className="tf-icons bx bx-printer"></span>&nbsp; Print
                                    </button>
                                </div>
                            </div>
                            <div className="card-body border-3 border-bottom">
                                <div className="row">
                                    <div className="col-1">
                                        <p className="fw-bold">Facility</p>
                                    </div>
                                    <div className="col-5">
                                        <p>Allion Technologies</p>
                                    </div>
                                    <div className="col-1">
                                        <p className="fw-bold">Date Takes</p>
                                    </div>
                                    <div className="col-5">
                                        <p>22/07/2022</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-1">
                                        <p className="fw-bold">Code</p>
                                    </div>
                                    <div className="col-5">
                                        <p>FCP6-4</p>
                                    </div>
                                    <div className="col-1">
                                        <p className="fw-bold">Description</p>
                                    </div>
                                    <div className="col-5">
                                        <p>Take into consideration main kitchen, kitchenettes and resident use fridges
                                            in bedrooms or other areas. Be specific in recording the exact location and
                                            / or pieces of equipment being referred to.</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-1">
                                        <p className="fw-bold">Assign To</p>
                                    </div>
                                    <div className="col-3">
                                        <Dropdown/>
                                    </div>
                                    <div className="col-2">&nbsp;</div>
                                    <div className="col-1">
                                        <p className="fw-bold">Designation</p>
                                    </div>
                                    <div className="col-5">
                                        <p> - </p>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive text-nowrap">
                                    <table className="table table-bordered table-hover">
                                        <thead>
                                        <tr>
                                            <th className="col-5 text-capitalize fw-semibold" colSpan={2}>Criteria</th>
                                            <th className="col-1 text-capitalize fw-semibold">Complies</th>
                                            <th className="col-3 text-capitalize fw-semibolde">Findings</th>
                                            <th className="col-1 text-capitalize fw-semibold">Responsible</th>
                                            <th className="col-1 text-capitalize fw-semibold">Corrective Actions</th>
                                            <th className="text-capitalize fw-semibold">Closed</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>
                                                <i className='bx bx-grid-alt'></i>
                                            </td>
                                            <td className="text-wrap">
                                                Are floors free from food scraps, visible cracks / splits etc., which
                                                could trap food? (includes areas under benches, behind doors and in
                                                corners).
                                            </td>
                                            <td>
                                                <div className="btn-group d-flex justify-content-center">
                                                    <Dropdown type="binary"/>
                                                </div>
                                            </td>
                                            <td>
                                                <textarea className="form-control" id="exampleFormControlTextarea1"
                                                          rows={1}></textarea>
                                            </td>
                                            <td className="text-wrap">
                                                <textarea className="form-control" id="exampleFormControlTextarea2"
                                                          rows={1}></textarea>
                                            </td>
                                            <td>
                                                <textarea className="form-control" id="exampleFormControlTextarea3"
                                                          rows={1}></textarea>
                                            </td>
                                            <td>
                                                <div className="form-check d-flex justify-content-center">
                                                    <input className="form-check-input" type="checkbox" value=""
                                                           id="defaultCheck1"/>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <i className='bx bx-grid-alt'></i>
                                            </td>
                                            <td className="text-wrap">
                                                Are floors free from food scraps, visible cracks / splits etc., which
                                                could trap food? (includes areas under benches, behind doors and in
                                                corners).
                                            </td>
                                            <td>
                                                <div className="btn-group d-flex justify-content-center">
                                                    <Dropdown type="binary"/>
                                                </div>
                                            </td>
                                            <td>
                                                <textarea className="form-control" id="exampleFormControlTextarea1"
                                                          rows={1}></textarea>
                                            </td>
                                            <td className="text-wrap">
                                                <textarea className="form-control" id="exampleFormControlTextarea2"
                                                          rows={1}></textarea>
                                            </td>
                                            <td>
                                                <textarea className="form-control" id="exampleFormControlTextarea3"
                                                          rows={1}></textarea>
                                            </td>
                                            <td>
                                                <div className="form-check d-flex justify-content-center">
                                                    <input className="form-check-input" checked type="checkbox" value=""
                                                           id="defaultCheck1"/>
                                                </div>
                                            </td>
                                        </tr>

                                        </tbody>
                                    </table>
                                </div>
                                <div className="row">
                                    <div className="col px-2">
                                        <div className="demo-inline-spacing">
                                            <Pagination/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        </AppLayout>
    )
}

export default InternalAuditDetails

