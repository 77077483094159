import React, {useEffect, useState} from "react";
import {IGlobalTemplateSchedule, IGlobalTemplateType} from "../redux/modules/auditManagementReducer";
import DataTable, {TableStyles} from "react-data-table-component";
import {BootyPagination} from "./pagination/BootyPagination";
import _ from "lodash";
import { sortByString } from "../config/commonMethods";

interface IAuditPlannerTabProps {
    data: IGlobalTemplateType[];
    monthsArray:IMonthsType[];
    onChange: (data:IGlobalTemplateSchedule)=>void;
    setSortData: (data:any) => void;
}

export interface IMonthsType {
    text: string;
    longText: string;
    value: string;
}

const AuditPlanner = ({data,monthsArray,onChange,setSortData} : IAuditPlannerTabProps) => {
    const [months, setMonths] = useState<IMonthsType[] | []>([]);
    const [changedSchedule, setChangedSchedule] = useState<IGlobalTemplateSchedule[]|[]>([]);
    const [globalTemplates, setGlobalTemplates] = useState<IGlobalTemplateType[]|[]>([]);
    const [dataTableFormat, setDataTableFormat] = useState<any>();

    useEffect(() => {
        setMonths(monthsArray);
    }, [monthsArray]);

    useEffect(() => {
        setChangedSchedule([]);
        if(data) {
            setGlobalTemplates(data);
            const dataSet = formatDataTable(data);
            setDataTableFormat(dataSet);
        }
    },[data]);

    const formatDataTable = (templates: IGlobalTemplateType[]) => {
        return templates?.map((template: IGlobalTemplateType) => {
            return {
                globalTemplateHeaderId: template.globalTemplateHeaderId,
                january: addCheckBox(template,"january"),
                february: addCheckBox(template, "february"),
                march: addCheckBox(template, "march"),
                april: addCheckBox(template, "april"),
                may: addCheckBox(template, "may"),
                june: addCheckBox(template, "june"),
                july: addCheckBox(template, "july"),
                august: addCheckBox(template, "august"),
                september: addCheckBox(template, "september"),
                october: addCheckBox(template, "october"),
                november: addCheckBox(template, "november"),
                december: addCheckBox(template, "december"),
                ...template,
            };
        });
    };

    const addCheckBox = (template: IGlobalTemplateType | any,key:string)=>{
        return (
            <td>
                <div className="form-check d-flex justify-content-center">
                    <input
                        className="pointer form-check-input checkbox-lg"
                        style={template?.isActive ? {} : {opacity:0.5}}
                        type="checkbox"
                        onChange={(event) => {
                            onChangeCheckBox(template,
                                key,
                                event.target.checked)
                        }}
                        checked={template.globalSchedule[key]}
                    />
                </div>
            </td>
        )
    }

    useEffect(() => {
        if(changedSchedule.length > 0) {
            const updatedGlobalTemplates = data.map(((globalTemplateSchedule:IGlobalTemplateType) => ({
                ...globalTemplateSchedule,
                globalSchedule:
                    changedSchedule.find((schedule:any) => schedule.globalTemplateHeaderId === globalTemplateSchedule.globalTemplateHeaderId)
                        ? changedSchedule.find((schedule:any) => schedule.globalTemplateHeaderId === globalTemplateSchedule.globalTemplateHeaderId) :
                        globalTemplateSchedule.globalSchedule
            })));
            setGlobalTemplates(updatedGlobalTemplates);
            const dataSet = formatDataTable(updatedGlobalTemplates);
            setDataTableFormat(dataSet);
        }
    },[changedSchedule]);


    const onChangeCheckBox = (globalTemplate: IGlobalTemplateType,
                              month: string,
                              isSelected: boolean) => {
        const {globalSchedule, globalTemplateHeaderId} = globalTemplate;
        let globalTemplateScheduleLocal: IGlobalTemplateSchedule | undefined = undefined;
        let listOfChangedSchedule: IGlobalTemplateSchedule[] | [] = [];
        if (globalSchedule) {
            globalTemplateScheduleLocal = {
                ...globalSchedule,
                [month]: isSelected,
            };
            listOfChangedSchedule =
                [...changedSchedule.filter((s: any) => s.globalTemplateHeaderId !== globalTemplateHeaderId), globalTemplateScheduleLocal];
        }
        setChangedSchedule(listOfChangedSchedule);
        globalTemplateScheduleLocal && onChange(globalTemplateScheduleLocal);
    };

    const monthsColumns = monthsArray.map(month =>({
        name: month.text,
        selector: (row: any) => row[month.value],
        sortable: false,
        width: "60px",

    }));
    const customStyles: TableStyles = {
        headRow: {
            style: { backgroundColor: "#f2f2f2", border: "1px solid #d9dee3" },
        },
        rows: {
            style: { border: "1px solid #d9dee3" },
        },
    };
    const columns: any = [
        {
            name: "Code",
            id:'globalTemplateCode',
            selector: (row: any) => row.globalTemplateCode,
            sortable: true,
        },
        {
            name: "Audit Name",        
            selector: (row: any) => row.globalTemplateName,
            sortable: true,
            minWidth: "300px",
            wrap:true,
        },
        ...monthsColumns

    ];


    return (
        <div className="card shadow-none p-0 m-1 mt-0">
            <div className="card-body p-0 m-0">
                <div className="table-responsive text-nowrap">
                        <DataTable
                            title=""
                            columns={columns}
                            data={dataTableFormat}
                            defaultSortFieldId="globalTemplateCode"
                            pagination={true}
                            paginationPerPage={10}
                            paginationComponent={BootyPagination}
                            selectableRows={false}
                            customStyles={customStyles}
                            onSort={(selectedColumn, sortDirection, sortedRows) => {
                                setSortData(sortedRows);
                            }}
                        />
                </div>
            </div>
        </div>
    );
};
export default AuditPlanner;