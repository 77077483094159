import React, {useEffect, useState} from 'react';
import {childPermissionsType, customGroupPermissionsType} from "../config/commonTypes";
import {SELECT_USER_PERMISSION_TYPE} from "../constants/constants";

interface propsTypes {
    data?: customGroupPermissionsType[];
    isSuperAdmin?: boolean;
    onSelect?:(data:string[])=>void;
    selectedIds?:string[];
    canReset?: boolean;
    type?: SELECT_USER_PERMISSION_TYPE.CREATE | SELECT_USER_PERMISSION_TYPE.UPDATE;
}

function SelectUserPolicies({data,canReset,
                                type,
                                onSelect,
                                selectedIds}: propsTypes) {
    const [permissionsData, setPermissionsData] = useState<customGroupPermissionsType[]>();
    const [isSelectedAll , setIsSelectedAll] = useState<boolean>(false);
    useEffect(() => {
        data && setPermissionsData(data);
    }, [data]);

    useEffect(() => {
        if (selectedIds && selectedIds?.length > 0) {
            resetAllPermissions();
            selectedIds?.forEach(id => {
                selectPermissions(true, id, 'child', false);
            });
        }else {
            type === SELECT_USER_PERMISSION_TYPE.UPDATE && resetAllPermissions();
        }
    }, [selectedIds]);

    useEffect(() => {
        if (canReset) {
            resetAllPermissions();
            setIsSelectedAll(false);
        }
    },[canReset]);

    const resetAllPermissions = () => {
        permissionsData?.forEach(permission => {
            permission?.childPermissions.forEach(child=>{
                selectPermissions(false, child?.id, 'child',false);
            })
        });
    }

    const selectPermissions = (isChecked: boolean, id:string,type: 'group' | 'child' ,isCheckedManually:boolean) => {
        let updatedPermissions: customGroupPermissionsType[] | undefined;
        if (type === 'group') {
            updatedPermissions = permissionsData?.map(permissions => {
                if(permissions.rolesGroupId === id) {
                    permissions.isSelected = isChecked;
                    permissions?.childPermissions.map(permissions => {
                        permissions.isSelected = isChecked;
                        return permissions
                    })
                }
                return permissions;
            });
        }
        if(type === 'child'){
            updatedPermissions = permissionsData?.map(permissions => {

                    permissions?.childPermissions.map(permission => {
                        if(permission.id === id) {
                            permission.isSelected = isChecked;
                            return permission;
                        }
                        return permission
                    });
                permissions.isSelected =
                    permissions?.childPermissions.filter(permissions => permissions.isSelected).length === permissions?.childPermissions.length;

                return permissions;
            });
        }
        updatedPermissions && setPermissionsData(updatedPermissions);
        let selectedIds: string[] =[];
        if(updatedPermissions){
            for(let i = 0; i < updatedPermissions?.length; i++) {
                updatedPermissions[i].childPermissions.forEach((child:childPermissionsType)=>{
                    child.isSelected && selectedIds.push(child.id);
                })
            }
        }
        checkIsSelectedAllOrNot(selectedIds);
        isCheckedManually && onSelect && onSelect(selectedIds)

    }

    const checkIsSelectedAllOrNot = (selectedIds:string[])=>{
        let allPermissionIds: string[] = [];
        permissionsData?.forEach(permission => {
            const childPermissionsIds = permission.childPermissions.map(cp=>cp.id);
            allPermissionIds=[...allPermissionIds,...childPermissionsIds];
        });
        setIsSelectedAll(selectedIds.length === allPermissionIds.length);
    }

    const selectAllPermissions = (isSelected: boolean)=>{
        setIsSelectedAll(isSelected);
        let localPermissionsData: customGroupPermissionsType[] = [];
        let localChildPermissionsData: childPermissionsType[] = [];
        let allPermissionIds: string[] = [];
        permissionsData?.forEach(permission => {
            const childPermissionsIds = permission.childPermissions.map(cp=>cp.id);
            allPermissionIds=[...allPermissionIds,...childPermissionsIds];
            localChildPermissionsData = permission.childPermissions.map(child => ({...child, isSelected: isSelected}));
            localPermissionsData.push({...permission, isSelected: isSelected,childPermissions: localChildPermissionsData})
        });
        setPermissionsData(localPermissionsData);
        onSelect && onSelect(isSelected ? allPermissionIds:[]);
    }

    return (
        <div className="row">
            <div className="row">
                <div className="col-4">
                    <div
                        className="d-flex justify-content-between align-items-center card-header mb-0 p-0">
                        <div>
                            <h6 className="p-0 m-2 mx-0 secondry-color">User Policies</h6>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col">
                <table
                    className="table table-bordered table-hover tbl-audit-planner mb-1">
                    <thead>
                    <tr>
                        <th className="d-flex justify-content-between align-items-center text-capitalize fw-semibold">
                            <p className='mb-0 fw-bold'>Permission Details</p>
                            <div className="d-flex align-items-center">
                                <label className="fw-semibold me-2">Select all</label>
                                <input className="form-check-input checkbox-lg m-1"
                                       type="checkbox"
                                       onClick={(e: any) =>
                                           selectAllPermissions(e.target.checked)
                                       }
                                       disabled={!permissionsData}
                                       checked={isSelectedAll}/>
                            </div>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {/*  <tr>
                        <td>
                            <input type="text"
                                   className="form-control form-control-sm"
                                   placeholder=""
                                   aria-describedby="defaultFormControlHelp"/>
                        </td>
                    </tr> */}
                    {permissionsData && permissionsData.map(groupPermission => (
                        <>
                            <tr>
                                <td className="app-bg-gray d-flex justify-contents-start align-items-center">
                                    <input className="form-check-input checkbox-lg"
                                           type="checkbox"
                                           onClick={(e:any) =>
                                               selectPermissions(e.target.checked, groupPermission.rolesGroupId, 'group',true)}
                                           checked={groupPermission.isSelected}/>
                                    <label className="fw-bold ms-3">{groupPermission.rolesGroupName}</label>
                                </td>
                            </tr>
                            {groupPermission?.childPermissions?.map(childPermissions => (
                                <tr>
                                    <td className="d-flex justify-content-between align-items-center text-wrap gap-3">
                                        <label>{childPermissions.name}</label>
                                        <input className="form-check-input checkbox-lg"
                                               type="checkbox"
                                               onClick={(e:any) =>
                                                   selectPermissions(e.target.checked, childPermissions.id, 'child',true)}
                                               checked={childPermissions.isSelected}/>
                                    </td>
                                </tr>
                            ))}
                        </>
                    ))}

                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default SelectUserPolicies;