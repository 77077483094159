import React, { useEffect, useState } from "react";
import AppHeader from "./AppHeader/AppHeader";
import AppFooter from "./AppFooter/AppFooter";
import AppSidebar from "./AppSideBar/AppSidebar";
import { connect, useDispatch } from "react-redux";
import {
  getClientDetailsByClientId,
  getRolesByUserId,
} from "../../actions/authAction";
import { getWindowSize } from "../../config/commonMethods";

const AppLayout = ({ children, roles, user, clientDetails }: any) => {

  const [windowSize, setWindowSize] = useState(getWindowSize());
  useEffect(() => { 
    function handleWindowResize() { setWindowSize(getWindowSize()); }
    window.addEventListener('resize', handleWindowResize); return () => { window.removeEventListener('resize', handleWindowResize); };
  }, []);

  useEffect(() => {

    if (windowSize.innerWidth <= 1200) {
      if ($(".layout-menu-expanded")[0]) {
        $("body").removeClass("layout-menu-expanded");
      }
    }
    if (windowSize.innerWidth >= 1200) {
      if ($(".layout-menu-expanded")[0]) {
        // $("body").addClass("layout-menu-expanded");
      }else
      {
        $("body").addClass("layout-menu-expanded");
       
      }
    }
  }, [windowSize.innerWidth]);

  const dispatch = useDispatch();
  useEffect(() => {
    if (roles === null || roles === undefined || roles.length < 0) {
      if (
        user &&
        user.isAgreeWithPrivacyPolicy &&
        user.isAgreeWithTermsCondition
      )
        dispatch(getRolesByUserId(user?.userId));
    }
    if (user && !user?.isSuperAdmin && !clientDetails) {
      dispatch(getClientDetailsByClientId(user?.clientId));
    }
  }, []);

  return (

    <div>
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <AppSidebar />
          <div className="layout-page layout-page-toggle">
            <AppHeader />
            <div className="content-wrapper">
              <div className="flex-grow-1 p-3">{children}</div>
              <AppFooter />

            </div>
            <div className="content-backdrop fade"></div>

          </div>
        </div>
      </div>
      <div className="layout-overlay layout-menu-toggle"></div>
    </div>
  );
};
const mapStateToProps = (state: any) => {
  return {
    roles: state?.auth?.roles,
    user: state?.auth?.user,
    clientDetails: state?.auth?.clientDetails,
  };
};
export default connect(mapStateToProps, null)(AppLayout);