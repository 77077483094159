import { actionType, apiResponseType } from "../../config/commonTypes";
import {
  GET_CRITERIA_LIST_BY_ID_FAILED,
  GET_CRITERIA_LIST_BY_ID_REQUEST,
  GET_TEMPLATE_BY_ID_FAILURE,
  GET_TEMPLATE_BY_ID_REQUEST,
  GET_TEMPLATE_BY_ID_SUCCESS,
  GET_CRITERIA_LIST_BY_ID_SUCCESS,
  UPDATE_CRITERIA_AUDIT_LOG_REQUEST,
  UPDATE_CRITERIA_AUDIT_LOG_SUCCESS,
  UPDATE_CRITERIA_AUDIT_LOG_FAILURE,
  GET_CRITERIA_AUDIT_LOG_BY_ID_REQUEST,
  GET_CRITERIA_AUDIT_LOG_BY_ID_SUCCESS,
  GET_CRITERIA_AUDIT_LOG_BY_ID_FAILURE,
  UPDATE_CRITERIA_LIST_VALUE,
} from "../../constants";
import { ICriteriaType, IGlobalTemplateType } from "./auditManagementReducer";
import { ILocalTemplateSchedule } from "./clientManagementReducer";

export interface ICorrectiveLogUpdateRequestType {
  correctiveActionLogId: number;
  localTemplateHeaderId: number;
  localTemplateCriteriaId: number;
  clientId: number;
  isComplies: null | number;
  findings: string;
  responsibleUserId: string;
  correctiveActions: string;
  isClosed: boolean | null;
  customCriteria?: string;
}
export interface CriteriaCorrectiveLog {
  clientUsers: ClientUser[] | undefined;
  completeDate: string | null;
  correctiveActionLogId: number;
  correctiveActions: string;
  findings: string;
  isClosed: null | boolean;
  isComplies: null | number;
  lineNo: number;
  localTemplateCriteriaCode: string;
  localTemplateCriteriaDescription: string;
  localTemplateCriteriaGroup: string;
  localTemplateCriteriaId: number;
  localTemplateHeaderId: number;
  responsibleUserId: string;
  startDate: string;
  responsibleUsername: string;
  responsibleUserFullName:string;
}
export interface ClientUser {
  userId: string;
  username: string;
  designation: string;
  responsibleUsername: string;
}
export interface ILocatlTemplateInterface {
  assignedUserId: string;
  clientId: number;
  clientUsers: ClientUser[];
  companyName: string;
  completedDate: string | null;
  globalTemplateHeaderId: number;
  isActive: boolean;
  isClosed: boolean;
  isDeleted: boolean;
  isGlobalTemplateModified: boolean;
  localSchedule: ILocalTemplateSchedule;
  localTemplateCode: string;
  localTemplateDescription: string;
  localTemplateHeaderId: number;
  localTemplateName: string;
  progress: string;
  startBy: string;
  startDate: string;
}

export interface ICriteriaCorrectiveLogResponseType extends apiResponseType {
  data: CriteriaCorrectiveLog;
}
export interface ICorrectiveLogInitialState {
  isLoading: boolean;
  selectedTemplate: ILocatlTemplateInterface | undefined;
  error: string;
  criteriaList: CriteriaCorrectiveLog[] | [];
}
const initialState: ICorrectiveLogInitialState = {
  isLoading: false,
  selectedTemplate: undefined,
  error: "",
  criteriaList: [],
};

export default function reducer(
  state: ICorrectiveLogInitialState = initialState,
  action: actionType
) {
  switch (action.type) {
    case GET_TEMPLATE_BY_ID_REQUEST:
      state.isLoading = true;
      return state;
    case GET_TEMPLATE_BY_ID_SUCCESS:
      state.isLoading = false;
      state.selectedTemplate = action?.payload;
      return { ...state };
    case GET_TEMPLATE_BY_ID_FAILURE:
      state.isLoading = false;
      state.error = action?.payload;
      return { ...state };

    case GET_CRITERIA_LIST_BY_ID_REQUEST:
      state.isLoading = true;
      return state;
    case GET_CRITERIA_LIST_BY_ID_SUCCESS:
      state.isLoading = false;
      state.criteriaList = action?.payload;
      return { ...state };
    case GET_CRITERIA_LIST_BY_ID_FAILED:
      state.isLoading = false;
      state.error = action?.payload;
      return { ...state };

    case UPDATE_CRITERIA_AUDIT_LOG_REQUEST:
      state.isLoading = true;
      return state;
    case UPDATE_CRITERIA_AUDIT_LOG_SUCCESS:
      state.isLoading = false;

      return { ...state };
    case UPDATE_CRITERIA_AUDIT_LOG_FAILURE:
      state.isLoading = false;
      state.error = action?.payload;
      return { ...state };

    case GET_CRITERIA_AUDIT_LOG_BY_ID_REQUEST:
      state.isLoading = true;
      return state;
    case GET_CRITERIA_AUDIT_LOG_BY_ID_SUCCESS:
      state.isLoading = false;

      return { ...state };
    case GET_CRITERIA_AUDIT_LOG_BY_ID_FAILURE:
      state.isLoading = false;
      state.error = action?.payload;
      return { ...state };

    case UPDATE_CRITERIA_LIST_VALUE: {
      state.criteriaList = updateCriteriaList(action?.payload, state.criteriaList);
      return { ...state };
    }
    default:
      return state;
  }
}

function updateCriteriaList(payload: CriteriaCorrectiveLog, criteriaList: CriteriaCorrectiveLog[]) {
  return criteriaList.map((criteria) =>
    criteria.localTemplateCriteriaId === payload.localTemplateCriteriaId ? payload : criteria
  );
}