import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../../../assets/img/logo/GIA-Blue-Transparent.png';
import { resetMessages } from "../../../messages/messages";


function ResetUserError() {
    const navigate = useNavigate();

    //redirect to login page
    useEffect(() => {
        const timer = setTimeout(() => navigate('/login'), 5000);
       // clearTimeout(timer);
      }, []);

    return (
        <div className="container-xxl">
                <div className="authentication-wrapper authentication-basic container-p-y">
                    <div className="authentication-inner">
                        <div className="card">
                            <div className="card-body  ">
                                <div className="app-brand rounded-top mb-0 justify-content-center">
                                    <div>
                                        <div className="text-center h-100">
                                            <img className="m-3" src={logo} width="80" height="80" />
                                        </div>
                                        <div className="text-center">
                                            <h6>Reset Your Password</h6>
                                        </div>
                                        <div className="bg-danger">
                                        <p className="text-white p-2">{resetMessages.Sorry_Your_password_reset_link_has_expired}</p>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
    );
}
export default ResetUserError