import {actionType, rolePermissionType, userType} from "../../config/commonTypes";
import {
    CREATE_USER_FAILURE,
    CREATE_USER_REQUEST,
    CREATE_USER_SUCCESS,
    DELETE_USER_FAILURE,
    DELETE_USER_REQUEST,
    DELETE_USER_SUCCESS,
    FETCH_USER_BY_ID_FAILURE,
    FETCH_USER_BY_ID_REQUEST,
    FETCH_USER_BY_ID_SUCCESS,
    FETCH_USERS_ACTIVITY_FAILURE,
    FETCH_USERS_ACTIVITY_REQUEST,
    FETCH_USERS_ACTIVITY_SUCCESS,
    FETCH_USERS_FAILURE,
    FETCH_USERS_REQUEST,
    FETCH_SELECTED_USER_ROLES_FAILURE,
    FETCH_SELECTED_USER_ROLES_REQUEST,
    FETCH_SELECTED_USER_ROLES_SUCCESS,
    FETCH_USERS_SUCCESS,
    RESET_USER_DATA_UM,
} from "../../constants";

// TODO : unwanted data should be removed


interface initialTypes {
    isLoading: boolean;
    users: userType[];
    inactiveusers: [];
}

const initialState: initialTypes = {
    isLoading: false,
    users: [],
    inactiveusers: [],
}
export default function reducer(state: initialTypes = initialState, action: actionType) {
    switch (action.type) {
        case FETCH_USERS_REQUEST:
            state.isLoading = true;
            return state;
        case FETCH_USERS_SUCCESS:
            state.isLoading = false;
            state.users = action?.payload?.data;
            return {...state};
        case FETCH_USERS_FAILURE:
            state.isLoading = false;
            return {...state};

        case CREATE_USER_REQUEST:
            state.isLoading = true;
            return {...state};
        case CREATE_USER_SUCCESS:
            state.isLoading = false;
            return {...state};
        case CREATE_USER_FAILURE:
            state.isLoading = false;
            return { ...state };

        case FETCH_USERS_ACTIVITY_REQUEST:
            state.isLoading = true;
            return state;
        case FETCH_USERS_ACTIVITY_SUCCESS:
            state.isLoading = false;
            state.inactiveusers = action?.payload?.data;
            return { ...state };
        case FETCH_USERS_ACTIVITY_FAILURE:
            state.isLoading = false;
            return { ...state };

        case DELETE_USER_REQUEST:
            state.isLoading = true;
            return { ...state };
        case DELETE_USER_SUCCESS:
            state.isLoading = false;
            return { ...state };
        case DELETE_USER_FAILURE:
            state.isLoading = true;
            return { ...state };

        case FETCH_USER_BY_ID_REQUEST:
            state.isLoading = true;
            return state;
        case FETCH_USER_BY_ID_SUCCESS:
            state.isLoading = false;
            return { ...state };
        case FETCH_USER_BY_ID_FAILURE:
            state.isLoading = false;
            return { ...state };

        case RESET_USER_DATA_UM:
            state = { ...initialState };
            return { ...state };


        case FETCH_SELECTED_USER_ROLES_REQUEST:
            state.isLoading = true;
            return state;
        case FETCH_SELECTED_USER_ROLES_SUCCESS:
            state.isLoading = false;
            return { ...state };
        case FETCH_SELECTED_USER_ROLES_FAILURE:
            state.isLoading = false;
            return { ...state };


        default:
            return state
    }
}

