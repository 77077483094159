import React from "react";

export const BootyPagination = ({
                             rowsPerPage,
                             rowCount,
                             onChangePage,
                             onChangeRowsPerPage, // available but not used here
                             currentPage
                         }) => {
    const handleBackButtonClick = () => {
        onChangePage(currentPage - 1);
    };

    const handleNextButtonClick = () => {
        onChangePage(currentPage + 1);
    };

    const handleNextEndButtonClick = () => {
        onChangePage(Math.ceil(rowCount / rowsPerPage));
    };
    const handleBackStartButtonClick = () => {
        onChangePage(1);
    };

    const handlePageNumber = (e) => {
        onChangePage(Number(e.target.value));
    };
    function getNumberOfPages(rowCount, rowsPerPage) {
        return Math.ceil(rowCount / rowsPerPage);
    }
    function toPages(pages) {
        const results = [];

        for (let i = 1; i <= pages; i++) {
            results.push(i);
        }

        return results;
    }
    const pages = getNumberOfPages(rowCount, rowsPerPage);
    const pageItems = toPages(pages);
    const nextDisabled = currentPage === pageItems.length;
    const previosDisabled = currentPage === 1;

    return (pageItems.length > 1 ? (
        <nav  aria-label="Page navigation" className="mt-2 ">
            <ul className="pagination justify-content-end pagination-sm mb-0">
                <li className="page-item first ">
                    <a className="page-link"
                       onClick={handleBackStartButtonClick}
                       style={previosDisabled ? {pointerEvents: 'none'}:{}}
                       aria-disabled={previosDisabled}
                       aria-label="previous page">
                        <i className="tf-icon bx bx-chevrons-left"></i>
                    </a>
                </li>
                <li className="page-item prev">
                    <a className="page-link"
                       onClick={handleBackButtonClick}
                       style={previosDisabled ? {pointerEvents: 'none'}:{}}
                       aria-disabled={previosDisabled}
                       aria-label="previous page"
                    ><i className="tf-icon bx bx-chevron-left"></i
                    ></a>
                </li>
                {pageItems.map((page) => {
                    const className =
                        page === currentPage ? "page-item active" : "page-item";

                    return (
                        <li key={page} className={className}>
                            <button
                                className="page-link"
                                onClick={handlePageNumber}
                                value={page}
                            >
                                {page}
                            </button>
                        </li>
                    );
                })}
                <li className="page-item next">
                    <a className="page-link"
                       onClick={handleNextButtonClick}
                       style={nextDisabled ? {pointerEvents: 'none'}:{}}
                       aria-disabled={nextDisabled}
                       aria-label="next page"
                    ><i className="tf-icon bx bx-chevron-right"></i
                    ></a>
                </li>
                <li className="page-item last">
                    <a className="page-link"
                       onClick={handleNextEndButtonClick}
                       style={nextDisabled ? {pointerEvents: 'none'}:{}}
                       aria-disabled={nextDisabled}
                       aria-label="next page"
                    ><i className="tf-icon bx bx-chevrons-right"></i
                    ></a>
                </li>
            </ul>
        </nav>
    ) : <></>
    );
};