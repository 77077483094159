export const userType = {
  superAdmin: "superAdmin",
  clientAdmin: "clientAdmin",
};
export const clientActionType = {
  create: "CREATE",
  update: "UPDATE",
  view: "VIEW",
};
export const userActionType = {
  create: "CREATE",
  update: "UPDATE",
  view: "VIEW",
};

export const createOrUpdateClientTabs = {
  CLIENT_DETAILS_TAB: "CLIENT_DETAILS_TAB",
  AUDIT_PLANNER_TAB: "AUDIT_PLANNER_TAB",
  USER_PERMISSIONS_TAB: "USER_PERMISSIONS_TAB",
};
export enum SELECT_USER_PERMISSION_TYPE {
  CREATE,
  UPDATE,
}
export const PHONE_REG_EEP : RegExp = /^[0-9-+()\s]+$/;

export const PASSWORD_REG_EEP =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;

export const ROOT_USER_URL = "/Users";
export const GET_ROLES_BY_USER_IDS = "/ApplicationRoles/ByUserIdList";

export const AUDIT_MODULE = {
  CREATE_AUDIT_TEMPLATE: "/GlobalTemplateHeaders",
  CREATE_CRITERIA_FOR_HEADER: "/GlobalTemplateCriterias",
  RE_ORDER_CRITERIA_LIST: "GlobalTemplateCriteriaPosition/Update",
  GET_LOCAL_TEMPLATE_CRITERIA_FOR_HEADER:
    "LocalTemplateCriteria/GetByTemplateHeaderId",
  GET_LOCAL_TEMPLATE_HEADER_BY_ID: "/LocalTemplateHeader",
  UPDATE_AUDIT_TEMPLATE_ASSIGNEES: "GlobalTemplateHeaders/UpdateAssignee",
  SET_CORRECTIVE_ACTION_AUDIT: "CorrctiveActionLog",
  ADD_OR_REMOVE_CLIENT: "GlobalTemplateHeaders/AorRClient",
};

export const GLOBAL_TEMPLATE_SCHEDULES_URLS = {
  UPDATE_BY_GLOBAL_TEMPLATE_ID: "GlobalTemplateSchedules",
  UPSERT_BY_GLOBAL_TEMPLATE_ID: "GlobalTemplateSchedules/Upsert",
};

export enum AccessControlType {
  DISABLE,
  HIDE,
}

export enum AuditPlannerTabs {
  AUDIT_PLANNER = "Audit Planner",
  AUDIT_MANAGEMENT = "Audit Templates",
  AUDIT_TEMPLATE = "Assign Templates",
}

//  AddUsers, ViewUsers,EditUsers,DeleteUsers
//  if Super admin have this permission, it means he/she has the permissions for client admin too
export enum UserPermissionsConstant {
  //Only for Super Admin
  AddSuperAdminUser = "AddUsers",
  ViewSuperAdminUser = "ViewUsers",
  EditSuperAdminUser = "EditUsers",
  DeleteSuperAdminUser = "DeleteUsers",
  EditClients = "EditClients",
  CreateAuditTemplates = "CreateAuditTemplates",
  AddClients = "AddClients",
  ViewClients = "ViewClients",
  DeleteClients = "DeleteClients",
  MimicAccess = "MimicAccess",
  ChangeAuditTemplates = "ChangeAuditTemplates",
  AssignTemplates = "AssignTemplates",

  //Only for Client
  AddClientUser = "AddUsers",
  EditClientUser = "EditUsers",
  DeleteClientUser = "DeleteUsers",
  ViewClientUser = "ViewUsers",
  DeleteAudit = "DeleteAudit",

  //  COMMON for both
  PrintAuditPlan = "PrintAuditPlan",
  ChangeAuditPlan = "ChangeAuditPlan",
  ViewPrintAudit = "ViewPrintAudit",
  PerformAudit = "PerformAudit",
}
