import React from 'react';
import {useNavigate} from "react-router-dom";

function NotFound(){
    const navigate = useNavigate();
    return (
        <div className="p-3 flex-grow-1">
            <div className="row">
                <div className="col">
                    <div className="card shadow-sm app-bg-secondry">
                        <div className="card-body notfound p-3 d-flex justify-content-center align-items-center">
                            <div className="notfound-container">
                                <div className="iconBG"></div>
                                <div className='text-center text-primary'>
                                    <h5 className="p-0 m-0 mb-4 app-color-light fw-light">Sorry, page not found</h5>
                                    <button className="btn btn-light"
                                       onClick={()=>navigate('/')}>Back to home</button>
                                </div>                               
                            </div>
                        </div>
                     </div>
                </div>
            </div>
        </div>      
    );
}export default NotFound;