import api from "../config/api";
import {
  AUTH_FETCH_CLIENT_DETAILS_FAILURE,
  AUTH_FETCH_CLIENT_DETAILS_REQUEST,
  AUTH_FETCH_CLIENT_DETAILS_SUCCESS,
  AUTH_FETCH_USER_ROLES_FAILURE,
  AUTH_FETCH_USER_ROLES_REQUEST,
  AUTH_FETCH_USER_ROLES_SUCCESS,
} from "../constants";

export function getRolesByUserId(userId: string): any {
  let url = "/ApplicationRoles/ByUserId?applicationUserId=" + userId;
  return (dispatch: any) => {
    dispatch({
      type: AUTH_FETCH_USER_ROLES_REQUEST,
    });
    return api.get(url).then(
      (response: any) => {
        dispatch({
          type: AUTH_FETCH_USER_ROLES_SUCCESS,
          payload: response,
        });
      },
      (error: any) =>
        dispatch({
          type: AUTH_FETCH_USER_ROLES_FAILURE,
          error,
        })
    );
  };
}

export function getClientDetailsByClientId(clientId: number): any {
  let url = "/Client/" + clientId;
  return (dispatch: any) => {
    dispatch({
      type: AUTH_FETCH_CLIENT_DETAILS_REQUEST,
    });
    return api.get(url).then(
      (response: any) => {
        dispatch({
          type: AUTH_FETCH_CLIENT_DETAILS_SUCCESS,
          payload: response,
        });
      },
      (error: any) =>
        dispatch({
          type: AUTH_FETCH_CLIENT_DETAILS_FAILURE,
          error,
        })
    );
  };
}
