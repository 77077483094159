import React, { useEffect, useState } from "react";
import AppLayout from "../../Layout/AppLayout";
import AuditManagement from "./AuditManagement";
import AssignTemplates from "./AssignTemplates";
import CustomModal from "../../../components/modal/CustomModal";
import AuditListWithSearch from "../home/AuditListWithSearch";
import SelectAssignModal from "./SelectAssignModal";
import {AccessControlType, AuditPlannerTabs, UserPermissionsConstant} from "../../../constants/constants";
import { useLocation, useNavigate } from "react-router-dom";
import AuditPlannerWrapperTab from "./AuditPlannerWrapperTab";
import { CREATE_AUDIT_TEMPLATE_SUCCESS } from "../../../constants";
import { useDispatch } from "react-redux";
import AccessControl from "../../../config/AccessControl";

interface IAuditPlannerProps {
  selectedTab?: AuditPlannerTabs;
}
function InternalAuditPlanner({
  selectedTab = AuditPlannerTabs.AUDIT_PLANNER,
}: IAuditPlannerProps) {
  const dispatch = useDispatch();

  const [tabs, setTabs] = useState<
    | AuditPlannerTabs.AUDIT_PLANNER
    | AuditPlannerTabs.AUDIT_MANAGEMENT
    | AuditPlannerTabs.AUDIT_TEMPLATE
  >(selectedTab);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

  const urlHiddenState: any = useLocation().state;
  const navigate = useNavigate();

  React.useEffect(() => {
    if (urlHiddenState?.tab === AuditPlannerTabs.AUDIT_PLANNER) {
      const tabsDomElement = document.querySelector(
        AuditPlannerTabs.AUDIT_PLANNER
      ) as HTMLElement | null;
      if (tabsDomElement) {
        tabsDomElement?.click();
      }
    }
  }, [urlHiddenState]);

  useEffect(() => {
    if (AuditPlannerTabs.AUDIT_PLANNER === selectedTab) {
      const tabsDomElement = document.querySelector(
        ".AUDIT_PLANNER"
      ) as HTMLElement | null;
      if (tabsDomElement) {
        tabsDomElement?.click();
      }
    }
    if (AuditPlannerTabs.AUDIT_MANAGEMENT === selectedTab) {
      const tabsDomElement = document.querySelector(
        ".AUDIT_MANAGEMENT"
      ) as HTMLElement | null;
      if (tabsDomElement) {
        tabsDomElement?.click();
      }
    }
    if (AuditPlannerTabs.AUDIT_TEMPLATE === selectedTab) {
      const tabsDomElement = document.querySelector(
        ".AUDIT_TEMPLATE"
      ) as HTMLElement | null;
      if (tabsDomElement) {
        tabsDomElement?.click();
      }
    }
  }, [selectedTab]);

  return (
    <AppLayout>
      <>
        {isOpenModal && tabs === "Audit Templates" && (
          <CustomModal
            size="md"
            isClose={!isOpenModal}
            title="Select Audit To Complete"
            onClose={() => setIsOpenModal(false)}
          >
            <AuditListWithSearch />
          </CustomModal>
        )}
        {isOpenModal && tabs === "Audit Templates" && (
          <CustomModal
            size="lg"
            isClose={!isOpenModal}
            title="Select Assign"
            onClose={() => setIsOpenModal(false)}
          >
            <SelectAssignModal />
          </CustomModal>
        )}
        <div className="row">
          {/*Custom style1 Breadcrumb */}
          <div className="col-md">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb breadcrumb-style1 d-flex align-items-center">
                <li className="breadcrumb-item d-flex align-items-center">
                  <a href="javascript:void(0);">Home</a>
                              </li>
                              <li className="breadcrumb-item d-flex align-items-center">
                                  <a href="javascript:void(0);">Audit Management</a>
                              </li>
                <li className="breadcrumb-item d-flex align-items-center active">
                  {tabs}
                </li>
              </ol>
            </nav>
          </div>
        </div>
        {/*Audit Detail Header*/}
        <div className="row">
          <div className="nav-align-top">
            <ul className="nav nav-tabs" role="tablist">
              <li className="nav-item">
              <AccessControl type={AccessControlType.DISABLE}
                               allowedPermissions={[
                                UserPermissionsConstant.ChangeAuditPlan,
                                UserPermissionsConstant.PrintAuditPlan
                                ]}>
                <button
                  type="button"
                  className={`nav-link active AUDIT_PLANNER`}
                  role="tab"
                  data-bs-toggle="tab"
                  data-bs-target="#navs-audit-planner"
                  aria-controls="navs-audit-planner"
                  aria-selected="true"
                  onClick={() => {
                    setTabs(AuditPlannerTabs.AUDIT_PLANNER);
                    navigate("/internal-audit-planner");
                  }}
                >
                  <h6 className="m-0">Audit Planner</h6>
                </button>
                </AccessControl>
              </li>
              <li className="nav-item">
              <AccessControl type={AccessControlType.DISABLE}
                               allowedPermissions={[
                                UserPermissionsConstant.ChangeAuditTemplates,
                                UserPermissionsConstant.CreateAuditTemplates
                                ]}>
                <button
                  type="button"
                  className={`nav-link AUDIT_MANAGEMENT`}
                  role="tab"
                  data-bs-toggle="tab"
                  data-bs-target="#navs-audit-management"
                  aria-controls="navs-audit-management"
                  aria-selected="false"
                  onClick={() => {
                    dispatch({
                      type: CREATE_AUDIT_TEMPLATE_SUCCESS,
                      payload: undefined,
                    });
                    setTabs(AuditPlannerTabs.AUDIT_MANAGEMENT);
                    navigate("/internal-audit-management");
                  }}
                >
                  <h6 className="m-0">Audit Templates</h6>
                </button>
                </AccessControl>
              </li>
              <li className="nav-item">
                <AccessControl type={AccessControlType.DISABLE}
                               allowedPermissions={[UserPermissionsConstant.AssignTemplates]}>
                <button
                  type="button"
                  className="nav-link AUDIT_TEMPLATE"
                  role="tab"
                  data-bs-toggle="tab"
                  data-bs-target="#navs-audit-templates"
                  aria-controls="navs-audit-templates"
                  aria-selected="false"
                  onClick={() => {
                    dispatch({
                      type: CREATE_AUDIT_TEMPLATE_SUCCESS,
                      payload: undefined,
                    });
                    setTabs(AuditPlannerTabs.AUDIT_TEMPLATE);
                    navigate("/internal-audit-assign-templates");
                  }}
                >
                  <h6 className="m-0">Assign Templates</h6>
                </button>
                </AccessControl>
              </li>
            </ul>
            <div className="tab-content p-3">
              <div
                className="tab-pane fade show active"
                id="navs-audit-planner"
                role="tabpanel"
              >
                {tabs === AuditPlannerTabs.AUDIT_PLANNER && (
                  <AuditPlannerWrapperTab />
                )}
              </div>
              <div
                className="tab-pane fade"
                id="navs-audit-management"
                role="tabpanel"
              >
                {tabs === AuditPlannerTabs.AUDIT_MANAGEMENT && (
                  <AuditManagement
                    criteriaList={[]}
                    openAddNewTemplate={() => {}}
                    clients={[]}
                    templateList={[]}
                  />
                )}
              </div>
              <div
                className="tab-pane fade"
                id="navs-audit-templates"
                role="tabpanel"
              >
                {tabs === AuditPlannerTabs.AUDIT_TEMPLATE && (
                  <AssignTemplates
                    openAssignToModal={() => setIsOpenModal(true)}
                    templateList={[]}
                    clients={[]}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="col-md"></div>
        </div>
      </>
    </AppLayout>
  );
}

export default InternalAuditPlanner;
