import React from 'react';

interface propsType {
    type?: 'multiple' | 'binary';
    binaryBtnStyle?: string;
    binaryBtnSize?: 'xs' | 'sm' | 'lg' | 'xl';
    binaryItemBtnSize?: 'mx-0' | 'mx-1' | 'mx-2' | 'mx-3' | 'mx-4' | 'mx-5' | 'mx-auto';
    multipleBtnSize?: 'sm' | 'lg'
    data?: dummyData[];
    selectedValue?: string;
    onChange?: (value: any) => void;
}

interface dummyData {
    name: string,
    id: string
}

const dummyDats: dummyData[] = [
    {
        name: 'dummy',
        id: '1'
    }, {
        name: 'dummy2',
        id: '2'
    },
]

function Dropdown({
                      type = 'multiple',
                      binaryBtnStyle = "btn-outline-primary",
                      binaryBtnSize = "xs",
                      binaryItemBtnSize = 'mx-0',
                      multipleBtnSize = "sm",
                      data = [...dummyDats],
                      selectedValue = '1',
                      onChange
                  }: propsType) {

    const changeValue = (value: any) => {
        onChange && onChange(value)
        console.log(value)
    }

    return (
        <>
            {type === 'binary' && (
                <>
                    <button
                        type="button"
                        className={`btn  btn-${binaryBtnSize} ${binaryBtnStyle} dropdown-toggle`}
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                    >
                        Yes
                    </button>
                    <ul className="dropdown-menu m-0 p-0">
                        <li className="border-bottom">
                            <a className={`dropdown-item p-1 px-2 ${binaryItemBtnSize}`}
                               href="javascript:void(0);">Yes</a>
                        </li>
                        <li>
                            <a className={`dropdown-item p-1 px-2 ${binaryItemBtnSize}`}
                               href="javascript:void(0);">No </a>
                        </li>
                    </ul>
                </>
            )}
            {type === 'multiple' && data && data.length > 0 && (
                <>
                    <select id="smallSelect" className={`form-select form-select-${multipleBtnSize}`}
                            onChange={(event) => changeValue(event.target.value)}>
                        <option>&nbsp;</option>
                        {data.map(data => (
                            <option selected={selectedValue === data.id ? true : false} key={data.id}
                                    value={data.id}>{data.name}</option>
                        ))}

                    </select>
                </>
            )}

        </>
    );
}

export default Dropdown