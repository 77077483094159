import React, { useEffect, useState } from "react";
import DataTable, { TableStyles } from "react-data-table-component";
import { BootyPagination } from "./pagination/BootyPagination";
import {
  ILocalTemplateSchedule,
  ILocalTemplateType,
} from "../redux/modules/clientManagementReducer";
import AccessControl from "../config/AccessControl";
import {
  AccessControlType,
  UserPermissionsConstant,
} from "../constants/constants";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { AddorRemoveClient } from "../actions/auditActions";
import { toast } from "react-toastify";
import _ from "lodash";
import { sortByString } from "../config/commonMethods";

interface IAuditPlannerTabProps {
  data: ILocalTemplateType[];
  monthsArray: IMonthsType[];
  onChange: (data: ILocalTemplateSchedule) => void;
  isSuperAdmin?: boolean;
  refresh?: Function;
  setSortData: (data:any) => void;
}

export interface IMonthsType {
  text: string;
  longText: string;
  value: string;
}

const AuditPlannerForLocal = ({
  data,
  monthsArray,
  onChange,
  isSuperAdmin,
  refresh,
  setSortData
}: IAuditPlannerTabProps) => {
  const dispatch = useDispatch();
  const [months, setMonths] = useState<IMonthsType[] | []>([]);
  const [changedSchedule, setChangedSchedule] = useState<
    ILocalTemplateSchedule | undefined
  >(undefined);
  const [localTemplates, setLocalTemplates] = useState<
    ILocalTemplateType[] | []
  >([]);
  const [dataTableFormat, setDataTableFormat] = useState<any>();

  useEffect(() => {
    setMonths(monthsArray);
  }, [monthsArray]);

  useEffect(() => {
    setChangedSchedule(undefined);
    if (data) {
      setLocalTemplates(data);
      const dataSet = formatDataTable(data);
      setDataTableFormat(dataSet);
    }
  }, [data]);

  const formatDataTable = (templates: ILocalTemplateType[]) => {
    return templates?.map((template: ILocalTemplateType) => {
      return {
        ...(isSuperAdmin && { action: removeButton(template) }),
        localTemplateHeaderId: template.localTemplateHeaderId,
        january: addCheckBox(template, "january"),
        february: addCheckBox(template, "february"),
        march: addCheckBox(template, "march"),
        april: addCheckBox(template, "april"),
        may: addCheckBox(template, "may"),
        june: addCheckBox(template, "june"),
        july: addCheckBox(template, "july"),
        august: addCheckBox(template, "august"),
        september: addCheckBox(template, "september"),
        october: addCheckBox(template, "october"),
        november: addCheckBox(template, "november"),
        december: addCheckBox(template, "december"),
        ...template,
      };
    }).filter((template: ILocalTemplateType) =>  isSuperAdmin ? true: !template.isRemoved );
  };

  const AddorRemoveClientPlanner = (
    template: ILocalTemplateType,
    remove: boolean
  ) => {
    console.log(remove);
    dispatch(
      AddorRemoveClient(
        {
          clientId: template.clientId!!,
          globalTemplateHeaderId: template.globalTemplateHeaderId!!,
          isActiveToClient: remove,
        },
        (error) => {
          toast.error(error?.message ? error?.message : "Audit update Failed!");
        },
        (response: any) => {
          toast.success(
            response?.message ? response?.message : "Audit update Success!"
          );
          refresh && refresh();
        }
      )
    );
  };

  const removeButton = (template: ILocalTemplateType) => {
    // TODO Please add the allowedPermissions
    return (
      // <AccessControl type={AccessControlType.DISABLE}
      //                allowedPermissions={[UserPermissionsConstant.DeleteClients]}>
      <div
        className="form-check form-switch d-flex justify-content-center
                align-items-center "
      >
        <input
          name="isActive"
          id="isActive"
          className="form-check-input"
          type="checkbox"
          checked={template.isRemoved}
          disabled={!template?.isGlobal}
          onChange={(e) => {
            AddorRemoveClientPlanner(template, !template.isRemoved);
          }}
        />
      </div>
      // </AccessControl>
    );
  };

  const addCheckBox = (template: ILocalTemplateType | any, key: string) => {
    return (
      <td>
        <div className="form-check d-flex justify-content-center">
          <input
            className="pointer form-check-input checkbox-lg"
            style={template?.isActive ? {} : { opacity: 0.5 }}
            type="checkbox"
            onChange={(event) => {
              onChangeCheckBox(template, key, event.target.checked);
            }}
            checked={template.localSchedule[key]}
          />
        </div>
      </td>
    );
  };

  useEffect(() => {
    if (changedSchedule) {
      const updatedLocalTemplates = data.map(
        (localTemplateSchedule: ILocalTemplateType) => ({
          ...localTemplateSchedule,
          localSchedule:
            changedSchedule.localTemplateHeaderId ===
            localTemplateSchedule.localTemplateHeaderId
              ? changedSchedule
              : localTemplateSchedule.localSchedule,
        })
      );
      console.log(updatedLocalTemplates);
      setLocalTemplates(updatedLocalTemplates);
      const dataSet = formatDataTable(updatedLocalTemplates);
      setDataTableFormat(dataSet);
    }
  }, [changedSchedule]);

  const onChangeCheckBox = (
    localTemplate: ILocalTemplateType,
    month: string,
    isSelected: boolean
  ) => {
    const { localSchedule, localTemplateHeaderId } = localTemplate;
    let localTemplateScheduleLocal: ILocalTemplateSchedule | undefined =
      undefined;
    if (localSchedule) {
      localTemplateScheduleLocal = {
        ...localSchedule,
        [month]: isSelected,
      };
    }
    setChangedSchedule(localTemplateScheduleLocal);
    localTemplateScheduleLocal && onChange(localTemplateScheduleLocal);
  };

  const monthsColumns = monthsArray.map((month) => ({
    name: month.text,
    selector: (row: any) => row[month.value],
    sortable: false,
    width: "60px",
  }));
  const customStyles: TableStyles = {
    headRow: {
      style: { backgroundColor: "#f2f2f2", border: "1px solid #d9dee3" },
    },
    rows: {
      style: { border: "1px solid #d9dee3" },
    },
  };

  const addActionCol = () => {
    return !!isSuperAdmin
      ? [
          {
            name: "Disabled",
            selector: (row: any) => row.action,
            sortable: false,
            width: "90px",
          },
        ]
      : [];
  };
  const columns: any = [
    ...addActionCol(),
    {
      name: "Code",
      id: 'localTemplateCode',
      selector: (row: any) => row.localTemplateCode,
      sortable: true,
    },
    {
      name: "Audit Name",
      selector: (row: any) => row.localTemplateName,
      sortable: true,
      minWidth: "300px",
      wrap: true,
    },
    ...monthsColumns,
  ];

  return (
    <div className="card shadow-none p-0 m-1 mt-0">
      <div className="card-body p-0 m-0">
        <div className="table-responsive text-nowrap">
            <DataTable
              title=""
              columns={columns}
              data={dataTableFormat}
              defaultSortFieldId="localTemplateCode"
              pagination={true}
              paginationPerPage={10}
              paginationComponent={BootyPagination}
              selectableRows={false}
              customStyles={customStyles}
              onSort={(selectedColumn, sortDirection, sortedRows)=>{
                setSortData(sortedRows);
              }}
            />
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state: any) => {
  const isSuperAdmin = state?.auth?.user?.isSuperAdmin;
  return { isSuperAdmin };
};
export default connect(mapStateToProps, null)(AuditPlannerForLocal);
