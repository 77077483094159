export const commonMessages = {
    Email_is_required: "Email is required",
}


export const resetMessages = {
    Successfully_ResetPassword:"Successfully ResetPassword!",
    ResetPassword_Link: "ResetPassword Link!",
    Sorry_Your_password_reset_link_has_expired:"Sorry, Your password reset link has expired.",
    Successfully_ResetPassword_Link:"Successfully ResetPassword Link!",
    We_have_emailed_your_password_reset_link : "We have emailed your password reset link!"
}