import api from "../config/api";
import { apiResponseType, startAuditType } from "../config/commonTypes";
import {
  ADD_OR_DELETE_CLIENT_FAILURE,
  ADD_OR_DELETE_CLIENT_REQUEST,
  ADD_OR_DELETE_CLIENT_SUCCESS,
  CREATE_AUDIT_TEMPLATE_FAILURE,
  CREATE_AUDIT_TEMPLATE_REQUEST,
  CREATE_AUDIT_TEMPLATE_SUCCESS,
  CREATE_CRITERIA_FOR_TEMPLATE_REQUEST,
  CREATE_CRITERIA_FOR_TEMPLATE_REQUEST_FAILURE,
  CREATE_CRITERIA_FOR_TEMPLATE_REQUEST_SUCCESS,
  CRITERIA_FOR_TEMPLATE_REQUEST,
  CRITERIA_FOR_TEMPLATE_REQUEST_FAILURE,
  CRITERIA_FOR_TEMPLATE_REQUEST_SUCCESS,
  DELETE_CRITERIA_FAILED,
  DELETE_CRITERIA_REQUEST,
  DELETE_CRITERIA_SUCCESS,
  EDIT_AUDIT_TEMPLATE_FAILURE,
  EDIT_AUDIT_TEMPLATE_REQUEST,
  EDIT_AUDIT_TEMPLATE_SUCCESS,
  EDIT_LOCAL_TEMPLATE_ASSIGNEE_LIST_FAILURE,
  EDIT_LOCAL_TEMPLATE_ASSIGNEE_LIST_REQUEST,
  EDIT_LOCAL_TEMPLATE_ASSIGNEE_LIST_SUCCESS,
  GET_AUDIT_TEMPLATES_FAILURE,
  GET_AUDIT_TEMPLATES_FOR_PLANNING_FAILURE,
  GET_AUDIT_TEMPLATES_FOR_PLANNING_REQUEST,
  GET_AUDIT_TEMPLATES_FOR_PLANNING_SUCCESS,
  GET_AUDIT_TEMPLATES_REQUEST,
  GET_AUDIT_TEMPLATES_SUCCESS,
  GET_CRITERIA_AUDIT_LOG_BY_ID_FAILURE,
  GET_CRITERIA_AUDIT_LOG_BY_ID_REQUEST,
  GET_CRITERIA_AUDIT_LOG_BY_ID_SUCCESS,
  GET_CRITERIA_LIST_BY_ID_FAILED,
  GET_CRITERIA_LIST_BY_ID_REQUEST,
  GET_CRITERIA_LIST_BY_ID_SUCCESS,
  GET_TEMPLATE_BY_ID_FAILURE,
  GET_TEMPLATE_BY_ID_REQUEST,
  GET_TEMPLATE_BY_ID_SUCCESS,
  UPDATE_AUDIT_ASSIGNEE_FAILURE,
  UPDATE_AUDIT_ASSIGNEE_REQUEST,
  UPDATE_AUDIT_ASSIGNEE_SUCCESS,
  UPDATE_CRITERIA_AUDIT_LOG_FAILURE,
  UPDATE_CRITERIA_AUDIT_LOG_REQUEST,
  UPDATE_CRITERIA_AUDIT_LOG_SUCCESS,
  UPDATE_CRITERIA_FAILED,
  UPDATE_CRITERIA_ORDER_FAILED,
  UPDATE_CRITERIA_ORDER_REQUEST,
  UPDATE_CRITERIA_ORDER_SUCCESS,
  UPDATE_CRITERIA_REQUEST,
  UPDATE_CRITERIA_SUCCESS,
} from "../constants";
import {
  AUDIT_MODULE,
  GLOBAL_TEMPLATE_SCHEDULES_URLS,
} from "../constants/constants";
import {
  ICorrectiveLogUpdateRequestType,
  ICriteriaCorrectiveLogResponseType,
} from "../redux/modules/auditCorrectiveLogReducer";
import {
  IAuditCreationResponseType,
  ICriteriaListResponseType,
  ICriteriaResponseType,
  ICriteriaType,
  IGlobalTemplateSchedule,
  IGlobalTemplateType,
} from "../redux/modules/auditManagementReducer";
import {
  IAddorRemoveClient,
  ILocalTemplateSchedule,
  ILocalTemplateType,
  ILocalTemplateUpdateType,
} from "../redux/modules/clientManagementReducer";

export function CreateAuditTemplate(
  template: IGlobalTemplateType,
  onSuccess: (data: IAuditCreationResponseType) => void,
  onFailure: (data: apiResponseType) => void
): any {
  return (dispatch: any) => {
    dispatch({
      type: CREATE_AUDIT_TEMPLATE_REQUEST,
      payload: "",
    });
    return api.post(AUDIT_MODULE.CREATE_AUDIT_TEMPLATE, template).then(
      (response: any) => {
        dispatch({
          type: CREATE_AUDIT_TEMPLATE_SUCCESS,
          payload: response.data,
        });
        onSuccess && onSuccess(response as IAuditCreationResponseType);
      },
      (error: any) => {
        dispatch({
          type: CREATE_AUDIT_TEMPLATE_FAILURE,
        });
        onFailure && onFailure(error);
      }
    );
  };
}

export function GetTemplateByID(
  id: string,
  onFailure: (data: apiResponseType) => void
): any {
  let url = AUDIT_MODULE.CREATE_AUDIT_TEMPLATE + "/" + id;
  return (dispatch: any) => {
    dispatch({
      type: CREATE_AUDIT_TEMPLATE_REQUEST,
      payload: "",
    });
    return api.get(url).then(
      (response: any) => {
        dispatch({
          type: CREATE_AUDIT_TEMPLATE_SUCCESS,
          payload: response.data,
        });
      },
      (error: any) => {
        dispatch({
          type: CREATE_AUDIT_TEMPLATE_FAILURE,
        });
        onFailure && onFailure(error);
      }
    );
  };
}

export function GetTemplateByIDForCorrectiveLog(
  id: string,
  onFailure: (data: apiResponseType) => void
): any {
  let url = AUDIT_MODULE.GET_LOCAL_TEMPLATE_HEADER_BY_ID + "/" + id;
  return (dispatch: any) => {
    dispatch({
      type: GET_TEMPLATE_BY_ID_REQUEST,
      payload: "",
    });
    return api.get(url).then(
      (response: any) => {
        dispatch({
          type: GET_TEMPLATE_BY_ID_SUCCESS,
          payload: response.data,
        });
      },
      (error: any) => {
        dispatch({
          type: GET_TEMPLATE_BY_ID_FAILURE,
        });
        onFailure && onFailure(error);
      }
    );
  };
}

export function EditLocalTemplateHeader(
  id: number,
  template: ILocalTemplateUpdateType,
  onFailure: (data: apiResponseType) => void,
  onSuccess: (data: apiResponseType) => void
): any {
  let url = AUDIT_MODULE.GET_LOCAL_TEMPLATE_HEADER_BY_ID + "/" + id;
  return (dispatch: any) => {
    dispatch({
      type: EDIT_LOCAL_TEMPLATE_ASSIGNEE_LIST_REQUEST,
      payload: "",
    });
    return api.put(url, template).then(
      (response: any) => {
        dispatch({
          type: EDIT_LOCAL_TEMPLATE_ASSIGNEE_LIST_SUCCESS,
          payload: response.data,
        });
        onSuccess && onSuccess(response as apiResponseType);
      },
      (error: any) => {
        dispatch({
          type: EDIT_LOCAL_TEMPLATE_ASSIGNEE_LIST_FAILURE,
        });
        onFailure && onFailure(error);
      }
    );
  };
}

export function AddorRemoveClient(
  client: IAddorRemoveClient,
  onFailure: (data: apiResponseType) => void,
  onSuccess: (data: apiResponseType) => void
): any {
  let url = AUDIT_MODULE.ADD_OR_REMOVE_CLIENT;
  return (dispatch: any) => {
    dispatch({
      type: ADD_OR_DELETE_CLIENT_REQUEST,
      payload: "",
    });
    return api.put(url, client).then(
      (response: any) => {
        dispatch({
          type: ADD_OR_DELETE_CLIENT_SUCCESS,
          payload: response.data,
        });
        onSuccess && onSuccess(response as apiResponseType);
      },
      (error: any) => {
        dispatch({
          type: ADD_OR_DELETE_CLIENT_FAILURE,
        });
        onFailure && onFailure(error);
      }
    );
  };
}

export function editAuditTemplate(
  template: IGlobalTemplateType,
  onSuccess: (data: IAuditCreationResponseType) => void,
  onFailure: (data: apiResponseType) => void
): any {
  return (dispatch: any) => {
    dispatch({
      type: EDIT_AUDIT_TEMPLATE_REQUEST,
      payload: "",
    });
    return api.put(AUDIT_MODULE.CREATE_AUDIT_TEMPLATE, template).then(
      (response: any) => {
        dispatch({
          type: EDIT_AUDIT_TEMPLATE_SUCCESS,
          payload: response.data,
        });
        onSuccess && onSuccess(response as IAuditCreationResponseType);
      },
      (error: any) => {
        dispatch({
          type: EDIT_AUDIT_TEMPLATE_FAILURE,
        });
        onFailure && onFailure(error);
      }
    );
  };
}

export function updateAuditAssignees(
  template: IGlobalTemplateType,
  onSuccess: (data: IAuditCreationResponseType) => void,
  onFailure: (data: apiResponseType) => void
): any {
  return (dispatch: any) => {
    dispatch({
      type: UPDATE_AUDIT_ASSIGNEE_REQUEST,
      payload: "",
    });
    return api.put(AUDIT_MODULE.UPDATE_AUDIT_TEMPLATE_ASSIGNEES, template).then(
      (response: any) => {
        dispatch({
          type: UPDATE_AUDIT_ASSIGNEE_SUCCESS,
          payload: response.data,
        });
        onSuccess && onSuccess(response as IAuditCreationResponseType);
      },
      (error: any) => {
        dispatch({
          type: UPDATE_AUDIT_ASSIGNEE_FAILURE,
        });
        onFailure && onFailure(error);
      }
    );
  };
}

export function GetAuditTemplates(): any {
  return (dispatch: any) => {
    dispatch({
      type: GET_AUDIT_TEMPLATES_REQUEST,
      payload: "",
    });
    return api.get(AUDIT_MODULE.CREATE_AUDIT_TEMPLATE).then(
      (response: any) => {
        dispatch({
          type: GET_AUDIT_TEMPLATES_SUCCESS,
          payload: response.data,
        });
      },
      (error: any) => {
        dispatch({
          type: GET_AUDIT_TEMPLATES_FAILURE,
        });
      }
    );
  };
}

export function CreateCriteriaForHeader(
  template: ICriteriaType,
  onSuccess: (data: ICriteriaResponseType) => void,
  onFailure: (data: apiResponseType) => void
): any {
  return (dispatch: any) => {
    dispatch({
      type: CREATE_CRITERIA_FOR_TEMPLATE_REQUEST,
      payload: "",
    });
    return api.post(AUDIT_MODULE.CREATE_CRITERIA_FOR_HEADER, template).then(
      (response: any) => {
        dispatch({
          type: CREATE_CRITERIA_FOR_TEMPLATE_REQUEST_SUCCESS,
          payload: response.data,
        });
        onSuccess && onSuccess(response as ICriteriaResponseType);
      },
      (error: any) => {
        dispatch({
          type: CREATE_CRITERIA_FOR_TEMPLATE_REQUEST_FAILURE,
        });
        onFailure && onFailure(error);
      }
    );
  };
}

export function ChangeCriteriaOrder(
  template: ICriteriaType[],
  onSuccess: (data: ICriteriaListResponseType) => void,
  onFailure: (data: apiResponseType) => void
): any {
  return (dispatch: any) => {
    dispatch({
      type: UPDATE_CRITERIA_ORDER_REQUEST,
      payload: "",
    });
    return api.put(AUDIT_MODULE.RE_ORDER_CRITERIA_LIST, template).then(
      (response: any) => {
        dispatch({
          type: UPDATE_CRITERIA_ORDER_SUCCESS,
          payload: response.data,
        });
        onSuccess && onSuccess(response as ICriteriaListResponseType);
      },
      (error: any) => {
        dispatch({
          type: UPDATE_CRITERIA_ORDER_FAILED,
        });
        onFailure && onFailure(error);
      }
    );
  };
}

export function UpdateCriteriaforHeader(
  criteria: ICriteriaType,
  onSuccess: (data: ICriteriaResponseType) => void,
  onFailure: (data: apiResponseType) => void
): any {
  return (dispatch: any) => {
    dispatch({
      type: UPDATE_CRITERIA_REQUEST,
      payload: "",
    });
    return api.put(AUDIT_MODULE.CREATE_CRITERIA_FOR_HEADER, criteria).then(
      (response: any) => {
        dispatch({
          type: UPDATE_CRITERIA_SUCCESS,
          payload: response.data,
        });
        onSuccess && onSuccess(response as ICriteriaResponseType);
      },
      (error: any) => {
        dispatch({
          type: UPDATE_CRITERIA_FAILED,
        });
        onFailure && onFailure(error);
      }
    );
  };
}

export function DeleteCriteria(
  id: number,
  onSuccess: (data: ICriteriaResponseType) => void,
  onFailure: (data: apiResponseType) => void
): any {
  let url = AUDIT_MODULE.CREATE_CRITERIA_FOR_HEADER + "/" + id;
  return (dispatch: any) => {
    dispatch({
      type: DELETE_CRITERIA_REQUEST,
      payload: "",
    });
    return api.delete(url).then(
      (response: any) => {
        dispatch({
          type: DELETE_CRITERIA_SUCCESS,
          payload: response.data,
        });
        onSuccess && onSuccess(response as ICriteriaResponseType);
      },
      (error: any) => {
        dispatch({
          type: DELETE_CRITERIA_FAILED,
        });
        onFailure && onFailure(error);
      }
    );
  };
}

export function getCriteriaList(
  headerID: number,
  onSuccess: (data: apiResponseType) => void,
  onFailure: (data: apiResponseType) => void
): any {
  let url =
    AUDIT_MODULE.CREATE_CRITERIA_FOR_HEADER +
    "?globaltemplateheaderid=" +
    headerID;
  return (dispatch: any) => {
    dispatch({
      type: CRITERIA_FOR_TEMPLATE_REQUEST,
      payload: "",
    });
    return api.get(url).then(
      (response: any) => {
        dispatch({
          type: CRITERIA_FOR_TEMPLATE_REQUEST_SUCCESS,
          payload: response.data,
        });
        onSuccess(response as apiResponseType)
      },
      (error: any) =>{
        dispatch({
          type: CRITERIA_FOR_TEMPLATE_REQUEST_FAILURE,
          error,
        });
        onFailure(error.response.data as apiResponseType);
      }
    );
  };
}

export function getCriteriaListForCorrectiveLog(
  headerID: number,
  onFailure: (data: apiResponseType) => void
): any {
  let url =
    AUDIT_MODULE.GET_LOCAL_TEMPLATE_CRITERIA_FOR_HEADER +
    "?templateHeaderId=" +
    headerID;
  return (dispatch: any) => {
    dispatch({
      type: GET_CRITERIA_LIST_BY_ID_REQUEST,
      payload: "",
    });
    return api.get(url).then(
      (response: any) => {
        dispatch({
          type: GET_CRITERIA_LIST_BY_ID_SUCCESS,
          payload: response.data,
        });
      },
      (error: any) =>
        dispatch({
          type: GET_CRITERIA_LIST_BY_ID_FAILED,
          error,
        })
    );
  };
}

export function setCorrectiveActionLog(
  corrctiveActionLog: ICorrectiveLogUpdateRequestType,
  onFailure: (data: apiResponseType) => void,
  onSuccess: (data: ICriteriaCorrectiveLogResponseType) => void
): any {
  return (dispatch: any) => {
    dispatch({
      type: UPDATE_CRITERIA_AUDIT_LOG_REQUEST,
      payload: "",
    });
    return api
      .put(AUDIT_MODULE.SET_CORRECTIVE_ACTION_AUDIT, corrctiveActionLog)
      .then(
        (response: any) => {
          dispatch({
            type: UPDATE_CRITERIA_AUDIT_LOG_SUCCESS,
            payload: response.data,
          });
          onSuccess &&
            onSuccess(response as ICriteriaCorrectiveLogResponseType);
        },
        (error: any) => {
          dispatch({
            type: UPDATE_CRITERIA_AUDIT_LOG_FAILURE,
            error,
          });
          onFailure && onFailure(error);
        }
      );
  };
}

export function getCriteriaAuditLogByID(
  id: number,
  onFailure: (data: apiResponseType) => void,
  onSuccess: (data: ICriteriaCorrectiveLogResponseType) => void
): any {
  let url = AUDIT_MODULE.SET_CORRECTIVE_ACTION_AUDIT + "/" + id;
  return (dispatch: any) => {
    dispatch({
      type: GET_CRITERIA_AUDIT_LOG_BY_ID_REQUEST,
      payload: "",
    });
    return api.get(url).then(
      (response: any) => {
        dispatch({
          type: GET_CRITERIA_AUDIT_LOG_BY_ID_SUCCESS,
          payload: response.data,
        });
        onSuccess && onSuccess(response as ICriteriaCorrectiveLogResponseType);
      },
      (error: any) => {
        dispatch({
          type: GET_CRITERIA_AUDIT_LOG_BY_ID_FAILURE,
          error,
        });
        onFailure && onFailure(error);
      }
    );
  };
}

export function GetAuditTemplatesForPlanning(): any {
  return (dispatch: any) => {
    dispatch({
      type: GET_AUDIT_TEMPLATES_FOR_PLANNING_REQUEST,
      payload: "",
    });
    return api.get(AUDIT_MODULE.CREATE_AUDIT_TEMPLATE).then(
      (response: any) => {
        dispatch({
          type: GET_AUDIT_TEMPLATES_FOR_PLANNING_SUCCESS,
          payload: response.data,
        });
      },
      (error: any) => {
        dispatch({
          type: GET_AUDIT_TEMPLATES_FOR_PLANNING_FAILURE,
          payload: error,
        });
      }
    );
  };
}

export function addTemplateSchedule(
  data: IGlobalTemplateSchedule,
  onSuccess: (data: apiResponseType) => void,
  onError: (data: apiResponseType) => void
): any {
  return api
    .post(GLOBAL_TEMPLATE_SCHEDULES_URLS.UPDATE_BY_GLOBAL_TEMPLATE_ID, data)
    .then(
      (response: any) => {
        onSuccess(response as apiResponseType);
      },
      (error: any) => {
        onError(error.response.data as apiResponseType);
      }
    );
}

export function updateTemplateSchedule(
  data: IGlobalTemplateSchedule,
  onSuccess: (data: apiResponseType) => void,
  onError: (data: apiResponseType) => void
): any {
  return api
    .put(GLOBAL_TEMPLATE_SCHEDULES_URLS.UPDATE_BY_GLOBAL_TEMPLATE_ID, data)
    .then(
      (response: any) => {
        onSuccess(response as apiResponseType);
      },
      (error: any) => {
        onError(error.response.data as apiResponseType);
      }
    );
}
export function UpsertGlobalTemplateSchedule(
  data: IGlobalTemplateSchedule,
  onSuccess: (data: apiResponseType) => void,
  onError: (data: apiResponseType) => void
): any {
  return api
    .put(GLOBAL_TEMPLATE_SCHEDULES_URLS.UPSERT_BY_GLOBAL_TEMPLATE_ID, data)
    .then(
      (response: any) => {
        onSuccess(response as apiResponseType);
      },
      (error: any) => {
        onError(error.response.data as apiResponseType);
      }
    );
}

export function GetLocalTemplatesWithSchedule(
  clientId: number,
  from_Date:Date ,to_Date:Date,
  onSuccess: (data: IGlobalTemplateType[]) => void,
  onError: (data: apiResponseType) => void
): any {
  return api.get("LocalTemplateHeader/GetByClientId?clientId=" + clientId+"&from_date="+from_Date+"&to_date="+to_Date).then(
    (response: any) => {
      onSuccess(response.data);
    },
    (error: any) => {
      onError(error.response as apiResponseType);
    }
  );
}

export function UpdateLocalTemplateSchedule(
  data: ILocalTemplateSchedule,
  onSuccess: (data: apiResponseType) => void,
  onError: (data: apiResponseType) => void
): any {
  //https://giachecks-devapi.azurewebsites.net/api/LocalTemplateSchedule/Upsert
  return api.put("LocalTemplateSchedule/Upsert", data).then(
    (response: any) => {
      onSuccess(response);
    },
    (error: any) => {
      onError(error.response.data as apiResponseType);
    }
  );
}
export function UpdateGlobalTemplateSchedule(
  data: IGlobalTemplateSchedule,
  onSuccess: (data: apiResponseType) => void,
  onError: (data: apiResponseType) => void
): any {
  return api.put("GlobalTemplateSchedules/Upsert", data).then(
    (response: any) => {
      onSuccess(response);
    },
    (error: any) => {
      onError(error as apiResponseType);
    }
  );
}

export function GetAssigneeTemplatesByClientId(
  clientId: number,
  onSuccess: (data: ILocalTemplateType[]) => void,
  onError: (data: apiResponseType) => void
): any {
  return api.get("AssineeTemplate/GetByClientId?clientId=" + clientId).then(
    (response: any) => {
      onSuccess(response.data);
    },
    (error: any) => {
      onError(error.response as apiResponseType);
    }
  );
}

export function UpsertLocalTemplateSchedule(
  data: ILocalTemplateSchedule,
  onSuccess: (data: apiResponseType) => void,
  onError: (data: apiResponseType) => void
): any {
  return api.put("LocalTemplateSchedule/Upsert", data).then(
    (response: any) => {
      onSuccess(response);
    },
    (error: any) => {
      onError(error.response as apiResponseType);
    }
  );
}

export function StartAuditing(
  data: startAuditType,
  onSuccess: (data: apiResponseType) => void,
  onError: (data: apiResponseType) => void
): any {
  return api.post("AssineeTemplate/StartAuditing", data).then(
    (response: any) => {
      onSuccess(response);
    },
    (error: any) => {
      onError(error.response.data as apiResponseType);
    }
  );
}

export function deleteLocalTemplateHeaderByIds(
  ids: number[],
  onSuccess: (data: apiResponseType) => void,
  onError: (data: apiResponseType) => void
): any {
  return api.delete("LocalTemplateHeader/DeleteList", {data:ids}).then(
    (response: any) => {
      onSuccess(response);
    },
    (error: any) => {
      onError(error.response as apiResponseType);
    }
  );
}