import React from 'react';
import {userType} from "../../../config/commonTypes";
import {useFormik} from "formik";

interface IPropsTypes{
    responsiblePerson: userType[] | [];
    onSubmit: (data: IAddCorrectiveActionFormType) => void;
}
export interface IAddCorrectiveActionFormType {
    responsibleUserId: string,
    findings: string,
    customCriteria: string,
}
function AddManualCorrectiveActionForm({responsiblePerson ,onSubmit}:IPropsTypes){


    const initialValues: IAddCorrectiveActionFormType = {
        responsibleUserId: '',
        findings: '',
        customCriteria: 'Manual corrective action',
    }
    const formik = useFormik({
        initialValues: initialValues,
        enableReinitialize:true,
        onSubmit: (value: IAddCorrectiveActionFormType) => {
            onSubmit(value);
        }
    });

    return (
        <form onSubmit={formik.handleSubmit} className="w-100" method={'POST'}>
            <div className="modal-body p-3 pb-0">
                <div className="row ">
                    <div className="col-6">
                    <label className="fw-bold form-label ">Subject / criteria</label>
                    </div>
                </div>
                <div className="row mb-2">
                    <div className="col">
                        <input name="customCriteria"
                               value={formik.values.customCriteria}
                               onChange={formik.handleChange}
                            type="text" placeholder='Manual corrective action' className="form-control form-control-sm"/>
                    </div>
                </div>
                <div className="row ">
                    <div className="col-6">
                    <label className="fw-bold form-label ">Findings</label>
                    </div>
                </div>
                <div className="row mb-2">
                    <div className="col">
                                    <textarea className="form-control form-control-sm"
                                              rows={6}
                                              name={"findings"}
                                              onChange={formik.handleChange}
                                              id="criteria">
                                    </textarea>
                    </div>
                </div>
                <div className="row  ">
                    <div className="col-6">
                    <label className="fw-bold form-label ">Person Responsible</label>
                    </div>
                </div>
                <div className="row   mb-2">
                    <div className="col">
                        <select id="responsibleUserId"
                                className={`form-select form-select-sm`}
                                onChange={(e) => formik.handleChange(e)}>
                            <option>&nbsp;</option>
                            {responsiblePerson.map((data:userType) => (
                                <option selected={false} key={data.id}
                                        value={data.id}>{data.name}</option>
                            ))}

                        </select>
                    </div>
                </div>
            </div>
            <div className="modal-footer p-3">
                <div className="row m-0">
                    <div className="col p-0 d-flex align-items-end justify-content-end">
                        <div className="p-0 d-flex flex-wrap d-grid gap-2">
                            <button type="submit" className="btn btn-primary btn-sm">
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
}
export default AddManualCorrectiveActionForm;