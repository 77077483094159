import React from 'react';

function SelectAssignModal() {
    return (
      <>
          <div className="modal-body pt-4">
              <div className="row m-0 gap-3">
                  <div className="col d-flex align-items-center justify-content-between py-2 border">
                      <div className="form-check d-flex justify-content-center align-items-center gap-2">
                          <input className="form-check-input checkbox-lg" type="checkbox" value=""/>
                          <label>Select All</label>
                      </div>
                      <button
                          type="button"
                          className="btn btn-primary btn-sm px-3">
                          Add
                      </button>
                  </div>
                  <div className="col d-flex align-items-center justify-content-between py-2 border">
                      <div className="form-check d-flex justify-content-center align-items-center gap-2">
                          <input className="form-check-input checkbox-lg" type="checkbox" value=""/>
                          <label>Select All</label>
                      </div>
                      <button
                          type="button"
                          className="btn btn-primary btn-sm px-3">
                          Remove
                      </button>
                  </div>
              </div>
              <div className="row m-0 gap-3">
                  <div className="col d-flex align-items-center justify-content-between py-2 border">
                      <input
                          type="text"
                          className="form-control"
                          id="defaultFormControlInput15"
                          placeholder=""
                          aria-describedby="defaultFormControlHelp"
                      />
                  </div>
                  <div className="col d-flex align-items-center justify-content-between py-2 border">
                      <input
                          type="text"
                          className="form-control"
                          id="defaultFormControlInput22"
                          placeholder=""
                          aria-describedby="defaultFormControlHelp"
                      />
                  </div>
              </div>
              <div className="row m-0 gap-3">
                  <div className="col d-flex align-items-center justify-content-between py-2 border">
                      <div className="form-check d-flex justify-content-center align-items-center gap-2">
                          <input className="form-check-input checkbox-lg" type="checkbox" value=""/>
                          <label>Annaliese Haven Rest Home and Hospital</label>
                      </div>
                  </div>
                  <div className="col d-flex align-items-center justify-content-between py-2 border">
                      <div className="form-check d-flex justify-content-center align-items-center gap-2">
                          <input className="form-check-input checkbox-lg" type="checkbox" value=""/>
                          <label>Annaliese Haven Rest Home and Hospital</label>
                      </div>
                  </div>
              </div>
              <div className="row m-0 gap-3">
                  <div className="col d-flex align-items-center justify-content-between py-2 border">
                      <div className="form-check d-flex justify-content-center align-items-center gap-2">
                          <input className="form-check-input checkbox-lg" type="checkbox" value=""/>
                          <label>Taradale Masonic Rest Home and Hospital</label>
                      </div>
                  </div>
                  <div className="col d-flex align-items-center justify-content-between py-2 border">
                      <div className="form-check d-flex justify-content-center align-items-center gap-2">
                          <input className="form-check-input checkbox-lg" type="checkbox" value=""/>
                          <label>Taradale Masonic Rest Home and Hospital</label>
                      </div>
                  </div>
              </div>
              <div className="row m-0 gap-3">
                  <div className="col d-flex align-items-center justify-content-between py-2 border">
                      <div className="form-check d-flex justify-content-center align-items-center gap-2">
                          <input className="form-check-input checkbox-lg" type="checkbox" value=""/>
                          <label>The Napler District Masonic Trust Villages</label>
                      </div>
                  </div>
                  <div className="col d-flex align-items-center justify-content-between py-2 border">
                      <div className="form-check d-flex justify-content-center align-items-center gap-2">
                          <input className="form-check-input checkbox-lg" type="checkbox" value=""/>
                          <label>The Napler District Masonic Trust Villages</label>
                      </div>
                  </div>
              </div>
              <div className="row m-0 gap-3">
                  <div className="col d-flex align-items-center justify-content-between py-2 border">
                      <div className="form-check d-flex justify-content-center align-items-center gap-2">
                          <input className="form-check-input checkbox-lg" type="checkbox" value=""/>
                          <label>Cunlife House Retrement Home</label>
                      </div>
                  </div>
                  <div className="col d-flex align-items-center justify-content-between py-2">

                  </div>
              </div>
              <div className="row m-0 gap-3">
                  <div className="col d-flex align-items-center justify-content-between py-2 border">
                      <div className="form-check d-flex justify-content-center align-items-center gap-2">
                          <input className="form-check-input checkbox-lg" type="checkbox" value=""/>
                          <label>Lady Wogram Village</label>
                      </div>
                  </div>
                  <div className="col d-flex align-items-center justify-content-between py-2">

                  </div>
              </div>
              <div className="row m-0 gap-3">
                  <div className="col d-flex align-items-center justify-content-between py-2 border">
                      <div className="form-check d-flex justify-content-center align-items-center gap-2">
                          <input className="form-check-input checkbox-lg" type="checkbox" value=""/>
                          <label>Pinehaven Cottage - Graceful Home Ovewa Ltd</label>
                      </div>
                  </div>
                  <div className="col d-flex align-items-center justify-content-between py-2">

                  </div>
              </div>
          </div>
          <div className="modal-footer">
              <div className="row m-0">
                  <div className="col pt-3 px-0 d-flex align-items-end justify-content-end">
                      <div className="p-0 d-flex flex-wrap d-grid gap-2">
                          <button type="button" className="btn btn-primary btn-sm px-4">
                              Assign
                          </button>
                      </div>
                  </div>
              </div>
          </div>
      </>
    );
}export default SelectAssignModal;