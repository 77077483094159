import { useFormik } from "formik";
import React, { useEffect } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import {
  ChangeCriteriaOrder,
  CreateAuditTemplate,
  CreateCriteriaForHeader,
  DeleteCriteria,
  editAuditTemplate,
  GetAuditTemplates,
  getCriteriaList,
  GetTemplateByID,
  UpdateCriteriaforHeader,
} from "../../../actions/auditActions";
import { getClients } from "../../../actions/clientAction";
import {
  ClientListModal,
  IClientTypeCheckBox,
} from "../../../components/modal/ClientListModal";
import CustomModal from "../../../components/modal/CustomModal";
import {
  ICriteriaType,
  IGlobalTemplateType,
} from "../../../redux/modules/auditManagementReducer";
import { clientType } from "../../../redux/modules/clientManagementReducer";
import { AllClientListModal } from "../../../components/modal/AllClientListModal";
import { TransformClientsToCheckBoxType } from "../../../DTO/transformers";
import {
  CREATE_AUDIT_TEMPLATE_SUCCESS,
  CREATE_CRITERIA_FOR_TEMPLATE_REQUEST_SUCCESS,
  CRITERIA_FOR_TEMPLATE_REQUEST_SUCCESS,
  RESET_CRITERIA_LIST,
  SET_CRITERIA_EDIT,
  SET_TEMPLATE_HEADER,
  SORT_CRITERIA_LIST_ON_DRAG,
} from "../../../constants";
import { Helpers } from "../../../js/helpers";
import _ from "lodash";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { ConfirmModal } from "../../../components/modal/ConfirmModal";
import AccessControl from "../../../config/AccessControl";
import {
  AccessControlType,
  UserPermissionsConstant,
} from "../../../constants/constants";
import { apiResponseType } from "../../../config/commonTypes";

interface INewAuditTemplateType {
  globalTemplateCode: string;
  globalTemplateName: string;
  globalTemplateDescription?: string;
  isGlobal: boolean;
  isActive: boolean;
}

export interface INewAuditTemplateCriteria {
  globalTemplateCode: string;
  globalTemplateGroup: string;
  globalTemplateDescription: string;
}
interface IpropsTypes {
  openAddNewTemplate: () => void;
  isLoading?: boolean;
  template?: IGlobalTemplateType;
  error?: string;
  clients: clientType[];
  criteriaList: ICriteriaType[];
  selectedCriteria?: ICriteriaType | undefined;
  templateList: IGlobalTemplateType[];
}
function AuditManagement({
  openAddNewTemplate,
  isLoading = false,
  error,
  template,
  clients = [],
  criteriaList = [],
  selectedCriteria = undefined,
  templateList = [],
}: IpropsTypes) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const TransformedClients = React.useMemo(
    () => TransformClientsToCheckBoxType(clients),
    [clients]
  );

  useEffect(() => {
    if (template) {
      fetchCriteriaList(template?.globalTemplateHeaderId!!);
    }
    setEditModeCriterai(false);
  }, [template]);

  useEffect(() => {
    dispatch(GetAuditTemplates());

    return ()=>{
      // Clear the criteriaList
      dispatch({type:RESET_CRITERIA_LIST,payload:[]});
    }
  }, []);

  const fetchCriteriaList = (id: number) => {
    dispatch(
      getCriteriaList(id,
        (sucess:apiResponseType)=>{
            if(sucess?.data.length === 0)
              formikCriteria.setFieldValue("globalTemplateCode", (1).toString());
        },
        (error: apiResponseType) => {
        console.log(
          error?.data
            ? error?.data
            : "Criteria fetching failed!"
        );
      })
    );
  };

  const [selectedClientsData, SetSelectedClients] =
    React.useState<IClientTypeCheckBox[]>(TransformedClients);
  const [searchData, setSearchData] = React.useState<IClientTypeCheckBox[]>([]);
  const [isOpenModalAllClients, setIsOpenModalSelectedClients] =
    React.useState(false);
  const [searchAssigneeString, setSearchString] = React.useState<string>("");
  const [TemplateSearchData, setSearchTemplateData] = React.useState<
    IGlobalTemplateType[]
  >([]);
  const [templateSearchQueryName, setSearchQueryName] =
    React.useState<string>("");
  const [templateSearchQuerCode, setSearchQueryCode] =
    React.useState<string>("");
  const [editModeCriteria, setEditModeCriterai] =
    React.useState<boolean>(false);
  const [editModeTemplate, setTemplateEditMode] = React.useState(false);
  const [isDelete, setDelete] = React.useState(false);

  useEffect(() => {
    if (template?.assignClients?.length) {
      setSelectedAssigneesForTemplate(template?.assignClients);
    } else {
      SetSelectedClients(TransformClientsToCheckBoxType(clients));
    }
  }, [template]);

  const initialValues: INewAuditTemplateType = {
    globalTemplateCode: "",
    globalTemplateName: "",
    globalTemplateDescription: "",
    isGlobal: false,
    isActive: false,
  };

  const IsCriteriaDisable = React.useMemo(() => {
    return selectedCriteria ? false : template ? false : true;
  }, [template, selectedCriteria]);

  const initialValuesCriteria: INewAuditTemplateCriteria = React.useMemo(() => {
    return {
      globalTemplateCode: selectedCriteria
        ? selectedCriteria.globalTemplateCriteriaCode
        : (criteriaList.length + 1).toString(),
      globalTemplateGroup: selectedCriteria
        ? selectedCriteria.globalTemplateCriteriaGroup
        : "",
      globalTemplateDescription: selectedCriteria
        ? selectedCriteria.globalTemplateCriteriaDescription
        : "",
    };
  }, [selectedCriteria,criteriaList]);

  useEffect(() => {
    dispatch<any>(getClients());
  }, []);

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: (values: INewAuditTemplateType) => {
      if (editModeTemplate) {
        updateTemplate(values);
      } else createTemplate(values);
    },
    validationSchema: Yup.object().shape({
      globalTemplateName: Yup.string().required("Name is required"),
      globalTemplateCode: Yup.string().required("Code is required"),
    }),
  });

  const setSelectedAssigneesForTemplate = (assigneeList: clientType[]) => {
    let statedata = [...selectedClientsData];
    assigneeList.map((o) => {
      let findObject = statedata.find((obj) => obj.clientId == o.clientId);
      if (!findObject) return;
      let index = statedata.indexOf(findObject);
      statedata[index] = { ...statedata[index], isSelected: true };
    });
    SetSelectedClients(statedata);
  };

  const formikCriteria = useFormik({
    initialValues: initialValuesCriteria,
    enableReinitialize:true,
    onSubmit: (values: INewAuditTemplateCriteria) => {
      if (editModeCriteria) {
        updateCriteria(values);
      } else createCriteria(values);
    },
    validationSchema: Yup.object().shape({
      globalTemplateCode: Yup.number()
        .typeError("Must be a number")
        .positive("Must be a positive number")
        .required("Code is required"),
      globalTemplateGroup: Yup.string().required("Group is required"),
      globalTemplateDescription: Yup.string().required("Criteria is required"),
    }),
  });

  useEffect(() => {
    if (template) {
      formik.setFieldValue("globalTemplateCode", template.globalTemplateCode);
      formik.setFieldValue("globalTemplateName", template.globalTemplateName);
      formik.setFieldValue(
        "globalTemplateDescription",
        template.globalTemplateDescription
      );
      formik.setFieldValue("isActive", template.isActive);
      formik.setFieldValue("isGlobal", template.isGlobal);
    }
  }, [template]);

  useEffect(() => {
    if (selectedCriteria) {
      formikCriteria.setFieldValue(
        "globalTemplateCode",
        selectedCriteria.globalTemplateCriteriaCode
      );
      formikCriteria.setFieldValue(
        "globalTemplateGroup",
        selectedCriteria.globalTemplateCriteriaGroup
      );
      formikCriteria.setFieldValue(
        "globalTemplateDescription",
        selectedCriteria.globalTemplateCriteriaDescription
      );
    } else {
      resetCriteriaForm();
    }
  }, [selectedCriteria]);

  const updateTemplate = (data: INewAuditTemplateType) => {
    dispatch(
      editAuditTemplate(
        {
          ...data,
          ...{
            globalTemplateHeaderId: template?.globalTemplateHeaderId,
            assineeIds: getAllSelectedAssignees(),
          },
        },
        (response) => {
          toast.success(
            response?.message
              ? response.message
              : "Template Created Successfully!"
          );
          dispatch(GetAuditTemplates());
        },
        (error: any) => {
          toast.error(
            error?.response?.data?.data
              ? error?.response?.data?.data
              : "Template update failed!"
          );
        }
      )
    );
  };

  const resetCriteriaForm = () => {
    const questionCode = criteriaList.length ? criteriaList.length + 1 : 1;
    formikCriteria.setFieldValue("globalTemplateCode", questionCode);
    formikCriteria.setFieldValue("globalTemplateGroup", "");
    formikCriteria.setFieldValue("globalTemplateDescription", "");
  };

  const createTemplate = (data: INewAuditTemplateType) => {
    dispatch(
      CreateAuditTemplate(
        { ...data, assineeIds: getAllSelectedAssignees() },
        (response) => {
          toast.success(
            response?.message
              ? response.message
              : "Template Created Successfully!"
          );
          setTemplateEditMode(true);
          dispatch(GetAuditTemplates());
          formikCriteria.setFieldValue("globalTemplateCode", (1).toString());
        },
        (error: any) => {
          console.log(error);
          toast.error(
            error?.response?.data?.data
              ? error?.response?.data?.data
                  : "Something went wrong!"
          );
        }
      )
    );
  };

  const updateCriteria = (data: INewAuditTemplateCriteria) => {
    dispatch(
      UpdateCriteriaforHeader(
        {
          globalTemplateCriteriaId: selectedCriteria?.globalTemplateCriteriaId,
          globalTemplateHeaderId: selectedCriteria?.globalTemplateHeaderId,
          globalTemplateCriteriaCode: data.globalTemplateCode,
          globalTemplateCriteriaGroup: data.globalTemplateGroup,
          globalTemplateCriteriaDescription: data.globalTemplateDescription,
        },
        (response) => {
          toast.success(
            response?.message
              ? response.message
              : "Criteria Updated Successfully!"
          );
          fetchCriteriaList(template?.globalTemplateHeaderId!!);
        },
        (error: any) => {
          toast.error(
            error?.response?.data?.data
              ? error?.response?.data?.data
              : "Something went wrong!"
          );
        }
      )
    );
  };

  const createCriteria = (data: INewAuditTemplateCriteria) => {
    dispatch(
      CreateCriteriaForHeader(
        {
          globalTemplateCriteriaCode: data.globalTemplateCode,
          globalTemplateCriteriaDescription: data.globalTemplateDescription,
          globalTemplateCriteriaGroup: data.globalTemplateGroup,
          globalTemplateHeaderId: template?.globalTemplateHeaderId,
        },
        (response) => {
          toast.success(
            response?.message
              ? response.message
              : "Criteria Created Successfully!"
          );
          fetchCriteriaList(template?.globalTemplateHeaderId!!);
          dispatch({
            type: SET_CRITERIA_EDIT,
            payload: undefined,
          });
        },
        (error: any) => {
          toast.error(
            error?.response?.data?.data
              ? error?.response?.data?.data
                  : "Something went wrong!"
          );
        }
      )
    );
  };

  const getAllSelectedAssignees = () => {
    let assignedList = [...selectedClientsData]
      .filter((o) => o.isSelected)
      .map((o) => {
        return o.clientId;
      });
    return assignedList;
  };

  const handleDrop = (droppedItem: any) => {
    if (!droppedItem.destination) return;
    var updatedList = [...criteriaList];
    // Remove dragged item
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    // Add dropped item
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
    // Update State

    updatedList.map((o, index) => {
      o.lineNo = index;
    });

    dispatch({
      type: SORT_CRITERIA_LIST_ON_DRAG,
      payload: updatedList,
    });
    dispatch(
      ChangeCriteriaOrder(
        updatedList,
        (response) => {
          fetchCriteriaList(template?.globalTemplateHeaderId!!);
          toast.success(
            response?.message
              ? response.message
              : "Criteria Sorted Successfully!"
          );
          dispatch({
            type: SORT_CRITERIA_LIST_ON_DRAG,
            payload: response.data,
          });
        },
        (error: any) => {
          toast.error(
            error?.response?.data?.data
              ? error?.response?.data?.data
              : "Criterai Sorting failed!"
          );
        }
      )
    );
  };

  const removeAssignee = (item: IClientTypeCheckBox) => {
    let statedata = [...selectedClientsData];
    statedata.map((o) => {
      if (o.clientId === item.clientId) {
        o.isSelected = false;
      }
      return o;
    });
    SetSelectedClients(statedata);
  };

  const deleteCriteria = (item: ICriteriaType) => {
    dispatch(
      DeleteCriteria(
        item.globalTemplateCriteriaId!!,
        (response) => {
          toast.success(
            response?.message
              ? response.message
              : "Criteria Deleted Successfully!"
          );
          fetchCriteriaList(template?.globalTemplateHeaderId!!);
          dispatch({
            type: SET_CRITERIA_EDIT,
            payload: undefined,
          });
          setDelete(false);
        },
        (error: any) => {
          toast.error(
            error?.response?.data?.data
              ? error?.response?.data?.data
                  : "Something went wrong!"
          );
        }
      )
    );
  };

  const selectTemplate = (item: IGlobalTemplateType) => {
    dispatch({ type: SET_TEMPLATE_HEADER, payload: item });
    dispatch({
      type: SET_CRITERIA_EDIT,
      payload: undefined,
    });
    setTemplateEditMode(true);
    dispatch(
      GetTemplateByID(
        item.globalTemplateHeaderId?.toString()!!,
        (error: any) => {
          console.log(
            error?.response?.data?.data
              ? error?.response?.data?.data
                  : "Something went wrong!"
          );
        }
      )
    );
  };

  const onchangeTemplateCode = (val: string) => {
    if (!val) {
      setSearchTemplateData([]);
      setSearchQueryCode(val);
      return;
    }
    let statedata = [...templateList].filter((object) => {
      return object.globalTemplateCode
        ?.toLowerCase()
        .includes(val.toLowerCase());
    });
    setSearchTemplateData(statedata);
    setSearchQueryCode(val);
  };

  const onChangeTemplateName = (val: string) => {
    if (!val) {
      setSearchTemplateData([]);
      setSearchQueryName(val);
      return;
    }
    let statedata = [...templateList].filter((object) => {
      return object.globalTemplateName
        ?.toLowerCase()
        .includes(val.toLowerCase());
    });
    setSearchTemplateData(statedata);
    setSearchQueryName(val);
  };

  return (
    <>
      {isDelete && (
        <ConfirmModal
          onCanceled={() => setDelete(false)}
          isOpenConfirmModal={isDelete}
          title={"Delete Criteria"}
          btn1Text={"Cancel"}
          btn1Class={"btn btn-outline-secondary btn-sm"}
          btn2Text={"Confirm"}
          btn2Class="btn btn-danger btn-sm"
          icon={
            <i className="bx bx-error-circle display-4 app-col link-warning"></i>
          }
          message={"Are you sure you want to delete this item?"}
          onConfirm={() => isDelete && deleteCriteria(selectedCriteria!!)}
        />
      )}
      <div className="row m-0  tb-mh">
        <div className="ol-sm-12 col-md-4 col-xl-3 ps-0">
          <div className="row p-3 d-grid mx-auto app-bg-gray border">
            <AccessControl
              type={AccessControlType.DISABLE}
              allowedPermissions={[
                UserPermissionsConstant.CreateAuditTemplates,
              ]}
            >
              <button
                type="button"
                onClick={() => {
                  formik.resetForm();
                  formikCriteria.resetForm();
                  dispatch({
                    type: CREATE_AUDIT_TEMPLATE_SUCCESS,
                    payload: undefined,
                  });
                  setTemplateEditMode(false);
                  setEditModeCriterai(false);
                  dispatch({
                    type: SET_CRITERIA_EDIT,
                    payload: undefined,
                  });
                  dispatch({
                    type: CRITERIA_FOR_TEMPLATE_REQUEST_SUCCESS,
                    payload: undefined,
                  });
                  dispatch({
                    type: CRITERIA_FOR_TEMPLATE_REQUEST_SUCCESS,
                    payload: undefined,
                  });
                  dispatch({
                    type: SET_CRITERIA_EDIT,
                    payload: undefined,
                  });
                }}
                className="btn btn-primary btn-sm px-4"
                data-bs-toggle="modal"
                data-bs-target="#modalCenter"
              >
                New Audit Template
              </button>
            </AccessControl>
          </div>
          <div className="row">
            <div className="col">
              <h6 className="mb-0 mt-3">Audit Template List</h6>
            </div>
          </div>
          <div className="row mt-3 mx-auto">
            <div className="col p-0">
              <div className="gridtable">
                <table className="scrollTable table table-bordered table-hover tbl-audit-management mb-1">
                  <thead className="fixedHeader">
                    <tr>
                      <th className="colda text-capitalize fw-semibold">
                        Code
                      </th>
                      <th className="coldb text-capitalize fw-semibold">
                        Audit Name
                      </th>
                    </tr>
                  </thead>
                  <tbody className="scrollContent">
                    <tr>
                      <td className="colda">
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          id="defaultFormControlInput1"
                          placeholder=""
                          aria-describedby="defaultFormControlHelp"
                          onChange={(e) => onchangeTemplateCode(e.target.value)}
                        />
                      </td>
                      <td className="coldb">
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          id="defaultFormControlInput2"
                          placeholder=""
                          aria-describedby="defaultFormControlHelp"
                          onChange={(e) => onChangeTemplateName(e.target.value)}
                        />
                      </td>
                    </tr>

                    {templateList && !TemplateSearchData.length
                      ? templateList.map((o, index) => {
                          return (
                            <AccessControl
                              type={AccessControlType.DISABLE}
                              allowedPermissions={[
                                UserPermissionsConstant.ChangeAuditTemplates,
                              ]}
                            >
                              <tr
                                className={
                                  o?.isAssignedtoClient
                                    ? `${
                                        o.globalTemplateHeaderId ==
                                        template?.globalTemplateHeaderId
                                          ? "selected"
                                          : ""
                                      }`
                                    : o?.isGlobal
                                    ? `aud-temp-b ${
                                        o.globalTemplateHeaderId ==
                                        template?.globalTemplateHeaderId
                                          ? "selected"
                                          : ""
                                      }`
                                    : `aud-temp-y ${
                                        o.globalTemplateHeaderId ==
                                        template?.globalTemplateHeaderId
                                          ? "selected"
                                          : ""
                                      }`
                                }
                                key={index}
                                onClick={() => selectTemplate(o)}
                              >
                                <td className="text-wrap col-4">
                                  <p className="py-2">{o.globalTemplateCode}</p>
                                </td>
                                <td className="text-wrap col-4">
                                  <p className="py-2">{o.globalTemplateName}</p>
                                </td>
                              </tr>
                            </AccessControl>
                          );
                        })
                      : null}
                    {TemplateSearchData.length
                      ? TemplateSearchData.map((o, index) => {
                          return (
                            <AccessControl
                              type={AccessControlType.DISABLE}
                              allowedPermissions={[
                                UserPermissionsConstant.PerformAudit,
                              ]}
                            >
                              <tr
                                className={
                                  o?.isAssignedtoClient
                                    ? `${
                                        o.globalTemplateHeaderId ==
                                        template?.globalTemplateHeaderId
                                          ? "selected"
                                          : ""
                                      }`
                                    : o?.isGlobal
                                    ? `aud-temp-b ${
                                        o.globalTemplateHeaderId ==
                                        template?.globalTemplateHeaderId
                                          ? "selected"
                                          : ""
                                      }`
                                    : `aud-temp-y ${
                                        o.globalTemplateHeaderId ==
                                        template?.globalTemplateHeaderId
                                          ? "selected"
                                          : ""
                                      }`
                                }
                                key={index}
                                onClick={() => selectTemplate(o)}
                              >
                                <td className="text-wrap">
                                  <p className="py-2">{o.globalTemplateCode}</p>
                                </td>
                                <td className="text-wrap">
                                  <p className="py-2">{o.globalTemplateName}</p>
                                </td>
                              </tr>
                            </AccessControl>
                          );
                        })
                      : null}
                  </tbody>
                </table>
              </div>            
            </div>
          </div>
          <div className="row">
            <div className="col-12 pt-2">
            <div className="d-flex justtify-contents-between">
                <div className="p-0 d-flex justify-contents-center align-items-center">
                  <div className="legnd legnd-gt"></div>
                  <label className="form-check-label ps-2 small">Global</label>
                </div>
                <div className="ps-3  d-flex justify-contents-center align-items-center">
                  <div className="legnd legnd-na"></div>
                  <label className="form-check-label ps-2 small">Not Assigned</label>
                </div>
                <div className="ps-3  d-flex justify-contents-center align-items-center">
                  <div className="legnd legnd-at border"></div>
                  <label className="form-check-label ps-2 small">Assigned</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-8 col-xl-9">
          <div className="row">
            <form onSubmit={formik.handleSubmit} method="POST" className="px-0">
              <div className="col p-3 app-bg-gray border py-0">
                <div className="row">
                  <div className="col-4 pt-0">
                    <div className="d-grid col mx-auto my-3 mb-0">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="isGlobal"
                          onChange={() =>
                            formik.setFieldValue(
                              "isGlobal",
                              !formik.values.isGlobal
                            )
                          }
                          checked={formik.values.isGlobal}
                          onBlur={formik.handleBlur}
                          id="defaultCheck2"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="defaultCheck2"
                        >
                          {" "}
                          Global Template{" "}
                        </label>
                      </div>
                    </div>
                    <div className="col">
                      <label
                        htmlFor="defaultFormControlInput"
                        className="col-form-label  text-capitalize fw-semibold"
                      >
                        Code<span className="text-danger">*</span>
                      </label>
                      <div className="col-md d-flex justify-content-end">
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          id="defaultFormControlInput11"
                          onChange={formik.handleChange}
                          value={formik.values.globalTemplateCode}
                          onBlur={formik.handleBlur}
                          placeholder=""
                          name="globalTemplateCode"
                          aria-describedby="defaultFormControlHelp"
                        />
                      </div>
                      {formik.errors.globalTemplateCode &&
                        formik.touched.globalTemplateCode && (
                          <p style={{ color: "red" }}>
                            {formik.errors.globalTemplateCode}
                          </p>
                        )}
                    </div>
                    <div className="col">
                      <label
                        htmlFor="defaultFormControlInput"
                        className="col-form-label  text-capitalize fw-semibold"
                      >
                        Name<span className="text-danger">*</span>
                      </label>
                      <div className="col-md d-flex justify-content-end">
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          id="defaultFormControlInput12"
                          placeholder=""
                          onChange={formik.handleChange}
                          value={formik.values.globalTemplateName}
                          onBlur={formik.handleBlur}
                          name="globalTemplateName"
                          aria-describedby="defaultFormControlHelp"
                        />
                      </div>
                      {formik.errors.globalTemplateName &&
                        formik.touched.globalTemplateName && (
                          <p style={{ color: "red" }}>
                            {formik.errors.globalTemplateName}
                          </p>
                        )}
                    </div>
                  </div>
                  <div className="col-8 p-3">
                    <div className="row d-flex align-items-center">
                      <div className="col-4 d-flex justify-content-start align-items-center"></div>
                      <div className="col-4 d-flex justify-content-start align-items-center"></div>
                      <div className="col-4 d-flex justify-content-end align-items-center">
                        <div className="form-check form-switch mb-0">
                          <input
                            className="form-check-input pointer"
                            type="checkbox"
                            id="flexSwitchCheckChecked"
                            name="isActive"
                            onChange={(e:any) =>{
                              editModeTemplate &&
                              updateTemplate({...formik.values, "isActive":e.target.checked});
                              formik.setFieldValue(
                                "isActive",
                                !formik.values.isActive
                              );
                            }}
                            checked={formik.values.isActive}
                            onBlur={formik.handleBlur}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexSwitchCheckChecked"
                          >
                            Status: Active
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row m-0">
                      <label
                        htmlFor="defaultFormControlInput"
                        className="col-form-label px-0 text-capitalize fw-semibold"
                      >
                        Description <span className="text-danger">*</span>
                      </label>
                      <textarea
                        className="form-control"
                        id="exampleFormControlTextarea111"
                        rows={5}
                        onChange={formik.handleChange}
                        value={formik.values.globalTemplateDescription}
                        onBlur={formik.handleBlur}
                        name="globalTemplateDescription"
                      />
                      {formik.errors.globalTemplateDescription &&
                        formik.touched.globalTemplateDescription && (
                          <p style={{ color: "red" }}>
                            {formik.errors.globalTemplateDescription}
                          </p>
                        )}
                      <div className="col pt-3 px-0 d-flex align-items-end justify-content-end">
                        <div className="p-0 d-flex flex-wrap d-grid gap-2">
                          <button
                            type="button"
                            className="btn btn-outline-primary btn-sm d-flex justify-content-between"
                            data-bs-toggle="modal"
                            data-bs-target="#modalCenter3"
                            disabled={
                              // formik.values.isGlobal || _.isEmpty(template)
                              false
                            }
                            onClick={() => setIsOpenModalSelectedClients(true)}
                          >
                            View All Assignees &nbsp;
                            <i className="bx bx-show"></i>
                          </button>
                          {/* <button
                            type="button"
                            className="btn btn-outline-primary btn-sm"
                            data-bs-toggle="modal"
                            data-bs-target="#modalCenter2"
                            disabled={
                              formik.values.isGlobal || _.isEmpty(template)
                            }
                            onClick={() => setIsOpenModal(true)}
                          >
                            <i className="bx bx-user-plus"></i>&nbsp; Assign To
                          </button> */}
                          <button
                            type="button"
                            className="btn btn-outline-secondary btn-sm"
                            onClick={() => {
                              formik.resetForm();
                              formikCriteria.resetForm();
                              dispatch({
                                type: CREATE_AUDIT_TEMPLATE_SUCCESS,
                                payload: undefined,
                              });
                              setTemplateEditMode(false);
                              setEditModeCriterai(false);
                              dispatch({
                                type: CRITERIA_FOR_TEMPLATE_REQUEST_SUCCESS,
                                payload: undefined,
                              });
                              dispatch({
                                type: SET_CRITERIA_EDIT,
                                payload: undefined,
                              });
                            }}
                          >
                            Clear
                          </button>
                          {editModeTemplate ? (
                            <AccessControl
                              type={AccessControlType.DISABLE}
                              allowedPermissions={[
                                UserPermissionsConstant.ChangeAuditTemplates,
                              ]}
                            >
                              <button
                                type="submit"
                                className="btn btn-primary btn-sm px-4"
                              >
                                Save
                              </button>
                            </AccessControl>
                          ) : (
                            <AccessControl
                              type={AccessControlType.DISABLE}
                              allowedPermissions={[
                                UserPermissionsConstant.CreateAuditTemplates,
                              ]}
                            >
                              <button
                                type="submit"
                                className="btn btn-primary btn-sm px-4"
                              >
                                Save
                              </button>
                            </AccessControl>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="row">
            <div className="col py-3 px-0">
              <h6 className="mb-0">Audit Criteria</h6>
            </div>
          </div>
          <div className="row">
            <form
              onSubmit={formikCriteria.handleSubmit}
              method="POST"
              className="px-0"
            >
              <div className="col p-3 border py-1">
                <div className="row">
                  <div className="col-4 pt-0">
                    <div className="col">
                      <label
                        htmlFor="defaultFormControlInput"
                        className="col-form-label text-capitalize fw-semibold"
                      >
                        Question<span className="text-danger">*</span>
                      </label>
                      <div className="col-md d-flex justify-content-end">
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          id="defaultFormControlInput13"
                          placeholder=""
                          aria-describedby="defaultFormControlHelp"
                          onChange={formikCriteria.handleChange}
                          value={formikCriteria.values.globalTemplateCode}
                          onBlur={formikCriteria.handleBlur}
                          name="globalTemplateCode"
                          disabled={true}
                        />
                      </div>
                      {formikCriteria.errors.globalTemplateCode &&
                        formikCriteria.touched.globalTemplateCode && (
                          <p style={{ color: "red" }}>
                            {formikCriteria.errors.globalTemplateCode}
                          </p>
                        )}
                    </div>
                    <div className="col">
                      <label
                        htmlFor="defaultFormControlInput"
                        className="col-form-label  text-capitalize fw-semibold"
                      >
                        Group<span className="text-danger">*</span>
                      </label>
                      <div className="col-md d-flex justify-content-end">
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          id="defaultFormControlInput14"
                          placeholder=""
                          aria-describedby="defaultFormControlHelp"
                          onChange={formikCriteria.handleChange}
                          value={formikCriteria.values.globalTemplateGroup}
                          onBlur={formikCriteria.handleBlur}
                          name="globalTemplateGroup"
                          disabled={IsCriteriaDisable}
                        />
                      </div>
                      {formikCriteria.errors.globalTemplateGroup &&
                        formikCriteria.touched.globalTemplateGroup && (
                          <p style={{ color: "red" }}>
                            {formikCriteria.errors.globalTemplateGroup}
                          </p>
                        )}
                    </div>
                  </div>
                  <div className="col-8 p-3 pt-2">
                    <div className="row m-0">
                      <div className="col">
                        <label
                          htmlFor="defaultFormControlInput"
                          className="px-0 col-form-label px-0 pt-0 text-capitalize fw-semibold"
                        >
                          Criteria<span className="text-danger">*</span>
                        </label>
                        <textarea
                          className="form-control"
                          id="exampleFormControlTextarea1"
                          rows={4}
                          onChange={formikCriteria.handleChange}
                          value={
                            formikCriteria.values.globalTemplateDescription
                          }
                          onBlur={formikCriteria.handleBlur}
                          name="globalTemplateDescription"
                          disabled={IsCriteriaDisable}
                        />
                        {formikCriteria.errors.globalTemplateDescription &&
                          formikCriteria.touched.globalTemplateDescription && (
                            <p style={{ color: "red" }}>
                              {formikCriteria.errors.globalTemplateDescription}
                            </p>
                          )}
                      </div>

                      <div className="col pt-3 px-0 d-flex align-items-end justify-content-end">
                        <div className="p-0 d-flex flex-wrap d-grid gap-2">
                          <button
                            type="button"
                            className="btn btn-outline-secondary btn-sm"
                            onClick={() => {
                              setEditModeCriterai(false);
                              formikCriteria.resetForm();
                              dispatch({
                                type: SET_CRITERIA_EDIT,
                                payload: undefined,
                              });
                            }}
                            disabled={IsCriteriaDisable}
                          >
                            Clear
                          </button>

                          {editModeCriteria ? (
                            <AccessControl
                              type={AccessControlType.DISABLE}
                              allowedPermissions={[
                                UserPermissionsConstant.ChangeAuditTemplates,
                              ]}
                            >
                              <button
                                type="submit"
                                className="btn btn-primary btn-sm px-4"
                                disabled={IsCriteriaDisable}
                              >
                                &nbsp; UPDATE
                              </button>
                            </AccessControl>
                          ) : (
                            <AccessControl
                              type={AccessControlType.DISABLE}
                              allowedPermissions={[
                                UserPermissionsConstant.CreateAuditTemplates,
                                UserPermissionsConstant.ChangeAuditTemplates,
                              ]}
                            >
                              <button
                                type="submit"
                                className="btn btn-primary btn-sm px-4"
                                disabled={IsCriteriaDisable}
                              >
                                <span className="tf-icons bx bx-plus"></span>
                                &nbsp; Add
                              </button>
                            </AccessControl>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="row">
            <div className="col p-0 border cls-scroller" id="">
              <div className="p-0">
                <DragDropContext onDragEnd={handleDrop}>
                  <Droppable droppableId="item-container px-3">
                    {(provided) => (
                      <div
                        className="item-container px-3"
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {criteriaList &&
                          criteriaList.map((item, index) => {
                            return (
                              <Draggable
                                key={item.globalTemplateCriteriaCode}
                                draggableId={item.globalTemplateCriteriaCode}
                                index={index}
                              >
                                {(provided) => (
                                  <div
                                    className="item-container"
                                    ref={provided.innerRef}
                                    {...provided.dragHandleProps}
                                    {...provided.draggableProps}
                                    key={index}
                                  >
                                    <div className="row row justify-contents-center align-items-center">
                                      <div className="col px-0 d-flex justify-content-center align-items-center"></div>
                                      <div className="col-10 p-0">
                                        <p className="mb-1 fw-bold secondry-color d-inline-block w-25">
                                          <span className="fw-bold secondry-color ">
                                          Question :{" "}
                                          </span>
                                          {item.globalTemplateCriteriaCode}
                                        </p>
                                        <p className="mb-1 fw-bold secondry-color d-inline-block">
                                          <span className="fw-bold secondry-color ">
                                            Group :{" "}
                                          </span>
                                          {item.globalTemplateCriteriaGroup}{" "}
                                        </p>
                                      </div>
                                      <div className="col d-flex justify-content-end align-items-end gap-2"></div>
                                    </div>
                                    <div className="row row justify-contents-center align-items-center">
                                      <div className="col px-0 d-flex justify-content-center align-items-center">
                                        <i className="bx bx-grid-vertical gt-icon-color"></i>
                                      </div>
                                      <div className="col-10 p-0">
                                        <p className="mb-0">
                                          {
                                            item.globalTemplateCriteriaDescription
                                          }
                                        </p>
                                      </div>
                                      <AccessControl
                                        type={AccessControlType.DISABLE}
                                        allowedPermissions={[
                                          UserPermissionsConstant.ChangeAuditTemplates,
                                        ]}
                                      >
                                        <div className="col d-flex justify-content-end align-items-end gap-2">
                                          <button
                                            type="button"
                                            onClick={() => {
                                              setEditModeCriterai(true);
                                              dispatch({
                                                type: SET_CRITERIA_EDIT,
                                                payload: item,
                                              });
                                            }}
                                            className="btn btn-outline-primary btn-xs"
                                          >
                                            <i className="bx bx-pencil small"></i>
                                          </button>
                                          <button
                                            type="button"
                                            onClick={() => {
                                              setDelete(true);
                                              dispatch({
                                                type: SET_CRITERIA_EDIT,
                                                payload: item,
                                              });
                                            }}
                                            className="btn btn-outline-danger btn-xs"
                                          >
                                            <i className="bx bx-trash-alt  small"></i>
                                          </button>
                                        </div>
                                      </AccessControl>
                                    </div>
                                  </div>
                                )}
                              </Draggable>
                            );
                          })}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            </div>

            {isOpenModalAllClients && (
              <CustomModal
                size="lg"
                isClose={!isOpenModalAllClients}
                title="Assignee List"
                onClose={() => setIsOpenModalSelectedClients(false)}
              >
                {(!template?.assineeIds?.length && template?.isGlobal) ? 
                  <div className="d-flex justify-content-center p-3">
                    <h4 className="form-check-label">
                      Assigned To All Clients
                    </h4>
                  </div>
                  :
                <AllClientListModal
                  assigneeList={selectedClientsData}
                  removeSelection={removeAssignee}
                />}
              </CustomModal>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state: any) => {
  const auditManageMent = state?.auditManagement;
  const clientManageMent = state?.clientManagement;
  return {
    isLoading: auditManageMent?.isLoading,
    error: auditManageMent?.error,
    template: auditManageMent?.template,
    clients: clientManageMent.clients,
    criteriaList: auditManageMent?.criteriaList,
    selectedCriteria: auditManageMent?.selectedCriteria,
    templateList: auditManageMent?.templateList,
  };
};
export default connect(mapStateToProps, null)(AuditManagement);
