import {
  AUTH_FETCH_CLIENT_DETAILS_FAILURE,
  AUTH_FETCH_CLIENT_DETAILS_REQUEST,
  AUTH_FETCH_CLIENT_DETAILS_SUCCESS,
  AUTH_FETCH_USER_ROLES_FAILURE,
  AUTH_FETCH_USER_ROLES_REQUEST,
  AUTH_FETCH_USER_ROLES_SUCCESS,
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  PRIVACY_POLICY_SUCCESS,
  REFRESH_TOKEN_FAILED,
  REFRESH_TOKEN_REQUEST,
  REFRESH_TOKEN_SUCCESS,
  SWITCH_CLIENT_ID_FOR_MIMIC,
  TERMS_AND_CONDITION_POLICY_SUCCESS,
} from "../../constants";
import jwt from "jwt-decode";
import moment from "moment";
import { clientType } from "./clientManagementReducer";

export interface authUserType {
  name: string;
  isSuperAdmin: boolean;
  userName: string;
  designation: string;
  email: string;
  mobile: string;
  status: string;
  exp: string;
  iss: string;
  aud: string;
  userId: string;
  clientId: number;
  isClientAdmin?: boolean;
  isAgreeWithPrivacyPolicy?: boolean;
  isAgreeWithTermsCondition?: boolean;
}

interface initialTypes {
  isLoading: boolean;
  user: authUserType | null;
  token: string | null;
  refreshToken: string | null;
  roles: string[] | null;
  loggedInAt: moment.Moment | null;
  clientDetails?: clientType | undefined;
}

interface actionType {
  type: string;
  payload: any;
}

const initialState: initialTypes = {
  isLoading: false,
  user: null,
  token: null,
  roles: null,
  loggedInAt: null,
  refreshToken: null,
};

export default function reducer(
  state: initialTypes = initialState,
  action: actionType
) {
  switch (action.type) {
    case LOGIN_REQUEST:
      state.isLoading = true;
      return { ...state };
    case LOGIN_SUCCESS:
      state.isLoading = false;
      const user: authUserType = jwt(action.payload.token);
      const beUser: any = jwt(action.payload.token);
      state.user = user;
      state.user.isSuperAdmin = beUser?.isSuperAdmin?.toLowerCase() === "true";
      state.user.isClientAdmin =
        beUser?.isClientAdmin?.toLowerCase() === "true";
      state.user.isAgreeWithPrivacyPolicy =
        beUser?.isAgreeWithPrivacyPolicy?.toLowerCase() === "true";
      state.user.isAgreeWithTermsCondition =
        beUser?.isAgreeWithTermsCondition?.toLowerCase() === "true";
      state.user.clientId = parseInt(beUser?.clientId);
      state.token = action.payload.token;
      state.refreshToken = action.payload.refreshToken;
      state.loggedInAt = moment();
      return { ...state };
    case LOGIN_FAILURE:
      state.isLoading = false;
      return { ...state };
    case LOGOUT_SUCCESS:
      return { ...initialState };
    case AUTH_FETCH_USER_ROLES_REQUEST:
      state.isLoading = true;
      return { ...state };
    case AUTH_FETCH_USER_ROLES_SUCCESS:
      state.isLoading = false;
      state.roles = [...action?.payload?.data];
      return { ...state };
    case AUTH_FETCH_USER_ROLES_FAILURE:
      state.isLoading = false;
      return { ...state };
    case AUTH_FETCH_CLIENT_DETAILS_REQUEST:
      state.isLoading = true;
      return { ...state };
    case AUTH_FETCH_CLIENT_DETAILS_SUCCESS:
      state.isLoading = false;
      state.clientDetails = action?.payload?.data;
      return { ...state };
    case AUTH_FETCH_CLIENT_DETAILS_FAILURE:
      state.isLoading = false;
      return { ...state };

    case REFRESH_TOKEN_REQUEST:
      state.isLoading = true;
      return { ...state };
    case REFRESH_TOKEN_SUCCESS:
      const userRefreshed: authUserType = jwt(action.payload.accessToken);
      const currentUser: any = jwt(action.payload.accessToken);
      state.isLoading = false;
      const prevIsSuperAdmin : boolean = !!state?.user?.isSuperAdmin;
      const prevClientId : any = state?.user?.clientId;
      state.user = userRefreshed;
      state.user.isSuperAdmin = prevIsSuperAdmin;
      state.user.isClientAdmin =
        currentUser?.isClientAdmin?.toLowerCase() === "true";
      state.user.isAgreeWithPrivacyPolicy =
        currentUser?.isAgreeWithPrivacyPolicy?.toLowerCase() === "true";
      state.user.isAgreeWithTermsCondition =
        currentUser?.isAgreeWithTermsCondition?.toLowerCase() === "true";
      state.user.clientId = prevClientId;
      state.token = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
      state.loggedInAt = moment();
      return { ...state };
    case REFRESH_TOKEN_FAILED:
      state.isLoading = false;
      return { ...state };

    case PRIVACY_POLICY_SUCCESS:
      state.isLoading = false;
      return {
        ...state,
        user: { ...state?.user, isAgreeWithPrivacyPolicy: true },
      };
    case TERMS_AND_CONDITION_POLICY_SUCCESS:
      state.isLoading = false;
      return {
        ...state,
        user: { ...state?.user, isAgreeWithTermsCondition: true },
      };

    case SWITCH_CLIENT_ID_FOR_MIMIC:
      if (state.user) {
        state.user = {
          ...state.user,
          clientId: action.payload.isSuperAdmin
            ? null
            : action.payload.clientId,
          isSuperAdmin: action.payload.isSuperAdmin,
        };
      }
      return { ...state };
    default:
      return state;
  }
}
