import React, {useState} from 'react';
import CustomModal from "./CustomModal";

interface propsTypes {
    isOpenConfirmModal: boolean;
    title?: string;
    message?: string;
    onCanceled?: () => void;
    onConfirm?: () => void;
    btn1Text: string;
    btn1Class: string;
    btn2Text: string;
    btn2Class: string;
    icon:JSX.Element

}

export function ConfirmModal({
                                 isOpenConfirmModal,
                                 title = "Confirm Modal",
                                 message,
                                 onCanceled,
                                 onConfirm,
                                 btn1Text="No",
                                 btn1Class="btn btn-outline-secondary btn-sm",
                                 btn2Text="Yes",
                                 btn2Class="btn btn-danger btn-sm",
                                 icon=<i className='bx bx-error-circle display-4 app-col link-warning'></i>,
                             }: propsTypes) {
    const [isOpenModal, setIsOpenModal] = useState<boolean>(isOpenConfirmModal);
    return (
        <CustomModal size="sm" isClose={!isOpenModal}
                     title={title}
                     onClose={() => {
                         setIsOpenModal(false);
                         onCanceled && onCanceled();
                     }}>
            <>
                <div className="modal-body p-3">
                    <div className="row m-0 gap-3">
                        <div className="col d-flex align-items-center py-2">
                            <div className="d-flex justify-content-center align-items-center">
                                     <span className="d-flex justify-content-center align-items-center">
                                        {icon}
                                     </span>
                                <span className="d-flex justify-content-center align-items-center mb-0 ms-3">
                                        {message}
                                     </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer p-3 pt-0">
                    <div className="row m-0">
                        <div className="col p-0 d-flex align-items-end justify-content-end">
                            <div className="p-0 d-flex flex-wrap d-grid gap-2">
                                <button type="button"
                                        onClick={() => {
                                            setIsOpenModal(false);
                                            onCanceled && onCanceled()
                                        }}
                                        className={btn1Class} data-bs-dismiss="modal">
                                    {btn1Text}
                                </button>
                                <button type="submit"
                                        onClick={() => onConfirm && onConfirm()}
                                        className={btn2Class}>
                                    {btn2Text}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </>


        </CustomModal>
    )
}