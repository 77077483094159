import { IClientTypeCheckBox } from "../components/modal/ClientListModal";
import { clientType } from "../redux/modules/clientManagementReducer";

export function TransformClientsToCheckBoxType(
  clients: clientType[],
  isSelected?: boolean
): IClientTypeCheckBox[] {
  let Transformed: IClientTypeCheckBox[] = clients.map((element) => ({
    ...element,
    isSelected: isSelected ? isSelected : false,
  }));

  return Transformed;
}
