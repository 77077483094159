import React, {useEffect, useState} from 'react';
import {connect, useDispatch} from "react-redux";
import {authUserType} from "../../../redux/modules/userReducer";
import {logOut} from "../../../actions/userAction";
import downArrow from '../../../assets/img/down-arrow-svgrepo-com.svg';
import {clientType} from "../../../redux/modules/clientManagementReducer";
import {getClientsForMimik} from "../../../actions/clientAction";
import AccessControl from '../../../config/AccessControl';
import {AccessControlType, UserPermissionsConstant} from '../../../constants/constants';
import {SWITCH_CLIENT_ID_FOR_MIMIC} from "../../../constants";
import {getClientDetailsByClientId} from "../../../actions/authAction";
import {useNavigate} from "react-router-dom";
import _ from 'lodash';
import { getWindowSize } from '../../../config/commonMethods';

interface propsType{
    user?:authUserType,
    clientDetails?:clientType | undefined,
    clients?: clientType[] | [],
    allowedPermissions?: string[];
    roles?:string[]
}
const AppHeader = ({user,clientDetails,clients,roles}:propsType) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [selectedClientId , setSelectedClientId] = useState<number | null>(null);
    const [windowSize, setWindowSize] = useState(getWindowSize());
    
    useEffect(()=>{
       if(roles && roles.includes(UserPermissionsConstant.MimicAccess) && _.isEmpty(clients)) {
           dispatch(getClientsForMimik());
       }
    },[roles]);

    useEffect(() => { 
        function handleWindowResize() { setWindowSize(getWindowSize()); }
        window.addEventListener('resize', handleWindowResize);
        
        return () => { window.removeEventListener('resize', handleWindowResize); };
      }, []);

    const toggleClass = () => {
        document.body.classList.toggle('layout-menu-expanded'); 
        if (windowSize.innerWidth >= 1200) { 
            if( $(".aside-menu-toggle").css('display').toLowerCase() == 'none') {
                $(".aside-menu-toggle").css("display","");
            }else $(".aside-menu-toggle").css("display","none");
            
            if ( $(".layout-page-toggle").hasClass("layout-page-remove-padding") ){
                $(".layout-page-toggle").removeClass('layout-page-remove-padding');
            } else $(".layout-page-toggle").addClass('layout-page-remove-padding');   

        }
    };    
    const logout = () => {
        dispatch(logOut())
    }
    useEffect(()=>{
        user?.clientId && setSelectedClientId(user?.clientId)
    },[user]);
    function handleOnClientChange(selectedValue: any){
        dispatch({
            type: SWITCH_CLIENT_ID_FOR_MIMIC,
            payload: {isSuperAdmin: !selectedValue , clientId:parseInt(selectedValue)},
        });
        navigate('/');
        setSelectedClientId(selectedValue);
        selectedValue && dispatch(getClientDetailsByClientId(selectedValue));
    }

    
    return (
        
        <nav
            className="px-4 position-fixed start-0 end-0 layout-navbar navbar navbar-expand-xl align-items-center bg-navbar-theme bg-dark"
            id="layout-navbar"
        ><div>
            <div className='w-100 d-flex align-items-center justify-content-start'>
            <div className="app-brand demo px-0 pe-3">
                <a className="app-brand-link cursor-pointer"
                 onClick={()=>navigate("/")}>
                    <img src="/static/media/logo.8a9ad6a32d9b85ae8f4a.png" width="50" alt="logo" height="50" title="Home" />
                </a>
            </div>
            <div className="layout-menu-toggle navbar-nav align-items-xl-center  rounded-1 border ms-xl-2 d-xl-block d-flex flex-column me-3">           
                <a id="burgerID" className="nav-item nav-link rounded-1  p-1 "
                   onClick={toggleClass}  href="javascript:void(0)" >
                    <i className="bx bx-menu bx-sm"></i>
                </a>         
                
            </div></div>
            </div>
            <div className='d-flex align-items-center w-75'>
                {!user?.isSuperAdmin && ( 
                    <p className='mb-0 text-white  p-2 rounded-2 fw-bold' >
                    {
                    clientDetails?.companyName} </p>    
                ) }
                

                </div>    
            <div className="navbar-nav-right d-flex align-items-center" id="navbar-collapse">
                <ul className="navbar-nav flex-row align-items-center ms-auto">
                    <AccessControl type={AccessControlType.HIDE}
                    allowedPermissions={[UserPermissionsConstant.MimicAccess]}>
                    <li className="pe-3 py-2 ps-3 border-start">
                        <div className="mimic-icon"></div>
                    </li>                    
                    <li className="nav-item navbar-dropdown dropdown-user  px-3 py-3dropdown ">
                        <select className="form-select form-select-sm"
                            onChange={e => handleOnClientChange(e.target.value)} id="status">
                            <option value="">
                                <u>{user?.clientId ? 'Switch to Admin' : 'Switch to Client'}</u>
                            </option>
                            {Array.isArray(clients) && clients.map(c => <option 
                            selected={c.clientId === selectedClientId} 
                            value={c.clientId}>{c.companyName}</option>)}
                        </select>
                    </li></AccessControl>
                    <li className="nav-item navbar-dropdown dropdown-user dropdown ps-3 py-1 border-start">
                        <a className="nav-link dropdown-toggle hide-arrow d-flex justify-content-center align-items-center cursor-pointer"

                           data-bs-toggle="dropdown">
                            <div className="avatar avatar-online">
                                <div className="avatar-icon"></div>
                                <div className="profile-img d-inline-block"></div>
                            </div>
                            <div className='d-grid px-3'>
                                <span className="fw-semibold d-block lh-1">{user?.name}</span>
                                <small className="text-muted d-block lh-1 mt-1">
                                    {
                                        user?.isSuperAdmin ? 'Super Admin'
                                        :
                                        <AccessControl type={AccessControlType.HIDE}
                                            allowedPermissions={[UserPermissionsConstant.MimicAccess]}>
                                                Super Admin
                                        </AccessControl>
                                    }
                                </small>
                            </div>
                            <div className="d-flex">
                                <i className='bx bx-chevron-down fs-3'></i>
                            </div>
                        </a>
                        <ul className={`dropdown-menu dropdown-menu-end py-0 end-0 start-auto`}>
                            <li>
                                <a className="dropdown-item pointer" 
                                    onClick={()=> navigate('/my-profile')}>
                                    <i className="bx bx-user me-2"></i>
                                    <span className="align-middle">My Profile</span>
                                </a>
                            </li>
                            {/* <li>
                                <a className="dropdown-item pointer" 
                                onClick={()=> navigate('/account-setting')}>
                                    <i className="bx bx-cog me-2"></i>
                                    <span className="align-middle">Account Settings</span>
                                </a>
                            </li> */}
                            <li>
                                <div className="dropdown-divider my-0"></div>
                            </li>
                            <li>
                                <a className="dropdown-item pointer" onClick={() => logout()}>
                                <i className='bx bx-log-out me-2' ></i>
                                    <span className="align-middle">Log Out</span>
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </nav>
    )
};

const mapStateToProps = (state: any) => {
    return {
        user: state?.auth?.user,
        roles: state?.auth?.roles,
        clientDetails: state?.auth?.clientDetails,
        clients: state?.commonManagement.clients
    };
};
export default connect(mapStateToProps, null)(AppHeader);