import _ from "lodash";
import React from "react";
import { connect, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  editAuditTemplate,
  EditLocalTemplateHeader,
  getCriteriaAuditLogByID,
  getCriteriaListForCorrectiveLog,
  GetTemplateByIDForCorrectiveLog,
  setCorrectiveActionLog,
} from "../../../actions/auditActions";
import CustomModal from "../../../components/modal/CustomModal";
import {
  ClientUser,
  CriteriaCorrectiveLog,
  ICriteriaCorrectiveLogResponseType,
  ILocatlTemplateInterface,
} from "../../../redux/modules/auditCorrectiveLogReducer";
import { clientType } from "../../../redux/modules/clientManagementReducer";
import AppLayout from "../../Layout/AppLayout";
import {
  CorrectiveActionLogModal,
  ICorrectiveLogData,
} from "../../../components/modal/CorrectiveActionLogModal";
import moment from "moment";
import { useLocation, useParams } from "react-router-dom";
import jsPDF from "jspdf";
import logoImage from "../../../assets/img/logo/logo.png";
import autoTable from "jspdf-autotable";
import AccessControl from "../../../config/AccessControl";
import {
  AccessControlType,
  UserPermissionsConstant,
} from "../../../constants/constants";
import { UPDATE_CRITERIA_LIST_VALUE } from "../../../constants";
import { toLocalformatDate,toLocalformatDateTime } from "../../../config/commonMethods";

interface IAuditCorrectiveLogProps {
  isLoading?: boolean;
  error?: string;
  template?: ILocatlTemplateInterface;
  clients?: clientType[];
  criteriaList: CriteriaCorrectiveLog[];
  clientDetails?: clientType;
}
const AuditCorrectiveLog = ({
  isLoading,
  error,
  template,
  criteriaList,
  clientDetails
}: IAuditCorrectiveLogProps) => {
  const dispatch = useDispatch();

  const { state } = useLocation();
  let { templateId } = useParams();
  const [selectedAssignee, setAssignee] = React.useState<ClientUser>();
  const [isOpenModal, setisOpenModal] = React.useState(false);
  const [selectedLog, setSelectedCriteriaLog] =
    React.useState<CriteriaCorrectiveLog>();

  const [onChangeFindingsText, setFindingsChange] = React.useState<{
    text: string;
    key: number;
  }>();

  React.useEffect(() => {
    fetchTemplateByIDForCorrectiveLog();
  }, []);

  const fetchTemplateByIDForCorrectiveLog =()=>{
    dispatch(
      GetTemplateByIDForCorrectiveLog(templateId!!, (error: any) => {
        toast.error(
          error?.response?.data?.data
            ? error?.response?.data?.data
            : null
        );
      })
    );
  }

  React.useEffect(() => {
    if (template?.assignedUserId) {
      setAssignee(findAssigneeFromId(template?.assignedUserId));
    }
  }, [template]);

  React.useEffect(() => {
    getCriteriaListforHeader();
  }, []);

  const getCriteriaListforHeader = () => {
    dispatch(
      getCriteriaListForCorrectiveLog(parseInt(templateId!!), (error: any) => {
        toast.error(
          error?.response?.data?.data
            ? error?.response?.data?.data
            : "Criteria fetching failed!"
        );
      })
    );
  };

  const findAssigneeFromId = (id: string) =>
    template?.clientUsers.find((o) => o.userId == id);

  const findCriteriaFromID = (id: number) =>
    criteriaList.find((o) => o.localTemplateCriteriaId == id);

  const onChangeAssignTo = (id: string) => {
    if (template?.isClosed) return;
    setAssignee(findAssigneeFromId(id));
    dispatch(
      EditLocalTemplateHeader(
        template?.localTemplateHeaderId!!,
        {
          assignedUserId: findAssigneeFromId(id)?.userId!!,
          clientId: template?.clientId!!,
          globalTemplateHeaderId: template?.globalTemplateHeaderId!!,
          isActive: template?.isActive!!,
          isDeleted: template?.isDeleted!!,
          isGlobalTemplateModified: template?.isGlobalTemplateModified!!,
          localTemplateCode: template?.localTemplateCode!!,
          localTemplateDescription: template?.localTemplateDescription!!,
          localTemplateHeaderId: template?.localTemplateHeaderId!!,
          localTemplateName: template?.localTemplateName!!,
        },
        (error: any) => {
          toast.error(
            error?.response?.data?.data?.data
              ? error?.response?.data?.data
              : "Template update failed!"
          );
        },
        (response: any) => {
          toast.success(
            response?.message ? response?.message : "Template update Success!"
          );
        }
      )
    );
  };

  const onChangeComplience = (
    complies: number | null,
    item: CriteriaCorrectiveLog
  ) => {
    let Criteria = findCriteriaFromID(item.localTemplateCriteriaId);
    dispatch(
      setCorrectiveActionLog(
        {
          clientId: 0,
          isComplies: complies,
          correctiveActionLogId: Criteria?.correctiveActionLogId!!,
          correctiveActions: Criteria?.correctiveActions!!,
          findings: Criteria?.findings!!,
          isClosed: complies ? null : false,
          localTemplateCriteriaId: Criteria?.localTemplateCriteriaId!!,
          localTemplateHeaderId: Criteria?.localTemplateHeaderId!!,
          responsibleUserId: Criteria?.responsibleUserId!!,
        },
        (error: any) => {
          toast.error(
            error?.response?.data?.data
              ? error?.response?.data?.data
              : "Corrective Log Update failed!"
          );
        },
        (response: ICriteriaCorrectiveLogResponseType) => {
          toast.success(
            response?.message
              ? response?.message
              : "Corrective Log Update Success!"
          );
          getCriteriaListforHeader();
          fetchTemplateByIDForCorrectiveLog();
        }
      )
    );
  };

  const onChangeTextFindings = (
    finding: string,
    item: CriteriaCorrectiveLog
  ) => {
    setFindingsChange({ text: finding, key: item.localTemplateCriteriaId });
  };

  const onChangeFindings = (finding: string, item: CriteriaCorrectiveLog) => {
    if (!finding) return;

    console.log(finding,item);
    dispatch({
      type:UPDATE_CRITERIA_LIST_VALUE,
      payload: {...item,findings:finding}
    });

    let listOfCriterias = [...criteriaList];
    let obj = findCriteriaFromID(item.localTemplateCriteriaId);
    let getIndex = listOfCriterias.indexOf(obj!!);

    dispatch(
      setCorrectiveActionLog(
        {
          clientId: 0,
          isComplies: item.isComplies,
          correctiveActionLogId: item?.correctiveActionLogId!!,
          correctiveActions: item?.correctiveActions!!,
          findings: finding,
          isClosed: item.isClosed!!,
          localTemplateCriteriaId: item?.localTemplateCriteriaId!!,
          localTemplateHeaderId: item?.localTemplateHeaderId!!,
          responsibleUserId: item?.responsibleUserId!!,
        },
        (error: any) => {
          toast.error(
            error?.response?.data?.data
              ? error?.response?.data?.data
              : "Corrective Log Update failed!"
          );
          dispatch({
            type:UPDATE_CRITERIA_LIST_VALUE,
            payload: {...item}
          });
        },
        (response: ICriteriaCorrectiveLogResponseType) => {
          toast.success(
            response?.message
              ? response?.message
              : "Corrective Log Update Success!"
          );
          getCriteriaListforHeader();
        }
      )
    );
  };

  const selectCorrectiveLog = (log: CriteriaCorrectiveLog) => {
    //if (template?.isClosed) return; Why is this commented : CR from Customer Referance HCSL 357 By Yohan
    setSelectedCriteriaLog(log);
    setisOpenModal(true);
    dispatch(
      getCriteriaAuditLogByID(
        log.correctiveActionLogId,
        (error: any) => {
          toast.error(
            error?.response?.data?.data
              ? error?.response?.data?.data
              : "Criteria Log fetching failed!"
          );
        },
        (response: ICriteriaCorrectiveLogResponseType) => {
          console.log("fetch criteria log for id", response);
        }
      )
    );
  };

  const setCorrectiveActionfromModal = (data: ICorrectiveLogData) => {
    dispatch(
      setCorrectiveActionLog(
        {
          clientId: 0,
          isComplies: data.item.isComplies,
          correctiveActionLogId: data.item.correctiveActionLogId!!,
          correctiveActions: data.correctiveActions,
          findings: data?.item.findings!!,
          isClosed: data?.markClosed!!,
          localTemplateCriteriaId: data?.item.localTemplateCriteriaId!!,
          localTemplateHeaderId: data?.item.localTemplateHeaderId!!,
          responsibleUserId: data?.personResponsible,
        },
        (error: any) => {
          toast.error(
            error?.response?.data?.data
              ? error?.response?.data?.data
              : "Criteria fetching failed!"
          );
        },
        (response: ICriteriaCorrectiveLogResponseType) => {
          toast.success(
            response?.message
              ? response?.message
              : "Corrective Log Update failed!"
          );
          getCriteriaListforHeader();
          fetchTemplateByIDForCorrectiveLog();
          setisOpenModal(false);
        }
      )
    );
  };

  const generatePDF = () => {
    if (_.isEmpty(criteriaList)) {
      toast.info("There is no data to print");
      return;
    }
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; //// portrait or landscape
    var doc = new jsPDF(orientation, unit, size);

    doc.addImage(logoImage, "PNG", 10, 10, 70, 70);
    doc.setFont("times");
    doc.setTextColor(65, 144, 197);
    doc.text("GIA+ Quality Assurance system", 100, 30);
    doc.text("__________________________", 100, 40);
    doc.setTextColor(0, 0, 0);
    doc.text("Internal Audit Results", 100, 60);

    autoTable(doc, {
      head: [],
      margin: {
        top: 80,
      },
      theme:'plain',
      body: [
        ['Client', clientDetails?.companyName ? clientDetails?.companyName :'',
         'Audit Name' ,template?.localTemplateName ? template?.localTemplateName :''],
        ['Code',template?.localTemplateCode ? template?.localTemplateCode :'',
         'Closed Date', template?.completedDate ? /*moment(template?.completedDate).format("DD/MM/YYYY")*/ toLocalformatDate(template?.completedDate) :''],
      ],
      columnStyles: {
        0:{ minCellWidth: 80, textColor:'#3F3F3F'},
        1:{ overflow: "linebreak",minCellWidth:100, textColor:'#6D6D6D'},
        2:{ minCellWidth:80, textColor:'#3F3F3F'},
        3:{ overflow: "linebreak",minCellWidth:100, textColor:'#6D6D6D'},
      }
    })
      const headers: string[] = [
      "QNo",
      "Criteria",
      "Complies",
      "Findings",
      "Responsible",
      "Corrective Actions",
      "Closed",
    ];
      const headKey: string[] = [
      "localTemplateCriteriaCode",
      "localTemplateCriteriaDescription",
      "isComplies",
      "findings",
      "responsibleUserFullName",
      "correctiveActions",
      "isClosed",
    ];
    let data: any = [];
    criteriaList?.forEach((row: any) => {
      let rowData: any[] = [];
      headKey?.forEach((key: string) => {
        if(key === "isClosed"){
          rowData.push( row[key] === null ? "" : row[key] ? "Yes" : "No");
        }else if(key === "isComplies" ){
          if(row[key] === null) rowData.push("");
          if(row[key] === 1) rowData.push("Yes");
          if(row[key] === 0) rowData.push("No");
          if(row[key] === 2) rowData.push("Not Applicable");
        }else
        rowData.push(row[key]);
      });
      data.push(rowData);
      rowData = [];
    });

    autoTable(doc, {
      head: [headers],
      margin: {
        top: 140,
      },
      body: data,
        columnStyles: {
        0:{ overflow: "linebreak", minCellWidth: 35 },
        1:{overflow: "linebreak",minCellWidth:100},
        2:{minCellWidth:55},
        3:{overflow: "linebreak",minCellWidth:100},
        4:{minCellWidth:80},
        5:{overflow: "linebreak",minCellWidth:120},
        6:{minCellWidth:50},
      }
    });
    doc.save(`Internal-Audit-Results-${ template?.localTemplateCode +"-"+ moment().format('DD-MM-YYYY')}.pdf`);
  };

  return (
    <AppLayout>
      <>
        <div className="row">
          <div className="col-md">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb breadcrumb-style1 d-flex align-items-center">
                <li className="breadcrumb-item d-flex align-items-center">
                  <a href="javascript:void(0);">Home</a>
                </li>
                <li className="breadcrumb-item d-flex align-items-center active">
                  Internal Audit Results
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-md">
            <div className="card shadow-sm">
              <div className="d-flex justify-content-between align-items-center card-header mb-0 p-3 border-bottom">
                <div>
                  <h6 className="m-0">Internal Audit Results</h6>
                </div>
                <div className="d-flex flex-wrap justify-content-end">
                  <AccessControl
                    type={AccessControlType.DISABLE}
                    allowedPermissions={[
                      UserPermissionsConstant.ViewPrintAudit,
                      UserPermissionsConstant.MimicAccess,
                    ]}
                  >
                    <button
                      type="button"
                      className="btn btn-outline-secondary btn-sm"
                      onClick={() => generatePDF()}
                    >
                      <span className="tf-icons bx bx-printer"></span>
                      &nbsp; Print
                    </button>
                  </AccessControl>
                </div>
              </div>
              <div className="card-body border-2 border-bottom p-3">
                <div className="row">
                  <div className="col-2">
                    <p className="fw-bold">Client</p>
                  </div>
                  <div className="col-3">
                    <p>{template?.companyName}</p>
                  </div>
                  <div className="col-2">
                    <p className="fw-bold">Date Taken</p>
                  </div>
                  <div className="col-5">
                    <p>
                      {
                      //moment(template?.startDate).format("DD/MM/YYYY")
                      toLocalformatDate(template?.startDate)
                      }
                      
                      </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-2">
                    <p className="fw-bold">Code</p>
                  </div>
                  <div className="col-3">
                    <p>{template?.localTemplateCode}</p>
                  </div>

                  <div className="col-2">
                    <p className="fw-bold">Description</p>
                  </div>
                  <div className="col-5">
                    <p>{template?.localTemplateDescription}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-2">
                    <p className="fw-bold">Audit Name</p>
                  </div>
                  <div className="col-3">
                    <p>{template?.localTemplateName}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-2">
                    <p className="fw-bold">Assign To</p>
                  </div>
                  <div className="col-3">
                    <AccessControl
                      type={AccessControlType.DISABLE}
                      allowedPermissions={[
                        UserPermissionsConstant.PerformAudit,
                        UserPermissionsConstant.MimicAccess,
                      ]}
                    >
                      <select
                        id="smallSelect"
                        className="form-select form-select-sm"
                        onChange={(e) => onChangeAssignTo(e.target.value)}
                        disabled={template?.isClosed}
                      >
                      <option value="">
                        <u>Select User</u>
                      </option>
                        {_.isEmpty(template?.assignedUserId) && (
                          <option selected={true}>Select</option>
                        )}
                        {!_.isEmpty(template?.clientUsers) &&
                          template?.clientUsers.map((o:any) => {
                            return (
                              <option
                                selected={template.assignedUserId == o.userId}
                                value={o.userId}
                              >
                                {o.responsibleUserFullName}
                              </option>
                            );
                          })}
                      </select>
                    </AccessControl>
                  </div>
                  <div className="col-2">
                    <p className="fw-bold">Designation</p>
                  </div>
                  <div className="col-5">
                    <p>
                      {" "}
                      {selectedAssignee
                        ? selectedAssignee?.designation
                        : "-"}{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div className="card-body p-3">
                <div className="table-responsive text-nowrap">
                  <AccessControl
                    type={AccessControlType.DISABLE}
                    allowedPermissions={[
                      UserPermissionsConstant.PerformAudit,
                      UserPermissionsConstant.MimicAccess,
                    ]}
                  >
                    <table className="table table-bordered table-hover">
                      <thead>
                                              <tr>
                                                  <th className="text-capitalize fw-semibold text-center">
                                                      Question
                                                  </th>
                          <th className="text-capitalize fw-semibold col-3">
                            Criteria
                          </th>
                          <th className="text-capitalize fw-semibold col-1">
                            Complies
                          </th>
                          <th className="col-3 text-capitalize fw-semibolde">
                            Findings
                          </th>
                          <th className="text-capitalize fw-semibold text-center col-2">
                            Responsible
                          </th>
                          <th style={{maxWidth:'250px!important', wordWrap:'break-word'}}
                           className="text-capitalize fw-semibold text-center col-3">
                            Corrective Actions
                          </th>
                          <th className="text-capitalize fw-semibold text-center">
                            Closed
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {!_.isEmpty(criteriaList) &&
                          criteriaList.map((obj) => {
                            return (
                                <tr key={obj.localTemplateCriteriaId}>
                                    <td className="text-center">
                                        {obj.localTemplateCriteriaCode}
                                    </td>
                                <td className="text-wrap">
                                  {obj.localTemplateCriteriaDescription}
                                </td>
                                <td>
                                  <div className="btn-group d-flex justify-content-center">
                                    <button
                                      type="button"
                                      className="form-select form-select-sm"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                     /* disabled={
                                        obj.isClosed!! || template?.isClosed
                                      } Why is this commented : CR from Customer Referance HCSL 357 By Yohan*/
                                    >
                                      {obj.isComplies == null
                                        ? "?"
                                        : obj.isComplies === 1
                                        ? "Yes"
                                        : obj.isComplies === 0 
                                        ? "No"
                                        : obj.isComplies === 2 
                                        ? "Not Applicable" : ""}
                                    </button>
                                    <ul className="dropdown-menu m-0 p-0">
                                      <li
                                        onClick={() =>
                                          onChangeComplience(1, obj)
                                        }
                                        className="border-bottom"
                                      >
                                        <a
                                          className="dropdown-item p-1 px-2 mx-0"
                                          href="javascript:void(0);"
                                        >
                                          Yes
                                        </a>
                                      </li>
                                      <li 
                                          onClick={() =>
                                            onChangeComplience(0, obj)
                                          }
                                          className="border-bottom">
                                        <a
                                          className="dropdown-item p-1 px-2 mx-0"
                                          href="javascript:void(0);"
                                        >
                                          No
                                        </a>
                                      </li>
                                      <li 
                                      onClick={() =>
                                        onChangeComplience(2, obj)
                                      }>
                                        <a
                                          className="dropdown-item p-1 px-2 mx-0"
                                          href="javascript:void(0);"
                                        >
                                          Not Applicable
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </td>
                                <td>
                                  <textarea
                                    className="form-control form-control-sm"
                                    id="exampleFormControlTextarea1"
                                    key={obj.localTemplateCriteriaId}
                                    rows={2}
                                    onChange={(e) =>
                                      onChangeTextFindings(e.target.value, obj)
                                    }
                                    onBlur={(e) => {
                                      onChangeFindings(e.target.value, obj);
                                    }}
                                    value={
                                      onChangeFindingsText?.key ==
                                      obj.localTemplateCriteriaId
                                        ? onChangeFindingsText?.text
                                        : obj.findings
                                    }
                                   /* disabled={
                                      obj.isComplies == null ||
                                      template?.isClosed
                                    } Why is this commented : CR from Customer Referance HCSL 357 By Yohan */
                                  ></textarea>
                                </td>
                                <td className="text-wrap text-center">
                                  <a
                                    href="#"
                                    className="d-flex justify-content-center text-decoration-underline"
                                    onClick={() => selectCorrectiveLog(obj)}
                                  >
                                    {obj.responsibleUserId ? (
                                      obj.responsibleUserFullName
                                    ) : (
                                      <div className="ar-icon"></div>
                                    )}
                                  </a>
                                </td>
                                <td style={{wordBreak:"break-all"}} className="text-wrap text-center">
                                  <a
                                    href="#"
                                    className="d-flex justify-content-center text-decoration-underline"
                                    onClick={() => selectCorrectiveLog(obj)}
                                  >
                                    {obj.correctiveActions ? (
                                      obj.correctiveActions
                                    ) : (
                                      <div className="ar-icon"></div>
                                    )}
                                  </a>
                                </td>
                                <td className="text-center">
                                  {obj.isClosed === null ? 
                                  obj.isComplies ?  (
                                    <i className="bx bx-minus display-5 text-secondary"></i>
                                  ) :  (
                                    <i className="bx bx-question-mark display-5 text-secondary"></i>
                                  ) : obj.isClosed == true ? (
                                    <i className=" bx bx-check display-5 text-success"></i>
                                  ) : (
                                    <i className=" bx bx-x display-5 text-danger"></i>
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </AccessControl>
                </div>
              </div>
            </div>

            {isOpenModal && (
              <CustomModal
                size="lg"
                isClose={!isOpenModal}
                title="Corrective Action"
                onClose={() => setisOpenModal(false)}
              >
                <CorrectiveActionLogModal
                  CorrectiveLogData={selectedLog!!}
                  auditName={template?.localTemplateName!!}
                  auditCode={template?.localTemplateCode!!}
                  clientUsers={template?.clientUsers!!}
                  saveCorrectiveLog={(data) =>
                    setCorrectiveActionfromModal(data)
                  }
                  takenBy={`${template?.startBy}`}
                  //takenDate = {`${moment(template?.startDate).format("DD/MM/YYYY hh:mm:ss")}`}
                  takenDate = {`${toLocalformatDateTime(template?.startDate)}`}
                  isTemplateClosed={template?.isClosed}
                  onClose={() => setisOpenModal(false)}
                />
              </CustomModal>
            )}
          </div>
        </div>
      </>
    </AppLayout>
  );
};
const mapStateToProps = (state: any) => {
  const correctiveLogsState = state?.correctiveLogs;
  const clientManageMent = state?.clientManagement;
  return {
    isLoading: correctiveLogsState?.isLoading,
    error: correctiveLogsState?.error,
    template: correctiveLogsState?.selectedTemplate,
    criteriaList: correctiveLogsState?.criteriaList,
    clientDetails:state?.auth?.clientDetails
  };
};
export default connect(mapStateToProps, null)(AuditCorrectiveLog);
