import { useFormik } from 'formik';
import React, {useEffect, useState} from 'react';
import DataTable, { TableStyles } from 'react-data-table-component';
import { useDispatch, connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Input from '../../../components/elements/Input';
import { BootyPagination } from '../../../components/pagination/BootyPagination';
import {apiResponseType, userType} from '../../../config/commonTypes';
import {AccessControlType, userActionType, UserPermissionsConstant} from '../../../constants/constants';
import AppLayout from '../../Layout/AppLayout';
import {getAllUserByClientId} from "../../../actions/userAction";
import {ConfirmModal} from "../../../components/modal/ConfirmModal";
import {deleteStateUserByUserId} from "../../../actions/clientAction";
import {toast} from "react-toastify";
import {jsPDF} from "jspdf";
import autoTable from "jspdf-autotable";
import moment from "moment";
import AccessControl from '../../../config/AccessControl';
import _ from 'lodash';
import logoImage from "../../../assets/img/logo/logo.png";
import { clientType } from '../../../redux/modules/clientManagementReducer';

function ClientUserList( {user, selectedClientDetails}:any) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [clientUserList, setClientUserList] = useState<userType[]>();
    const [clientUserFormattedList, setClientUserFormattedList] = useState<userType[]>([]);
    const [isDelete , setIsDelete] = useState<boolean>();
    const [userIdForDelete , setUserIdForDelete] = useState<string|null>();

    useEffect(() => {
        user?.clientId && getAllUserByClientId(user.clientId,(usersData:userType[])=>{
            setClientUserList(usersData.filter(u=> u.id !== user.userId));
        });
    }, [user]);

    const indicateStatus = (superadmin: userType) => {
        return (
            <span className={`badge bg-label-${superadmin.isActive ? 'success' : 'secondary'} d-grid`}>
                {superadmin.isActive ? "Active" : "Inactive"}</span>
        );
    }

    const userActions = (id: any) => {
        return (
            <span className="d-flex justify-content-center gap-1">
                <AccessControl type={AccessControlType.DISABLE}
                               allowedPermissions={[UserPermissionsConstant.EditClientUser, UserPermissionsConstant.MimicAccess]}>
                <button type="button"
                    onClick={() => navigate({
                        pathname: '/edit-client-user-details/' + id
                    }, { state: { action: userActionType.update } })}
                    className="btn btn-outline-primary btn-xs app-btn-xs py-0">
                    <i className="bx bx-pencil  small"></i>
                </button>
                </AccessControl>
                <AccessControl type={AccessControlType.DISABLE}
                               allowedPermissions={[UserPermissionsConstant.ViewClientUser, UserPermissionsConstant.MimicAccess]}>
                <button type="button"
                    onClick={() => navigate({
                        pathname: '/view-client-user-details/' + id
                    }, { state: { action: userActionType.view } })}
                    className="btn btn-outline-primary btn-xs app-btn-xs py-0">
                    <i className="bx bx-show  small"></i>
                </button>
                </AccessControl>
                <AccessControl type={AccessControlType.DISABLE}
                               allowedPermissions={[UserPermissionsConstant.DeleteClientUser, UserPermissionsConstant.MimicAccess]}>
                <button type="button"
                    onClick={() => {
                        setIsDelete(true);
                        setUserIdForDelete(id);
                    }}
                    className="btn btn-outline-danger btn-xs app-btn-xs py-0">
                    <i className="bx bx-trash-alt  small"></i>
                </button>
                </AccessControl>
            </span>
        )
    }

    useEffect(() => {
        if (clientUserList && clientUserList?.length > 0) {
            const dataSet = formatDataTable(clientUserList);
            setClientUserFormattedList(dataSet);
        }
    }, [clientUserList]);

    const formatDataTable = (clients: userType[]) => {
        return clients?.map((user: userType) => {
            return (
                { actions: userActions(user?.id), indicator: indicateStatus(user), ...user }
            )
        });
    }

    const columns: any = [
        {
            name: "Name",
            selector: (row: any) => row.name,
            sortable: true,
        },
        {
            name: "Username",
            selector: (row: any) => row.userName,
            sortable: true
        },
        {
            name: "Designation",
            selector: (row: any) => row.designation,
            sortable: true
        },
        {
            name: "Email",
            selector: (row: any) => row.email,
            sortable: true
        },
        {
            name: "Action",
            selector: (row: any) => row.actions,
            sortable: false,
        }
    ];    
    
    const initialValues: any = {
        name: '',
        designation: '',
    }

    const customStyles: TableStyles = {
        headRow: {
            style: { backgroundColor: '#f2f2f2', border: '1px solid #d9dee3' }
        },
        rows: {
            style: { border: '1px solid #d9dee3' }
        },
    }
    
    const formik = useFormik({
        initialValues: initialValues,
        onSubmit: (value: any) => {
            const cloneALlOriginalUsers: userType[] = clientUserList && clientUserList?.length > 0 ? [...clientUserList] : [];
            const filterCloneUsers = cloneALlOriginalUsers?.filter(
                user => (value?.name ? user?.name.toLowerCase().includes(value?.name?.toLowerCase()) : true)
                    &&
                    (value?.email ? user?.email.toLowerCase().includes(value?.email?.toLowerCase()) : true)
            );
            const dataSet = formatDataTable(filterCloneUsers);
            setClientUserFormattedList(dataSet);
        }
    });

    const deleteUserById=(userIdForDelete:string)=>{
        setIsDelete(false);
        setUserIdForDelete(null);
        deleteStateUserByUserId(userIdForDelete ,
            (response:apiResponseType) => {
                toast.success(response?.message ? response.message : 'Successfully deleted user!');
                setClientUserList(clientUserList && clientUserList.filter(u=> u.id !== userIdForDelete));
            },
            (error:apiResponseType) => {
                toast.error(error?.message ? error.message : 'Delete user failed!');
            });
    }

    const generatePDF = () => {
        if (_.isEmpty(clientUserFormattedList)) {
            toast.info("There is no data to print");
            return;
          }
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; //// portrait or landscape
        var doc = new jsPDF(orientation, unit,size);

        doc.addImage(logoImage, "PNG", 10, 10, 70, 70);
        doc.setFont("times");
        doc.setTextColor(65, 144, 197);
        doc.text("GIA+ Quality Assurance system", 100, 30);
        doc.text("__________________________", 100, 40);
        doc.setTextColor(0, 0, 0);
        doc.text("User List", 100, 60);

        autoTable(doc, {
            head: [],
            margin: {
              top: 80,
            },
            theme:'plain',
            body: [
              ['Client', selectedClientDetails?.companyName ? selectedClientDetails?.companyName :'']
            ],
            columnStyles: {
              0:{ cellWidth: 80, textColor:'#3F3F3F'},
              1:{ overflow: "linebreak",minCellWidth:100, textColor:'#6D6D6D'},
            }
          });
          
    
        const headers :string[] = ['Name','Username', 'Designation','Email']
        const headKey :string[] = ['name','userName' ,'designation','email'];
        let data:any =[];
        clientUserFormattedList.forEach((row:any) => {
            let rowData :string[]=[];
            headKey?.forEach((key:string)=>{
                if(key === 'status'){
                    rowData.push(row?.indicator?.props?.children)
                }else rowData.push(row[key])
            });
            data.push(rowData);
            rowData=[];
        });

        autoTable(doc, {
            head: [headers],
            margin: {
                top: 100,
            },
            body: data,
        });
        doc.save(`User-List-${moment().format('DD-MM-YYYY-hh-mm-ss')}.pdf`)
    }

    return (
        <AppLayout>
            <>
                {isDelete && (
                    <ConfirmModal
                        onCanceled={()=>setIsDelete(false)}
                        isOpenConfirmModal={isDelete}
                        title={"Delete User"}
                        btn1Text={"Cancel"}
                        btn1Class={"btn btn-outline-secondary btn-sm"}
                        btn2Text={"Confirm"}
                        btn2Class="btn btn-danger btn-sm"
                        icon={<i className='bx bx-error-circle display-4 app-col link-warning'></i>}
                        message={"Are you sure you want to delete this item?"}
                        onConfirm={()=>userIdForDelete && deleteUserById(userIdForDelete)}
                    />
                )}
            <div className="row">
                    {/*Custom style1 Breadcrumb */}
                    <div className="col-md">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb breadcrumb-style1">
                                <li className="breadcrumb-item d-flex align-items-center">
                                    <a href="javascript:void(0);">Home</a>
                                </li>
                                <li className="breadcrumb-item d-flex align-items-center">User Management</li>
                                <li className="breadcrumb-item d-flex align-items-center active">Users</li>
                            </ol>
                        </nav>
                    </div>
                </div>

                <div className="row">
                    <form onSubmit={formik.handleSubmit} method="POST" >
                        <div className="col-md">
                            <div className="accordion" id="accordionExample">
                                <div className="card accordion-item shadow-sm active">
                                    <h6 className="accordion-header" id="headingOne">
                                        <button
                                            type="button"
                                            className="accordion-button border-bottom p-3"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#accordionOne"
                                            aria-expanded="true"
                                            aria-controls="accordionOne"
                                        >
                                            Search Filters
                                        </button>
                                    </h6>

                                    <div
                                        id="accordionOne"
                                        className="accordion-collapse collapse show"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body p-3 pt-2">
                                            <div className="row">
                                                <div className="col">
                                                    <Input
                                                        id="name"
                                                        name="name"
                                                        onChange={(e: any) => {
                                                            formik.handleChange(e);
                                                            formik.submitForm();
                                                        }}
                                                        type="text"
                                                        label="Name"
                                                        inputClasses="form-control form-control-sm"
                                                        labelClasses="col-form-label text-capitalize fw-semibold"
                                                    />
                                                </div>
                                                <div className="col">
                                                    <Input
                                                        id="email"
                                                        name="email"
                                                        onChange={(e: any) => {
                                                            formik.handleChange(e);
                                                            formik.submitForm();
                                                        }}
                                                        type="text"
                                                        label="Email"
                                                        inputClasses="form-control form-control-sm"
                                                        labelClasses="col-form-label text-capitalize fw-semibold"
                                                    />
                                                </div>                                                
                                                <div className="col d-flex align-items-end">
                                                    <div
                                                        className="py-3 pb-0 d-flex flex-wrap justify-content-end d-grid gap-2 w-100">
                                                        <button type="button"
                                                             onClick={() => generatePDF()}
                                                            className="btn btn-outline-secondary btn-sm">
                                                            <span className="tf-icons bx bx-printer"></span>&nbsp; Print
                                                        </button>                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>

                <div className="row">
                    <div className="col">
                        <div className="card shadow-sm mt-4">
                            <div
                                className="d-flex justify-content-between align-items-center card-header mb-0 border-bottom p-3">
                                <div>
                                    <h6 className="m-0">Users</h6>
                                </div>
                                <div>
                                    <AccessControl type={AccessControlType.DISABLE}
                                                   allowedPermissions={[UserPermissionsConstant.AddClientUser,UserPermissionsConstant.MimicAccess]}>
                                        <button type="button"
                                            className="btn btn-primary btn-sm px-4"
                                            onClick={() => navigate({
                                                pathname: '/add-new-user/'
                                            }, { state: { action: userActionType.create } })}>
                                            Add New User
                                        </button>
                                    </AccessControl>


                                </div>
                            </div>
                            {clientUserList && clientUserList?.length > 0 && (
                                <>
                                    <div className="card-body p-3">
                                        <div className="table-responsive text-nowrap">
                                            <DataTable
                                                title=""
                                                columns={columns}
                                                data={clientUserFormattedList}
                                                defaultSortFieldId="name"
                                                pagination={true}
                                                paginationPerPage={10}
                                                paginationRowsPerPageOptions={[5, 10, 20, 50, 100]}
                                                paginationComponent={BootyPagination}
                                                selectableRows={false}
                                                customStyles={customStyles}
                                            />
                                        </div>
                                    </div>
                                </>)}
                        </div>
                    </div>
                </div>
            </>
        </AppLayout>
        );
}

const mapStateToProps = (state: any) => {
    const {user, isLoading} = state?.auth;
    const selectedClientDetails : clientType | undefined = state?.auth?.clientDetails;
    return {user, isLoading, selectedClientDetails}
}
export default connect(mapStateToProps, null)(ClientUserList);